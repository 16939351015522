import React, { useEffect, useState } from "react";
import { TitleDegreeBanner } from "./components/microProgramDetails/titleDegreeBanner";
import { ProgramBenefits } from "./components/microProgramDetails/programBenefits";
import { JoinWaitlistComp } from "./components/microProgramDetails/joinWaitlistComp";
import { Navbar } from "../landingPage/navbar/navbar";
import { Reviews } from "./components/microProgramDetails/reviews";
import { Curriculum } from "./components/microProgramDetails/curriculum";
import { MeetYourMentor } from "./components/microProgramDetails/meetYourMentor";
import { EdyodaVideo } from "./components/microProgramDetails/edyodaVideo";
import { LearningTools } from "./components/microProgramDetails/learningTools";
import { WhatToLearn } from "./components/microProgramDetails/whatToLearn";
import { ProgramPrice } from "./components/microProgramDetails/programPrice";
import { IndustryRecognizedCertificate } from "./components/microProgramDetails/industryRecognizedCertificate";
import { CertificateBlurrImg } from "./components/microProgramDetails/certificateBlurrImg";
import { OurAlumni } from "./components/microProgramDetails/ourAlumni";
import { useLocation, useNavigate } from "react-router";
import { calculateTimeLeft, courseById, courseByIdWithoutToken, inWaitListUser, joinWaitlist } from "../../services/courses";
import { formatDistanceToNow } from 'date-fns';
import { MentorsFrom } from "./components/JobPlacement/mentorsFrom";
import { FAQ } from "./components/JobPlacement/FAQ";
import { CourseJourney } from "./components/JobPlacement/courseJourney";
import Loader from "../../components/loader/loader";
import { getUserDetails } from "../../services/loginOrSignUp";
import { Helmet } from "react-helmet-async";

export const ProgramDetails = () => {
    const location = useLocation();
    const [courseDetails, setCourseDetails] = useState({});
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(courseDetails?.registrationStartTime));
    const [timerStarted, setTimerStarted] = useState(false);
    const navigate = useNavigate();
    const [isRegister, setIsRegister] = useState(false);
    const [inWaitlist, setInWaitlist] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState("");

    
 
const auth = localStorage.getItem('auth');

    const formatDate = (dateString) => {
        const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'Asia/Kolkata' };
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('en-US', options).toUpperCase();
        const [monthDay, time] = formattedDate.split(',');
        return `${monthDay} | ${time}`;
      };


      const redirectToLogin = (id) => {
        navigate(`/join-waitlist/login?id=${id}`); // Redirect to login page
      };
  

      const joinWaitingList = async (id)=>{
        const response = await joinWaitlist(id)
        if(response){
            navigate(`/join-waitlist/success?id=${id}`)
            console.log(response)
        }
    }

 // Fetch course details and start timer
 useEffect(() => {
    window.scrollTo(0, 0);
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get('id');
    const isInWaitlist = queryParams.get('waitlist');
    console.log(isInWaitlist, "waitlist")
    setInWaitlist(isInWaitlist)
    const isRegister = queryParams.get('register');
    setIsRegister(isRegister)

    const auth = localStorage.getItem('auth');

    const fetchCourseDetails = async () => {
        try {
if(auth){
    const response = await courseById(courseId);
    console.log(response?.data)
    setCourseDetails(response?.data);
} else {
            const response = await courseByIdWithoutToken(courseId);
            console.log(response?.data)
            setCourseDetails(response?.data);
}
        } catch (error) {
            console.error("Error fetching course details:", error);
        } finally {
            setIsLoading(false); // Set loading to false after the fetch is done
        }
    };

    
    if (courseId) {
        fetchCourseDetails();
    }

    // Start timer if registrationStartTime is available
    if (courseDetails?.registrationStartTime) {
        const timer = setInterval(() => {
            const timeLeft = calculateTimeLeft(courseDetails?.registrationStartTime);
            setTimeLeft(timeLeft);
            setTimerStarted(true);
        }, 1000);

        // Clean up timer on component unmount
        return () => clearInterval(timer);
    }

}, [location.search, courseDetails?.registrationStartTime]);



useEffect(()=>{
    const users = async ()=>{
        const response = await inWaitListUser();
        if(response){
            console.log(response)

        }
      
    }
    const data = async ()=>{
        const user = await getUserDetails();
        console.log(user, "tttt")
        setUserData(user)
        }
        data();
    users();
}, [courseDetails])

if (isLoading) {
    return <Loader />; // Show loader while loading
}
    // bg-gradient-to-b from-teal-950 to-black
  return (
  <>
  <Helmet>
    <title>
        Programs: {`${courseDetails?.type} - ${courseDetails?.description}`}
    </title>
  </Helmet>
  <div className="w-screen min-h-screen sm:h-auto overflow-scrollbar no-scrollbar bg-[#000000] gap-8">
    <Navbar textColor="textBlack"/>
    {courseDetails &&
    <div className="w-full h-auto">
<TitleDegreeBanner 
courseDetails={courseDetails}
formatDate={formatDate}
timeLeft={timeLeft}
timerStarted={timerStarted}
/>
</div>
}

{courseDetails?.highlights &&
<div className="w-full h-auto">
<ProgramBenefits 
courseDetails={courseDetails}
/>
</div>
}
{(courseDetails?.reviews_1?.length > 0 || courseDetails?.reviews_2?.length > 0) &&
<div className="w-full h-auto">
<Reviews 
courseDetails={courseDetails}
/>
</div>
}
{courseDetails?.modules?.length > 0 &&
<div className="w-full h-auto">
<Curriculum 
courseDetails={courseDetails}
/>
</div>
}
{courseDetails?.type === "JOB PLACEMENT" && courseDetails?.mentorsCompany?.length > 0 &&
<div className="w-full h-auto py-16">
    <MentorsFrom 
    courseDetails={courseDetails}
    />
</div>
}
{courseDetails?.mentorImages?.length > 0 && 
<div className="w-full h-auto">
<MeetYourMentor 
courseDetails={courseDetails}
/>
</div>
}

{/* <div className="w-full h-auto">
<EdyodaVideo 
courseDetails={courseDetails}
/>
</div> */}
{courseDetails?.skills?.length > 0 &&
<div className="w-full h-auto py-16">
<LearningTools 
courseDetails={courseDetails}
/>
</div>
}
{courseDetails?.learningPoints?.length > 0 &&
<div className="w-full h-auto">
<WhatToLearn 
courseDetails={courseDetails}
/>
</div>
}
{courseDetails &&
<div className="w-full h-auto">
<ProgramPrice 
auth={auth}
timeLeft={timeLeft}
courseDetails={courseDetails}
redirectToLogin={redirectToLogin}
isRegister={isRegister}
inWaitlist={inWaitlist}
joinWaitingList={joinWaitingList}
userData={userData}
/>
</div>
}
{courseDetails?.certificationPoints?.length > 0 &&
<div className="w-full h-auto">
<IndustryRecognizedCertificate 
courseDetails={courseDetails}
/>
</div>
}
{courseDetails?.certificateImage &&
<div className="w-full h-auto">
<CertificateBlurrImg  
courseDetails={courseDetails}
/>
</div>
}
{courseDetails?.type === "JOB PLACEMENT" &&
<div className="w-full h-auto">
<CourseJourney />
</div>
}
{courseDetails?.alumini &&
<div className="w-full h-auto py-16">
<OurAlumni 
courseDetails={courseDetails}
/>
</div>
}
{courseDetails?.type === "JOB PLACEMENT" &&
<div className="w-full h-full">
    <FAQ /> 
</div>
}
<div className="sticky bottom-0" style={{zIndex:1}}>
<JoinWaitlistComp 
courseDetails={courseDetails}
redirectToLogin={redirectToLogin}
isRegister={isRegister}
inWaitlist={inWaitlist}
joinWaitingList={joinWaitingList}
userData={userData}
/>
</div>
  </div>
  </>
  )
};
