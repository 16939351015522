import React from "react";

export const FeaturedLogos = () => {
  return <div className="w-full h-auto bg-white px-4 sm:px-8 lg:px-16 lg:px-[124px]">
  <div className="w-full h-48 flex flex-col justify-center gap-8 items-center bg-neutral-50 border-2 border-zinc-400/5">
  <div className="w-full justify-center items-center inline-flex">
      <div className="text-center text-slate-700 text-base font-black font-satoshi uppercase">Featured on</div>
  </div>
  <div className="w-full justify-center items-center gap-[54px] inline-flex overflow-scroll no-scrollbar">
          <img className="w-[70.88px] h-[74.97px] " src="/featuredLogos/Group 8.svg" />
          <img className="w-[70.88px] h-[74.97px] " src="/featuredLogos/Group 9.svg" />
          <img className="w-[70.88px] h-[74.97px] " src="/featuredLogos/Group 10.svg" />
          <img className="w-[70.88px] h-[74.97px] " src="/featuredLogos/Group 14.svg" />
          <img className="w-[70.88px] h-[74.97px] " src="/featuredLogos/Group 15.svg" />
          <img className="w-[70.88px] h-[74.97px] " src="/featuredLogos/Group 16.svg" />
          <img className="w-[70.88px] h-[74.97px] " src="/featuredLogos/Group 20.svg" />
          <img className="w-[70.88px] h-[74.97px] " src="/featuredLogos/Group 21.svg" />
          <img className="w-[70.88px] h-[74.97px] " src="/featuredLogos/Group 22.svg" />
          <img className="w-[70.88px] h-[74.97px] " src="/featuredLogos/Group 23.svg" />
          <img className="w-[70.88px] h-[74.97px] " src="/featuredLogos/Group 25.svg" />


      </div>
      
  </div>
  </div> 

};
