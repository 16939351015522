import React, { useEffect, useState } from "react";
import InfoBox from "../../../components/input_fields/infoBox";
import InputFieldComp from "../../../components/input_fields/inputFieldComp";
import TextInput from "../../../components/input_fields/textInput";
import SearchComponent from "../../sign_up_flow/utils/tagSelect";
import BorderButton from "../../../components/buttons/borderButton";
import {
  getUserDetails,
  updateAppPreference,
  updateAppPreferenceSkills,
} from "../../../services/settingsflow";
import FillButton from "../../../components/buttons/fillButton";

function AppPreferences({
  form,
  onJobWorkplaceSelect,
  onJobTypeSelect,
  setForm,
}) {
  const [toggle, setToggle] = useState(false);
  const [missingField, setMissingField] = useState({});

  const handleSaveChanges = async () => {
    setToggle(!toggle);
    const missing = {};
    const missingSkills = {};

    if (!form.location || form.location.length === 0)
      missing["location"] = true;
    if (!form?.JobType) missing["jobType"] = true;
    if (!form.workplaceType) missing["workplaceType"] = true;
    if (!form.designations) missing["designations"] = true;

    setMissingField(missing);
    if (Object.keys(missing).length === 0) {
      const response = await updateAppPreference(
        form?.location,
        form?.JobType,
        form?.workplaceType,
        form?.designations
      );
      if (response?.data?.message == "Preference updated") {
        // setSaveChanges(true);
        // handleChangesMessage();
      }
    }

    if (!form.skills || form.skills.length === 0)
      missingSkills["skills"] = true;

    setMissingField(missingSkills);

    if (Object.keys(missingSkills).length === 0) {
      const updateSkills = await updateAppPreferenceSkills(form.skills);
      if (updateSkills?.data?.message == "Skills updated") {
        // setSaveChanges(true);
        // handleChangesMessage();
      }
    }
  };
  return (
    <div className="w-full h-auto flex-col justify-start items-start inline-flex">
      {" "}
      <div className="self-stretch h-auto px-8 pt-6 pb-8 bg-white flex-col justify-start items-end gap-6 flex">
        <div className="self-stretch h-[58px] flex-col justify-start items-start gap-1 flex">
          <div className="w-full h-7 text-gray-800 text-lg font-semibold font-satoshi leading-[28.80px]">
            Basic Information
          </div>
          <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
            Your recommendations will be tuned as per your preferences here
          </div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>

        <>
          <div className="w-full flex-col xl:flex-row self-stretch justify-start items-start gap-4 xl:gap-[117px] inline-flex">
            <div className="flex-col justify-start items-start gap-[5px] inline-flex">
              <div className="text-teal-950 text-base font-bold font-satoshi leading-relaxed">
                Job Title
              </div>
              <div className="w-[259px] text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                Select your desired job recommendations
              </div>
            </div>

            <div className="w-full ">
              <SearchComponent
                form={form}
                type="jobprofilePref"
                placeholder="Select the job title"
                className="w-72 md:w-[540px] "
                setForm={setForm}
              />
            </div>
          </div>
          <div className="w-full flex-col xl:flex-row self-stretch justify-start items-start gap-4 xl:gap-[117px] inline-flex">
            <div className="flex-col justify-start items-start gap-[5px] inline-flex">
              <div className="text-teal-950 text-base font-bold font-satoshi leading-relaxed">
                Enter your Skills
              </div>
              <div className="w-[259px] text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                Enter at least 3 skills to get better recommendations
              </div>
            </div>
            <div className="w-full">
              <SearchComponent
                form={form}
                type="skill"
                className="w-72 md:w-[540px] "
                setForm={setForm}
                placeholder="Enter Skills"
              />
            </div>
          </div>
        </>

        <div className="self-stretch h-[0px] border border-slate-200"></div>
        <div className="self-stretch w-full flex-col lg:flex-row justify-start items-start gap-4 xl:gap-[117px] inline-flex">
          <div className="flex-col justify-start items-start gap-[5px] inline-flex">
            <div className="text-teal-950 text-base font-bold font-satoshi leading-relaxed">
              Types of Opportunity
            </div>
            <div className="w-[259px] text-slate-700 text-base font-normal font-satoshi leading-relaxed">
              {" "}
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-6 inline-flex md:mr-[230px]">
            <div className="w-full lg:w-[535px] justify-between items-start gap-2 inline-flex">
              <div style={{ width: "50%" }}>
                <BorderButton
                  onClick={() => onJobTypeSelect("job")}
                  className="w-full"
                  textClass="w-full flex justify-start"
                  color={form?.JobType?.includes("job") ? "green" : "gray"}
                  textColor={form?.JobType?.includes("job") ? "green" : "gray"}
                  leftIcon={
                    form?.JobType?.includes("job") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                          fill="#16C083"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                          fill="#CCD2D6"
                        />
                      </svg>
                    )
                  }
                  text={"Jobs"}
                />
              </div>

              <div style={{ width: "50%" }}>
                <BorderButton
                  onClick={() => onJobTypeSelect("internship")}
                  className="w-full"
                  color={
                    form?.JobType?.includes("internship") ? "green" : "gray"
                  }
                  textColor={
                    form?.JobType?.includes("internship") ? "green" : "gray"
                  }
                  textClass="w-full flex justify-start"
                  leftIcon={
                    form?.JobType?.includes("internship") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                          fill="#16C083"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                          fill="#CCD2D6"
                        />
                      </svg>
                    )
                  }
                  text={"Internship"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch h-[0px] border border-slate-200"></div>
        <div className="self-stretch w-full flex-col lg:flex-row justify-start items-start gap-4 xl:gap-[117px] inline-flex">
          <div className="flex-col justify-start items-start gap-[5px] inline-flex">
            <div className="text-teal-950 text-base font-bold font-satoshi leading-relaxed">
              Preferred Workplace
            </div>
            <div className="w-[259px] text-slate-700 text-base font-normal font-satoshi leading-relaxed">
              {" "}
            </div>
          </div>

          <div className="flex-col justify-start items-start gap-4 inline-flex">
            <div className="w-full flex-col lg:flex-row justify-start items-start gap-2 inline-flex">
              <div style={{ width: "50%" }}>
                <BorderButton
                  onClick={() => onJobWorkplaceSelect("remote")}
                  color={
                    form?.workplaceType?.includes("remote") ? "green" : "gray"
                  }
                  textColor={
                    form?.workplaceType?.includes("remote") ? "green" : "gray"
                  }
                  leftIcon={
                    form?.workplaceType?.includes("remote") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                          fill="#16C083"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                          fill="#CCD2D6"
                        />
                      </svg>
                    )
                  }
                  text={"Remote"}
                  className="w-full"
                  textClass="w-full flex justify-start"
                />
              </div>
              <div style={{ width: "50%" }}>
                <BorderButton
                  onClick={() => onJobWorkplaceSelect("hybrid")}
                  color={
                    form?.workplaceType?.includes("hybrid") ? "green" : "gray"
                  }
                  textColor={
                    form?.workplaceType?.includes("hybrid") ? "green" : "gray"
                  }
                  leftIcon={
                    form?.workplaceType?.includes("hybrid") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                          fill="#16C083"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                          fill="#CCD2D6"
                        />
                      </svg>
                    )
                  }
                  text={"Hybrid"}
                  className="w-full"
                  textClass="w-full flex justify-start"
                />
              </div>
            </div>
            <div className="w-[540px] justify-start items-start gap-2 inline-flex">
              <div style={{ width: "50%" }}>
                <BorderButton
                  onClick={() => onJobWorkplaceSelect("office")}
                  color={
                    form?.workplaceType?.includes("office") ? "green" : "gray"
                  }
                  textColor={
                    form?.workplaceType?.includes("office") ? "green" : "gray"
                  }
                  leftIcon={
                    form?.workplaceType?.includes("office") ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                          fill="#16C083"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                          fill="#CCD2D6"
                        />
                      </svg>
                    )
                  }
                  text={"In-Office"}
                  className="w-full"
                  textClass="w-full flex justify-start"
                />
              </div>
            </div>
          </div>
        </div>

        {(form?.workplaceType?.includes("office") ||
          form?.workplaceType?.includes("hybrid")) && (
          <>
            <div className="self-stretch h-[0px] border border-neutral-300"></div>
            <div className="w-full flex-col lg:flex-row self-stretch justify-start items-start gap-4 xl:gap-[117px] inline-flex">
              <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                <div className="text-teal-950 text-base font-bold font-satoshi leading-relaxed">
                  Enter your Location
                </div>
                <div className="w-[259px] text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                  Enter at least 3 Location to get better recommendations
                </div>
              </div>
              <div className="w-full">
                <SearchComponent
                  form={form}
                  type="location"
                  className="w-72 md:w-[540px] "
                  setForm={setForm}
                  placeholder="Enter Location"
                />
              </div>
            </div>
          </>
        )}

        <div className="self-stretch h-[0px] border"></div>
        {/* {saveChanges && (
          <span className="text-green-550 text-md">Changes Saved!</span>
        )} */}
        <div className="w-full  justify-center items-center flex">
          <FillButton
            className={"w-full  font-satoshi md:w-[200px] my-10 text-center"}
            onClick={handleSaveChanges}
            text={"Save Changes"}
            color={"green"}
            textColor={"white"}
          />
        </div>
      </div>
    </div>
  );
}

export default AppPreferences;
