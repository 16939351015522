import React from "react";

export const LearningTools = ({courseDetails}) => {
  return <div className="w-full h-auto flex justify-center">
    <div className="w-full h-auto flex-col justify-center items-center gap-8 inline-flex">
    <div className="w-full flex-col justify-start items-center gap-4 flex">
        <div className="w-full h-[53px] text-center text-gray-100 text-2xl font-bold font-satoshi">Tools you will learn</div>
    </div>
    <div className="w-full justify-center items-start gap-4 inline-flex">
        <div className="w-full py-4 flex-col justify-start items-start gap-2.5 inline-flex">
        
                <div className="w-full justify-center items-center gap-10 flex overflow-scroll no-scrollbar">
                    {courseDetails?.skills?.map((skill, index)=>(
                    <div key={index} className="flex-col justify-start items-center gap-2 inline-flex">
                            <img width="64.15px" height="h-[70px]" src={skill?.image} />
          
                        <div className="self-stretch text-center text-gray-400 text-xs font-medium font-satoshi leading-3">{skill?.title} </div>
                    </div>
                    ))}
                </div>
           
        </div>
    </div>
</div>
  </div>;
};
