import React from "react";
import TextInput from "./textInput";
import InfoBox from "./infoBox";

const InputFieldComp = ({
  addInfo,
  htmlFor,
  name,
  type,
  placeholder,
  errorMsg,
  labelText,
  inforText,
  inputLeftText,
  inputRightText,
  leftIcon,
  rightIcon,
  variant,
  infoText,
  infoLeftIcon,
  buttonText,
  buttonLeftIcon,
  hasError,
  className,
  textInputClass,
  buttonClass,
  autoComplete,
  placeClass,
  disabled,
  maxLength,
  ...props
}) => {
  return (
    <div
      className={`w-full h-auto flex py-spaceBase flex-col gap-spaceXxxSmall ${className}`}
    >
      <div className={`${textInputClass}`}>
        <TextInput
          {...props}
          textType="input"
          type={type}
          htmlFor={htmlFor}
          name={name}
          placeholder={placeholder}
          textColor="gray"
          labelText={labelText}
          inforText={inforText}
          leftText={inputLeftText}
          rightText={inputRightText}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          hasError={hasError}
          buttonClass={buttonClass}
          autoComplete={autoComplete}
          placeClass={placeClass}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>

      {variant && (
        <div>
          <InfoBox
            {...props}
            textType="info"
            variant={variant}
            infoText={infoText}
            leftIcon={infoLeftIcon}
            buttonText={buttonText}
            buttonLeftIcon={buttonLeftIcon}
          />
        </div>
      )}
      {addInfo && (
        <div className="w-full text-gray-400 text-xs font-medium font-['Satoshi Variable'] leading-[18px]">
          {addInfo}
        </div>
      )}
      {errorMsg && (
        <div className="w-full h-[18px] text-red-500 text-xs font-medium font-['Satoshi Variable'] leading-[18px]">
          {errorMsg}
        </div>
      )}
    </div>
  );
};

export default InputFieldComp;
