import React, { useRef, useState } from "react";

const TextInput = ({
  inforText,
  labelText,
  placeClass,
  type,
  placeholder,
  buttonClass,
  infoText,
  leftText,
  rightText,
  leftIcon,
  rightIcon,
  className,
  disabled,
  isLoading,
  textColor,
  leftIconClass,
  rightIconClass,
  rightTextClass,
  infoTextClass,
  leftTextClass,
  hasError,
  verified,
  style,
  textType,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  htmlFor,
  addInfo,
  errorMsg,
  autoComplete,
  onKeyDown,
  maxLength,
}) => {
  const [isPressed, setIsPressed] = useState(false);

  // const [value, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [otpInputValues, setOtpInputValues] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const otpHandleInputchange = (e, index) => {
    const newValue = e.target.value;
    setOtpInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = newValue;
      return updatedValues;
    });

    if (newValue !== "" && index < 5 && inputRefs[index + 1]) {
      // Move focus to the next input field if it exists
      inputRefs[index + 1].current.focus();
    }
    setIsTyping(true);
  };

  const handleInputClick = () => {
    if (type === "otp") {
      // For OTP input fields
      if (inputRefs[0] && inputRefs[0].current) {
        const currentInput = inputRefs.find((ref, index) => {
          if (ref.current && otpInputValues[index] === "") {
            ref.current.focus();
            ref.current.select();
            return true;
          }
          return false;
        });

        if (!currentInput) {
          // If all inputs are filled, focus on the last one
          const lastIndex = inputRefs.length - 1;
          inputRefs[lastIndex].current.focus();
          inputRefs[lastIndex].current.select();
        }
      }
    } else if (type === "input") {
      // For regular input fields, just set focus and select all text
      if (value[0] && inputRefs[0].current) {
        value[0].current.focus();
        value[0].current.select();
      }
    }
  };

  const handleBackspace = (index) => {
    if (index > 0 && inputRefs[index - 1]) {
      // Move focus to the previous input field
      inputRefs[index - 1].current.focus();

      // Clear the value of the current input field
      setOtpInputValues((prevValues) => {
        const updatedValues = [...prevValues];
        updatedValues[index] = "";
        return updatedValues;
      });
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0) {
      e.preventDefault(); // Prevent the browser's default Backspace behavior
      handleBackspace(index);
    }
  };

  const colorMap = {
    gray: {
      default: `text-gray-500 border-gray-100 active:border-gray-200 active:shadow-[rgba(210,215,211,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset] focus:border-gray-200 focus:shadow-[rgba(210,215,211,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]`,
      hover: "hover:",
      active: "active:text-gray-550",
      pressed: "text-gray-800",
      disabled: "cursor-not-allowed text-gray-500 bg-gray-50",
      loading: "text-gray-400 ",
    },
    white: {
      default: "text-white",
      pressed: "text-white",
    },
  };

  const textColorClass = isPressed
    ? colorMap[textColor].pressed
    : disabled
    ? `${colorMap[textColor].disabled}`
    : isLoading
    ? `${colorMap[textColor].loading} ${colorMap[textColor].disabled}`
    : hasError
    ? "border-red-500 active:shadow-[rgba(255,0,0,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset] focus:shadow-[rgba(255,0,0,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]" // Add a red border on error
    : ` ${colorMap[textColor].default} ${colorMap[textColor].hover} ${colorMap[textColor].active}`;

  const buttonClasses = ` ${
    disabled
      ? `${colorMap[textColor].disabled} cursor-not-allowed `
      : isLoading
      ? `${colorMap[textColor].loading} ${colorMap[textColor].disabled}`
      : hasError
      ? "border-red-500 active:shadow-[rgba(255,0,0,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset] focus:shadow-[rgba(255,0,0,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]"
      : `${colorMap[textColor].default} ${colorMap[textColor].hover}`
  } ${className}`;

  const borderColorClass = verified
    ? "border-green-500"
    : hasError
    ? "border-red-500"
    : "";

  return (
    <>
      {(labelText || inforText) && (
        <div className="w-full inline-flex justify-between text-gray-600 text-sm font-medium font-satoshi leading-tight mb-spaceXxxSmall">
          <label htmlFor={htmlFor}>{labelText}</label>
          {textType === "info" ||
            (textType === "input" && inforText && <div>{inforText}</div>)}
        </div>
      )}

      <div
        className={`w-full ${
          textType === "textarea" || textType === "info" ? "h-auto" : "h-11"
        } px-3 py-2 rounded-xl ${
          textType === "info" ? "" : "border-2"
        } justify-start items-center gap-1 inline-flex ${buttonClasses} ${textColorClass} ${buttonClass} ${borderColorClass}
          ${
            isTyping || isFocused
              ? "border-gray-200 shadow-[rgba(210,215,211,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]"
              : ""
          }`}
        disabled={isLoading || disabled}
        onClick={handleInputClick}
      >
        {leftIcon && (
          <div className={`p-1 justify-start items-center inline-flex`}>
            <div className={`w-auto h-auto ${leftIconClass}`}>{leftIcon}</div>
          </div>
        )}
        {leftText && (
          <div className={`w-auto p-1 justify-start items-center inline-flex`}>
            <div
              className={`w-auto text-sm font-medium font-satoshi leading-none flex ${leftTextClass}`}
            >
              {leftText}
            </div>
          </div>
        )}
        {textType && (
          <div className="flex flex-wrap w-full">
            {textType === "textarea" ? (
              <div className="textarea_wrapper w-full">
                <textarea
                  style={style}
                  className={` overflow-hidden min-h-[80px] custom-input w-full flex justify-start grow shrink basis-0 text-teal-950 text-sm font-medium font-satoshi leading-tight ${
                    isTyping || value === 0 || hasError
                      ? "bg-white text-gray-900 border-red-500"
                      : disabled
                      ? "cursor-not-allowed bg-gray-50"
                      : "text-gray-900"
                  } ${placeClass}`}
                  type="text"
                  contentEditable="true"
                  value={value}
                  onChange={onChange}
                  onBlur={() => {
                    setIsTyping(false);
                    setIsFocused(false);
                  }}
                  onFocus={() => {
                    setIsFocused(true);
                    setIsTyping(true);
                  }}
                  disabled={disabled}
                />
                <div className="expander-icon"></div>
              </div>
            ) : textType === "input" ? (
              <input
                className={` custom-input w-full h-[40px] flex justify-start grow shrink basis-0 text-gray-200 text-sm font-large font-satoshi leading-tight ${
                  isTyping || value === 0 || hasError
                    ? "bg-white text-gray-900 border-red-500 "
                    : disabled
                    ? "cursor-not-allowed bg-gray-50"
                    : "text-gray-900"
                } ${placeClass}`}
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                htmlFor={htmlFor}
                autoComplete={autoComplete}
                onChange={onChange}
                onKeyDown={onKeyDown}
                maxLength={maxLength}
                onBlur={() => {
                  setIsTyping(false);
                  setIsFocused(false);
                }}
                onFocus={() => {
                  setIsFocused(true);
                  setIsTyping(true);
                }}
                disabled={disabled}
              />
            ) : textType === "otp" ? (
              <div className="flex items-center justify-start cursor-auto">
                {otpInputValues.map((value, index) => (
                  <input
                    key={index}
                    className={`custom-input w-6 h-6 text-center text-gray-200 text-sm font-medium font-satoshi leading-tight ${
                      isTyping || value === 0 || hasError
                        ? "bg-white text-gray-900 border-red-500"
                        : disabled
                        ? "cursor-not-allowed bg-gray-50"
                        : "text-gray-900"
                    } ${placeClass}`} //For gap in otp add "gap-spaceLarge"
                    type="text"
                    placeholder={placeholder}
                    ref={inputRefs[index]}
                    value={value}
                    onChange={(e) => otpHandleInputchange(e, index)}
                    onBlur={() => {
                      setIsTyping(false);
                      setIsFocused(false);
                    }}
                    onFocus={() => {
                      setIsFocused(true);
                      setIsTyping(true);
                    }}
                    disabled={disabled}
                    verified={verified}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    maxLength="1"
                  />
                ))}
              </div>
            ) : textType === "info" ? (
              <div className="flex flex-wrap">
                {infoText && (
                  <div
                    className={`w-fit flex-wrap h-auto text-sm font-medium font-satoshi leading-tight ${infoTextClass}`}
                  >
                    {infoText}
                  </div>
                )}
              </div>
            ) : textType === "input" || textType === "info" ? (
              <div className={`w-1 h-7 relative`} />
            ) : null}
          </div>
        )}

        {rightText && (
          <div className={`p-1 justify-start items-center inline-flex`}>
            <div
              className={` cursor-auto text-sm font-medium font-satoshi leading-tight ${rightTextClass}`}
            >
              {rightText}
            </div>
          </div>
        )}
        {rightIcon && (
          <div
            className={`p-1 justify-start items-center inline-flex ${rightIconClass}`}
          >
            <div className={`w-auto h-auto flex ${rightIconClass}`}>
              {rightIcon}
            </div>
          </div>
        )}
      </div>
      {addInfo && (
        <div className="w-full text-gray-400 text-xs font-medium font-['Satoshi Variable'] leading-[18px]">
          {addInfo}
        </div>
      )}
      {errorMsg && (
        <div className="w-full h-[18px] text-red-500 text-xs font-medium font-['Satoshi Variable'] leading-[18px]">
          {errorMsg}
        </div>
      )}
    </>
  );
};

export default TextInput;
