import React from "react";

export const UserCount = () => {
  return <div className="bg-white h-auto px-4 sm:px-8 lg:px-16 py-[70px] lg:px-[124px] w-full h-[400px] justify-center items-start gap-8 inline-flex">
 <div>
    <img src="userReview.png" />
 </div>
 <div>
 <img src="recruiterCount.png" />

 </div>

 <div>
 <img src="studentCount.png" />

 </div>

</div>
};
