import React, { useEffect, useState } from "react";
import {
  courseById,
  courseByIdWithoutToken,
} from "../../../../services/courses";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export const PaymentSuccess = () => {
  const location = useLocation();
  const [courseDetails, setCourseDetails] = useState({});
  const [isRedirect, setIsRedirect] = useState(false);
  const navigate = useNavigate();
  const paymentId = sessionStorage.getItem("paymentId");
  useEffect(() => {
    // Check if the paymentId exists in sessionStorage

    if (!paymentId) {
      // If there's no paymentId, redirect to the home page or another appropriate page
      navigate("/programs");
    }

    // Function to fetch course details
    const fetchCourseDetails = async (courseId, auth) => {
      try {
        let response;
        if (auth) {
          response = await courseById(courseId);
        } else {
          response = await courseByIdWithoutToken(courseId);
        }
        console.log(response?.data);
        setCourseDetails(response?.data);
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };

    // Get the courseId and auth status
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get("id");
    const auth = localStorage.getItem("auth");

    // Fetch course details if courseId is present
    if (courseId) {
      fetchCourseDetails(courseId, auth);
    }

    // Cleanup function to clear the sessionStorage item
    return () => {
      sessionStorage.removeItem("paymentId");
    };
  }, [navigate, location]);
  // const [select, isSelect] = useState('');

  //         useEffect(() => {
  //             // Check if the session flag is set
  //             console.log("kkkkk")
  //             const isRedirected = localStorage.getItem('isRedirected');
  //             if(isRedirected){
  // setIsRedirect(true);
  // localStorage.removeItem('isRedirected');
  //             }

  //             try {
  //              const elements = document.getElementsByClassName("razorpay-container");

  //              elements[0].parentNode.removeChild(elements[0]);
  //             } catch (error) {

  //             }
  //             // If the flag is not set, redirect to another page
  //             if (!isRedirected) {
  //                 navigate("/programs");

  //             }

  //             if(sessionStorage.getItem("failureRefreshPending") == true){
  //              sessionStorage.removeItem('failureRefreshPending');
  //             //  window.location.reload();
  //             }

  //           }, []);

  return (
    <>
      <Helmet>
        <title>Payment Success</title>
      </Helmet>

      <div className="min-w-screen max-w-full min-h-screen max-h-full px-2 sx:px-4 md:px-8 lg:p-[52px] flex flex-col lg:flex-row justify-center items-center bg-gradient-to-br from-black to-[#000000] ">
        <div className="w-full lg:w-[50%] h-auto flex-col justify-center items-start gap-6 inline-flex">
          <div className="hidden md:flex w-[50px] h-[50px] absolute top-12 rounded-xl">
            <Link to="/programs">
              <img src="/svg/whiteBeepLogo.svg" />
            </Link>
          </div>
          <div className="md:hidden w-[50px] h-[50px] rounded-xl">
            <Link to="/programs">
              <img src="/svg/whiteBeepLogo.svg" />
            </Link>
          </div>

          <div className="w-full md:mt-[200px] lg:mt-0 h-auto text-white opacity-80 text-[40px] font-bold font-satoshi flex flex-wrap">
            {courseDetails?.name}
          </div>
          <div className="w-full text-start text-slate-500 text-xl font-bold font-satoshi leading-normal flex flex-wrap">
            {courseDetails?.description}
          </div>
        </div>
        <div className="w-[50%] h-auto my-8 h-auto flex justify-end">
          <div className="w-[532px] h-auto px-4 sm:px-8 md:px-[62px] py-[62px] bg-white rounded-lg flex-col justify-between items-center gap-8 inline-flex overflow-scroll no-scrollbar">
            <div
              className="w-[342px] h-[595px] relative flex flex-col pb-8 justify-end items-center"
              style={{
                backgroundImage: `url(/svg/registrationSuccessBgImg.svg)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="w-[310px] p-4 pt-8 h-auto gap-6 relative bg-gradient-to-br from-black via-teal-950 to-black rounded-[20px]">
                <div className="w-full  justify-center items-center inline-flex">
                  <div className="self-stretch flex-col justify-center items-center gap-8 inline-flex">
                    <div className="h-8 flex-col justify-start items-center gap-2 flex">
                      <div className="text-amber-300 text-2xl font-black font-satoshi">
                        {courseDetails?.type}
                      </div>
                    </div>
                    <div className="h-[131px] flex-col justify-center items-center gap-4 flex">
                      <div className="w-[199px] text-center text-neutral-50 text-xl font-bold font-satoshi tracking-wide">
                        {courseDetails?.name}
                      </div>
                      <div className="h-[15px] flex-col justify-center items-center gap-1 flex">
                        <div className="text-gray-300 text-sm font-bold font-satoshi leading-[15px]">
                          Transaction ID -{paymentId}
                        </div>
                      </div>
                      <div />
                    </div>
                  </div>
                </div>
                <div className="w-full text-center text-gray-400 text-base font-bold font-satoshi">
                  Our team will reach out to you with more details about the
                  program
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
