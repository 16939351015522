import React, { useEffect, useState, useRef } from "react";
import { DashLeftSection } from "../dashboard/LoggedInUsers/leftSection";
import { getUserDetails } from "../../services/loginOrSignUp";
import { ApplyPageOptions } from "./applyPageOptions";
import { ScreensHeading } from "../dashboard/LoggedInUsers/rightSection/screens/heading";
import { ScreeningQuestion } from "./JobScreeningQuestions";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";


export const ApplyJob = () => {
  const [userData, setUserData] = useState("");
  const [appliedDate, setAppliedDate] = useState("");

  const [menuOpen, setMenuOpen] = useState(false); // State to track menu open/close
  const menuRef = useRef(null);

  const [id, setId] = useState("");
  const [jobId, setJobId] = useState("");
  const [showReportBox, setShowReportBox] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const response = await getUserDetails();
      setUserData(response);
    };
    getData();
  }, []);

  const openPlacementReport = () => {
    setShowReportBox(true);

  };
  const closePlacementReport = () => {
    setShowReportBox(false);
  };
  const [activeSection, setActiveSection] = useState("findjobs");
    // Close the menu when clicking outside of it

   
    const appliedDateTime = new Date(appliedDate).getTime();
    const currentTime = new Date().getTime();
    const daysDifference = Math.floor((currentTime - appliedDateTime) / (1000 * 60 * 60 * 24));
const millisecondsDifference = currentTime - appliedDateTime;
const hoursDifference = Math.floor(millisecondsDifference / (1000 * 60 * 60));
const millisecondsInDay = 1000 * 60 * 60 * 24;
const remainingMilliseconds = millisecondsInDay * 7 - millisecondsDifference;
const remainingDays = Math.floor(remainingMilliseconds / millisecondsInDay);
const remainingHours = Math.floor((remainingMilliseconds % millisecondsInDay) / (1000 * 60 * 60));
const remainingMinutes = Math.floor((remainingMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
const remainingSeconds = Math.floor((remainingMilliseconds % (1000 * 60)) / 1000);

    

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setMenuOpen(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [menuRef]);

    const navigate = useNavigate();
    useEffect(()=>{
      const auth = localStorage.getItem("auth");
      if(!auth)
      navigate("/login-or-signup")
    },[])
  
    const [Loading, setLoading] = useState(true);
    useEffect(() => {
      // Simulating an asynchronous action
      const fakeAsyncAction = async () => {
        try {
          // Simulate an API call or any asynchronous action
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } finally {
          // Set isLoading to false once the asynchronous action is done
          setLoading(false);
        }
      };
  
      // Call the fakeAsyncAction when the component mounts
      fakeAsyncAction();
    }, []); // Empty dependency array means this effect runs once on mount
  
    // Render the loader if isLoading is true
    if (Loading) {
      return <Loader />;
    }

  return (
    <>
    <div className="w-screen h-auto inline-flex">
      
      <div
        className={`min-w-[20%] lg:max-w-[25%] ${
          menuOpen ? "w-full sm:min-w-[20%]" : "hidden sm:flex"
        } transition-all duration-300`}
        style={{
          position: menuOpen ? "absolute" : "static",
          left: menuOpen ? 0 : "-100%",
          zIndex: menuOpen ? (window.innerWidth < 768 ? 5 : 999) : "auto",
        }}
        ref={menuRef}
      >
        <DashLeftSection
          setActiveSection={setActiveSection}
          activeSection={activeSection}
          userData={userData}
          setMenuOpen={setMenuOpen}
          menuOpen={menuOpen}
        />
      </div>
      <div className="w-full h-auto overflow-hidden" style={{ marginLeft: menuOpen ? "20%" : 0 }}>
        
          <div className="overflow-y-auto h-screen">
            <ScreensHeading />
            <ApplyPageOptions />
            <ScreeningQuestion />
          </div>
        </div>
      
    </div>
   
    </>
  );
};
