import React from "react";
import TextInput from "./textInput";
import ButtonChild from "../buttons/buttonChild";

const InfoBox = ({
  leftIcon,
  errorMsg,
  rightIconClass,
  buttonClass,
  infoTextClass,
  infoClass,
  labelText,
  infoText,
  rightIcon,
  text,
  buttonText,
  buttonLeftIcon,
  buttonTextColor,
  variant,
  className,
  ...props
}) => {
  let bgColorClass;

  // Determine the background color class based on the "variant" prop
  switch (variant) {
    case "standard":
      bgColorClass = "bg-gray-50 ";
      break;
    case "highlight":
      bgColorClass = "bg-green-50";
      break;
    case "danger":
      bgColorClass = "bg-red-50";
      break;
    case "warning":
      bgColorClass = "bg-orange-50 ";
      break;
    default:
      bgColorClass = ""; // Default to no background color
      break;
  }

  return (
    <div className={`w-full h-auto rounded-xl  ${className}`}>
      <TextInput
        labelText={labelText}
        buttonClass={` ${buttonClass}`}
        className={`${bgColorClass} hover:shadow-none active:shadow-none h-auto  ${infoClass} `}
        variant={variant}
        infoTextClass={infoTextClass}
        textType="info"
        leftIcon={leftIcon}
        rightIconClass={`py-0 ${rightIconClass}`}
        rightIcon={
          <ButtonChild
            text={buttonText}
            textColor={variant === "danger" ? "red" : "green"}
            leftIcon={buttonLeftIcon}
          />
        }
        infoText={infoText}
        textColor="gray"
        errorMsg={errorMsg}
        {...props}
      />
    </div>
  );
};

export default InfoBox;
