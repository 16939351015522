import React, { useEffect, useState } from "react";
import EditButtonWithPenIcon from "./compnanets/editButton";
import TextInput from "../../components/input_fields/textInput";
import FillButton from "../../components/buttons/fillButton";
import InfoBox from "../../components/input_fields/infoBox";
import { extractDigits, isValidEmail, isValidIndianMobileNumber } from "../../services/utils";
import { sendOTP } from "../../services/loginOrSignUp";
import axios from "axios";

const ContactComponent = ({contactDetails}) => {
    
  const [isEditOn, setIsEditOn] = useState(false);
  // const [phoneNo, setPhoneNo] = useState((contactDetails.phoneNo).replace("+91", ""));
  const initialPhoneNo = typeof contactDetails.phoneNo === 'string' ? contactDetails.phoneNo.replace("+91", "") : "";
const [phoneNo, setPhoneNo] = useState(initialPhoneNo);

  const [isPhoneVerified, setIsPhoneVerified] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isPhoneOTPSent, setIsPhoneOTPSent] = useState(false);
  const [isEmailOTPSent, setIsEmailOTPSent] = useState(false);
  const [email, setEmail] = useState(contactDetails.email);
  const [inValidEmail, setInvalidEmail]  = useState(false);
  const [inValidPhone, setInvalidPhone]  = useState("");

  const [phoneOTP, setPhoneOTP] = useState("");
  const [emailOTP, setEmailOTP] = useState("");

  const [emailOTPError, setEmailOTPError] = useState("");
  const [phoneOTPError, setPhoneOTPError] = useState("");

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  

  useEffect(()=>{
    // setPhoneNo((contactDetails.phoneNo).replace("+91", ""));
    // Check for undefined or null

// Type check for string
const updatedPhoneNo = typeof contactDetails.phoneNo === 'string' ? contactDetails.phoneNo.replace("+91", "") : "";

setPhoneNo(updatedPhoneNo);

  setIsPhoneVerified(contactDetails.isPhoneVerified===false?false:true);
  setIsEmailVerified(contactDetails.isEmailVerified===false?false:true);
  setEmail(contactDetails.email);
  },[contactDetails])

  const onVerifyEmail =async()=>{
    if(!isValidEmail(email)){
        setInvalidEmail(true)
        return;
    }else setInvalidEmail(false);


    const url = `${process.env.REACT_APP_baseURL}/user/change/email`;

    const response = await axios
      .post(url, {email:email}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      }).then(()=>{
        setIsEmailOTPSent(true)
        setEmailError("")
        setInvalidEmail(false)
      })
      .catch((error) => {
        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
        setEmailError(error.response?.data.message)
      });

    
  }



  const onVerifyPhone =async()=>{
    if(!isValidIndianMobileNumber(phoneNo)){
        setInvalidPhone(true)
        return;
    }else setInvalidPhone(false);


    const url = `${process.env.REACT_APP_baseURL}/user/change/phone`;

    const response = await axios
      .post(url, {phoneNumber:phoneNo}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      }).then(()=>{
        setIsPhoneOTPSent(true)
        setPhoneError("")
        setInvalidPhone(false)
      })
      .catch((error) => {
        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
        setPhoneError(error.response?.data.message)
      });
    
  }

  const verifyEmailOTP =async()=>{

    const url = `${process.env.REACT_APP_baseURL}/user/verify/email`;

    const response = await axios
      .post(url, {OTP:emailOTP}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      }).then(()=>{
        setIsEmailVerified(true);
      })
      .catch((error) => {
        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
          setEmailOTPError(error.response?.data.message)
      });

  }

  const verifyPhoneOTP =async()=>{

    const url = `${process.env.REACT_APP_baseURL}/user/verify/phone`;

    const response = await axios
      .post(url, {OTP:phoneOTP}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      }).then(()=>{
        setIsPhoneVerified(true);
      })
      .catch((error) => {
        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
          setPhoneOTPError(error.response?.data.message)
      });

  }

  return (
    <div className="w-full p-6 bg-white border border-neutral-300 flex-col justify-start items-start gap-4 inline-flex">
      <div className="w-full justify-between items-center inline-flex">
        <div className="text-teal-950 text-xl font-bold   leading-normal">
          Additional Details
        </div>
        <div className="p-2.5 rounded-[10px] justify-center items-center gap-2.5 flex">
          
            {!isEditOn && (
              <EditButtonWithPenIcon onClick={() => setIsEditOn(true)} />
            )}
          
        </div>
      </div>

      {/* Email */}
      <div className="flex flex-col w-full gap-2">
          
      <div className=" w-full justify-start items-start gap-4 inline-flex hari">
        <div className="w-6 h-6 relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <g clip-path="url(#a)">
              <path
                stroke="#667984"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 5H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2Z"
              />
              <path
                stroke="#667984"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m3 7 9 6 9-6"
              />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
              </clipPath>
            </defs>
          </svg>
        </div>
        {/* Email */}
        <div className="w-full flex flex-col gap-2">
          <div className="w-full flex-col justify-start items-start inline-flex">
            <div className="text-slate-500 text-base font-normal   leading-relaxed">
              Email
            </div>

            {!isEditOn  && (
              <div className="text-teal-950 text-base font-normal   leading-relaxed">
                {email? email: "Add Email"}
              </div>
            )}
          </div>
        </div>
      </div>

        {isEditOn &&  (
              <TextInput
                textType="input"
                textColor="gray"
                type="text"
                //   labelText="Name"

                value={email}
                disabled={isEmailOTPSent}
                onChange={(e)=>{
                    setEmail(e.target.value)
                    setIsEmailVerified(false)
                }}
                hasError={inValidEmail || emailError!=""}
                errorMsg={emailError}
                placeholder="Url"
              />
            )}

      { (email && !isEmailVerified && !isEmailOTPSent) && <div className="w-full h-11 px-3 py-2 bg-rose-100 rounded-xl justify-start items-center gap-1 inline-flex">
            <div className="p-1 justify-start items-center flex">
              <div className="w-5 h-5 relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#DB1717"
                    d="M16.25 3.125H3.75a1.25 1.25 0 0 0-1.25 1.25v4.591c0 7.002 5.923 9.324 7.11 9.718.253.086.527.086.78 0 1.188-.395 7.11-2.717 7.11-9.718V4.375a1.25 1.25 0 0 0-1.25-1.25ZM9.375 7.5a.625.625 0 0 1 1.25 0v3.125a.624.624 0 1 1-1.25 0V7.5ZM10 14.375a.938.938 0 1 1 0-1.875.938.938 0 0 1 0 1.875Z"
                  />
                </svg>
              </div>
            </div>
            <div className="grow shrink basis-0 text-slate-500 text-sm font-medium leading-tight">
              This Email is not Verified.
            </div>

            <div className="p-1 rounded-xl justify-center items-center gap-1 flex cursor-pointer">
            <div className="text-center text-red-600 text-sm font-black leading-tight" onClick={onVerifyEmail}>
                Verify
              </div>
            </div>
          </div>}

          { (!isEmailVerified && isEmailOTPSent) &&<>
          <div className=" h-11 px-3 py-2 bg-orange-50 rounded-xl justify-start items-center gap-1 inline-flex">
            <div className="p-1 justify-start items-center flex">
              <div className="w-5 h-5 relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#FFB941"
                    d="M16.875 3.75H3.125A1.25 1.25 0 0 0 1.875 5v12.5a1.237 1.237 0 0 0 .723 1.133 1.241 1.241 0 0 0 1.338-.187l2.51-2.196h10.429a1.25 1.25 0 0 0 1.25-1.25V5a1.25 1.25 0 0 0-1.25-1.25ZM12.5 11.875h-5a.625.625 0 1 1 0-1.25h5a.625.625 0 1 1 0 1.25Zm0-2.5h-5a.625.625 0 0 1 0-1.25h5a.625.625 0 1 1 0 1.25Z"
                  />
                </svg>
              </div>
            </div>
            <div className="grow shrink basis-0 text-slate-500 text-sm font-medium leading-tight">
              An OTP has been sent to this email.
            </div>
            <div className="w-1 h-7 relative" />
          </div>

          <div className="w-full flex flex-col gap-[22px]">
            <div className="w-full">
              <TextInput
                placeClass={"tracking-[15px]"}
                labelText="Enter OTP"
                textColor="gray"
                textType="input"
                type="text"
                htmlFor="emailOTP"
                name="otp"
                placeholder="000000"
                  hasError={emailOTPError!=""}
                  errorMsg={emailOTPError}
                  value={emailOTP}
                  onChange={(e)=>setEmailOTP(extractDigits(e.target.value))}
                maxLength={6}
              />
            </div>
            <div className="w-full">
              <FillButton
                  onClick={verifyEmailOTP}
                className={``}
                color="green"
                textColor="white"
                text="Verify"
                disabled={emailOTP.length!=6}
              />
            </div>
          </div>
          </>}

      </div>
      

      {/* phone  */}
      <div className="flex flex-col gap-2 w-full">

      <div className="w-full justify-start items-start gap-4 inline-flex">
        <div className="w-6 h-6 relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <g clip-path="url(#a)">
              <path
                stroke="#667984"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 4H8a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Zm-5 1h2"
              />
              <path
                stroke="#002032"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 17v.01"
              />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div className="w-full flex flex-col gap-2">
          <div className="w-full justify-start items-start gap-4 inline-flex">
            <div className="w-full flex-col justify-start items-start inline-flex">
              <div className="w-full text-slate-500 text-base font-normal   leading-relaxed">
                Phone
              </div>
              {!isEditOn && (
                <div className="text-teal-950 text-base font-normal   leading-relaxed">
                  {phoneNo? phoneNo: "Add Phone"}
                </div>
              )}
            </div>
          </div>

        </div>
      </div>

      {isEditOn && (
                <TextInput
                  textType="input"
                  textColor="gray"
                  type="text"
                    // labelText="Name"
                  leftText={"+91"}
                  value={phoneNo}
                  onChange={(e)=>{
                    setPhoneNo(e.target.value)
                    setIsPhoneVerified(false)
                }}
                hasError={inValidPhone || phoneError!=""}
                errorMsg={phoneError}
                maxLength={10}
                  placeholder="Url"
                  disabled={isPhoneOTPSent}
                />
              ) }



      {(phoneNo && !isPhoneVerified && !isPhoneOTPSent) && <div className="w-full h-11 px-3 py-2 bg-rose-100 rounded-xl justify-start items-center gap-1 inline-flex">
            <div className="p-1 justify-start items-center flex">
              <div className="w-5 h-5 relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#DB1717"
                    d="M16.25 3.125H3.75a1.25 1.25 0 0 0-1.25 1.25v4.591c0 7.002 5.923 9.324 7.11 9.718.253.086.527.086.78 0 1.188-.395 7.11-2.717 7.11-9.718V4.375a1.25 1.25 0 0 0-1.25-1.25ZM9.375 7.5a.625.625 0 0 1 1.25 0v3.125a.624.624 0 1 1-1.25 0V7.5ZM10 14.375a.938.938 0 1 1 0-1.875.938.938 0 0 1 0 1.875Z"
                  />
                </svg>
              </div>
            </div>
            <div className="grow shrink basis-0 text-slate-500 text-sm font-medium leading-tight" >
              This Phone is not Verified.
            </div>

            <div className="p-1 rounded-xl justify-center items-center gap-1 flex">
              <button className="text-center text-red-600 text-sm font-black leading-tight" onClick={onVerifyPhone}>
                Verify
              </button>
            </div>
          </div>}

          { (!isPhoneVerified && isPhoneOTPSent) &&<>
          <div className="h-11 px-3 py-2 bg-orange-50 rounded-xl justify-start items-center gap-1 inline-flex">
            <div className="p-1 justify-start items-center flex">
              <div className="w-5 h-5 relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#FFB941"
                    d="M16.875 3.75H3.125A1.25 1.25 0 0 0 1.875 5v12.5a1.237 1.237 0 0 0 .723 1.133 1.241 1.241 0 0 0 1.338-.187l2.51-2.196h10.429a1.25 1.25 0 0 0 1.25-1.25V5a1.25 1.25 0 0 0-1.25-1.25ZM12.5 11.875h-5a.625.625 0 1 1 0-1.25h5a.625.625 0 1 1 0 1.25Zm0-2.5h-5a.625.625 0 0 1 0-1.25h5a.625.625 0 1 1 0 1.25Z"
                  />
                </svg>
              </div>
            </div>
            <div className="grow shrink basis-0 text-slate-500 text-sm font-medium leading-tight">
              An OTP has been sent to this phone.
            </div>
            <div className="w-1 h-7 relative" />
          </div>

          <div className="w-full flex flex-col gap-[22px]">
            <div className="w-full">
              <TextInput
                placeClass={"tracking-[15px]"}
                labelText="Enter OTP"
                textColor="gray"
                textType="input"
                type="text"
                htmlFor="mobileOTP"
                name="otp"
                placeholder="0000"
                  hasError={phoneOTPError!=''}
                  errorMsg={phoneOTPError}
                  value={phoneOTP}
                onChange={(e)=>setPhoneOTP(extractDigits(e.target.value))}
                maxLength={4}
              />
            </div>
            <div className="w-full">
              <FillButton
                  onClick={verifyPhoneOTP}
                className={``}
                color="green"
                textColor="white"
                text="Verify"
                disabled={phoneOTP.length!=4}
              />
            </div>
          </div>
          </>}

      </div>
      

      {/* <div className="justify-start items-start gap-4 inline-flex">
        <div className="w-6 h-6 relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <g clip-path="url(#a)">
              <path
                stroke="#667984"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 7h7m-2-2v2c0 2.122-.527 4.157-1.464 5.657C7.598 14.157 6.326 15 5 15m1-4c-.002 1.032.695 2.024 1.943 2.77 1.249.745 2.953 1.186 4.757 1.23M11 19l4-9 4 9m-.9-2h-6.2"
              />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="flex-col justify-start items-start inline-flex">
          <div className="text-slate-500 text-base font-normal   leading-relaxed">
            Languages
          </div>
          <div className="text-teal-950 text-base font-normal   leading-relaxed">
            English, French
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ContactComponent;
