import React from "react";

const EditButtonWithPenIcon = ({onClick}) => {
  return (
    <button onClick={onClick} className="w-10 h-10 p-2.5 rounded-[10px] border border-neutral-300 justify-center items-center gap-2.5 inline-flex">
      <div className="w-5 h-5 relative">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Icon" clip-path="url(#clip0_6962_24688)">
            <path
              id="Vector (Stroke)"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.23223 5.73223C3.70107 5.26339 4.33696 5 5 5H7.5C7.96024 5 8.33333 5.3731 8.33333 5.83333C8.33333 6.29357 7.96024 6.66667 7.5 6.66667H5C4.77899 6.66667 4.56702 6.75446 4.41074 6.91074C4.25446 7.06702 4.16667 7.27899 4.16667 7.5V15C4.16667 15.221 4.25446 15.433 4.41074 15.5893C4.56702 15.7455 4.77899 15.8333 5 15.8333H12.5C12.721 15.8333 12.933 15.7455 13.0893 15.5893C13.2455 15.433 13.3333 15.221 13.3333 15V12.5C13.3333 12.0398 13.7064 11.6667 14.1667 11.6667C14.6269 11.6667 15 12.0398 15 12.5V15C15 15.663 14.7366 16.2989 14.2678 16.7678C13.7989 17.2366 13.163 17.5 12.5 17.5H5C4.33696 17.5 3.70107 17.2366 3.23223 16.7678C2.76339 16.2989 2.5 15.663 2.5 15V7.5C2.5 6.83696 2.76339 6.20107 3.23223 5.73223Z"
              fill="#16C083"
            />
            <path
              id="Vector (Stroke)_2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.9944 2.32727C14.4822 1.83947 15.1438 1.56543 15.8337 1.56543C16.5235 1.56543 17.1851 1.83947 17.6729 2.32727C18.1607 2.81507 18.4348 3.47667 18.4348 4.16653C18.4348 4.85639 18.1607 5.51799 17.6729 6.00579L10.5896 13.0891C10.4333 13.2454 10.2213 13.3332 10.0003 13.3332H7.50033C7.04009 13.3332 6.66699 12.9601 6.66699 12.4999V9.99986C6.66699 9.77885 6.75479 9.56689 6.91107 9.41061L13.9944 2.32727ZM15.8337 3.2321C15.5858 3.2321 15.3482 3.33055 15.1729 3.50579L8.33366 10.345V11.6665H9.65515L16.4944 4.82727C16.6696 4.65203 16.7681 4.41436 16.7681 4.16653C16.7681 3.9187 16.6696 3.68103 16.4944 3.50579C16.3192 3.33055 16.0815 3.2321 15.8337 3.2321Z"
              fill="#16C083"
            />
            <path
              id="Vector (Stroke)_3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.7441 3.57709C13.0695 3.25165 13.5972 3.25165 13.9226 3.57709L16.4226 6.07709C16.748 6.40252 16.748 6.93016 16.4226 7.2556C16.0972 7.58103 15.5695 7.58103 15.2441 7.2556L12.7441 4.7556C12.4186 4.43016 12.4186 3.90252 12.7441 3.57709Z"
              fill="#16C083"
            />
          </g>
          <defs>
            <clipPath id="clip0_6962_24688">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </button>
  );
};

export default EditButtonWithPenIcon;
