import React, { useEffect, useState } from "react";
import EditButtonWithPenIcon from "./compnanets/editButton";
import { extractDomainFromUrl } from "../../services/utils";
import TextInput from "../../components/input_fields/textInput";
import AddButtonWithPlusIcon from "./compnanets/addButton";

const SocialComponent = ({
  socailData,
  removeSocial,
  updateSocaialData,
  addSocialData,
  isSocaislEditing ,
        isSocialEditOn ,
        setIsSocaislEditOn ,
}) => {

  

  return (
    <div className="w-full p-6 bg-white border border-neutral-300 flex-col justify-start items-start gap-4 inline-flex">
      <div className="w-full h-fit justify-between items-center inline-flex">
        <div className="text-teal-950 text-xl font-bold   leading-normal">
          Social Links
        </div>
        <div className="px-2.5 rounded-[10px] justify-center items-center gap-2.5 flex">
          
            {!isSocialEditOn ? (
              <EditButtonWithPenIcon onClick={() => setIsSocaislEditOn(true)} />
            ) : (
              <AddButtonWithPlusIcon onClick={addSocialData} />
            )}
          
        </div>
      </div>
      {socailData?.map((socail, index) => {
        return isSocaislEditing[index] == 1 ? (
          <div className=" w-full flex  items-center flex-row-reverse gap-1">
            <button className="w-5 h-5 relative" onClick={()=>removeSocial(index)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Binary / Minus Circle">
                  <path
                    id="Vector"
                    d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.125 10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z"
                    fill="#DB1717"
                  />
                </g>
              </svg>
            </button>
            <TextInput
              textType="input"
              textColor="gray"
              type="text"
              //   labelText="Name"
                value={socail}
                onChange={(e)=>updateSocaialData(e.target.value, index)}

              placeholder="Url"
            />
          </div>
        ) : (
            <div className="flex justify-between w-full">

            <div className="justify-start items-start gap-4 inline-flex">
            <div className="w-6 h-6 relative">
              <img
                src={`https://s2.googleusercontent.com/s2/favicons?domain=${socail}&sz=128`}
              />
            </div>
            <div className="flex-col justify-start items-start inline-flex">
              <div className="text-slate-500 text-base font-normal   leading-relaxed capitalize">
                {extractDomainFromUrl(socail)}
              </div>
              <a
                href={socail}
                target="_blank"
                className="text-emerald-500 text-base font-normal   leading-relaxed"
              >
                {socail}
              </a>
            </div>
            
          </div>

            {isSocialEditOn && <button className="w-5 h-5 relative " onClick={()=>removeSocial(index)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Binary / Minus Circle">
                  <path
                    id="Vector"
                    d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.125 10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z"
                    fill="#DB1717"
                  />
                </g>
              </svg>
            </button>}

            </div>
          
        );
      })}
    </div>
  );
};

export default SocialComponent;
