import React, { useEffect } from "react";
import ButtonChild from "../../../components/buttons/buttonChild";
import InfoBox from "../../../components/input_fields/infoBox";
import FillButton from "../../../components/buttons/fillButton";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../components/input_fields/textInput";

export const VerifyMobOtp = ({
  otp,
  onOtpChange,
  onVerify,
  missing,
  setActiveSection,
}) => {
  const phoneNo = localStorage.getItem("phoneNo");
  const navigate = useNavigate();
  const handleBackButtonClick = () => {
    setActiveSection("login");
    navigate("/login-or-signup");
  };
  const auth = localStorage.getItem("auth");
  useEffect(() => {
    if (!auth) navigate("/login-or-signup");
  }, []);
  return (
    <div>
      <div className="w-full sm:min-w-[570px] h-auto p-0 sx:p-4 sm:p-[72px] bg-white rounded-2xl sm:shadow flex-col justify-center items-center gap-4 sm:gap-[45px] inline-flex overflow-scroll no-scrollbar">
        <div
          className="w-full flex justify-start"
          onClick={handleBackButtonClick}
        >
          <ButtonChild
            textColor="gray"
            text="Back"
            // onClick={() => navigate("/login-or-signup")}
            leftIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M13.125 4.24952V16.7495C13.1251 16.8732 13.0885 16.9941 13.0198 17.097C12.9512 17.1999 12.8535 17.2801 12.7392 17.3274C12.625 17.3747 12.4992 17.3871 12.3779 17.363C12.2566 17.3388 12.1452 17.2792 12.0578 17.1917L5.80782 10.9417C5.74971 10.8837 5.70361 10.8147 5.67215 10.7389C5.6407 10.663 5.62451 10.5817 5.62451 10.4995C5.62451 10.4174 5.6407 10.3361 5.67215 10.2602C5.70361 10.1843 5.74971 10.1154 5.80782 10.0573L12.0578 3.80733C12.1452 3.71982 12.2566 3.66022 12.3779 3.63606C12.4992 3.6119 12.625 3.62428 12.7392 3.67163C12.8535 3.71897 12.9512 3.79916 13.0198 3.90203C13.0885 4.0049 13.1251 4.12583 13.125 4.24952Z"
                  fill="#667984"
                />
              </svg>
            }
          />
        </div>

        <div className="w-full flex xl:hidden">
          <img src="/leftBanner.png" alt="leftBanner" />
        </div>

        <div className="w-full text-teal-950 text-[18px] sx:text-xl font-black font-satoshi leading-normal">
          Verify your Mobile Number
        </div>

        <div className="w-full">
          <InfoBox
            variant="standard"
            infoText={
              <div className="p-2 gap-1">
                <span className="font-satoshi">An OTP has been sent to</span>
                <p className="text-green-500 font-satoshi">{phoneNo}</p>
              </div>
            }
            buttonLeftIcon={
              <div onClick={() => setActiveSection("login")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M17.7586 6.23165L14.268 2.74024C14.1519 2.62414 14.0141 2.53204 13.8624 2.4692C13.7107 2.40637 13.5482 2.37402 13.384 2.37402C13.2198 2.37402 13.0572 2.40637 12.9056 2.4692C12.7539 2.53204 12.6161 2.62414 12.5 2.74024L2.86641 12.3746C2.74983 12.4903 2.65741 12.6279 2.59451 12.7796C2.5316 12.9313 2.49948 13.094 2.50001 13.2582V16.7496C2.50001 17.0811 2.6317 17.3991 2.86612 17.6335C3.10054 17.8679 3.41849 17.9996 3.75001 17.9996H7.24141C7.40563 18.0001 7.5683 17.968 7.71999 17.9051C7.87168 17.8422 8.00935 17.7498 8.12501 17.6332L17.7586 7.99962C17.8747 7.88354 17.9668 7.74573 18.0296 7.59405C18.0925 7.44238 18.1248 7.27981 18.1248 7.11563C18.1248 6.95145 18.0925 6.78889 18.0296 6.63721C17.9668 6.48554 17.8747 6.34772 17.7586 6.23165ZM15 8.99024L11.5086 5.49962L13.3836 3.62462L16.875 7.11524L15 8.99024Z"
                    fill="#16C083"
                  />
                </svg>
              </div>
            }
          />
        </div>
        <div className="w-full flex flex-col gap-[22px]">
          <div className="w-full">
            <TextInput
              placeClass={"tracking-[15px]"}
              labelText="Enter OTP"
              textColor="gray"
              textType="input"
              type="text"
              htmlFor="mobileOTP"
              name="otp"
              placeholder="0000"
              hasError={missing.otp}
              errorMsg={missing.otp ? "Enter valid OTP." : ""}
              value={otp}
              onChange={onOtpChange}
              maxLength={4}
            />
          </div>
          <div className="w-full">
            <FillButton
              onClick={onVerify}
              className={``}
              color="green"
              textColor="white"
              text="Continue"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
