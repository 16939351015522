import React, { useEffect, useState } from "react";
import { userNotifications } from "../../services/settingsflow";
import moment from "moment";

function Notifications({ isNotificationClicked }) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const getNotifications = async () => {
      const response = await userNotifications();
      setNotifications(response?.notifications);
    };
    getNotifications();
  }, []);

  const formatDate = (timestamp) => {
    const date = moment(timestamp).format("MMM DD");
    const today = moment().format("MMM DD");
    if (date === today) {
      return "Today";
    } else if (moment(timestamp).isAfter(moment().subtract(1, "week"))) {
      return "Past Week";
    } else {
      return "Past Month";
    }
  };
  const formatDateCorrect = (timestamp) => {
    const date = moment(timestamp).format("MMM DD");
    const today = moment().format("MMM DD");
    if (date === today) {
      return moment(timestamp).format("h:mm A");
    } else {
      return date;
    }
  };
  const todayNotifications = [];
  const pastWeekNotifications = [];
  const pastMonthNotifications = [];

  notifications.forEach((notification) => {
    const formattedDate = formatDate(notification.timestamp);
    switch (formattedDate) {
      case "Today":
        todayNotifications.push(notification);
        break;
      case "Past Week":
        pastWeekNotifications.push(notification);
        break;
      case "Past Month":
        pastMonthNotifications.push(notification);
        break;
      default:
        break;
    }
  });

  return (
    <div
      className={`absolute z-50  right-2 max-w-full md:w-[514px] h-auto py-6 bg-white shadow border border-neutral-300 flex-col justify-start items-center gap-4 md:gap-6 inline-flex overflow-scroll no-scrollbar`}
    >
      <div className=" w-full px-6 md:px-4 inline-flex items-center justify-between">
        <div className="text-teal-950 text-xl font-bold font-satoshi leading-normal">
          Notifications
        </div>
        <div className="text-right text-emerald-500 text-base font-medium font-satoshi cursor-pointer leading-relaxed">
          Mark all as read
        </div>
      </div>
      <div className="self-stretch max-h-screen flex-col justify-start items-start flex overflow-scroll no-scrollbar">
        {todayNotifications.length > 0 && (
          <>
            {todayNotifications.map((notification, index) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                showDate={index === 0}
                formatDateCorrect={formatDateCorrect}
              />
            ))}
          </>
        )}
        {pastWeekNotifications.length > 0 && (
          <>
            <div className="self-stretch px-4 py-2 bg-gray-100 justify-start items-center gap-2 inline-flex">
              Past Week
            </div>
            {pastWeekNotifications.map((notification, index) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                showDate={index === 0}
                formatDateCorrect={formatDateCorrect}
              />
            ))}
          </>
        )}
        {pastMonthNotifications.length > 0 && (
          <>
            <div className="self-stretch px-4 py-2 bg-gray-100 justify-start items-center gap-2 inline-flex">
              Past Month
            </div>
            {pastMonthNotifications.map((notification, index) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                showDate={index === 0}
                formatDateCorrect={formatDateCorrect}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

function NotificationItem({ notification, showDate, formatDateCorrect }) {
  return (
    <div className="cursor-pointer self-stretch p-4 bg-gradient-to-r from-white via-white to-emerald-50 justify-start items-center gap-2 inline-flex">
      <div className="w-12 h-12 relative bg-white">
        <img
          className="w-10 h-10 left-[4px] top-[8px] "
          src="./Notification.png"
          alt="Notification Logo Error"
        />
      </div>
      <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
        <div className="self-stretch text-teal-950 text-sm font-medium font-satoshi leading-tight">
          {notification?.title}
        </div>
        <div className="self-stretch text-slate-500 text-xs font-medium font-satoshi capitalize leading-[18px]">
          {notification?.body}
        </div>
      </div>
      {
        <div className="text-right text-emerald-700 text-xs font-black font-satoshi capitalize leading-[14px]">
          {formatDateCorrect(notification?.timestamp)}
        </div>
      }
    </div>
  );
}

export default Notifications;
