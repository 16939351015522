import React, { useEffect, useState } from "react";
import TextInput from "../../../../components/input_fields/textInput";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { courseById, courseByIdWithoutToken, joinWaitlist } from "../../../../services/courses";
import { getUserDetails } from "../../../../services/loginOrSignUp";
import ConfirmJoinWailist from "./confirmJoinWaitlist";
import { updateProfile } from "../../../../services/loginOrSignUp";
import InputFieldComp from "../../../../components/input_fields/inputFieldComp";
import Loader from "../../../../components/loader/loader";
import FillButton from "../../../../components/buttons/fillButton";

export const JoinWaitlistFillDetails = () => {
    
const location = useLocation();
const [courseDetails, setCourseDetails] = useState({});
const [userData, setUserData] = useState({});
const auth = localStorage.getItem('auth');
const [missing, setMissing] = useState([]);
const queryParams = new URLSearchParams(location.search);
const courseId = queryParams.get('id');
const courseName = queryParams.get('name');
const phoneNo = localStorage.getItem('phoneNo');
const courseDesc = queryParams.get('desc');
const [isLoading, setIsLoading] = useState(true);

const [form, setForm] = useState({
    fname:"",
    email:"",
})

const navigate = useNavigate();

    const [openBox, setOpenBox] = useState(false);

    const close = ()=>{
        setOpenBox((prev)=>!prev)
    }
    const onSetFormData = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
          ...prevForm,
          [name]: value,
        }));
      };

      const joinWaitingList = async (id)=>{
console.log(id, courseDetails, "courseee")
        if(courseDetails?.userInWaitlist === false){
        
            const response = await joinWaitlist(id);
            console.log(response, "response");
            if(response){
                navigate(`/join-waitlist/success?id=${id}`)
                console.log(response)
            }
        } else if(courseDetails?.isRegistrationLive === true && courseDetails?.userInWaitlist === true && courseDetails?.userRegistered === false){
            navigate(`/register/payment?id=${courseDetails?._id}`);
        }
       
    }

    const OnUpdateProfile = async (id) => {
        try {
          const missingField = {};
          if (!form.fname) missingField["fname"] = true;
          if (!form.email && form.email === "") missingField["email"] = true;
          let gender = null;
          let upload = null;
          // if (SSOID && !form.phoneNo) missingField["phoneNo"] = true;
    
          setMissing(missingField);
    
          if (Object.keys(missingField).length === 0) {
            const response = await updateProfile(
              form.fname,
              gender,
              upload
            );
            console.log(response)
            if (response?.data) {
                console.log(id, "helloo")
                joinWaitingList(courseDetails?._id);
            } else {

              console.log("error");
            }
          }
        } catch (error) {
          console.error("Error updating profile data:", error);
        }
      };

useEffect(() => {
    // window.scrollTo(0, 0);
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get('id');


    const fetchCourseDetails = async () => {
        try {
            
            const response = await courseById(courseId);
            console.log(response?.data)
            setCourseDetails(response?.data);
        } catch (error) {
            console.error("Error fetching course details:", error);
        } finally {
          setIsLoading(false)
        }
    };

    const data = async ()=>{
        const user = await getUserDetails();
        console.log(user, "tttt")
        setUserData(user)
        }
        data()

    
    if (courseId) {
        fetchCourseDetails();
    }
},[]);

if (isLoading) {
  return <Loader />; // Show loader while loading
}

    return  <div className="min-w-screen max-w-full min-h-screen max-h-full px-2 sx:px-4 md:px-8 lg:p-[52px] flex flex-col lg:flex-row justify-center items-center bg-gradient-to-br from-black to-[#000000] ">
       
    <div className="w-full lg:w-[50%] h-auto flex-col justify-center items-start gap-6 inline-flex">
      
    <div className="hidden md:flex w-[50px] h-[50px] absolute top-12 rounded-xl">
    <Link to="/programs">
     <img src="/svg/whiteBeepLogo.svg"/>
     </Link>
    </div>
    <div className="md:hidden w-[50px] h-[50px] rounded-xl">
    <Link to="/programs">
     <img src="/svg/whiteBeepLogo.svg"/>
     </Link>
    </div>
  
      <div className="w-full md:mt-[200px] lg:mt-0 h-auto text-white opacity-80 text-[40px] font-bold font-satoshi flex flex-wrap">
        {courseDetails?.name || courseName}
      </div>
      <div className="w-full text-start text-slate-500 text-xl font-bold font-satoshi leading-normal flex flex-wrap">
        {courseDetails?.description || courseDesc}
      </div>
    </div>
    <div className="w-full lg:w-[50%] my-8 h-auto flex justify-center">
    <div className="w-full md:w-[450px] xl:w-[532px] h-auto md:h-[600px] px-4 sm:px-8 md:px-[62px] py-[99px] bg-white rounded-lg flex-col justify-start items-center gap-8 inline-flex">
    <Link to="/programs" className="w-full flex justify-start">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z" fill="#667984"/>
</svg>
</Link>
  

{(auth && userData?.status === 3) &&
<>
<div className="w-full md:w-[408px] h-auto grow py-8 px-8 shrink basis-0 bg-gradient-to-br from-black via-teal-950 to-black rounded-xl border-2 border-slate-700/opacity-20 flex-col justify-center items-center gap-2 flex">
<div className="w-full h-auto rounded-[20px] flex-col justify-center items-center gap-2.5 flex">
            <div className="w-full flex-col justify-center items-center gap-6 flex">
            <div className="w-full justify-center items-center gap-[6.38px] inline-flex">
                        <div className="w-[31.11px] h-[31.11px] relative rounded">
                        <img className="w-[83.77px]" src="/svg/beepLogo.svg" />
                        </div>
                        <div className="w-[18.35px] text-center text-white text-xl font-normal font-satoshi">x</div>
                        {courseDetails?.type == "MICRO DEGREE" ?
                         <img className="w-[83.77px] h-[39.09px]" src="edyoda.png" />
                         :
                         <img className="h-[39.09px]" src="/svg/jp.svg" />
                    }
                    </div>
             
                    <div className="w-full flex flex-col justify-center items-center">
    <div className="text-neutral-50 text-2xl text-center font-bold font-satoshi tracking-wide">
        {courseDetails?.name ? courseDetails?.name : courseName}
    </div>
    <div className="w-full md:w-max text-transparent text-center bg-clip-text bg-gradient-to-r from-purple-500 via-white to-purple-500 text-[33.96px] font-black font-satoshi tracking-wider">
    {courseDetails?.type ? courseDetails?.type :  type}
    </div>
</div>

              
            </div>
        </div>
        </div>
        <FillButton
          className="w-full"
          onClick={()=>{joinWaitingList(courseDetails?._id)}}
           textColor="white"
           color="black1"
           text="Join Waitlist"
          />

</>


}

{(auth && userData?.status === 0) && 
<>
    <div className="w-full text-teal-950 text-[18px] sx:text-xl font-black font-satoshi leading-normal">
    Enter your details to join waitlist
          </div>
  <div className="w-full flex flex-col gap-2">
  <div className="w-full">
                <InputFieldComp
                  className="w-full"
                  type="text"
                  labelText="Full Name"
                  placeholder="Enter Your Name"
                  hasError={missing.fname}
                  errorMsg={missing.fname ? "Full name is required." : ""}
                  name="fname"
                  value={form.fname}
                  onChange={onSetFormData}
                />
              </div>
              <div className="w-full">
                <InputFieldComp
                  className="w-full"
                  type="text"
                  labelText="Email"
                  placeholder="Enter Your Email"
                  hasError={missing.email}
                  errorMsg={missing.email ? "Full name is required." : ""}
                  name="email"
                  value={form.email}
                  onChange={onSetFormData}
                />
              </div>
  </div>

    
  <div onClick={()=>{OnUpdateProfile(courseDetails?._id)}} className="self-stretch h-[50px] flex-col justify-end items-center gap-[22px] flex cursor-pointer">
        <div className="self-stretch px-6 py-3 bg-[#002032] hover:shadow-md hover:border hover:border-gray-300 hover:opacity-95 rounded-xl justify-center items-center gap-2.5 inline-flex ">
          <div className="text-center text-white text-base font-bold font-satoshi leading-relaxed">
            Join Waitlist
          </div>
        </div>
      </div>
      </>

}

    </div>
    </div>

    {openBox && <ConfirmJoinWailist close={close} courseDetails={courseDetails}/>}
  </div>;
};
