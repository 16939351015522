import { useEffect } from "react";
import "./App.css";
import { MainRoutes } from "./routes/mainRoutes";
import ReactGA from 'react-ga';
import queryString from 'query-string';
import { clarity } from "react-microsoft-clarity";




function initializeReactGA() {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID);
}

function App() {
  useEffect(() => {
    initializeReactGA();
    const queryParams = queryString.parse(window.location.search);
    const { utm_source, utm_medium, utm_campaign } = queryParams;

    if (utm_source || utm_medium || utm_campaign) {
      ReactGA.set({
        campaignSource: utm_source,
        campaignMedium: utm_medium,
        campaignName: utm_campaign
      });
    }

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(()=>{
    clarity.init("lnt0ytw57a")
      },[])

  return (
    <div>
      <MainRoutes />
    </div>
  );
}

export default App;
