import React, { useEffect, useState } from "react";
import ProfilePage from "../profile/profile";
import { useLocation, useNavigate } from "react-router-dom";
import { canUserApply, jobDescbyId, jobDescbyIdLoggedInUser } from "../../services/landingPageApi";
import FillButton from "../../components/buttons/fillButton";
import BorderButton from "../../components/buttons/borderButton";
import axios from "axios";
import moment from "moment";
import { Mixpanel } from "../../services/mixpanel";
import { isMobileOnly, browserName } from "react-device-detect";
import Loader from "../../components/loader/loader";

export const PreviewAndApply = () => {

  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState(true);
  const [isEdited, setIsEdited] = useState(false);
  const [applyCondition, setApplyCondition] = useState(null);
  const [submitting, setSubmitting] =useState(false)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jobId = queryParams.get('jobId');
 
const onSubmit = async()=>{
  setSubmitting(true)
  const jobId = queryParams.get('jobId');
  const url = `${process.env.REACT_APP_baseURL}/user/apply_jobv2/${jobId}`;
  const answers = JSON.parse(localStorage.getItem(jobId+"_answer"));
  const response = await axios.post(url,{
    questions:answers??[]
    
  },  {headers: {
    Authorization: "Bearer " + localStorage.getItem("auth"),
  },}).then(()=>{
   
      Mixpanel.track("send_application_CTA_clicked", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        user_id: localStorage.getItem("userId"),
        browser: browserName,
        jobId:jobId,
        mobile_device: isMobileOnly,
        type:"webapp",
      });
  
    navigate('/application_sent');
  }).catch((error) => {
    console.log(error.response?.data);
  });

  
  setSubmitting(false)

}
  const fetchJob = async () => {
    const jobId = queryParams.get('jobId');
    const jobDesc = await jobDescbyIdLoggedInUser(jobId);
    setJobDetails(jobDesc?.data);
    setApplyCondition(canUserApply(jobDesc?.data));
    
  };

  useEffect(() => {
    fetchJob();

  // Get specific query parameter
  const paramValue = queryParams.get('jobId');

  }, []);

    
  // const [Loading, setLoading] = useState(true);
  // useEffect(() => {
  //   // Simulating an asynchronous action
  //   const fakeAsyncAction = async () => {
  //     try {
  //       // Simulate an API call or any asynchronous action
  //       await new Promise((resolve) => setTimeout(resolve, 1000));
  //     } finally {
  //       // Set isLoading to false once the asynchronous action is done
  //       setLoading(false);
  //     }
  //   };

  //   // Call the fakeAsyncAction when the component mounts
  //   fakeAsyncAction();
  // }, []); // Empty dependency array means this effect runs once on mount

  // // Render the loader if isLoading is true
  // if (Loading) {
  //   return <Loader />;
  // }

  return (
    <div className="w-full h-screen px-8 pt-6 pb-8 bg-white flex-col justify-start items-start gap-6 inline-flex">
      <div className="self-stretch h-[58px] flex-col justify-start items-start gap-1 flex">
        <div className="w-[228px] h-7 text-gray-800 text-lg font-semibold leading-[28.80px]">
          {jobDetails? "Preview & Apply" : <span className=" text-red-500"> Invalid Job</span>}
        </div>
        <div className="text-slate-700 text-base font-normal    leading-relaxed">
          This is how the recruiters will view your profile. Review and edit it
          below before applying.
        </div>
      </div>

      <div className="self-stretch h-[0px] border border-neutral-300"></div>
<div className="w-full flex justify-center items-center">
    <ProfilePage isEdited={setIsEdited}/>
    </div>

     { (!isEdited && applyCondition)  && <><div className="self-stretch h-[0px] border"></div>
      <div className="self-stretch h-[92px] py-2 flex-col justify-end md:items-end items-center gap-2 inline-flex">
        
        
          <div className=" w-full md:w-fit">
        <FillButton color={'green'} 
        onClick={onSubmit} 
          textColor="white"
          disabled={!applyCondition.canApply || submitting}
          text={"Send your application"}
          />
          </div>
        
        {applyCondition.reason!='' && <div className="text-red-600 text-xs text-center font-black leading-[18px]">
          {applyCondition.canApply ?  (applyCondition.reason>2?`• Closes in ${applyCondition.reason-1} Days •`:`• Closes Today •`  ) : applyCondition.reason}
        </div>}
      </div></>}
    </div>
  );
};
