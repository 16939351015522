import React from "react";
import { ConfirmSuccessScreen } from "./components/JoinWaitlistFlow/confirmSuccessScreen";
import { Helmet } from "react-helmet-async";

export const JoinListConfirm = () => {
  return (
    <>
    <Helmet>
      <title>
        Successfully Enrolled for the Course.
      </title>
    </Helmet>
  <div className="w-full h-auto">
    <ConfirmSuccessScreen  />
  </div>
  </>
  )
};
