import React, { useState, useEffect } from "react";
import { canUserApply, jobDescbyId } from "../../services/landingPageApi";
import { uploadFiles } from "../../services/jobs";
import TextInput from "../../components/input_fields/textInput";
import BorderButton from "../../components/buttons/borderButton";
import FillButton from "../../components/buttons/fillButton";
import { useNavigate, useParams } from "react-router-dom";

export const ScreeningQuestion = ({ setJobPageSection }) => {
  const params = useParams();
  const jobId = params['jobId'];
  const [jobDetails, setJobDetails] = useState({});
  const [applyCondition, setApplyCondition] = useState(null);
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    const fetchJob = async () => {
      const response = await jobDescbyId(jobId);
      setJobDetails(response?.data || {});
      setApplyCondition(canUserApply(response?.data));
      // Check if there are saved answers in localStorage
      const savedAnswers = localStorage.getItem(jobId+"_answer");
      if (savedAnswers) {
        setInputs(JSON.parse(savedAnswers));
      }
    };
    fetchJob();
  }, [jobId]);

  const handleInputChange = (question, value) => {
    setInputs(prevState => ({
      ...prevState,
      [question]: value
    }));
  };
  

  const handleFileInputChange = async (e, question) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const response = await uploadFiles(file, fileType, question);
      handleInputChange(question, response?.answer.data.url);
    }
  };


  const handleApplyJob = () => {
    const answers = jobDetails.questions.map(question => {
      switch (question.questionType) {
        case 0:
          return { question: question.question, answer: inputs[question.question] || "" };
        case 1:
          return { question: question.question, selectOption: inputs[question.question] || "" };
        case 2:
          return { question: question.question, selectOptions: inputs[question.question] || [] };
        case 3:
          return { question: question.question, url: inputs[question.question] || "" };
        default:
          return { question: question.question, answer: "" };
      }
    });
    
    localStorage.setItem(jobId+"_answer", JSON.stringify(answers));
  };

  const navigate = useNavigate();
  const previewApply = () => {
    handleApplyJob();
    navigate(`/preview?jobId=${jobId}`);
  };

  const renderQuestion = (question) => {
    switch (question.questionType) {
      case 0:
        return (
          <TextInput
          className="inputBox w-full"
          textColor="gray"
          textType="textarea"
          leftTextClass={`w-max`}
          placeClass="text-black w-max"
          style={{ width: "100%" }}
            key={question.question}
            placeholder="Enter text..."
            value={inputs[question.question] || ""}
            onChange={(e) => handleInputChange(question.question, e.target.value)}
          />
        );
      case 1:
        return (
          <div key={question.question}>
            {question.options.map((option, index) => (
              <BorderButton
                key={index}
                text={option}
                onClick={() => handleInputChange(question.question, index)}
                color={inputs[question.question] === index ? "green" : "gray"}
              />
            ))}
          </div>
        );
      case 2:
        return (
          <div key={question.question}>
            {question.options.map((option, index) => (
              <BorderButton
                key={index}
                text={option}
                onClick={() => {
                  const selectedOptions = inputs[question.question] || [];
                  const updatedOptions = selectedOptions.includes(index)
                    ? selectedOptions.filter(item => item !== index)
                    : [...selectedOptions, index];
                  handleInputChange(question.question, updatedOptions);
                }}
                color={inputs[question.question]?.includes(index) ? "green" : "gray"}
              />
            ))}
          </div>
        );
      case 3:

          return (
            <div className="w-full" key={question.question}>

              <div className="self-stretch px-3 py-2 bg-white rounded-xl border-2 border-gray-200 justify-start items-center gap-1 inline-flex">
                <input
                  type="file"
                  onChange={(e) => handleFileInputChange(e, question.question)}
                />
              </div>
            </div>
          );
          
      default:
        return null;
    }
  };


    return (
      <div className="w-full h-auto pb-8 flex-col justify-start items-start inline-flex overflow-scroll no-scrollbar">
        <div className="w-full h-auto flex-col justify-start items-start flex">
          <div className="w-full h-auto p-8 bg-white justify-start items-start gap-8 inline-flex">
            <div className="w-full self-stretch flex-col justify-start items-start gap-8 inline-flex">
              <div className="text-teal-950 text-xl font-black font-['Poppins'] capitalize leading-normal">
                Screening Questions
              </div>
              {jobDetails?.questions && jobDetails?.questions.map((question, index) => (
                  <div
                    key={index}
                    className="self-stretch h-auto flex-col justify-start items-start gap-1 flex"
                  >
                    <div className="py-1">{question.question}</div>
                    {renderQuestion(question)}
                  </div>
                ))}
            </div>
          </div>
          <div className="self-stretch h-auto px-8 py-2 flex-col justify-center items-start gap-2 flex">
            {/* <Link to="/preview"> */}
            <FillButton 
            onClick={previewApply}
            textColor="white"
            color="green"
            text="Preview and apply"
            />
            {/* </Link> */}
            {/* <div className="px-6 py-3 bg-emerald-500 rounded-xl justify-center items-center gap-2.5 inline-flex">
              <div
                className="text-center text-white text-base font-bold font-['Satoshi'] leading-relaxed"
                // onClick={handleApplyJob}
  
              >
                Preview and apply
              </div>
            </div> */}
              {applyCondition?.reason!='' && <div className="text-red-600 text-xs text-center font-black leading-[18px]">
            {applyCondition?.canApply ?  (applyCondition?.reason > 2 ? `• Closes in ${applyCondition?.reason-1} Days •`:`• Closes Today •`  ) : applyCondition?.reason}
          </div>}
          </div>
        </div>
      </div>
    );

};
