import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import TextInput from "../input_fields/textInput";
import TimeRange from "./timeRange";

const Calendar = ({
  // for calender component pass-
  onSelectDate, //pass onchange function with date parameter
  initialDate, //set selected date from state
  inputPlaceholder, //add desired placeholder
  className, //if want to change style of the component add classes
  labelText, //title lable for the component
  dateFormat, //you can show whatever dataformat in your input field, just pass like { day: "numeric", month: "short", year: "numeric" }
  hasError, //if you wanna show error at the time of validation you can add here
  type, // type for time range here, if want to add time range then add type like (type == "time" )
}) => {
  const refOne = useRef(null);
  const refInside = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(initialDate || null);
  const [showMonths, setShowMonths] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());


  useEffect(()=>{
    setSelectedDate(initialDate || null)
  }, [initialDate])
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const generateCalendarDays = (year, month) => {
    const daysInMonth = getDaysInMonth(year, month);
    const firstDay = new Date(year, month, 1).getDay();

    // Get the last day of the previous month
    const lastDayPrevMonth = new Date(year, month, 0).getDate();
    const daysInPrevMonth = lastDayPrevMonth - firstDay + 1;

    const days = [];
    let row = [];

    // Add empty cells for previous month
    for (let i = 0; i < firstDay; i++) {
      row.push(new Date(year, month - 1, daysInPrevMonth + i));
    }

    for (let i = 1; i <= daysInMonth; i++) {
      row.push(new Date(year, month, i));

      if (row.length === 7) {
        days.push(row);
        row = [];
      }
    }

    let i = 1;
    // Add remaining days
    if (row.length > 0) {
      // Add empty cells for next month
      while (row.length < 7) {
        row.push(new Date(year, month + 1, i++));
      }

      days.push(row);
    }
    return days;
  };

  const [selectedTime, setSelectedTime] = useState("");

  const handleSelectedTime = (time) => {
    // Do something with the selected time, e.g., store it in state
    setSelectedTime(time);
  };

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const isCurrentDate = (date) => {
    return (
      date &&
      currentDate.getDate() === date.getDate() &&
      currentDate.getMonth() === date.getMonth() &&
      currentDate.getFullYear() === date.getFullYear()
    );
  };
  const isDateSelected = (date) => {
    const currentDate = new Date();
    // const isCurrentMonth = currentDate.getMonth() === date.getMonth();

    return (
      date &&
      (isCurrentDate(date) ||
        (selectedStartDate && selectedStartDate.getTime() === date.getTime()) ||
        isCurrentDate(date) ||
        (selectedEndDate && selectedEndDate.getTime() === date.getTime()))
    );
  };

  const handleDateClick = (date) => {
    if (showMonths) {
      setCurrentMonth(date.getMonth());
      setShowMonths(false);
      setOpen(true); // Open the calendar when clicking on the month
    } else {
      setSelectedDate(Array.isArray(date) ? date[0] : date);
      setCurrentMonth(date.getMonth());
      setOpen(false); // Close the calendar when clicking on a date
    }

    if (date.getMonth() === 11 && currentMonth === 0) {
      setCurrentYear(date.getFullYear());
    } else if (date.getMonth() === 0 && currentMonth === 11) {
      setCurrentYear(date.getFullYear());
    }

    if (date) {
      if (isDateSelected(date)) {
        setSelectedStartDate(null);
        setSelectedEndDate(null);
      } else {
        setSelectedStartDate(date);
        setSelectedEndDate(date);
      }
    }

    if (onSelectDate) {
      if (type == "time") {
        onSelectDate(date + "," + selectedTime);
      } else {
        onSelectDate(date);
      }
    }

    setOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refOne.current &&
        !refOne.current.contains(event.target) &&
        !refInside.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [refOne, refInside]);

  return (
    <>
      {/* ... (rest of the component remains the same) */}
      <div
        className={`w-full h-auto justify-start items-start gap-4 inline-flex ${className}`}
      >
        <div
          onClick={(event) => {
            if (true) {
              event.stopPropagation();
            }
            setOpen(true);
          }}
          className=" w-full flex-col justify-start items-start inline-flex relative"
        >
          <TextInput
            labelText={labelText}
            className="inputBox"
            textColor="gray"
            textType="input"
            leftTextClass={`w-max`}
            placeClass="text-black w-max"
            hasError={hasError}
            readonly
            value={
              selectedDate
                ? `${new Date(selectedDate)?.toLocaleDateString(
                    "en-US",
                    dateFormat
                      ? dateFormat
                      : { day: "numeric", month: "short", year: "numeric" }
                  )} ${selectedTime}`
                : ""
            }
            onChange={(value) => {
              setSelectedDate(value);
            }}
            placeholder={
              inputPlaceholder ? inputPlaceholder : `Select Date....`
            }
            rightTextClass={`w-max`}
            rightIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M16.25 2.5H14.375V1.875C14.375 1.70924 14.3092 1.55027 14.1919 1.43306C14.0747 1.31585 13.9158 1.25 13.75 1.25C13.5842 1.25 13.4253 1.31585 13.3081 1.43306C13.1908 1.55027 13.125 1.70924 13.125 1.875V2.5H6.875V1.875C6.875 1.70924 6.80915 1.55027 6.69194 1.43306C6.57473 1.31585 6.41576 1.25 6.25 1.25C6.08424 1.25 5.92527 1.31585 5.80806 1.43306C5.69085 1.55027 5.625 1.70924 5.625 1.875V2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM11.875 12.5H10.625V13.75C10.625 13.9158 10.5592 14.0747 10.4419 14.1919C10.3247 14.3092 10.1658 14.375 10 14.375C9.83424 14.375 9.67527 14.3092 9.55806 14.1919C9.44085 14.0747 9.375 13.9158 9.375 13.75V12.5H8.125C7.95924 12.5 7.80027 12.4342 7.68306 12.3169C7.56585 12.1997 7.5 12.0408 7.5 11.875C7.5 11.7092 7.56585 11.5503 7.68306 11.4331C7.80027 11.3158 7.95924 11.25 8.125 11.25H9.375V10C9.375 9.83424 9.44085 9.67527 9.55806 9.55806C9.67527 9.44085 9.83424 9.375 10 9.375C10.1658 9.375 10.3247 9.44085 10.4419 9.55806C10.5592 9.67527 10.625 9.83424 10.625 10V11.25H11.875C12.0408 11.25 12.1997 11.3158 12.3169 11.4331C12.4342 11.5503 12.5 11.7092 12.5 11.875C12.5 12.0408 12.4342 12.1997 12.3169 12.3169C12.1997 12.4342 12.0408 12.5 11.875 12.5ZM3.75 6.25V3.75H5.625V4.375C5.625 4.54076 5.69085 4.69973 5.80806 4.81694C5.92527 4.93415 6.08424 5 6.25 5C6.41576 5 6.57473 4.93415 6.69194 4.81694C6.80915 4.69973 6.875 4.54076 6.875 4.375V3.75H13.125V4.375C13.125 4.54076 13.1908 4.69973 13.3081 4.81694C13.4253 4.93415 13.5842 5 13.75 5C13.9158 5 14.0747 4.93415 14.1919 4.81694C14.3092 4.69973 14.375 4.54076 14.375 4.375V3.75H16.25V6.25H3.75Z"
                  fill="#667984"
                />
              </svg>
            }
          />
          {/* ... (rest of the component remains the same) */}
          {open && (
            <div ref={refOne} className="" style={{ zIndex: 2 }}>
              <div
                ref={refInside}
                className="absolute  h-max bg-white rounded-xl shadow border-2 border-gray-200 flex-col justify-start items-center flex"
              >
                <div className="w-96 p-1 bg-gray-100 justify-center items-center gap-1 inline-flex rounded-tl-[10px] rounded-tr-[10px]">
                  {/* ... (same as before) */}
                  <div className="p-2 rounded-lg justify-center items-center gap-1 flex">
                    <div
                      onClick={() => {
                        handleDateClick(
                          new Date(currentYear, currentMonth - 1)
                        );
                      }}
                      className="w-5 h-5 relative cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M13.1245 3.74952V16.2495C13.1246 16.3732 13.088 16.4941 13.0193 16.597C12.9507 16.6999 12.853 16.7801 12.7388 16.8274C12.6245 16.8747 12.4988 16.8871 12.3774 16.863C12.2561 16.8388 12.1447 16.7792 12.0573 16.6917L5.80733 10.4417C5.74922 10.3837 5.70312 10.3147 5.67167 10.2389C5.64021 10.163 5.62402 10.0817 5.62402 9.99952C5.62402 9.91738 5.64021 9.83605 5.67167 9.76018C5.70312 9.6843 5.74922 9.61537 5.80733 9.55733L12.0573 3.30733C12.1447 3.21982 12.2561 3.16022 12.3774 3.13606C12.4988 3.1119 12.6245 3.12428 12.7388 3.17163C12.853 3.21897 12.9507 3.29916 13.0193 3.40203C13.088 3.5049 13.1246 3.62583 13.1245 3.74952Z"
                          fill="#002032"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="grow shrink basis-0 h-9 p-2 rounded-lg justify-center items-center gap-1 flex">
                    <div
                      onClick={() => {
                        setShowMonths(!showMonths);
                        setSelectedDate(null); // Clear selected date when switching between months and dates
                      }}
                      className="text-center text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight cursor-pointer"
                    >
                      {showMonths
                        ? `${new Date(
                            currentYear,
                            currentMonth,
                            1
                          )?.toLocaleDateString("en-US", {
                            month: "short",
                          })}, ${new Date(
                            currentYear,
                            currentMonth,
                            1
                          ).getFullYear()}`
                        : selectedDate
                        ? `${new Date(selectedDate)?.toLocaleDateString(
                            "en-US",
                            { month: "short" }
                          )}, ${new Date(selectedDate).getFullYear()}`
                        : `${new Date(
                            currentYear,
                            currentMonth,
                            1
                          )?.toLocaleDateString("en-US", {
                            month: "short",
                          })}, ${new Date(
                            currentYear,
                            currentMonth,
                            1
                          ).getFullYear()}`}
                    </div>
                  </div>
                  {/* ... (same as before) */}
                  <div className="p-2 rounded-lg justify-center items-center gap-1 flex">
                    <div
                      onClick={() => {
                        handleDateClick(
                          new Date(currentYear, currentMonth + 1)
                        );
                      }}
                      className="w-5 h-5 relative cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M14.1922 10.4417L7.94219 16.6917C7.85478 16.7792 7.74337 16.8388 7.62207 16.863C7.50076 16.8871 7.37502 16.8747 7.26076 16.8274C7.14649 16.7801 7.04884 16.6999 6.98017 16.597C6.91151 16.4941 6.8749 16.3732 6.875 16.2495V3.74952C6.8749 3.62583 6.91151 3.5049 6.98017 3.40203C7.04884 3.29916 7.14649 3.21897 7.26076 3.17163C7.37502 3.12428 7.50076 3.1119 7.62207 3.13606C7.74337 3.16022 7.85478 3.21982 7.94219 3.30733L14.1922 9.55733C14.2503 9.61537 14.2964 9.6843 14.3279 9.76018C14.3593 9.83605 14.3755 9.91738 14.3755 9.99952C14.3755 10.0817 14.3593 10.163 14.3279 10.2389C14.2964 10.3147 14.2503 10.3837 14.1922 10.4417Z"
                          fill="#002032"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {!showMonths && (
                  <div className="w-full p-1 bg-gray-100 justify-start items-center gap-1 inline-flex">
                    {/* ... (same as before) */}
                    {weekDays.map((day, index) => (
                      <div
                        key={index}
                        className="grow shrink basis-0 p-2 rounded-lg flex-col justify-start items-center gap-1 inline-flex"
                      >
                        <div className="text-center text-slate-500 text-sm font-black font-['Satoshi Variable'] leading-tight">
                          {day}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="h-auto w-auto py-1 flex-col justify-start items-center gap-1 flex">
                  {/* Render either months or calendar days based on the state */}
                  {showMonths ? (
                    <div className="grid grid-cols-3 gap-4 px-4">
                      {Array.from({ length: 12 }).map((_, index) => (
                        <div
                          key={index}
                          onClick={() =>
                            handleDateClick(new Date(currentYear, index, 1))
                          }
                          className={`w-[122.67px] h-9 p-2 rounded-lg flex-col justify-center items-center gap-1 inline-flex ${
                            currentMonth === index ? "bg-green-500" : ""
                          }`}
                        >
                          {/* <div className="w-[122.67px] rounded-lg flex-col justify-center items-center gap-1 inline-flex"> */}
                          <div className=" text-center text-teal-950 text-sm font-medium font-['Satoshi Variable'] leading-tight cursor-pointer">
                            {new Date(
                              currentYear,
                              index,
                              1
                            )?.toLocaleDateString("en-US", { month: "short" })}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    generateCalendarDays(currentYear, currentMonth).map(
                      (row, rowIndex) => (
                        <div key={rowIndex} className="w-full">
                          {row.map((date, colIndex) => (
                            <div
                              key={colIndex}
                              className={`w-[54px] grow shrink basis-0 p-2 rounded-lg flex-col justify-start items-center gap-1 inline-flex hover:bg-green-50 focus:bg-green-500 ${
                                date ? "cursor-pointer" : ""
                              } 
                          ${isCurrentDate(date) ? "bg-green-500" : ""}
                          ${isDateSelected(date) ? "bg-green-500" : ""}
                          `}
                              onClick={() => {
                                if (date) {
                                  if (date.getMonth() < currentMonth) {
                                    handleDateClick(
                                      new Date(
                                        currentYear,
                                        currentMonth - 1,
                                        date.getDate()
                                      )
                                    );
                                  } else if (date.getMonth() > currentMonth) {
                                    handleDateClick(
                                      new Date(
                                        currentYear,
                                        currentMonth + 1,
                                        date.getDate()
                                      )
                                    );
                                  } else {
                                    handleDateClick(date);
                                  }
                                }
                              }}
                            >
                              {/* ... (same as before) */}
                              {date ? (
                                <div
                                  className={`text-center text-slate-500 text-sm font-medium font-['Satoshi Variable'] leading-tight ${
                                    currentMonth !== date.getMonth()
                                      ? "opacity-50"
                                      : ""
                                  } `}
                                >
                                  {date.getDate()}
                                </div>
                              ) : (
                                <div className="grow shrink basis-0 p-2 rounded-lg flex-col justify-start items-start gap-1 inline-flex"></div>
                              )}
                            </div>
                          ))}
                        </div>
                      )
                    )
                  )}
                </div>
                {type == "time" && (
                  <TimeRange selectedTime={handleSelectedTime} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Calendar.propTypes = {
  onSelectDate: PropTypes.func, // Callback when a date is selected
  initialDate: PropTypes.instanceOf(Date), // Initial selected date
  inputPlaceholder: PropTypes.string, // Placeholder for the input field
};

export default Calendar;
