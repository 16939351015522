import React from "react";

export const MentorsFrom = ({courseDetails}) => {
  return <div className="w-full h-auto flex-col justify-start items-center gap-8 inline-flex">
  <div className="w-full h-auto text-center text-gray-100 text-2xl font-bold font-satoshi">Our mentors are from</div>

  <div className="w-full py-4 flex overflow-scroll no-scrollbar ">
  {courseDetails?.mentorsCompany?.map((company, index) => (
    <div key={index} className="w-full justify-center items-center inline-flex overflow-scroll no-scrollbar">
        <img height="88" className="" src={company} alt={`Company ${index}`} /> 
    </div>
))}

  </div>
</div>
};
