import React from "react";

export const ProgramComp = ({reviewData}) => {
    console.log(reviewData)
  return <div className=" py-[70px] w-full h-auto flex flex-col gap-[72px] bg-gradient-to-b from-black to-[#000000]">

<div className="w-full h-auto flex-col justify-start items-center gap-10 inline-flex">
    <div className="self-stretch flex-col justify-center items-center gap-6 flex">
        <div className="justify-start items-start gap-4 inline-flex">
            <div className="flex-col justify-start items-start inline-flex">
                <div className="text-center text-white text-[56px] font-bold font-satoshi leading-[61.60px]">Programs</div>
            </div>
        </div>
        <div className="text-center text-neutral-300 text-lg font-normal font-satoshi leading-[28.80px]">Land your next job at top companies with these certified programs</div>
    </div>
</div>
<div className="w-full inline-flex overflow-scroll no-scrollbar gap-[15px] hover:animate-scroll">
    {/* <div className="bg-gray-100 opacity-20 w-[200px] " style={{zIndex:2}}></div> */}

   
    <div className="w-full inline-flex relative">

   {/* <img src="GradienteffectLeft.png" alt="Gradient Effect" className="absolute sticky top-0 left-0 h-full object-cover"/> */}
   <div className="ml-[-6rem] w-full inline-flex gap-[15px] animate-marquee whitespace-nowrap">
   {reviewData?.reviews_2?.map((index)=>(
       <div key={index} className="w-max h-[83px] p-2 bg-white/5 rounded-2xl border-2 border-white/5 flex-col justify-end items-start inline-flex">
           <div className="w-max flex-col justify-end items-start gap-2.5 flex">
               <div className="w-full justify-start items-start gap-4 inline-flex">
                   <img className="w-[53.92px] h-[54px] rounded-[26.96px]" src={index?.image} />
                   <div className="flex-col justify-start items-start gap-[9px] inline-flex">
                       <div className="justify-start items-center gap-1.5 inline-flex">
                           <div className="p-[5px] bg-emerald-100/opacity-10 rounded-[7px] justify-start items-start gap-2.5 flex">
                               <div className="text-emerald-500 text-xs font-bold font-satoshi leading-[17.88px]">{index?.from}</div>
                           </div>
                           <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
  <path d="M3.35156 9H12.7339" stroke="#73D9B5" stroke-width="1.34033" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.04297 4.30884L12.7341 9L8.04297 13.6912" stroke="#73D9B5" stroke-width="1.34033" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                           </div>
                           <div className="w-max text-white text-xs font-bold font-satoshi leading-[17.88px]">{index?.to}</div>
                       </div>
                       <img className="h-7" src={index?.companyImage} />
                   </div>
               </div>
           </div>
       </div>
   ))}
    {reviewData?.reviews_2?.map((index)=>(
       <div key={index} className="w-max h-[83px] p-2 bg-white/5 rounded-2xl border-2 border-white/5 flex-col justify-end items-start inline-flex">
           <div className="w-max flex-col justify-end items-start gap-2.5 flex">
               <div className="w-full justify-start items-start gap-4 inline-flex">
                   <img className="w-[53.92px] h-[54px] rounded-[26.96px]" src={index?.image} />
                   <div className="flex-col justify-start items-start gap-[9px] inline-flex">
                       <div className="justify-start items-center gap-1.5 inline-flex">
                           <div className="p-[5px] bg-emerald-100/opacity-10 rounded-[7px] justify-start items-start gap-2.5 flex">
                               <div className="text-emerald-500 text-xs font-bold font-satoshi leading-[17.88px]">{index?.from}</div>
                           </div>
                           <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
  <path d="M3.35156 9H12.7339" stroke="#73D9B5" stroke-width="1.34033" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.04297 4.30884L12.7341 9L8.04297 13.6912" stroke="#73D9B5" stroke-width="1.34033" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                           </div>
                           <div className="w-max text-white text-xs font-bold font-satoshi leading-[17.88px]">{index?.to}</div>
                       </div>
                       <img className="h-7" src={index?.companyImage} />
                   </div>
               </div>
           </div>
       </div>
   ))}

       </div>

   </div>
</div>
  </div>;
};
