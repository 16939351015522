import React, { useEffect, useState } from "react";
import ButtonChild from "../../../../../components/buttons/buttonChild";
import FillButton from "../../../../../components/buttons/fillButton";
import { placementReports } from "../../../../../services/jobs";
import { categoriesWithJobCount, jobDescbyId } from "../../../../../services/landingPageApi";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getUserDetails } from "../../../../../services/loginOrSignUp";
import { usePath } from "../../../../../routes/context/path/pathContext";
import Loader from "../../../../../components/loader/loader";

export const PlacementReport = ({hoursDifference, remainingDays, remainingHours,remainingSeconds,remainingMinutes,closePlacementReport,setActiveSection, id , jobId, appliedDate, daysDifference}) => {
    const [jobDetails, setJobDetails] = useState([]);
    const { goToJobDetails} = usePath();
    const [userData, setUserData] = useState([]);

    const [reports, setReports] = useState([]);
    const [activeSubSection , setActiveSubSection] = useState("reportSection")

    const [isOpen, setIsOpen] = useState(false);
    const [isQ1Open, setIsQ1Open] = useState(false);
    const [isQ2Open, setIsQ2Open] = useState(false);
    const [category, setCategory] = useState([]);
    const [Loading, setLoading] = useState(true);

    const onChange =()=>{
        setIsOpen((prev)=>!prev);
    }

    const navigate = useNavigate()
    // const jobDescription = (id) =>{
    //     setActiveSection("jobDescription");
    // }
    useEffect(()=>{
        const fetchReport = async ()=>{
            const user = await  getUserDetails();
            setUserData(user)
            const response = await placementReports(id);
            const response1 = await jobDescbyId(jobId);
            if(response1){
                setLoading(false);
            }
            setJobDetails(response1?.data)
            setReports(response?.data)
        }
        fetchReport();
    },[])


    const onReportClick = ()=>{
        setActiveSubSection("reports")
    }

    useEffect(() => {
        const fetchJob = async () => {
          const categoriesJobs = await categoriesWithJobCount();
          setCategory(
            categoriesJobs?.data?.categories.map((index) => ({
              _id: index._id,
              name: index.name,
            }))
          );
        };
        fetchJob();
      }, []);



    //   useEffect(() => {
    //     // Simulating an asynchronous action
    //     const fakeAsyncAction = async () => {
    //       try {
    //         // Simulate an API call or any asynchronous action
    //         await new Promise((resolve) => {
    //             if(jobDetails){
    //             setTimeout(resolve, 1000)}
    //       });
    //       } finally {
    //         // Set isLoading to false once the asynchronous action is done
    //         setLoading(false);
    //       }
    //     };
    
    //     // Call the fakeAsyncAction when the component mounts
    //     fakeAsyncAction();
    //   }, []); // Empty dependency array means this effect runs once on mount
    
      // Render the loader if isLoading is true
    //   if (Loading) {
    //     return <Loader />;
    //   }
    
  return (
    <div className=" absolute max-h-screen top-0 overflow-scroll no-scrollbar inset-0 flex items-start justify-center w-full  ">
        <div className=" fixed h-full overflow-auto bg-white rounded-lg shadow-2xl w-full sm:w-[70%] xl:w-[60%] my-auto flex justify-center">
     <div className="inset-0 bg-black opacity-5" onClick={close}></div>
      {Loading ?
    <Loader />  
    :
      <div className="z-10 bg-white rounded-lg shadow-lg overflow-hidden min-w-5xl w-full h-auto overflow-scroll no-scrollbar">
        <div className="px-4 md:px-16 py-8 h-auto overflow-auto no-scrollbar">
          <div className="flex justify-between items-center mb-8">
          
            <div className="flex flex-row">
                <div onClick={()=>setActiveSubSection("reportSection")} className={`${activeSubSection == "reports" ? "flex" : "hidden"} flex cursor-pointer justify-center items-center leading-none`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M5.80781 9.5583L12.0578 3.3083C12.1452 3.22079 12.2566 3.16119 12.3779 3.13703C12.4992 3.11287 12.625 3.12525 12.7392 3.1726C12.8535 3.21994 12.9512 3.30012 13.0198 3.403C13.0885 3.50587 13.1251 3.6268 13.125 3.75048L13.125 16.2505C13.1251 16.3742 13.0885 16.4951 13.0198 16.598C12.9512 16.7008 12.8535 16.781 12.7392 16.8284C12.625 16.8757 12.4992 16.8881 12.3779 16.8639C12.2566 16.8398 12.1452 16.7802 12.0578 16.6927L5.80781 10.4427C5.7497 10.3846 5.7036 10.3157 5.67215 10.2398C5.6407 10.1639 5.62451 10.0826 5.62451 10.0005C5.62451 9.91835 5.6407 9.83702 5.67215 9.76115C5.7036 9.68527 5.7497 9.61634 5.80781 9.5583Z" fill="#667984"/>
</svg>
</div>
                <div className="">
              <div className="text-teal-950 text-xl font-bold">{jobDetails?.job_profile}</div>
              <div className="text-sm text-gray-600">{jobDetails?.company_info?.name}</div>
              </div>
            </div>
            <div onClick={closePlacementReport}>
            <ButtonChild
              textColor="gray"
              leftIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M16.0673 15.1829C16.1254 15.241 16.1714 15.3099 16.2028 15.3858C16.2343 15.4617 16.2505 15.543 16.2505 15.6251C16.2505 15.7072 16.2343 15.7885 16.2028 15.8644C16.1714 15.9403 16.1254 16.0092 16.0673 16.0673C16.0092 16.1254 15.9403 16.1714 15.8644 16.2028C15.7885 16.2343 15.7072 16.2505 15.6251 16.2505C15.543 16.2505 15.4617 16.2343 15.3858 16.2028C15.3099 16.1714 15.241 16.1254 15.1829 16.0673L10.0001 10.8837L4.81729 16.0673C4.70002 16.1846 4.54096 16.2505 4.3751 16.2505C4.20925 16.2505 4.05019 16.1846 3.93292 16.0673C3.81564 15.95 3.74976 15.791 3.74976 15.6251C3.74976 15.4593 3.81564 15.3002 3.93292 15.1829L9.11651 10.0001L3.93292 4.81729C3.81564 4.70002 3.74976 4.54096 3.74976 4.3751C3.74976 4.20925 3.81564 4.05019 3.93292 3.93292C4.05019 3.81564 4.20925 3.74976 4.3751 3.74976C4.54096 3.74976 4.70002 3.81564 4.81729 3.93292L10.0001 9.11651L15.1829 3.93292C15.3002 3.81564 15.4593 3.74976 15.6251 3.74976C15.791 3.74976 15.95 3.81564 16.0673 3.93292C16.1846 4.05019 16.2505 4.20925 16.2505 4.3751C16.2505 4.54096 16.1846 4.70002 16.0673 4.81729L10.8837 10.0001L16.0673 15.1829Z"
                    fill="currentColor"
                  />
                </svg>
              }
            
            />
            </div>
          </div>
          {activeSubSection === "reportSection" &&
          <div className="w-full flex flex-col">
          <div className="pb-4">
            <div className="text-sm font-bold text-teal-950">Status:</div>
          </div>
          <div className="w-full h-auto p-2 bg-white rounded-2xl border-2 border-gray-100 flex-col justify-start items-start gap-4 inline-flex">
    <div className="self-stretch px-2 py-4 border-dotted border-b-2 border-gray-200 justify-start items-center gap-4 inline-flex">
        <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
            <div className="text-[#667984] text-base font-[700]  leading-normal">Applied</div>
        </div>
        <div className="text-slate-500 text-base font-bold  capitalize leading-tight"> {daysDifference < 1
            ? `${hoursDifference} hr`
            : daysDifference < 7
            ? `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'}`
            : daysDifference < 30
            ? `${Math.floor(daysDifference / 7)} ${Math.floor(daysDifference / 7) === 1 ? 'week' : 'weeks'}`
            : `${Math.floor(daysDifference / 30)} ${Math.floor(daysDifference / 30) === 1 ? 'month' : 'months'}`}</div>
    </div>
    
    <div onClick={onChange} className="cursor-pointer w-full self-stretch px-2 border-dotted border-b-2 border-gray-200 justify-end items-center gap-4 flex">
        <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
            <div className="text-gray-400 text-base font-bold  leading-normal">FAQ’s</div>
        </div>
        <div className={` h-12 py-[3.12px] flex items-center justify-end ${isOpen ? "-rotate-0": "-rotate-180"} `}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M9.55732 14.1922L3.30732 7.94219C3.21981 7.85478 3.16021 7.74337 3.13605 7.62207C3.1119 7.50076 3.12427 7.37502 3.17162 7.26076C3.21896 7.14649 3.29915 7.04884 3.40202 6.98017C3.50489 6.91151 3.62582 6.8749 3.74951 6.875L16.2495 6.875C16.3732 6.8749 16.4941 6.91151 16.597 6.98017C16.6999 7.04884 16.7801 7.14649 16.8274 7.26076C16.8747 7.37502 16.8871 7.50076 16.863 7.62207C16.8388 7.74337 16.7792 7.85478 16.6917 7.94219L10.4417 14.1922C10.3836 14.2503 10.3147 14.2964 10.2388 14.3279C10.163 14.3593 10.0816 14.3755 9.99951 14.3755C9.91737 14.3755 9.83604 14.3593 9.76017 14.3279C9.6843 14.2964 9.61537 14.2503 9.55732 14.1922Z" fill="#99A6AD"/>
</svg>
            </div>
    </div>
    {isOpen && 
    <div className="w-full">
    <div className="w-full h-auto px-2 py-4 border-dotted border-b-2 border-gray-200 flex-col justify-start items-start gap-2 inline-flex">
    <div onClick={()=>setIsQ1Open((prev)=>!prev)} className="cursor-pointer w-full h-auto inline-flex justify-between items-center">
    <div className="w-full text-gray-400 text-base font-bold font-satoshi leading-[17.88px]">What happens next?</div>
{/* origin-top-left -rotate-180 */}
    <div className={`w-[29px] h-[29px] -rotate-180 ${isQ1Open ? "-rotate-0" : ""}`}><svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none"><path d="M8.5 17.5L14.5 11.5L20.5 17.5" stroke="#99A6AD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
</div>

{isQ1Open &&

    <div className="self-stretch text-gray-400 text-opacity-70 text-base font-medium font-satoshi leading-[17.88px]">It takes some time for companies to respond, as it depends on the volume of applications. Generally, they respond to candidates within 1-2 weeks. We will keep you updated on any progress here.</div>
}
</div>
<div className="w-full h-auto px-2 py-4 border-dotted border-b-2 border-gray-200 flex-col justify-start items-start gap-2 inline-flex">
<div onClick={()=>setIsQ2Open((prev)=>!prev)} className=" cursor-pointer w-full h-auto inline-flex justify-between items-center">
    <div className="w-full text-gray-400 text-base font-bold font-satoshi leading-[17.88px]">What happens if I cancel / apply again?</div>
{/* origin-top-left -rotate-180 */}
<div className={`w-[29px] h-[29px] -rotate-180 ${isQ2Open ? "-rotate-0" : ""}`}><svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none"><path d="M8.5 17.5L14.5 11.5L20.5 17.5" stroke="#99A6AD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
</div>
    {/* <div className="self-stretch justify-start items-center inline-flex justify-between itesm-center">
        <div className="grow shrink basis-0 text-gray-400 text-base font-bold font-satoshi leading-[17.88px]">What happens if I cancel / apply again?</div>
        <div className="w-[29px] h-[29px] origin-top-left -rotate-180">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
  <path d="M8.5 17.5L14.5 11.5L20.5 17.5" stroke="#99A6AD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
    </div> */}
    {isQ2Open &&
    <div className="self-stretch"><span className="text-gray-400 text-opacity-70 text-base font-medium font-satoshi leading-[17.88px]">Cancelling the Application still allows the Recruiter to view your profile and see that the application was cancelled by you.<br/><br/>It doesn't reflect well on your profile to cancel your application unnecessarily. If there's an issue with a recruiter, please </span>
    <Link target="_blank" to="mailto:contact@eventbeep.com" >
    <span  className="text-gray-400 text-base font-bold font-satoshi underline leading-[17.88px]">get in touch</span>
    </Link>
    <span className="text-gray-400 text-opacity-70 text-base font-medium font-satoshi leading-[17.88px]"> with us.<br/><br/>If it was a mistake, you can apply once again. In that case, your status will move back to applied.</span></div>
    }
    </div>
</div>
    }
    <div className="self-stretch px-2 py-2 justify-start items-start gap-4 inline-flex flex-col">
    {daysDifference <= 7 && (
    <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
        {daysDifference > 1 ? (
            <div className="text-amber-300 text-base font-bold  leading-normal">
                Report to be available in {remainingDays} days
            </div>
        ) : (
            <div className="text-amber-300 text-base font-bold  leading-normal">
                Report to be available in {remainingHours} hours
            </div>
        )}
    </div>
)}

      {daysDifference >= 7 && (
                                <div
                                    onClick={onReportClick}
                                    className="w-full h-11 px-11 py-3 bg-amber-300 rounded-xl justify-center items-end gap-2 inline-flex cursor-pointer"
                                >
                                    <div className="text-center text-white text-base font-black  leading-tight">
                                        View Report
                                    </div>
                                    <div className="w-5 h-5 relative" />
                                </div>
                            )}
    </div>
</div>
<div className="w-full h-auto flex-col justify-start items-start gap-4 pt-8 inline-flex">
    <div className="self-stretch justify-center items-center gap-2 inline-flex">
        <div className="grow shrink basis-0 h-6 text-teal-950 text-base font-black  leading-normal">Review:</div>
    </div>
    <div className="self-stretch h-auto bg-gray-100 rounded-2xl border-2 border-gray-100 flex-col justify-start items-start gap-4 flex">
        <div className="w-full py-4 justify-start items-center gap-4 flex px-2">
        <div className=" bg-blue-500 bg-opacity-10 rounded-[28px] justify-center items-center flex">
                {/* <div className="grow shrink basis-0 self-stretch pl-[1.88px] pr-[2.62px] pt-0.5 pb-[2.50px] justify-center items-center inline-flex"> */}
                {jobDetails?.company_info?.logo ? 
           <img className="w-12 h-12 rounded-full" src={jobDetails?.company_info?.logo} />
           :
           <img className="w-12 h-12 rounded-full" src="profile_9706583.png" />
        }
                {/* </div> */}
            </div>
                <div className="h-auto flex-col justify-start items-start inline-flex">
                    <div className="w-max self-stretch text-[#002032] text-base font-[500]  capitalize leading-normal">{jobDetails?.job_profile}</div>
                    <div className="w-max self-stretch text-[#667984] text-sm font-medium  capitalize leading-tight">{jobDetails?.company_info?.name}</div>
                </div>
            <div onClick={()=>{goToJobDetails({category:category,type:jobDetails?.job_type, jobCateg:jobDetails?.category, profile:jobDetails?.job_profile, id:jobDetails._id })}}  
            // onClick={()=>jobDescription(jobId)} 
            className="grow text-right text-slate-500 text-base font-bold  capitalize leading-tight cursor-pointer mr-4">
            View
                </div>
        </div>
    </div>
    <div className="self-stretch h-auto bg-gray-100 rounded-2xl border-2 border-gray-100 flex-col justify-start items-start gap-4 flex">
        <div className="w-full px-2 py-4 justify-start items-center gap-4 inline-flex">
            <div className=" bg-blue-500 bg-opacity-10 rounded-[28px] justify-center items-center flex">
                {/* <div className="grow shrink basis-0 self-stretch pl-[1.88px] pr-[2.62px] pt-0.5 pb-[2.50px] justify-center items-center inline-flex"> */}
                {userData?.profileInfo?.avatar ? 
           <img className="w-12 h-12 rounded-full" src={userData?.profileInfo?.avatar} />
           :
           <img className="w-12 h-12 rounded-full" src="profile_9706583.png" />
        }
                {/* </div> */}
            </div>
            <div className="h-auto flex-col justify-start items-start inline-flex grow">
                <div className="self-stretch text-teal-950 text-base font-medium  capitalize leading-normal">{userData?.profileInfo?.name}</div>
                {/* <div className="w-max self-stretch text-[#667984] text-sm font-medium  capitalize leading-tight">Your Application</div> */}
            </div>
            {/* <div  
            // onClick={()=>jobDescription(jobId)} 
            className="grow text-right text-slate-500 text-base font-bold  capitalize leading-tight cursor-pointer mr-4">
            View
                </div> */}

        </div>
    </div>
</div>
</div>
}

{activeSubSection === "reports" &&
<div className="w-full">
<div className="w-full h-14 py-4 border-dotted border-t-2 border-b-2 border-gray-200 flex-col justify-center items-center gap-4 inline-flex">
    <div className="self-stretch h-6 justify-start items-center gap-2 inline-flex">
        <div className={`grow shrink basis-0 ${reports?.recruiterViewed == false ? "text-orange-500": "text-emerald-500"} text-base font-bold  leading-normal`}>{reports?.recruiterViewed == false ? "Recruiter did not view your profile" : "Recruiter viewed your profile"}</div>
    </div>
</div>
<div className="w-full h-72 p-2 my-8 bg-gray-100 rounded-2xl border-2 border-gray-100 flex-col justify-start items-start gap-4 inline-flex">
    <div className="self-stretch px-2 py-4 border-dotted border-b-2 border-gray-200 justify-start items-center gap-4 inline-flex">
        <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
            <div className="text-teal-950 text-base font-bold  leading-normal">Applied candidates</div>
        </div>
        <div className="text-teal-950 text-base font-bold  capitalize leading-tight">{reports?.applied}</div>
    </div>
    <div className="self-stretch px-2 py-4 border-dotted border-b-2 border-gray-200 justify-start items-center gap-4 inline-flex">
        <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
            <div className="text-teal-950 text-base font-bold  leading-normal">Shortlisted candidates</div>
        </div>
        <div className="text-teal-950 text-base font-bold  capitalize leading-tight">{reports?.shortlisted}</div>
    </div>
    <div className="self-stretch px-2 py-4 border-dotted border-b-2 border-gray-200 justify-start items-center gap-4 inline-flex">
        <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
            <div className="text-teal-950 text-base font-bold  leading-normal">Viewed candidates</div>
        </div>
        <div className="text-teal-950 text-base font-bold  capitalize leading-tight">{reports?.viewed}</div>
    </div>
    <div onClick={onChange} className="cursor-pointer w-full self-stretch px-2 border-dotted border-b-2 border-gray-200 justify-end items-center gap-4 flex">
        <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
            <div className="text-gray-400 text-base font-bold  leading-normal">FAQ’s</div>
        </div>
        <div className={` h-12 py-[3.12px] flex items-center justify-end ${isOpen ? "-rotate-0": "-rotate-180"} `}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M9.55732 14.1922L3.30732 7.94219C3.21981 7.85478 3.16021 7.74337 3.13605 7.62207C3.1119 7.50076 3.12427 7.37502 3.17162 7.26076C3.21896 7.14649 3.29915 7.04884 3.40202 6.98017C3.50489 6.91151 3.62582 6.8749 3.74951 6.875L16.2495 6.875C16.3732 6.8749 16.4941 6.91151 16.597 6.98017C16.6999 7.04884 16.7801 7.14649 16.8274 7.26076C16.8747 7.37502 16.8871 7.50076 16.863 7.62207C16.8388 7.74337 16.7792 7.85478 16.6917 7.94219L10.4417 14.1922C10.3836 14.2503 10.3147 14.2964 10.2388 14.3279C10.163 14.3593 10.0816 14.3755 9.99951 14.3755C9.91737 14.3755 9.83604 14.3593 9.76017 14.3279C9.6843 14.2964 9.61537 14.2503 9.55732 14.1922Z" fill="#99A6AD"/>
</svg>
            </div>
    </div>
    {isOpen && 
    <div className="w-full bg-white p-1 rounded-xl border border-2-gray" style={{zIndex:1}}>
    <div className="w-full h-auto px-2 py-4 border-dotted border-b-2 border-gray-200 flex-col justify-start items-start gap-2 inline-flex">
    <div onClick={()=>setIsQ1Open((prev)=>!prev)} className="cursor-pointer w-full h-auto inline-flex justify-between items-center">
    <div className="w-full text-gray-400 text-base font-bold font-satoshi leading-[17.88px]">What happens next?</div>
{/* origin-top-left -rotate-180 */}
    <div className={`w-[29px] h-[29px] -rotate-180 ${isQ1Open ? "-rotate-0" : ""}`}><svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none"><path d="M8.5 17.5L14.5 11.5L20.5 17.5" stroke="#99A6AD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
</div>

{isQ1Open &&

    <div className="self-stretch text-gray-400 text-opacity-70 text-base font-medium font-satoshi leading-[17.88px]">It takes some time for companies to respond, as it depends on the volume of applications. Generally, they respond to candidates within 1-2 weeks. We will keep you updated on any progress here.</div>
}
</div>
<div className="w-full h-auto px-2 py-4 border-dotted border-b-2 border-gray-200 flex-col justify-start items-start gap-2 inline-flex">
<div onClick={()=>setIsQ2Open((prev)=>!prev)} className=" cursor-pointer w-full h-auto inline-flex justify-between items-center">
    <div className="w-full text-gray-400 text-base font-bold font-satoshi leading-[17.88px]">What happens if I cancel / apply again?</div>
{/* origin-top-left -rotate-180 */}
<div className={`w-[29px] h-[29px] -rotate-180 ${isQ2Open ? "-rotate-0" : ""}`}><svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none"><path d="M8.5 17.5L14.5 11.5L20.5 17.5" stroke="#99A6AD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg></div>
</div>
    {/* <div className="self-stretch justify-start items-center inline-flex justify-between itesm-center">
        <div className="grow shrink basis-0 text-gray-400 text-base font-bold font-satoshi leading-[17.88px]">What happens if I cancel / apply again?</div>
        <div className="w-[29px] h-[29px] origin-top-left -rotate-180">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
  <path d="M8.5 17.5L14.5 11.5L20.5 17.5" stroke="#99A6AD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
    </div> */}
    {isQ2Open &&
    <div className="self-stretch"><span className="text-gray-400 text-opacity-70 text-base font-medium font-satoshi leading-[17.88px]">Cancelling the Application still allows the Recruiter to view your profile and see that the application was cancelled by you.<br/><br/>It doesn't reflect well on your profile to cancel your application unnecessarily. If there's an issue with a recruiter, please </span>
    <Link target="_blank" to="mailto:contact@eventbeep.com" >
    <span  className="text-gray-400 text-base font-bold font-satoshi underline leading-[17.88px]">get in touch</span>
    </Link>
    <span className="text-gray-400 text-opacity-70 text-base font-medium font-satoshi leading-[17.88px]"> with us.<br/><br/>If it was a mistake, you can apply once again. In that case, your status will move back to applied.</span></div>
    }
    </div>
</div>
    }
</div>
<div className="w-full h-[104px] py-4 flex-col justify-start items-start gap-4 inline-flex">
    <div className="self-stretch justify-center items-center gap-2 inline-flex">
        <div className="grow shrink basis-0 h-6 text-teal-950 text-base font-black  leading-normal">Top skills by shortlisted candidates</div>
    </div>
    <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
    {reports?.mySkills?.map((skill, index) => (
        <div key={index} className="px-2 py-1.5 bg-gray-100 rounded-3xl border-2 border-gray-200 justify-start items-start gap-0.5 flex">
            <div className="text-slate-700 text-sm font-medium  leading-tight">{skill}</div>
        </div>
    ))}
</div>

</div>

</div>
}
          <div className="flex justify-center my-8">
            
            <FillButton
            onClick={() => {navigate(`/internships`), closePlacementReport()}}
            color="green"
            textColor="white"
            text="View Recommended Jobs"
             />
            
            {/* <button className="px-6 py-3 bg-teal-500 text-white font-bold rounded-lg">View Recommended Jobs</button> */}
          </div>
        </div>
      </div>
}
      </div>
      </div>
   
  );
};
