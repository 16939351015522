import React, { useState } from "react";
import ButtonChild from "./buttonChild";

const BorderButton = ({
  leftIcon,
  rightIcon,
  color,
  text,
  textClass,
  textColor,
  isLoading,
  buttonClass,
  colorMap,
  className,
  disabled,
  rightText,
  leftText,
  hasError,
  leftIconClass,
  fullWidth,
  ...props
}) => {
  const [isPressed, setIsPressed] = useState(false);
  colorMap = {
    red: {
      default:
        "border-red-500 bg-white hover:cursor-pointer active:cursor-auto hover:shadow-[rgba(255,0,0,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset] active:bg-red-50 active:shadow-[rgba(255,0,0,0.5)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]",
      pressed:
        "bg-red-50 border-red-500 shadow-[rgba(255,0,0,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]",
      disabled: "cursor-not-allowed border-red-100 text-red-100",
      loading: "border-red-300 text-red-300",
    },

    green: {
      default:
        "border-green-500 bg-white hover:cursor-pointer active:cursor-auto hover:shadow-[rgba(22,192,131,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset] active:bg-green-50 active:shadow-[rgba(22,192,131,0.5)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]",
      pressed:
        "bg-green-50 border-green-500 shadow-[rgba(22,192,131,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]",
      disabled: "cursor-not-allowed border-green-100 text-green-100",
      loading: "border-green-300 text-green-300",
    },
    orange: {
      default:
        "border-orange-500 bg-white hover:cursor-pointer active:cursor-auto hover:shadow-[rgba(250,190,88,0.4)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset] active:bg-orange-50 active:shadow-[rgba(250,190,88,0.6)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]",
      pressed:
        "bg-orange-50 border-orange-500 shadow-[rgba(250,190,88,0.4)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]",
      disabled: "cursor-not-allowed border-orange-200 text-orange-200 ",
      loading: "border-orange-300 text-orange-300",
    },
    gray: {
      default:
        "border-gray-100 bg-white hover:cursor-pointer active:cursor-auto hover:shadow-[rgba(210,215,211,0.2)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset] active:broder-gray-200 active:bg-gray-50 active:shadow-[rgba(210,215,211,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]",
      pressed:
        "bg-gray-50 border-gray-200 shadow-[rgba(210,215,211,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]",
      disabled: "cursor-not-allowed border-gray-200 text-gray-200",
      loading: "border-gary-400 text-gray-400",
    },
  };

  if (!colorMap) {
    colorMap = {}; // You might want to provide default values here
  }

  const colorData = colorMap[color] || {}; // Handle the case where color is undefined

  const textColorClass = isPressed
    ? colorData.pressed || ""
    : disabled
    ? colorData.disabled || ""
    : isLoading
    ? `${colorData.loading}`
    : colorData.default || "";

  const buttonClasses = `w-auto h-11 px-spaceBase py-spaceSmall rounded-xl justify-start items-center inline-flex border-2  ${
    disabled
      ? `${colorData.disabled} opacity-50 cursor-not-allowed`
      : isPressed
      ? `${colorData.pressed}`
      : isLoading
      ? `${colorData.loading}`
      : hasError
      ? `border-red-500`
      : `${colorData.default}`
  } ${className}`;

  return (
    <>
      <button
        className={`disabled:cursor-not-allowed ${className} `}
        disabled={isLoading || disabled}
        {...props}
      >
        <ButtonChild
          {...props}
          text={text}
          buttonClass={`${
            (leftIcon || rightIcon) && text
              ? "gap-2"
              : leftIcon || rightIcon || text
              ? "gap-0"
              : "gap-0"
          } ${buttonClass}  ${buttonClasses}  ${textColorClass}
       
        `}
          textColor={textColor ? textColor : color}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          rightText={rightText}
          leftText={leftText}
          colorMap={colorData}
          disabled={isLoading || disabled}
          isLoading={isLoading}
          textClass={`${textClass}`}
          leftIconClass={leftIconClass}
        />
      </button>
    </>
  );
};
export default BorderButton;
