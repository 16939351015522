export const extractDomainFromUrl =(url)=> {
    let domain = "";
    // Regex to match domain name from URL
    const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)\.(?:com|in|org|net|gov|edu|uk)(?:$|\/)/;
  
    const matches = url.match(domainRegex);
    if (matches && matches.length > 1) {
      domain = matches[1];
    }
  
    return domain;
  }


  export const isValidIndianMobileNumber=(number) =>{
    const regex = /^[6-9]\d{9}$/; 
    return regex.test(number);
  }

  export const isValidEmail=(email)=> {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  export const extractDigits =(str) =>{
    return (str.match(/\d+/g)??[""]).join('');
  }

  