import React, { useEffect, useState } from 'react';
import { DashLeftSection } from '../dashboard/LoggedInUsers/leftSection';
import { ScreensHeading } from '../dashboard/LoggedInUsers/rightSection/screens/heading';
import Chat from './chat';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/loader';
import { Helmet } from 'react-helmet-async';

const ChatWrapper = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const auth = localStorage.getItem("auth");
    const navigate = useNavigate();
  useEffect(()=>{
 
    if(!auth)
    navigate("/login-or-signup")
  },[])

  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };

    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount

  // Render the loader if isLoading is true
  if (Loading && !auth) {
    return <Loader />;
  }
    return ( 
        <>
           <Helmet>
          <title>Dashboard: Messages</title>
        </Helmet>
        <div className="flex max-h-screen">
            <div className={`min-w-[20%] lg:max-w-[25%] ${
          menuOpen ? "w-full sm:min-w-[20%]" : "hidden sm:flex"
        } h-screen transition-all duration-300`}
        style={{
          position: menuOpen ? "absolute" : "static",
          left: menuOpen ? 0 : "-100%",
          zIndex: menuOpen ? (window.innerWidth < 768 ? 5 : 999) : "auto",
        }}>
        <DashLeftSection
        
        activeSection={"messages"}
        setMenuOpen={setMenuOpen}
        menuOpen={menuOpen}

        /></div>
        <div className="flex flex-col w-full max-h-screen overflow-scroll gap-2">
        <ScreensHeading activeSection={"messages"} setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
        <div className="px-4 w-full h-screen">
        <Chat />
        </div>
        
        
        </div>
        </div>
        
        </>
     );
}
 
export default ChatWrapper;