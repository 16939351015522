import React from "react";

export const ProgramBenefits = ({ courseDetails }) => {
  return (
    <div className="w-full h-auto">
      {" "}
      <div
        className={`self-stretch h-auto  flex-col justify-start items-start gap-10 flex ${courseDetails?.type === "JOB PLACEMENT" ? "pt-16 xl:pt-[240px]" : "pt-0 md:pt-0"}`}
      >
        {/* {courseDetails?.type === "JOB PLACEMENT" &&
  <div className="h-[76.45px]" />
} */}
        <div className="self-stretch h-auto flex-col justify-start items-center gap-8 flex">
          <div className="flex-col justify-start items-center gap-4 flex">
            <div className="max-w-[652px] h-[53px] text-center text-gray-100 text-2xl font-bold font-satoshi">
              Amazing benefits from one degree
            </div>
            <div className="max-w-[376px] h-[0px] border-2 border-black"></div>
          </div>
          <div className="self-stretch h-auto px-4 md:px-8 lg:px-[124px] py-8 flex-col justify-start items-center gap-16 flex">
            <div className="w-full justify-center items-center gap-24 inline-flex overflow-scroll no-scrollbar">
              {courseDetails?.highlights?.map((index) => (
                <div
                  key={index}
                  className="flex-col justify-start items-center gap-4 inline-flex border-gradient-to-t from-[#00000033] to-[#FFF4C633]"
                >
                  <div className="w-[66px] h-[66px] relative">
                    {/* <div className="w-[66px] h-[66px] left-0 top-0 absolute rounded-full border-2 border-emerald-300" /> */}
                    <div className="w-[66px] h-[66px]">
                      <img src={index?.image} />
                    </div>
                  </div>
                  <div className="flex-col justify-start items-center gap-2 flex">
                    <div className="text-white text-base font-bold font-satoshi leading-[17.85px] tracking-tight">
                      {index?.title}
                    </div>
                    <div className="text-center text-gray-100 text-base font-medium font-satoshi leading-[17.85px] tracking-tight">
                      {index?.subTitle}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="relative flex-col justify-start items-center gap-4 md:gap-8 flex rounded-xl ">
              {courseDetails?.type === "MICRO DEGREE" && (
                <div className="absolute flex justify-center items-center transform translate[-50%,-50%] bg-slate-900 h-auto lg:min-h-[400px] min-w-[800px] rounded-full filter blur-[100px] opacity-70"></div>
              )}
              <div className="w-full justify-center items-center flex flex-wrap md:grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 inline-flex relative z-0 ">
                {courseDetails?.perks?.map((index) => (
                  <div
                    key={index}
                    className="relative w-[269px] h-auto p-6 bg-stone-900/70 rounded-2xl flex-col justify-start items-start gap-10 inline-flex border-gradient "
                  >
                    <div className="justify-start items-center gap-[11px] inline-flex">
                      <div className="justify-start items-start gap-[16.26px] flex">
                        <div className="bg-white/5 rounded-lg justify-start items-start gap-[16.26px] flex">
                          <div className="justify-start items-start gap-[12.12px] flex">
                            <div className="w-[38.80px] h-[38.82px] relative">
                              <img src={index?.image} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-[158px] text-white text-xl font-bold font-satoshi tracking-tight">
                        {index?.title}
                      </div>
                    </div>
                    <div className="self-stretch h-auto flex-col justify-start items-start gap-0.5 flex">
                      <div className="self-stretch text-white text-base font-medium font-satoshi leading-[17.85px]">
                        {index?.subTitle}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
