import moment from "moment";
import React, { useState } from "react";
import { browserName, isMobileOnly } from "react-device-detect";
import { useNavigate } from "react-router";
import { Mixpanel } from "../../../../services/mixpanel";
import FillButton from "../../../../components/buttons/fillButton";
import { usePath } from "../../../../routes/context/path/pathContext";

export const MicroDegree = ({ courseData, userData, joinWaitingList, formatDate, redirectToLogin, redirectToProgramDetails }) => {

  const { goToCourseDetails } = usePath()
  // Extract keys of courseData.courseData only if it's an object
  const courseKeys = courseData && courseData.courseData ? Object.keys(courseData.courseData) : [];
  const auth  = localStorage.getItem('auth');
const navigate = useNavigate();

 // State to manage selected category
 const [selectedCategory, setSelectedCategory] = useState(courseData?.categories[0]);

  // Function to handle category click
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };



  return (
    <div className="px-4 sm:px-8 lg:px-16 py-[70px] lg:px-[124px] w-full bg-white h-auto flex-col justify-center items-center gap-8 inline-flex">
            <div className="w-full h-auto flex-col justify-center items-start gap-8 flex">
        <div className="flex-col justify-center items-start gap-4 flex">
            <div className="flex-col justify-start items-start gap-1 flex">
                <div className="text-teal-950 text-[40px] font-black font-satoshi leading-[48px]">{courseData?.programsList?.[0]?.title}</div>
            </div>
            <div className="text-slate-500 text-xl font-bold font-satoshi leading-normal">  Get certified and increase your chances to get placed</div>
        </div>
        <div className="w-full h-auto flex flex-row justify-start items-center gap-8 flex-wrap py-8">
        {/* <div>
        <FillButton
              className={`  ${
                selectedCategory === "All" ? "bg-[#E5E9EA] border-2 border-teal-950 active:bg-[#E5E9EA]" : "bg-gray-50/20 active:bg-[#E5E9EA] hover:bg-[#E5E9EA]"
              }`}
              textColor="black"
              color="gray"
              text="All"
              onClick={() => handleCategoryClick("All")}
            />
          </div> */}

          {courseData?.categories?.map((category) => (
            <div key={category}>
              <FillButton
                className={`active:border-black active:bg-[#E5E9EA]  px-8 ${
                  selectedCategory === category ? "bg-[#E5E9EA] border-2 border-teal-950 active:bg-[#E5E9EA]" : "bg-gray-50/20 hover:bg-[#E5E9EA] active:bg-[#E5E9EA]"
                }`}
                textColor="black"
                color=""
                text={category}
                onClick={() => handleCategoryClick(category)}
              />
            </div>
          ))}
           <div className="w-full md:min-w-[500px] md:max-w-[902px] h-[2px] bg-gradient-to-r from-gray-100 to-white"></div>
      
</div>
    </div>
   

<div className="w-full h-auto flex flex-row gap-8 flex-wrap justify-start">
{courseKeys.length > 0 && (
    courseKeys.map((key) => {
      const course = courseData?.courseData[key];
               // Filter by selected category
            if (
              selectedCategory !== "All" &&
              course?.category !== selectedCategory
            ) {
              return null;
            }

            let cardBgClass = "bg-white";
            if (course?.category === "Programming & Development") {
              cardBgClass = "bg-gradient-to-t from-cyan-100 to-white";
            } else if (course?.category === "Data Science & Analysis") {
              cardBgClass = "bg-gradient-to-t from-lime-100 to-white";
            } else if (course?.category === "Cloud & DevOps") {
              cardBgClass = "bg-gradient-to-t from-indigo-200 to-white";
            } else if (course?.category === "Business & Marketing") {
              cardBgClass = "bg-gradient-to-t from-purple-200 to-white";
            }
      console.log(course?.name)
      if(course?.type === "MICRO DEGREE"){
      return (
          <div key={key} className={`w-full md:w-[376.33px] h-auto`}>
     
            <div onClick={()=>{
              Mixpanel.track("Micro Degree Card Clicked", {
                date: moment().format(),
                distinct_id: localStorage.getItem("userId"),
                user_id: localStorage.getItem("userId"),
                name:userData?.profileInfo?.name,
                number:userData?.number,
                email:userData?.email,
                course_Id:course?._id,
                browser: browserName,
                mobile_device: isMobileOnly,
                type:"webapp",
              });
              // redirectToProgramDetails(key,course?.type, course?.userInWaitlist, course?.userRegistered);
              // goToCourseDetails()
              goToCourseDetails(course?.type.toLowerCase(), course?.description.toLowerCase(), key, course?.userInWaitlist, course?.userRegistered)
              
            }} className={`cursor-pointer w-full  md:w-[376.33px] h-[371px] p-3 bg-white rounded-2xl border-4 border-teal-950 flex-col justify-between items-start  inline-flex relative ${cardBgClass}`} >
            {/* <img src={course.bgImageWeb} alt="Course" className="absolute top-0 right-0 w-[35%] h-[45%] object-cover rounded-tr-2xl" /> */}
              <div className="flex-col justify-start items-start gap-8 flex">
                <div className="justify-start items-start gap-2 inline-flex">
                  <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
                    <div className="justify-start items-start gap-1.5 inline-flex">
                      <div className="rounded-lg px-1.5 py-[3px] bg-gradient-to-r from-rose-100 to-rose-50 justify-center items-center gap-1.5 flex">
                        <div className="text-red-600 text-xs font-black font-satoshi leading-tight">
                          LIVE
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                    <div className="justify-start items-start gap-1.5 inline-flex">
                      <div className="rounded-lg px-1.5 py-[3px] bg-gradient-to-r from-emerald-500 to-emerald-500 justify-center items-center gap-1.5 flex">
                        <div className="text-white text-xs font-black font-satoshi leading-tight">
                          NEW
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div className="flex-col justify-start items-start gap-[10px] flex ">
                  <div className="w-full text-[32px] font-bold font-satoshi text-black flex flex-wrap  leading-normal truncate-2-lines ">
                    {course?.name.toUpperCase()}
                  </div>
                   {(course?.registrationStartTime && !course?.bypassWaitlist) && 
            <div>
              <span className="text-black text-[10px] font-bold font-satoshi leading-none">
                REGISTRATION FROM{" "}
              </span>
              <span className="text-black text-[10px] font-black font-satoshi leading-none">
                {formatDate(course?.registrationStartTime)} IST
              </span>
            </div>

          }

                </div>
              </div>
              <div className="w-full flex flex-col justify-start items-start gap-[10px]">
              <div className="w-full h-11 px-2.5 py-[11px] bg-white/80 justify-start items-center inline-flex">
    <div className="w-max text-black text-xs font-bold font-satoshi leading-[17.88px]">Mentor Led </div>
    <img className="px-2" src="Line.png"/>
    <div className="w-max text-black text-xs font-bold font-satoshi leading-[17.88px]">Internship Assistance</div>
    {/* <div className="w-[22px] h-[0px] origin-top-left rotate-90 border border-amber-200"></div> */}
    <img className="px-2" src="Line.png"/>
    <div className="w-max text-black text-xs font-bold font-satoshi leading-[17.88px]">Industry Projects</div>
</div>
              <div className="self-stretch p-3 rounded-xl justify-start items-center gap-[3px] inline-flex">
                <div className="w-[130px] h-[53px]" >
                  <div className="w-[130px] h-[30px] flex-col justify-center items-start gap-2.5 inline-flex">
                    {/* style={{ backgroundImage: `url(/svg/bgGreenbox.svg)`, backgroundSize: 'cover', backgroundPosition: 'center' }} */}
                    <div className="justify-start items-center gap-1 inline-flex">
                      <div className="text-teal-950 text-xl font-black font-satoshi leading-none">
                        ₹{course?.finalPrice}
                      </div>
                      <div className="opacity-70 text-slate-700 text-xs font-bold font-satoshi line-through leading-none">
                        {course?.actualPrice}
                      </div>
                    </div>
                  </div>
                  <div className="w-[116px] h-5 justify-start items-center gap-[5px] inline-flex">
                    {/* <div className="w-5 h-5 pl-[3px] pr-0.5 pt-0.5 pb-[3px] justify-center items-center flex">
                                    <div className="w-[15px] h-[15px] relative flex-col justify-start items-start flex" />
                                </div> */}
                    <div className="text-slate-700 text-base font-bold font-satoshi leading-none">
                      {course?.waitlistBuffer + course?.waitlistCount} Joined
                    </div>
                  </div>
                </div>
                <div onClick={(e)=>e.stopPropagation()}  className="grow shrink basis-0 self-stretch justify-end items-center flex">
                  {/* <button  

  onClick={() => {
    if (course?.isRegistrationLive === false && !auth) {
      Mixpanel.track("Join Waitlist CTA Clicked", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        user_id: localStorage.getItem("userId"),
        name:userData?.profileInfo?.name,
        number:userData?.number,
        email:userData?.email,
        course_Id:course?._id,
        browser: browserName,
        mobile_device: isMobileOnly,
        type:"webapp",
      });
      redirectToLogin(course?._id);
    } else if(course?.isRegistrationLive === false && auth && !userData?.profileInfo?.name && course?.userInWaitlist === false) {
      navigate(`/join-waitlist/fill-details?id=${course?._id}`);
    } else if(course?.isRegistrationLive === false && auth && userData?.profileInfo?.name !== "" && course?.userInWaitlist === false) {
      Mixpanel.track("Join Waitlist CTA Clicked", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        user_id: localStorage.getItem("userId"),
        name:userData?.profileInfo?.name,
        number:userData?.number,
        email:userData?.email,
        course_Id:course?._id,
        browser: browserName,
        mobile_device: isMobileOnly,
        type:"webapp",
      });
    joinWaitingList(course?._id);
    console.log("h12")
    } else if(course?.isRegistrationLive === true && !auth) {
      Mixpanel.track("Join Waitlist CTA Clicked", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        user_id: localStorage.getItem("userId"),
        name:userData?.profileInfo?.name,
        number:userData?.number,
        email:userData?.email,
        course_Id:course?._id,
        browser: browserName,
        mobile_device: isMobileOnly,
        type:"webapp",
      });
        redirectToLogin(course?._id);
    } else if(course?.isRegistrationLive === true && auth && course?.userInWaitlist === true && course?.userRegistered === false) {
      Mixpanel.track("register CTA Clicked", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        user_id: localStorage.getItem("userId"),
        name:userData?.profileInfo?.name,
        number:userData?.number,
        email:userData?.email,
        course_Id:course?._id,
        browser: browserName,
        mobile_device: isMobileOnly,
        type:"webapp",
      });
    navigate(`/register/payment?id=${course?._id}`);
    } else if(course?.isRegistrationLive === true && auth && !userData?.profileInfo?.name && course?.userInWaitlist === false) {
        navigate(`/join-waitlist/fill-details?id=${course?._id}`);
    } else if(course?.isRegistrationLive === true && auth && userData?.profileInfo?.name !== "" && course?.userInWaitlist === false) {
      Mixpanel.track("Join Waitlist CTA Clicked", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        user_id: localStorage.getItem("userId"),
        name:userData?.profileInfo?.name,
        number:userData?.number,
        email:userData?.email,
        course_Id:course?._id,
        browser: browserName,
        mobile_device: isMobileOnly,
        type:"webapp",
      });
      joinWaitingList(course?._id);
      console.log("h11")
  } else {
    Mixpanel.track("Micro Degree Card Clicked", {
      date: moment().format(),
      distinct_id: localStorage.getItem("userId"),
      user_id: localStorage.getItem("userId"),
      name:userData?.profileInfo?.name,
      number:userData?.number,
      email:userData?.email,
      course_Id:course?._id,
      browser: browserName,
      mobile_device: isMobileOnly,
      type:"webapp",
    });
      navigate(`/program-details?id=${course?._id}&type=${course?.type}`)
  }
  }} 

  disabled={(course?.userInWaitlist === true && course?.userRegistered === false && course?.isRegistrationLive === false) || (course?.userRegistered === true)}
           className={`disabled:cursor-not-allowed disabled:opacity-50 w-max self-stretch px-4 py-3 bg-teal-950 rounded-xl justify-center items-center gap-2 flex`}>
                    <div className="text-center text-white text-base font-black font-satoshi leading-tight">
                    {(course?.userInWaitlist === true && course?.userRegistered === false)
              ? "Register"
              : course?.userRegistered === true ? "Registered" : "Join Waitlist"}
                    </div>
                  </button> */}
                  <FillButton 
                    onClick={() => {
                      if (course?.isRegistrationLive === false && !course?.bypassWaitlist && !auth) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                        redirectToLogin(course?._id);
                      } else if(course?.isRegistrationLive === false && auth && !userData?.profileInfo?.name && course?.userInWaitlist === false && !course?.bypassWaitlist) {
                        navigate(`/join-waitlist/fill-details?id=${course?._id}`);
                      } else if(course?.isRegistrationLive === false && auth && userData?.profileInfo?.name !== "" && course?.userInWaitlist === false && !course?.bypassWaitlist) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      joinWaitingList(course?._id);
                      console.log("h12")
                      } else if(course?.isRegistrationLive === true && !course?.bypassWaitlist && !auth) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                          redirectToLogin(course?._id);
                      } else if((auth && course?.isRegistrationLive === true && auth && course?.userInWaitlist === true && course?.userRegistered === false && !course?.bypassWaitlist)) {
                        Mixpanel.track("register CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      navigate(`/register/payment?id=${course?._id}`);
                      }else if((auth && course?.bypassWaitlist === true)) {
                        Mixpanel.track("register CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      navigate(`/register/payment?id=${course?._id}`);
                      } else if(!auth && course?.bypassWaitlist === true) {
                        Mixpanel.track("register CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      navigate(`/register/fill-details?id=${course?._id}`);
                      } else if(course?.isRegistrationLive === true && auth && !userData?.profileInfo?.name && course?.userInWaitlist === false && !course?.bypassWaitlist) {
                          navigate(`/join-waitlist/fill-details?id=${course?._id}`);
                      } else if(course?.isRegistrationLive === true && auth && userData?.profileInfo?.name !== "" && course?.userInWaitlist === false && !course?.bypassWaitlist) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                        joinWaitingList(course?._id);
                        console.log("h11")
                    } else {
                      Mixpanel.track("Micro Degree Card Clicked", {
                        date: moment().format(),
                        distinct_id: localStorage.getItem("userId"),
                        user_id: localStorage.getItem("userId"),
                        name:userData?.profileInfo?.name,
                        number:userData?.number,
                        email:userData?.email,
                        course_Id:course?._id,
                        browser: browserName,
                        mobile_device: isMobileOnly,
                        type:"webapp",
                      });
                        navigate(`/program-details?id=${course?._id}&type=${course?.type}`)
                    }
                    }} 
                  disabled={(!course?.bypassWaitlist && course?.userInWaitlist === true && course?.userRegistered === false && course?.isRegistrationLive === false) || (course?.userRegistered === true)  }
           className="w-max h-[53px]"
                  textColor="white"
                  color="black1"
                  text={((auth && course?.userInWaitlist === true && course?.userRegistered === false && !course?.bypassWaitlist) || (course?.bypassWaitlist === true && course?.userRegistered === false))
                    ? "Register"
                    : course?.userRegistered === true ? "Registered" : "Join Waitlist"}
                  />
                </div>
              </div>
            </div>
            </div>

          </div>
          )
        }
        }))}

<div className="w-full md:w-[375.33px] h-[371px] flex justify-center items-center flex-col bg-gradient-to-br from-gray-50 to-gray-100 rounded-2xl">
            <div className="w-full h-auto flex flex-row justify-center items-center">
              <img
                className="w-10 h-10 rounded-[39px] "
                src="/svg/saurabh.svg"
              />
              <img
                className="w-10 h-10 rounded-3xl ml-[-5px]"
                src="/svg/thirdPerson.svg"
              />
              <img
                className="w-10 h-10 rounded-[39px] ml-[-5px]"
                src="/svg/rakhi.svg"
              />
              <div className="w-10 h-10 bg-neutral-200 rounded-[50%] justify-center items-center flex ml-[-5px]">
                <div className="opacity-80 text-zinc-800 text-sm font-bold font-satoshi leading-[23.15px] tracking-tight">
                  +42
                </div>
              </div>
            </div>
            <div className="w-[205px] text-center text-slate-500 text-xl font-bold font-satoshi">
              More programs launching soon
            </div>
          </div>
</div>

      {/* <div className="w-full md:min-w-[500px] h-[5px] bg-gradient-to-r from-gray-100 to-white"></div> */}
    </div>
  );
};
