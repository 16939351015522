import React, { useState, useEffect } from "react";
import ButtonChild from "./buttonChild";

const DropDownButton = ({
  color,
  disabled,
  textClass,
  buttonClass,
  isLoading,
  textColor,
  className,
  rightIcon,
  leftIcon,
  ...props
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const buttonStyle = {
    borderBottom: `2px solid #E5E9EA`,
    borderRadius: "0",
  };

  const colorMap = {
    red: {
      default:
        "hover:bg-red-50 active:bg-red-100 hover:cursor:pointer active:cursor-auto hover:text-red-400 active:text-red-550",
      pressed: "bg-red-100",
      disabled:
        "cursor-not-allowed text-red-100 hover:cursor-not-allowed active:cursor-not-allowed ",
      loading:
        "text-red-300 hover:cursor-not-allowed active:cursor-not-allowed",
    },
    green: {
      default:
        "hover:bg-green-50 active:bg-green-100 hover:cursor:pointer active:cursor-auto hover:text-green-400 active:text-green-550",
      pressed: "bg-green-100",
      disabled:
        "cursor-not-allowed text-green-100 hover:cursor-not-allowed active:cursor-not-allowed",
      loading:
        "text-green-300 hover:cursor-not-allowed active:cursor-not-allowed",
    },
    orange: {
      default:
        "hover:bg-orange-50 active:bg-orange-100 hover:cursor:pointer active:cursor-auto hover:text-orange-400 active:text-orange-550",
      pressed: "bg-orange-100",
      disabled:
        "cursor-not-allowed text-orange-100 hover:cursor-not-allowed active:cursor-not-allowed ",
      loading:
        "text-orange-300 hover:cursor-not-allowed active:cursor-not-allowed",
    },
    gray: {
      default:
        "hover:bg-gray-50 active:bg-gray-100 hover:cursor:pointer active:cursor-auto hover:text-gray-400 active:text-gray-550",
      pressed: "bg-gray-100",
      disabled:
        "cursor-not-allowed text-gray-200 hover:cursor-not-allowed active:cursor-not-allowed",
      loading:
        "text-gray-400 hover:cursor-not-allowed active:cursor-not-allowed",
    },
  };

  const textColorClass = isPressed
    ? colorMap[textColor].pressed
    : disabled
    ? colorMap[textColor].disabled
    : isLoading
    ? colorMap[textColor].loading
    : colorMap[textColor].default;

  return (
    <>
      <button
        className={`flex w-full py-2 px-4 disabled:cursor-not-allowed ${className}  ${textColorClass}`}
        style={buttonStyle}
        disabled={isLoading || disabled} // Disable the button when loading
        {...props}
      >
        <ButtonChild
          {...props}
          buttonClass={`${buttonClass} gap-spaceXSmall w-max`}
          textClass={`w-max ${textClass}`}
          textColor={textColor ? textColor : color}
          disabled={isLoading || disabled}
          isLoading={isLoading}
          rightIcon={rightIcon}
          leftIcon={leftIcon}
        />
      </button>
    </>
  );
};

export default DropDownButton;
