
import React, { useState } from "react";

export const FAQ = () => {
  // State to manage visibility of each answer
  const [visibleAnswers, setVisibleAnswers] = useState(Array(12).fill(false)); // Assuming there are 12 questions

  // Function to toggle visibility of an answer
  const toggleAnswer = (index) => {
    const newVisibleAnswers = [...visibleAnswers];
    newVisibleAnswers[index] = !newVisibleAnswers[index];
    setVisibleAnswers(newVisibleAnswers);
  };

  return (
    <div className="w-full h-auto px-16 py-8 lg:px-[281px] pt-[72px] lg:pb-[253px] bg-[#000000] flex-col justify-center items-center gap-10 inline-flex">
      <div className="text-white text-2xl font-bold font-satoshi leading-[17.88px] tracking-tight">FAQs</div>
      <div className="md:w-[848px] relative">
        <div className="w-full sm:w-[500px] md:w-[848px] h-auto flex-col justify-start items-start inline-flex">
          {/* Map through questions and answers */}
          {faqData.map((item, index) => (
            <div key={index} className="w-full self-stretch h-auto px-[13px] py-[23px] flex-col justify-start items-start gap-4 flex">
              <div className="w-full self-stretch justify-between items-center gap-4 inline-flex" onClick={() => toggleAnswer(index)}>
                <div className="grow shrink basis-0 text-gray-400 text-base font-bold font-satoshi leading-[17.88px] tracking-tight">
                  {item.question}
                </div>
                <div className=" h-[29px] relative transform rotate-[-180deg] cursor-pointer">
                  <img src="/svg/faqarrow.svg" alt="arrow" style={{ transform: visibleAnswers[index] ? 'rotate(-180deg)' : 'rotate(0deg)' }} />
                </div>
              </div>
              {/* Show answer if visibleAnswers[index] is true */}
              {visibleAnswers[index] && (
                <div className="w-full self-stretch text-gray-400 opacity-70 text-base font-medium font-satoshi leading-[17.88px] tracking-tight">
                  {item.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const faqData = [
  {
    question: "How do I sign up for the course?",
    answer: "To sign up, please join our waitlist through our website. This ensures you get early information about the course and access to special discounted pricing. Once the waitlist period ends, registration slots will open, and waitlisted individuals will have the first chance to apply."
  },
  {
    question: "What are the benefits of joining the waitlist?",
    answer: "Joining the waitlist gives you the priority to register for the course at a discounted rate before it opens to the general public. It's a great way to secure your spot and save on the course fee."
  },
  {
    question: "Is this program online or offline?",
    answer: "This program can be done completely online and you can attend our offline workshops as well."
  },
  {
    question: "Can we get a demo class?",
    answer: "Every Sunday we do a founders meet where you get to meet our company founder, learn about UX/UI design, and get informed about our course in detail."
  },
  {
    question: "Is this a certified program?",
    answer: "Yes! We are a registered bootcamp and provide a certificate at the end of the bootcamp. You also get internship certificates."
  },
  {
    question: "Do you also teach design tools?",
    answer: "Absolutely. We teach and use Figma, Miro, Docs, and other related tools during your learning experience."
  },
  {
    question: "Are there weekend classes available?",
    answer: "Our live classes with mentors are on weekends - Saturday and Sunday between 10AM and 2PM."
  },
  {
    question: "Do you help with portfolio projects?",
    answer: "We help you work on two complete portfolio projects. One project is done in class with the mentors and other students. The other is a personal project we ask you to conduct as a test of your new skills."
  },
  {
    question: "What is the cost of the entire course?",
    answer: "We have kept the program fee affordable. We have two main plans, one which is the course with job placement assurance; the other is our learning+internship program. We also have various monthly payment plans which makes paying for this super easy. Our team will reach out to you once you register for this course and pay a registration fee of ₹999 only."
  },
  {
    question: "Will you help with placements and jobs?",
    answer: "We have a dedicated team that supports us for placement processes. We focus on building relationships with companies and teams on your behalf. Apart from job placements, we prepare you for interviews, resumes, and feedback."
  },
  {
    question: "Is there a limit to the number of students you accept in a course?",
    answer: "Our class sizes are kept manageable to ensure quality education and personalized attention for each student."
  }
];

