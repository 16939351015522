import React from 'react';
import { useAuth } from '../auth/authContext';

const PrivateRoute = ({ element: Component, fallback: FallbackComponent, ...rest }) => {
    const auth = localStorage?.getItem('auth');
  const { isAuthenticated } = useAuth();

  return isAuthenticated || auth ? <Component {...rest} /> : <FallbackComponent {...rest} />;
};

export default PrivateRoute;