import axios from "axios";

export const getChatToken = async () => {
  
  const url = `${process.env.REACT_APP_baseURL}/user/chat_token`;
  
  const response = await axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("auth"),
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    localStorage.setItem("chatAuth", response?.data.token);
    
    console.log(response?.data.token);
    return response?.data.token;
  }
};

export const blockUser = async (userId) => {
  const url = `${process.env.REACT_APP_baseURL}/recruiter/block_user/${userId}`;
  const response = await axios
    .post(
      url,
      {
        email: form.email,
        password: form.password,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    console.log(response?.data.token);
  }
};

export const unblockUser = async (userId) => {
  const url = `${process.env.REACT_APP_baseURL}/recruiter/unblock_user/${userId}`;
  const response = await axios
    .post(
      url,
      {
        email: form.email,
        password: form.password,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    console.log(response?.data.token);
  }
};

export const getAllConversations = async () => {
  const url = `${process.env.REACT_APP_chatURL}/conversation`;
  let params = {
    skip: 0,
    limit: 100,
  };
  const response = await axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("chatAuth"),
      },
      params,
    })
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    return response.data;
  }
};

export const getAConversation = async (conversationId) => {
  const url = `${process.env.REACT_APP_chatURL}/conversation/${conversationId}`;
  const response = await axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("chatAuth"),
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    if (response?.data.messages)
      response.data.messages = response?.data.messages.reverse();
    return response?.data;
  }
};

export const readConversation = async (convoId, messageId) => {
  const url = `${process.env.REACT_APP_chatURL}/message/read`;
  const response = await axios
    .post(
      url,
      {
        conversationId: convoId,
        messageId: messageId,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("chatAuth"),
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
  }
};

export const sendMessage = async (messageObj) => {
  const url = `${process.env.REACT_APP_chatURL}/message/private`;
  const response = await axios
    .post(url, messageObj, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("chatAuth"),
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    console.log(response?.data);
  }
};

export const getConversationId = async (userId) => {
  const url = `${process.env.REACT_APP_chatURL}/conversation/id/private/${userId}`;
  const response = await axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("chatAuth"),
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
      return null;
    });

  if (response?.data) {
    console.log(response?.data, "get convo id");
    return response?.data;
  }
};

export const getPresignedUrl = async (messageType, mimeType, extenstion) => {
  const url = `${process.env.REACT_APP_chatURL}/message/uploadUrl`;
  const response = await axios
    .post(
      url,
      {
        messageType: messageType,
        mimeType: mimeType,
        extension: `.${extenstion}`,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("chatAuth"),
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
      return null;
    });

  if (response?.data) {
    console.log(response?.data, "get convo id");
    return response?.data;
  }
};

export const putObjectInUrl = async (url, file) => {
  console.log(url);

  const response = await axios.put(url, file, {
    headers: {
      "x-ms-blob-type": "BlockBlob",
    },
  }).catch((error) => {
    console.log(error.response?.data);
    if (error.response && error.response?.data.message)
      // setAlertData(error.response?.data.message);
      console.log(error.response?.data.message);
    return null;
  });

  if (response?.data) {
    console.log(response?.data, "get convo id");
    return response?.data;
  }
};

export const getFileSizeText = (size) => {
  let sizeunit = ["B", "KB", "MB", "GB"];

  let count = 0;

  while (size > 900) {
    size /= 1024;
    count++;
  }

  return `${Math.round(size * 100) / 100} ${sizeunit[count]}`;
};

export const trimFileName = (name) => {
    if(!name) return "N/A"
  if (name?.length < 30) return name;
  return `${name.slice(0, 10)}...${name.slice(name.length - 10, name.length)}`;
};

export const convoTime =(inputDate)=>{
    const date = new Date(inputDate)
    const now = new Date();
  const diff = now - date;
  
  
  if (diff < 2 * 60 * 1000) {
    return "now";
  } else if (diff < 60 * 60 * 1000) {
    
    const minutes = Math.floor(diff / (60 * 1000));
    return `${minutes} mins ago`;
  } else if (diff < 24 * 60 * 60 * 1000) {
    
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  } else {
    const day = date.getDate();
    const month = date.getMonth() + 1; 
    
    
    return `${day}/${month < 10 ? '0' + month : month} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  }
}

export const searchConversations = async (value) => {
    const url = `${process.env.REACT_APP_chatURL}/user/search`;
    let params = {
      skip: 0,
      limit: 100,
    };
    const response = await axios
      .post(url,{"name":value}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("chatAuth"),
        },
        params,
      })
      .catch((error) => {
        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
          // setAlertData(error.response?.data.message);
          console.log(error.response?.data.message);
      });
  
    if (response?.data) {
      return response.data;
    }
  };

export const getAvatarImageUrl = ({avatar, name})=>{
    if(!avatar || !avatar.small) return {
        small: `https://ui-avatars.com/api/?name=${name}`,
        large: `https://ui-avatars.com/api/?name=${name}`,
        medium: `https://ui-avatars.com/api/?name=${name}`
    }

    if(avatar.small?.search("https://beepchat01.blob.core.windows.netbeepchat01")!=-1)return {
        small: avatar.small.replace('.net', '.net/'),
        large: avatar.large.replace('.net', '.net/'),
        medium: avatar.medium.replace('.net', '.net/')
    }

    return avatar
}