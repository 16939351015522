import React, { useEffect, useState } from "react";
import {
  categoriesWithJobCount,
  fetchJobs,
} from "../../../services/landingPageApi";
import ButtonChild from "../../../components/buttons/buttonChild";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../../../services/mixpanel";
import moment from "moment";
import { isMobileOnly, browserName } from "react-device-detect";
import { generateJobUrl } from "../../../services/jobs";
import { usePath } from "../../../routes/context/path/pathContext";

export const InternOpportunity = ({ redirect }) => {
  const { goToJobDetails } = usePath();
  const [allinternJob, setallInternJobs] = useState([]);
  const [category, setCategory] = useState([]);
  console.log(category);
  const navigate = useNavigate();
  const auth = localStorage.getItem("auth");

  const handleClick = (type) => {
    localStorage.setItem("type", type);
    // Redirect to another page
    if(auth){
      // window.location.reload();
      navigate('/internship')
     
    } else {
      // window.location.reload();
      navigate("/internship");
     
    }
  };

  useEffect(() => {
    const fetchJob = async () => {
      const internJobs = await fetchJobs({ type: ["Internship"] });
      console.log(internJobs?.data);
      setallInternJobs(internJobs?.data);
      const categoriesJobs = await categoriesWithJobCount();
      setCategory(
        categoriesJobs?.data?.categories.map((index) => ({
          _id: index._id,
          name: index.name,
        }))
      );
    };
    fetchJob();
  }, []);

  const onJobSelect = (index, category, auth) => {
    Mixpanel.track("Job_card_clicked", {
      date: moment().format(),
      distinct_id: localStorage.getItem("userId"),
      user_id: localStorage.getItem("userId"),
      browser: browserName,
      jobId: index?._id,
      mobile_device: isMobileOnly,
      type: "webapp",
    });
    goToJobDetails({index:index,category:category})
    // navigate(generateJobUrl(index, category, auth));
    // if (auth) {
    //   navigate(`/job/${id}`);
    // } else {
    //   navigate(`/job-desc/${id}`);
    // }
  };
  return (
    <div className="w-full h-auto px-2 sm:px-4 md:px-8 xl:px-[124px] py-8 md:py-[150px] bg-white flex-col justify-center items-start gap-12 inline-flex">
      <div className="w-full justify-between items-center inline-flex">
        <div>
          <span className="text-teal-950 text-[32px] md:text-[45px] xl:text-[56px] font-bold font-satoshi leading-[61.60px]">
            Internship{" "}
          </span>
          <span className="text-emerald-500 text-[32px] md:text-[45px] xl:text-[56px] font-bold font-satoshi leading-[61.60px]">
            Opportunities
          </span>
        </div>
        <div
          onClick={() => {
            Mixpanel.track("Internship_clicked", {
              date: moment().format(),
              distinct_id: localStorage.getItem("userId"),
              user_id: localStorage.getItem("userId"),
              browser: browserName,
              mobile_device: isMobileOnly,
              type: "webapp",
            });
            handleClick("Internship");
          }}
        >
          {/* <div onClick={()=>{handleClick("Internship")}}> */}
          <ButtonChild
            className="hidden md:flex"
            textColor="green"
            textClass="w-max"
            text="Show all internships"
            rightIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
              >
                <path
                  d="M16.75 7.72559L1.75 7.72559"
                  stroke="#16C083"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.7002 1.70124L16.7502 7.72524L10.7002 13.7502"
                  stroke="#16C083"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          />
        </div>
      </div>
      <div className="w-full justify-center items-center gap-8 inline-flex">
        <div className="w-full flex flex-col sm:grid sm:grid-cols-2 gap-4 sm:gap-8 ">
          {allinternJob?.jobs?.slice(0, 8).map((index) => (
            <div
              onClick={() => onJobSelect(index, category, auth)}
              key={index}
              className="cursor-pointer w-auto self-stretch p-4 sm:px-10 py-6 bg-white rounded-xl border border-neutral-300 justify-start items-start gap-6 flex-col md:flex-row inline-flex hover:transform hover:scale-105 hover:shadow-md cursor-pointer"
            >
              <div className="w-16 h-16 relative">
                <div className="w-16 h-16 rounded-full">
                  <img src={index.company_info.logo} />
                </div>
              </div>
              <div className="flex-col justify-start items-start gap-2 inline-flex">
                <div className="justify-start items-start gap-[100px] inline-flex">
                  <div className="text-teal-950 text-[20px] sm:text-xl font-bold font-satoshi leading-normal">
                    {index.job_info?.job_profile}
                  </div>
                </div>
                <div className="w-full h-auto justify-start items-start gap-2 inline-flex flex-wrap">
                  <div className="text-slate-700 text-[16px] sm:text-base font-normal font-satoshi leading-relaxed">
                    {index.company_info.name}
                  </div>
                  {index.job_info?.workplace_type && (
                    <>
                      <div className="w-1 h-1 bg-slate-700 rounded-full mt-[0.8rem]" />
                      <div className="text-slate-700 text-[16px] sm:text-base font-normal font-satoshi leading-relaxed">
                        {index.job_info?.workplace_type}
                      </div>
                    </>
                  )}
                  {index.job_info?.location && (
                    <>
                      <div className="w-1 h-1 bg-slate-700 rounded-full mt-[0.8rem]" />
                      <div className="text-slate-700 text-[16px] sm:text-base font-normal font-satoshi leading-relaxed">
                        {index.job_info?.location}
                      </div>
                    </>
                  )}
                </div>
                <div className="w-full justify-start items-start gap-2 inline-flex flex-wrap">
                  <div className="px-2.5 py-1.5 bg-emerald-300 bg-opacity-10 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                    <div className="w-max text-emerald-300 text-sm font-medium font-satoshi leading-snug">
                      {index.job_info?.job_type}
                    </div>
                  </div>
                  <div className=" self-stretch bg-neutral-300" />
                  <div className="px-2.5 py-1.5 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                    <div className="w-max text-emerald-500 text-sm font-medium font-satoshi leading-snug">
                      {index.job_info?.category &&
                        category.find(
                          (item) => item._id === index.job_info.category
                        )?.name}
                    </div>
                  </div>
                  <div className="px-2.5 py-1.5 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                    {index.job_info?.job_salary ||
                    index.job_info?.job_max_salary ? (
                      <>
                        <div className="w-5 h-5 relative">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.125 10C13.125 10.6181 12.9417 11.2223 12.5983 11.7362C12.255 12.2501 11.7669 12.6506 11.1959 12.8871C10.6249 13.1236 9.99653 13.1855 9.39034 13.065C8.78415 12.9444 8.22733 12.6467 7.79029 12.2097C7.35325 11.7727 7.05562 11.2158 6.93505 10.6097C6.81447 10.0035 6.87635 9.37513 7.11288 8.80411C7.3494 8.2331 7.74994 7.74504 8.26384 7.40166C8.77775 7.05828 9.38193 6.875 10 6.875C10.8288 6.875 11.6237 7.20424 12.2097 7.79029C12.7958 8.37634 13.125 9.1712 13.125 10ZM19.375 5V15C19.375 15.1658 19.3092 15.3247 19.1919 15.4419C19.0747 15.5592 18.9158 15.625 18.75 15.625H1.25C1.08424 15.625 0.925268 15.5592 0.808058 15.4419C0.690848 15.3247 0.625 15.1658 0.625 15V5C0.625 4.83424 0.690848 4.67527 0.808058 4.55806C0.925268 4.44085 1.08424 4.375 1.25 4.375H18.75C18.9158 4.375 19.0747 4.44085 19.1919 4.55806C19.3092 4.67527 19.375 4.83424 19.375 5ZM18.125 8.62109C17.4153 8.41125 16.7694 8.02719 16.2461 7.50389C15.7228 6.98059 15.3387 6.33468 15.1289 5.625H4.87109C4.66125 6.33468 4.27719 6.98059 3.75389 7.50389C3.23059 8.02719 2.58468 8.41125 1.875 8.62109V11.3789C2.58468 11.5887 3.23059 11.9728 3.75389 12.4961C4.27719 13.0194 4.66125 13.6653 4.87109 14.375H15.1289C15.3387 13.6653 15.7228 13.0194 16.2461 12.4961C16.7694 11.9728 17.4153 11.5887 18.125 11.3789V8.62109Z"
                              fill="#16C083"
                            />
                          </svg>
                        </div>
                        <div className="w-max text-slate-500 text-sm font-medium font-satoshi leading-snug">
                          ₹
                          {index.job_info?.job_salary
                            ? index.job_info?.job_salary
                            : index.job_info?.job_max_salary}{" "}
                          / {index.job_info?.job_salary_frequency}
                        </div>
                      </>
                    ) : (
                      <div className="w-max text-slate-500 text-sm font-medium font-satoshi leading-snug">
                        Unpaid
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
