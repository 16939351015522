import React, { useEffect, useState } from "react";
import { appliedJobs } from "../../../../../services/jobs";
import ButtonChild from "../../../../../components/buttons/buttonChild";
import { PlacementReport } from "./placementReport";
import { getUserDetails } from "../../../../../services/loginOrSignUp";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../../components/loader/loader";
import FillButton from "../../../../../components/buttons/fillButton";

export const MyApplication = ({setActiveSection, openPlacementReport,closePlacementReport, showReportBox}) => {
    
    const [appliedJobData, setAppliedJobData] = useState([])
    const [id, setId] = useState('')
    const [jobId, setJobId] = useState('')
    const [userData, setUserData] = useState('')

const navigate = useNavigate();
    const [appliedDate, setAppliedDate] = useState("");
    const [filterStatus, setFilterStatus] = useState(0);

    const isWithinOneWeek = (appliedDate) => {
        const ONE_WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds
        const currentDate = new Date();
        const difference = currentDate - new Date(appliedDate);
        return difference <= ONE_WEEK_IN_MS;
    };

    useEffect(()=>{
        const data = async ()=>{
        const user = await getUserDetails();
        setUserData(user)
        }
        data()
    },[])
    
    // Helper function to calculate the number of days since the applied date
    const calculateDaysAgo = (appliedDate) => {
        const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000; // 1 day in milliseconds
        const currentDate = new Date();
        const difference = currentDate - new Date(appliedDate);
        return Math.floor(difference / ONE_DAY_IN_MS);
    };
  
    const appliedDateTime = new Date(appliedDate).getTime();
    const currentTime = new Date().getTime();
    const daysDifference = Math.floor((currentTime - appliedDateTime) / (1000 * 60 * 60 * 24));
const millisecondsDifference = currentTime - appliedDateTime;
const hoursDifference = Math.floor(millisecondsDifference / (1000 * 60 * 60));
const millisecondsInDay = 1000 * 60 * 60 * 24;
const remainingMilliseconds = millisecondsInDay * 7 - millisecondsDifference;
const remainingDays = Math.floor(remainingMilliseconds / millisecondsInDay);
const remainingHours = Math.floor((remainingMilliseconds % millisecondsInDay) / (1000 * 60 * 60));
const remainingMinutes = Math.floor((remainingMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
const remainingSeconds = Math.floor((remainingMilliseconds % (1000 * 60)) / 1000);

     useEffect(()=>{
const fetchJob = async () =>{
    const appliedJobList = await appliedJobs()
    setAppliedJobData(appliedJobList?.data?.applied_jobs)
}
fetchJob();
calculateDaysAgo();
    },[])

    // Filter jobs based on the selected status
  const filteredJobs = appliedJobData?.filter(
    (job) => filterStatus === null || job?.job_info?.application_status === filterStatus
  );


    
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };

    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount

  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader />;
  }
  return <div className="w-full">
    <div className="w-full h-auto px-2 sm:px-4 md:p-8 bg-white justify-between items-center inline-flex">
    <div className="flex-col justify-start items-start gap-2 inline-flex">
        <div className="text-teal-950 text-[32px] font-bold font-satoshi leading-[38.40px]">Keep it up, {userData?.profileInfo?.name}</div>
        <div className="text-slate-500 text-base font-medium font-satoshi leading-relaxed">Here is job applications status</div>
        {/* <div className="text-slate-500 text-base font-medium font-satoshi leading-relaxed">Here is job applications status from July 19 - July 25.</div> */}
    </div>

</div>
<div className="w-full mx-auto flex flex-col  gap-6">
<div className="w-full px-2 sm:px-4 md:px-8">
<div className="w-full h-auto">
    <div className="w-full h-auto flex flex-col sx:flex-row justify-start items-start sx:justify-center sx:items-center bg-violet-100 gap-3">
    <div className="w-14 h-[58.84px] flex justify-center items-center pt-2 pl-4">
        <div className="w-14 h-14  absolute">
            <div className="w-14 h-14 left-0 top-0 absolute bg-indigo-600 rounded-full" />
            <div className="w-14 h-14 left-0 top-0 absolute bg-voilet-100 rounded-full" />
            <div className="w-[37.77px] h-12 left-[8.32px] top-[18px] absolute">
                <div className="w-[18px] h-0.5 left-[16.13px] top-[12px] absolute bg-blue-300 rounded-sm" />
                <div className="w-[18px] h-0.5 left-[16.13px] top-[18px] absolute bg-blue-300 rounded-sm" />
                <div className="w-[18px] h-0.5 left-[16.13px] top-[24px] absolute bg-blue-300 rounded-sm" />
                <div className="w-[21px] h-0.5 left-[13.13px] top-[30px] absolute bg-blue-300 rounded-sm" />
            </div>
            <div className="w-5 h-5 " />
        </div>
        <div className="w-[50.70px] h-[39.24px]  absolute">
            <div className="w-[3.65px] h-[3.68px] left-[15.13px] top-[4.90px] absolute bg-emerald-300 rounded-full" />
            <div className="w-[4.87px] h-[4.90px] left-[43.13px] top-0 absolute bg-amber-400 rounded-full" />
            <div className="w-[4.78px] h-[4.82px] left-0 top-[11.47px] absolute bg-blue-500 rounded-full" />
            <div className="w-[2.43px] h-[2.45px] left-[48.26px] top-[36.79px] absolute bg-amber-500 rounded-full" />
            <div className="w-[2.43px] h-[2.45px] left-[44.09px] top-[16.20px] absolute bg-emerald-300 rounded-full" />
        </div>
    </div>
    <div className="w-full h-auto flex flex-col p-4">
         <div className=" text-indigo-600 text-lg font-semiboldfont-satoshi leading-[27px]">New Feature</div>
        <div className="w-full text-slate-500 text-base font-normalfont-satoshi leading-relaxed">You will receive your detailed application report 7 days after applying for a job. Only one follow-up is allowed per job.
        </div>
    </div>
    </div>
  
</div>
</div>
<div className=" px-4 md:px-8 w-full h-auto pt-8 flex-col justify-start items-center gap-6 inline-flex">
    <div className="self-stretch bg-white justify-start items-start gap-4 sm:gap-8 inline-flex">
        <div className="rounded-lg flex-col justify-start items-center gap-[7px] inline-flex" onClick={() => setFilterStatus(0)}>
            <ButtonChild 
            textColor={`${filterStatus === 0 ? "green" : "gray" }`}
            text={`Applied`}
            />
          {/* if application status == 0 */}

        </div>
        <div className="flex-col justify-start items-center gap-[7px] inline-flex" onClick={() => setFilterStatus(2)}>
        <ButtonChild 
            textColor={`${filterStatus === 2 ? "green" : "gray" }`}
            text={`Offers`}
            />
          {/* if application status == 2 */}
        </div>
        <div className="flex-col justify-start items-center gap-[7px] inline-flex" onClick={() => setFilterStatus(1)}>
        <ButtonChild 
           textColor={`${filterStatus === 1 ? "green" : "gray" }`}
            text={`Shortlisted`}
            />
            {/* if application status == 1 */}
        </div>
        <div className="flex-col justify-start items-center gap-[7px] inline-flex" onClick={() => setFilterStatus(3)}>
        <ButtonChild 
           textColor={`${filterStatus === 3 ? "green" : "gray" }`}
            text={`Others`}
            />
             {/* if application status == 4 & 3*/}
            
        </div>
    </div>
    <div className=" w-full py-6 bg-white flex-col justify-center items-center gap-6 flex">
        <div className="w-full self-stretch justify-start items-center inline-flex">
            <div className="text-teal-950 text-xl font-bold font-satoshi leading-normal">Applications History</div>
            <div />
        </div>

        {(filteredJobs?.length === 0 && !Loading) &&(
                               <div className="w-full flex justify-center items-center">

      <div className="w-max flex flex-col items-center justify-center mt-8">
       
        <p className="text-red-500 text-lg">{filterStatus === 0 ? "You don't have any applications, please apply for a job." : filterStatus === 2 ? "No applications, please apply for a job." : filterStatus === 1 ? "You don't have any shortlisted applications, please apply for a job." : filterStatus === 3 ? "Don't have any other application, please apply for a job" : ""}</p>
    
        {/* <Link to="/" className="mt-4"> */}
          <FillButton
          className={`my-4 w-max`}
         onClick={() =>{navigate("/dashboard")}}
            text="Go back"
            color="green"
            textColor="white"
            leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M5.80781 9.55781L12.0578 3.30781C12.1452 3.2203 12.2566 3.1607 12.3779 3.13654C12.4992 3.11238 12.625 3.12476 12.7392 3.17211C12.8535 3.21945 12.9512 3.29964 13.0198 3.40251C13.0885 3.50538 13.1251 3.62631 13.125 3.75L13.125 16.25C13.1251 16.3737 13.0885 16.4946 13.0198 16.5975C12.9512 16.7004 12.8535 16.7805 12.7392 16.8279C12.625 16.8752 12.4992 16.8876 12.3779 16.8635C12.2566 16.8393 12.1452 16.7797 12.0578 16.6922L5.80781 10.4422C5.7497 10.3841 5.7036 10.3152 5.67215 10.2393C5.6407 10.1635 5.62451 10.0821 5.62451 10C5.62451 9.91786 5.6407 9.83653 5.67215 9.76066C5.7036 9.68478 5.7497 9.61585 5.80781 9.55781Z" 
            fill="currentColor"/>
          </svg>}
          />
        {/* </Link> */}
      </div>
   
    </div>
     )}
 {/* Table */}
 <div className="hidden lg:flex w-full overlow-auto no-scrollbar" style={{overflow:"auto"}}>
                               {/* Conditional rendering for no data */}
                              
    {filteredJobs?.length > 0 && 
 <table className="w-full justify-center items-center xl:w-full overlow-scroll no-scrollbar">
                {/* Table Header */}
                <thead className="border-b h-16">
                    <tr>
                    <th className="w-[3rem]"> # </th>
                <th className="w-[200px]"> Company </th>
                <th className="w-[200px]"> Roles </th>
                <th className="w-[200px]"> Date Applied </th>
                <th className="w-[200px]"> Status </th>
                <th className="w-[100px]"> Action </th>
                    </tr>
                </thead>

                {/* Table Body */}
                <tbody className="">
                    {filteredJobs?.map((job, index) => (
                        <tr key={job.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} h-[88px] cursor-pointer text-center`} onClick={() => {
                            openPlacementReport();
                            setId(job._id);
                            setJobId(job.job_id);
                            setAppliedDate(job.timeline?.Applied_1);

                        }}>
                            <td  className="w-[3rem]">{index + 1}</td>
                            <td className="grow">
                                <div className="flex flex-row justify-start items-center gap-2 text-wrap text-left">
                                    <img className="w-10 h-10 rounded-full " src={job?.company_info?.logo} alt={job?.company_info?.name} />
                                    <div className="capitalize-first-letter truncate" title={job?.company_info?.name}>{job?.company_info?.name}</div>
                                </div>
                            </td>
                            <td className="w-[200px]">{job?.job_info?.job_profile}</td>
                            <td className="w-[200px]">{new Date(job?.timeline?.Applied_1).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td className="w-[200px]">
                                {job?.job_info?.application_status === 0 && 
                                <div className={`px-2.5 py-1.5 justify-center items-center gap-2 inline-flex`}>
                                    <div className="justify-center items-center gap-1.5 flex">
                                        <div className={`w-2 h-2 rounded-full shadow border ${calculateDaysAgo(job.timeline.Applied_1) === 0 ? "bg-emerald-300 border-emerald-400" :
        isWithinOneWeek(job.timeline.Applied_1) ?"bg-orange-300 border-orange-500":
            daysDifference >= 7 ? "bg-emerald-300 border-emerald-400"  :  "bg-red-300 border-red-500"}`} />
                                        <div className={`text-sm font-black font-satoshi leading-tight ${calculateDaysAgo(job.timeline.Applied_1) === 0 ? "text-emerald-400" :
        isWithinOneWeek(job.timeline.Applied_1) ?"text-orange-400":
            daysDifference >= 7 ? "text-emerald-500"  :  "text-red-400"}`}>
    {calculateDaysAgo(job.timeline.Applied_1) === 0 ? "Today" :
        isWithinOneWeek(job.timeline.Applied_1) ?
            (<span>{`Applied ${calculateDaysAgo(job.timeline.Applied_1)} days ago`}</span>) :
            daysDifference >= 7 ? "Report Available" :  "Report not available"}
</div>

                                    </div>
                                </div>
}
{job?.job_info?.application_status === 3 && 
                                <div className={`px-2.5 py-1.5 justify-center items-center gap-2 inline-flex`}>
                                    <div className="justify-center items-center gap-1.5 flex">
                                        <div className="w-2 h-2 bg-red-300 rounded-full shadow border border-red-500" />
                                        <div className={`text-red-300 text-sm font-black font-satoshi leading-tight`}>
                                            {isWithinOneWeek(job.timeline.Applied_1) ? `applied ${calculateDaysAgo(job.timeline.Applied_1)} days ago` : daysDifference >= 7 ? "Report Available" : 'Report not available'}
                                        </div>
                                    </div>
                                </div>
}
                            </td>
                            <td className="w-[100px]">
                                <div className={`px-2.5 py-1.5 justify-center items-center gap-2 inline-flex `}>
                                    <div className="justify-center items-center gap-1.5 flex">
                   
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_7865_40294)">
    <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#002032" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#002032" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#002032" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_7865_40294">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
                                    </div>
                                </div>
                            </td>
                           
                        </tr>
                    ))}
                </tbody>


            </table>
}
            </div>
    </div>
    {showReportBox && (
        <div className=" top-0 left-0 w-full h-full bg-opacity-50 flex items-start z-50">
          <PlacementReport
          remainingDays={remainingDays} 
          remainingHours={remainingHours} 
          remainingSeconds={remainingSeconds}
          remainingMinutes={remainingMinutes}
          daysDifference={daysDifference} 
          hoursDifference={hoursDifference}

          appliedDate={appliedDate} 
          setActiveSection={setActiveSection} 
          closePlacementReport={closePlacementReport} 
          id={id} 
          jobId={jobId} 
          userData={userData}
          />
        </div>
      )}

<div className="w-full lg:hidden gap-6 py-2">
{filteredJobs?.map((job, index) => (
<div key={job.id} onClick={() => {
                            openPlacementReport();
                            setId(job._id);
                            setJobId(job.job_id);
                            setAppliedDate(job.timeline?.Applied_1);

                        }} className="w-full h-auto p-4 bg-white rounded-[25px] shadow border-2 border-gray-100 flex-col justify-start items-start gap-4 inline-flex my-2">
    <div className="w-full justify-between items-center inline-flex cursor-pointer">
        <div className="w-full h-auto pt-2 flex-col justify-start items-start inline-flex">
        <div className="self-stretch capitalize-first-letter truncate " title={job?.company_info?.name}>{job?.company_info?.name}</div>
            <div className="self-stretch text-teal-950 text-base font-black font-satoshi capitalize leading-normal">{job?.job_info?.job_profile}</div>
            <div className="self-stretch text-slate-500 text-xs font-medium font-['Poppins'] capitalize leading-[18px]">{job?.job_info?.job_type}</div>
        </div>
        <div className="w-11 h-11 rounded-[28px]">
        <img className="w-10 h-10 rounded-full " src={job?.company_info?.logo} alt={job?.company_info?.name} />
        </div>
    </div>
    {job?.job_info?.application_status === 0 && 
                                <div className={`px-2.5 py-1.5 justify-center items-center gap-2 inline-flex`}>
                                    <div className="justify-center items-center gap-1.5 flex">
                                        <div className={`w-2 h-2 rounded-full shadow border ${calculateDaysAgo(job.timeline.Applied_1) === 0 ? "bg-emerald-300 border-emerald-400" :
        isWithinOneWeek(job.timeline.Applied_1) ?"bg-orange-300 border-orange-500":
            daysDifference >= 7 ? "bg-emerald-300 border-emerald-400"  :  "bg-red-300 border-red-500"}`} />
                                        <div className={`text-sm font-black font-satoshi leading-tight ${calculateDaysAgo(job.timeline.Applied_1) === 0 ? "text-emerald-400" :
        isWithinOneWeek(job.timeline.Applied_1) ?"text-orange-400":
            daysDifference >= 7 ? "text-emerald-500"  :  "text-red-400"}`}>
    {calculateDaysAgo(job.timeline.Applied_1) === 0 ? "Today" :
        isWithinOneWeek(job.timeline.Applied_1) ?
            (<span>{`Applied ${calculateDaysAgo(job.timeline.Applied_1)} days ago`}</span>) :
            daysDifference >= 7 ? "Report Available" :  "Report not available"}
</div>

                                    </div>
                                </div>
}
{job?.job_info?.application_status === 3 && 
                                <div className={`px-2.5 py-1.5 justify-center items-center gap-2 inline-flex`}>
                                    <div className="justify-center items-center gap-1.5 flex">
                                        <div className="w-2 h-2 bg-red-300 rounded-full shadow border border-red-500" />
                                        <div className={`text-red-300 text-sm font-black font-satoshi leading-tight`}>
                                            {isWithinOneWeek(job.timeline.Applied_1) ? `applied ${calculateDaysAgo(job.timeline.Applied_1)} days ago` : daysDifference >= 7 ? "Report Available" : 'Report not available'}
                                        </div>
                                    </div>
                                </div>
}
<Link to={`/job/${job.job_id}`} className="w-full" >
    <div  className="w-full h-11 pr-px py-3 rounded-xl justify-center items-start gap-4 md:gap-[142px] inline-flex">
        <div className="grow shrink basis-0 text-slate-500 text-sm font-black font-satoshi leading-tight">View Application</div>
        <div className="w-5 h-5 relative flex-col justify-start items-start flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M14.1922 10.473L7.94219 16.723C7.85478 16.8105 7.74337 16.8701 7.62207 16.8942C7.50076 16.9184 7.37502 16.906 7.26076 16.8587C7.14649 16.8113 7.04884 16.7311 6.98017 16.6283C6.91151 16.5254 6.8749 16.4045 6.875 16.2808V3.78077C6.8749 3.65708 6.91151 3.53615 6.98017 3.43328C7.04884 3.33041 7.14649 3.25022 7.26076 3.20288C7.37502 3.15553 7.50076 3.14315 7.62207 3.16731C7.74337 3.19147 7.85478 3.25107 7.94219 3.33858L14.1922 9.58858C14.2503 9.64662 14.2964 9.71555 14.3279 9.79143C14.3593 9.8673 14.3755 9.94863 14.3755 10.0308C14.3755 10.1129 14.3593 10.1942 14.3279 10.2701C14.2964 10.346 14.2503 10.4149 14.1922 10.473Z" fill="#667984"/>
</svg>
        </div>
    </div>
    </Link>
</div>
))}
     </div>
</div>



</div>

  </div>;
};
