import React from "react";
import InputFieldComp from "../../../components/input_fields/inputFieldComp";
import BorderButton from "../../../components/buttons/borderButton";
import FillButton from "../../../components/buttons/fillButton";
import { googleSSOLogin } from "../../../services/loginOrSignUp";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Mixpanel } from "../../../services/mixpanel";
import { isMobileOnly, browserName } from "react-device-detect";
import { useAuth } from "../../../routes/context/auth/authContext";

export const Login = ({
  form,
  setForm,
  onSetFormData,
  handleSendOTP,
  missing,
  setActiveSection,
  setActiveSubSection,
  jobId,
}) => {
  const { login } = useAuth();
  const navigate = useNavigate();
  console.log(jobId, "jobbbbbbbbbbbbbbbbb");
  const loginUser = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        console.log(tokenResponse);
        const response = await googleSSOLogin(tokenResponse.access_token);
        console.log(response);
        if (response?.data?.token) {
login();
          Mixpanel.track("sso is completed", {
            date: moment().format(),
            distinct_id: response?.data?.userProfile?._id,
            user_id: response?.data?.userProfile?._id,
            isVerified:response?.data?.userProfile?.isEmailVerified,
            browser: browserName,
            mobile_device: isMobileOnly,
            type:"webapp",
          });
          console.log(response?.data?.token);
          localStorage.setItem("auth", response?.data?.token);
          localStorage.setItem("userId", response?.data?.userProfile?._id);
          localStorage.setItem("email", response?.data?.userProfile?.email);
          localStorage.setItem("name", response?.data?.userProfile?.profileInfo?.email);
          localStorage.setItem("profile", response?.data?.userProfile?.profileInfo?.avatar);




          localStorage.setItem(
            "gogleSSOId",
            response?.data?.userProfile?.google_SSO_Id
          );

          if (response?.data?.userStatus === 0) {
            setActiveSection("profileSetUp");
            setActiveSubSection("setprofile");
            window.location.reload();
          } else if (response?.data?.userStatus === 1) {
            setActiveSection("profileSetUp");
            setActiveSubSection("education");
            window.location.reload();
          } else if (response?.data?.userStatus === 2) {
            setActiveSection("profileSetUp");
            setActiveSubSection("skills");
            window.location.reload();
          } else if (response?.data?.userStatus === 3) {
            if (
              response?.data?.userProfile?.jobType ||
              response?.data?.userProfile?.workplace ||
              response?.data?.userProfile?.locations ||
              response?.data?.userProfile?.designations
            ) {
              if (jobId) {
                navigate(`/job/?id=${jobId}`);
              } else {
                localStorage.setItem('type', "Internship");  
                navigate("/internships");
              }
            } else {
              console.log(userData, "userData");
              setActiveSection("profileSetUp");
              setActiveSubSection("workpref");
              window.location.reload();
            }

            // Check if page is redirected to workpref and any field is empty
            // const urlParams = new URLSearchParams(window.location.search);
          } else {
            localStorage.setItem('type', "Internship");  
            navigate("/internships");
          }
        }
        setForm((prev) => ({
          ...prev,
          fname: response?.data?.userProfile?.profileInfo?.name,
          profileLogo: response?.data?.userProfile?.profileInfo?.avatar,
        }));
      } catch (error) {
        console.error("An error occurred while fetching user data", error);
      }
    },
  });
  console.log(form.phoneNo?.length <= 9);

  return (
    <div>
      <div className="w-full sm:min-w-[570px] h-auto p-0 sx:p-4 sm:p-[72px] pb-4 bg-white rounded-2xl sm:shadow flex-col justify-center items-center gap-2 sm:gap-[72px] inline-flex">
        <div className="w-full flex xl:hidden">
          <img src="/leftBanner.png" alt="leftBanner" />
        </div>
        <div className="w-full flex flex-col h-auto">
          <span className="w-full md:w-max text-amber-300 text-[23px] sm:text-[32px] font-black font-satoshi leading-[38.40px]">
            Launch your Career{" "}
            <span className="text-black font-black "> with </span>
            <span className=" sx:hidden w-full md:w-max  text-teal-950 text-[23px] sm:text-[32px] font-black font-satoshi leading-[38.40px]">
              the Perfect Opportunity
            </span>
          </span>
          <span className="hidden sx:flex w-full md:w-max  text-teal-950 text-[23px] sm:text-[32px] font-black font-satoshi leading-[38.40px]">
            the Perfect Opportunity
          </span>
        </div>
        <div className="w-full flex-col justify-start items-start flex">
          <div className="w-full">
            <InputFieldComp
              type="text"
              name="phoneNo"
              labelText="Enter Mobile Number"
              hasError={missing.phoneNo}
              errorMsg={
                missing.phoneNo
                  ? "Phone number is required in valid format."
                  : ""
              }
              inputLeftText="+91"
              placeholder="9728371300"
              value={form.phoneNo}
              onChange={onSetFormData}
              maxLength={10}
            />
          </div>
          <div className="flex mb-4 sm:my-4">
            <label className="label cursor-pointer">
              <input
                // onChange={() => setIsChecked(prev => !prev)}
                type="checkbox"
                className="w-5 h-5 border-none rounded-sm border-2 focus:border-emerald-500 accent-emerald-500 text-white"
              />
              <span className="text-slate-500 text-sm font-medium font-satoshi leading-tight mx-3 ">
                This is my WhatsApp Number
              </span>
            </label>
          </div>
          <div className="w-full">
            <FillButton
              disabled={form.phoneNo?.length < 10 ? true : false}
              onClick={handleSendOTP}
              // className="w-full"
              buttonClass="w-full flex justify-center"
              textColor="white"
              color="green"
              text="Sign in with OTP"
            />
          </div>
          <div
            className="flex w-full h-[0px] border my-[22px] border-dashed border-t-0 border-gray-100 justify-evenly"
            style={{ borderSpacing: "5px" }}
          >
            <button className="pointer-none m-[-10px] w-[23px] h-[18px] p-1 bg-gray-100 rounded-2xl text-[9px] font-['Satoshi Variable'] font-bold text-slate-500 leading-[1.19]">
              OR
            </button>
          </div>
          <div className="w-full inline-flex gap-1 justify-evenly items-center">
            <div className="w-full">
              <BorderButton
                onClick={loginUser}
                className="w-full"
                buttonClass="w-full flex justify-center"
                color="gray"
                // text="Sign up with google"
                leftIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M19.4176 10.1941C19.4176 9.47471 19.358 8.94971 19.2291 8.40527H10.8461V11.6525H15.7667C15.6676 12.4594 15.1318 13.6747 13.9413 14.4913L13.9247 14.6L16.5752 16.6123L16.7588 16.6303C18.4453 15.1039 19.4176 12.858 19.4176 10.1941Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M10.8456 18.75C13.2562 18.75 15.28 17.9722 16.7582 16.6305L13.9408 14.4916C13.1868 15.0068 12.1749 15.3666 10.8456 15.3666C8.48448 15.3666 6.48053 13.8402 5.76618 11.7305L5.66148 11.7392L2.90543 13.8295L2.86938 13.9277C4.33762 16.786 7.35349 18.75 10.8456 18.75Z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.7667 11.7302C5.57822 11.1858 5.46913 10.6024 5.46913 9.99967C5.46913 9.39686 5.57822 8.81355 5.75679 8.26911L5.7518 8.15316L2.9612 6.0293L2.8699 6.07186C2.26477 7.25798 1.91754 8.58995 1.91754 9.99967C1.91754 11.4094 2.26477 12.7413 2.8699 13.9274L5.7667 11.7302Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M10.8456 4.63331C12.5222 4.63331 13.6531 5.34303 14.298 5.93612L16.8178 3.525C15.2702 2.11528 13.2563 1.25 10.8456 1.25C7.35351 1.25 4.33763 3.21387 2.86938 6.07218L5.75628 8.26943C6.48055 6.15972 8.48451 4.63331 10.8456 4.63331Z"
                      fill="#EB4335"
                    />
                  </svg>
                }
              />
            </div>
            <div className="w-full">
              <BorderButton
                className="w-full"
                buttonClass="w-full flex justify-center"
                color="gray"
                leftIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <rect
                      x="1.25"
                      y="1.25"
                      width="17.5"
                      height="17.5"
                      rx="8.75"
                      fill="#1275B1"
                    />
                    <path
                      d="M7.8866 6.05759C7.8866 6.64169 7.38032 7.11519 6.7558 7.11519C6.13128 7.11519 5.625 6.64169 5.625 6.05759C5.625 5.4735 6.13128 5 6.7558 5C7.38032 5 7.8866 5.4735 7.8866 6.05759Z"
                      fill="white"
                    />
                    <path
                      d="M5.77964 7.89256H7.71263V13.75H5.77964V7.89256Z"
                      fill="white"
                    />
                    <path
                      d="M10.8247 7.89256H8.89175V13.75H10.8247C10.8247 13.75 10.8247 11.906 10.8247 10.753C10.8247 10.061 11.061 9.36596 12.0039 9.36596C13.0694 9.36596 13.063 10.2716 13.058 10.9732C13.0515 11.8903 13.067 12.8262 13.067 13.75H15V10.6586C14.9836 8.68461 14.4693 7.77505 12.7771 7.77505C11.7721 7.77505 11.1492 8.23129 10.8247 8.64406V7.89256Z"
                      fill="white"
                    />
                  </svg>
                }
              />
            </div>
            <div className="w-full">
              <BorderButton
                className="w-full"
                buttonClass="w-full flex justify-center"
                color="gray"
                leftIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M17.1669 6.96902C17.0834 7.01776 15.0942 8.04647 15.0942 10.3273C15.188 12.9285 17.6044 13.8408 17.6459 13.8408C17.6044 13.8895 17.2811 15.0834 16.3232 16.335C15.563 17.4131 14.7192 18.5 13.438 18.5C12.2192 18.5 11.7817 17.7815 10.3755 17.7815C8.86528 17.7815 8.43797 18.5 7.28172 18.5C6.00046 18.5 5.09421 17.3548 4.29258 16.2869C3.25114 14.8891 2.36594 12.6956 2.33469 10.5894C2.31363 9.47336 2.54325 8.37628 3.12614 7.44444C3.94883 6.14352 5.41758 5.26042 7.02153 5.2313C8.25047 5.19268 9.34422 6.01754 10.0942 6.01754C10.813 6.01754 12.1567 5.2313 13.6771 5.2313C14.3334 5.23193 16.0834 5.41615 17.1669 6.96902ZM9.99028 5.00846C9.77153 3.98926 10.3755 2.97005 10.938 2.31991C11.6567 1.53366 12.7919 1 13.7709 1C13.8334 2.01921 13.4373 3.01879 12.7294 3.7468C12.0942 4.53305 11.0005 5.12495 9.99028 5.00846Z"
                      fill="black"
                    />
                  </svg>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
