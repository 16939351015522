import React from "react";
import FillButton from "../../../../components/buttons/fillButton";
import { useNavigate } from "react-router";
import { formatPrice } from "../../../../services/courses";
import { Mixpanel } from "../../../../services/mixpanel";
import moment from "moment";
import { browserName, isMobileOnly } from "react-device-detect";

export const ProgramPrice = ({
  courseDetails,
  joinWaitingList,
  userData,
  timeLeft,
  redirectToLogin,
  auth,
  isRegister,
  inWaitlist,
}) => {
  const navigate = useNavigate();
  console.log(isRegister, inWaitlist);
  return (
    <div className="w-full h-auto">
      <div className="self-stretch h-auto px-4 py-8 md:px-[124px] md:py-[72px] flex-col justify-start items-center gap-10 flex">
        <div className="w-full lg:w-[960px] h-auto px-4 py-8 bg-gradient-to-br from-violet-200 via-violet-500 to-violet-950 rounded-[32px] border-2 border-white/opacity-10 flex-col justify-center items-center flex">
          <div className="h-auto flex-col justify-start items-center gap-7 flex">
            <div className="text-white opacity-80 text-xl font-bold font-satoshi leading-[18.69px]">
              {courseDetails?.type === "MICRO DEGREE"
                ? "Program Price"
                : "Registration Price"}
            </div>
            <div className="flex-col justify-start items-center gap-[30px] flex">
              <div className="text-center text-white text-[32px] font-bold font-satoshi leading-[35px]">
                Get your exclusive seat today
              </div>
              <div>
                <span className="text-white text-[40px] font-bold font-satoshi tracking-tight">
                  ₹
                  {courseDetails?.type === "JOB PLACEMENT"
                    ? formatPrice(courseDetails?.finalPrice)
                    : formatPrice(courseDetails?.finalPrice)}
                </span>{" "}
                {courseDetails?.type === "MICRO DEGREE" ? (
                  <span className="text-white/50 text-xl font-medium font-satoshi tracking-tight line-through">
                    {" "}
                    {courseDetails?.type === "MICRO DEGREE"
                      ? `${courseDetails?.actualPrice}`
                      : ""}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="flex-col justify-start items-start gap-2.5 flex">
                <div className="h-[22.33px] justify-start items-center gap-[5px] inline-flex">
                  <div className="w-[15.24px] h-[15.24px] relative ">
                    <img src="/svg/greenTrueMark.svg" />
                  </div>
                  <div className="text-white text-sm font-bold font-satoshi leading-none">
                    Real Time Projects
                  </div>
                </div>
                <div className="h-[22.33px] justify-start items-center gap-[5px] inline-flex">
                  <div className="w-[15.24px] h-[15.24px] relative">
                    <img src="/svg/greenTrueMark.svg" />
                  </div>
                  <div className="text-white text-sm font-bold font-satoshi leading-none">
                    Verified Certificate
                  </div>
                </div>
                <div className="h-[22.33px] justify-start items-center gap-[5px] inline-flex">
                  <div className="w-[15.24px] h-[15.24px] relative">
                    <img src="/svg/greenTrueMark.svg" />
                  </div>
                  <div className="text-white text-sm font-bold font-satoshi leading-none">
                    Students Forum
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch h-[110.33px] flex-col justify-start items-center gap-2 flex">
              {!courseDetails?.bypassWaitlist && (
                <>
                  {timeLeft.hours >= 0 && (
                    <div className="text-emerald-400 text-xs font-black font-satoshi leading-tight">
                      Registration opens in{" "}
                      {String(timeLeft.hours).padStart(2, "0")}:
                      {String(timeLeft.minutes).padStart(2, "0")}:
                      {String(timeLeft.seconds).padStart(2, "0")} Hrs
                    </div>
                  )}
                </>
              )}

              <FillButton
                className="sm:max-w-[288px]"
                onClick={() => {
                  if (
                    courseDetails?.isRegistrationLive === false &&
                    !courseDetails?.bypassWaitlist &&
                    !auth
                  ) {
                    Mixpanel.track("Join Waitlist CTA Clicked", {
                      date: moment().format(),
                      distinct_id: localStorage.getItem("userId"),
                      user_id: localStorage.getItem("userId"),
                      name: userData?.profileInfo?.name,
                      number: userData?.number,
                      email: userData?.email,
                      course_Id: courseDetails?._id,
                      browser: browserName,
                      mobile_device: isMobileOnly,
                      type: "webapp",
                    });
                    redirectToLogin(courseDetails?._id);
                  } else if (
                    courseDetails?.isRegistrationLive === false &&
                    auth &&
                    !userData?.profileInfo?.name &&
                    courseDetails?.userInWaitlist === false &&
                    !courseDetails?.bypassWaitlist
                  ) {
                    navigate(
                      `/join-waitlist/fill-details?id=${courseDetails?._id}`,
                    );
                  } else if (
                    courseDetails?.isRegistrationLive === false &&
                    auth &&
                    userData?.profileInfo?.name !== "" &&
                    courseDetails?.userInWaitlist === false &&
                    !courseDetails?.bypassWaitlist
                  ) {
                    Mixpanel.track("Join Waitlist CTA Clicked", {
                      date: moment().format(),
                      distinct_id: localStorage.getItem("userId"),
                      user_id: localStorage.getItem("userId"),
                      name: userData?.profileInfo?.name,
                      number: userData?.number,
                      email: userData?.email,
                      course_Id: courseDetails?._id,
                      browser: browserName,
                      mobile_device: isMobileOnly,
                      type: "webapp",
                    });
                    joinWaitingList(courseDetails?._id);
                    console.log("h12");
                  } else if (
                    courseDetails?.isRegistrationLive === true &&
                    !courseDetails?.bypassWaitlist &&
                    !auth
                  ) {
                    Mixpanel.track("Join Waitlist CTA Clicked", {
                      date: moment().format(),
                      distinct_id: localStorage.getItem("userId"),
                      user_id: localStorage.getItem("userId"),
                      name: userData?.profileInfo?.name,
                      number: userData?.number,
                      email: userData?.email,
                      course_Id: courseDetails?._id,
                      browser: browserName,
                      mobile_device: isMobileOnly,
                      type: "webapp",
                    });
                    redirectToLogin(courseDetails?._id);
                  } else if (
                    auth &&
                    courseDetails?.isRegistrationLive === true &&
                    auth &&
                    courseDetails?.userInWaitlist === true &&
                    courseDetails?.userRegistered === false &&
                    !courseDetails?.bypassWaitlist
                  ) {
                    Mixpanel.track("register CTA Clicked", {
                      date: moment().format(),
                      distinct_id: localStorage.getItem("userId"),
                      user_id: localStorage.getItem("userId"),
                      name: userData?.profileInfo?.name,
                      number: userData?.number,
                      email: userData?.email,
                      course_Id: courseDetails?._id,
                      browser: browserName,
                      mobile_device: isMobileOnly,
                      type: "webapp",
                    });
                    navigate(`/register/payment?id=${courseDetails?._id}`);
                  } else if (auth && courseDetails?.bypassWaitlist === true) {
                    Mixpanel.track("register CTA Clicked", {
                      date: moment().format(),
                      distinct_id: localStorage.getItem("userId"),
                      user_id: localStorage.getItem("userId"),
                      name: userData?.profileInfo?.name,
                      number: userData?.number,
                      email: userData?.email,
                      course_Id: courseDetails?._id,
                      browser: browserName,
                      mobile_device: isMobileOnly,
                      type: "webapp",
                    });
                    navigate(`/register/payment?id=${courseDetails?._id}`);
                  } else if (!auth && courseDetails?.bypassWaitlist === true) {
                    Mixpanel.track("register CTA Clicked", {
                      date: moment().format(),
                      distinct_id: localStorage.getItem("userId"),
                      user_id: localStorage.getItem("userId"),
                      name: userData?.profileInfo?.name,
                      number: userData?.number,
                      email: userData?.email,
                      course_Id: courseDetails?._id,
                      browser: browserName,
                      mobile_device: isMobileOnly,
                      type: "webapp",
                    });
                    navigate(`/register/fill-details?id=${courseDetails?._id}`);
                  } else if (
                    courseDetails?.isRegistrationLive === true &&
                    auth &&
                    !userData?.profileInfo?.name &&
                    courseDetails?.userInWaitlist === false &&
                    !courseDetails?.bypassWaitlist
                  ) {
                    navigate(
                      `/join-waitlist/fill-details?id=${courseDetails?._id}`,
                    );
                  } else if (
                    courseDetails?.isRegistrationLive === true &&
                    auth &&
                    userData?.profileInfo?.name !== "" &&
                    courseDetails?.userInWaitlist === false &&
                    !courseDetails?.bypassWaitlist
                  ) {
                    Mixpanel.track("Join Waitlist CTA Clicked", {
                      date: moment().format(),
                      distinct_id: localStorage.getItem("userId"),
                      user_id: localStorage.getItem("userId"),
                      name: userData?.profileInfo?.name,
                      number: userData?.number,
                      email: userData?.email,
                      course_Id: courseDetails?._id,
                      browser: browserName,
                      mobile_device: isMobileOnly,
                      type: "webapp",
                    });
                    joinWaitingList(courseDetails?._id);
                    console.log("h11");
                  } else {
                    Mixpanel.track("Micro Degree Card Clicked", {
                      date: moment().format(),
                      distinct_id: localStorage.getItem("userId"),
                      user_id: localStorage.getItem("userId"),
                      name: userData?.profileInfo?.name,
                      number: userData?.number,
                      email: userData?.email,
                      course_Id: courseDetails?._id,
                      browser: browserName,
                      mobile_device: isMobileOnly,
                      type: "webapp",
                    });
                    navigate(
                      `/program-details?id=${courseDetails?._id}&type=${courseDetails?.type}`,
                    );
                  }
                }}
                disabled={
                  (!courseDetails?.bypassWaitlist &&
                    courseDetails?.userInWaitlist === true &&
                    courseDetails?.userRegistered === false &&
                    courseDetails?.isRegistrationLive === false) ||
                  courseDetails?.userRegistered === true
                }
                textColor="white"
                color="green"
                text={
                  (auth &&
                    courseDetails?.userInWaitlist === true &&
                    courseDetails?.userRegistered === false &&
                    !courseDetails?.bypassWaitlist) ||
                  (courseDetails?.bypassWaitlist === true &&
                    courseDetails?.userRegistered === false)
                    ? "Register"
                    : courseDetails?.userRegistered === true
                      ? "Registered"
                      : "Join Waitlist"
                }
              />

              {/* <div className="self-stretch p-4 bg-emerald-500 rounded-lg justify-center items-center gap-2.5 inline-flex">
                        <div className="text-white text-base font-black font-satoshi leading-tight">Join Waitlist Now</div>
                    </div> */}
              <div className="h-[22.33px] justify-center items-center gap-[5px] inline-flex">
                <div className="w-5 h-5 pl-[3px] pr-0.5 pt-0.5 pb-[3px] justify-center items-center flex">
                  <div className="w-[15px] h-[15px] relative flex-col justify-start items-start flex">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_12369_34571)">
                        <path
                          d="M7.60171 9.66793C8.23065 9.25017 8.70837 8.64101 8.96419 7.93063C9.22001 7.22024 9.24032 6.44637 9.02213 5.72355C8.80393 5.00072 8.35882 4.36734 7.75267 3.91715C7.14652 3.46696 6.41154 3.22388 5.6565 3.22388C4.90146 3.22388 4.16648 3.46696 3.56033 3.91715C2.95418 4.36734 2.50907 5.00072 2.29087 5.72355C2.07268 6.44637 2.093 7.22024 2.34881 7.93063C2.60463 8.64101 3.08235 9.25017 3.71129 9.66793C2.63914 10.0634 1.7125 10.7754 1.0542 11.7095C1.00478 11.7798 0.975589 11.8622 0.969812 11.9478C0.964036 12.0335 0.981896 12.1191 1.02145 12.1953C1.06099 12.2715 1.12071 12.3354 1.19409 12.38C1.26746 12.4246 1.35167 12.4481 1.43753 12.4481L9.87528 12.4478C9.96114 12.4478 10.0453 12.4242 10.1187 12.3797C10.1921 12.3351 10.2518 12.2712 10.2913 12.195C10.3309 12.1188 10.3487 12.0331 10.3429 11.9475C10.3372 11.8618 10.308 11.7794 10.2585 11.7092C9.60024 10.7753 8.67371 10.0634 7.60171 9.66793Z"
                          fill="white"
                        />
                        <path
                          d="M15.0342 11.7091C14.3759 10.7751 13.4494 10.0633 12.3774 9.66783C13.0744 9.20386 13.5829 8.50637 13.8114 7.70086C14.04 6.89536 13.9735 6.03474 13.624 5.27387C13.2746 4.51299 12.665 3.90183 11.9051 3.55035C11.1451 3.19887 10.2847 3.13015 9.47856 3.35657C9.40538 3.37715 9.3383 3.41522 9.28311 3.4675C9.22792 3.51978 9.18627 3.58469 9.16176 3.65665C9.13725 3.72861 9.1306 3.80545 9.1424 3.88055C9.15419 3.95565 9.18408 4.02675 9.22948 4.08773C9.75937 4.79953 10.064 5.65368 10.1041 6.54015C10.1442 7.42662 9.91797 8.30479 9.45452 9.06153C9.39332 9.16133 9.37168 9.28042 9.39385 9.39537C9.41603 9.51032 9.48041 9.61282 9.57434 9.68269C9.75207 9.81496 9.92306 9.95604 10.0867 10.1054C10.0951 10.1146 10.1036 10.1237 10.113 10.1324C10.7386 10.7108 11.2457 11.4054 11.6059 12.1776C11.6436 12.2584 11.7036 12.3268 11.7789 12.3747C11.8541 12.4226 11.9415 12.4481 12.0306 12.448L14.651 12.4477C14.7369 12.4477 14.8211 12.4241 14.8944 12.3795C14.9678 12.3349 15.0275 12.271 15.067 12.1948C15.1066 12.1186 15.1244 12.033 15.1186 11.9474C15.1128 11.8617 15.0836 11.7793 15.0342 11.7091Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_12369_34571">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.5 0.413086)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div>
                  <span className="text-white text-sm font-bold font-satoshi leading-none">
                    {" "}
                    {courseDetails?.waitlistBuffer +
                      courseDetails?.waitlistCount}{" "}
                    Joined{" "}
                  </span>
                  <span className="text-white text-[12px] font-bold font-satoshi leading-none">
                    In Waitlist
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
