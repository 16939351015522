import React from "react";

export const WhatToLearn = ({courseDetails}) => {
  return <div className="w-full h-auto">
    <div className="w-[100vw] h-auto py-[72px] flex-col justify-start items-start gap-10 inline-flex">
    <div className="self-stretch h-auto flex-col justify-start items-center gap-8 flex">
        <div className="flex-col justify-start items-center gap-4 flex">
            <div className="max-w-[652px] h-auto text-center text-gray-100 text-2xl font-bold font-satoshi">What you will learn from this</div>
        </div>
        {/* <div className="w-full h-auto bg-gradient-to-l from-black to-black" /> */}


        <div className="w-full inline-flex overflow-scroll no-scrollbar gap-[15px] hover:animate-scroll">
    {/* <div className="bg-gray-100 opacity-20 w-[200px] " style={{zIndex:2}}></div> */}

   
    <div className="w-full h-auto inline-flex relative py-2">

   {/* <img src="GradienteffectLeft.png" alt="Gradient Effect" className="absolute sticky top-0 left-0 h-full object-cover"/> */}
   <div className="ml-[-6rem] w-full inline-flex gap-8 animate-marquee whitespace-nowrap">
   {courseDetails?.learningPoints?.map((point, index)=>(
                 <div key={index} className={`p-8 ${courseDetails?.type === "MICRO DEGREE" ? 
                 "bg-[#180B2B]" : 
                 (courseDetails?.type === "JOB PLACEMENT" && 
                 courseDetails?.topSectionColors[1] === "#191772") ? 
                 "bg-[#191772]/20" : 
                 (courseDetails?.type === "JOB PLACEMENT" && 
                 courseDetails?.topSectionColors[1] === "#7A2D28") ? 
                 "bg-[#7A2D28]/20" : 
                 (courseDetails?.type === "JOB PLACEMENT" &&
                  courseDetails?.topSectionColors[1] === "#725017") ?
                   "bg-[#725017]/20" : ""} 
                   rounded-2xl shadow border-2 border-gray-400 justify-start items-start flex `} style={{ boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)'}}>
                 <div className="flex-col justify-start items-start gap-6 inline-flex">
                     <div className="w-max self-stretch text-gray-400 text-base font-medium font-satoshi">{point}</div>
                     
                 </div>
             </div>
            ))}

       </div>
       {/* <img src="GradienteffectRight.png" alt="Gradient Effect" className="absolute sticky top-0 right-0 h-full object-cover"/> */}

   </div>



{/* <div className="bg-gray-100 opacity-50 w-[200px]" style={{zIndex:1}}></div> */}
</div>
  
    </div>
</div>
  </div>;
};
