import React from "react";

export const BackupByTheBest = () => {
  return <div className="bg-white w-full h-auto px-4 sm:px-8 lg:px-16 py-[70px] lg:px-[124px]">
    <div className="w-full h-auto flex-col justify-center items-center gap-[54px] inline-flex">
    <div className="flex-col justify-start items-center gap-6 flex">
        <div className="w-full h-[29px] text-center text-teal-950 text-2xl font-bold font-satoshi leading-[28.80px]">We are backed up by the best</div>
    </div>
    <div className="w-full h-auto justify-center items-center gap-4 sm:gap-8 md:gap-[54px] flex flex-col sm:flex-row ">
   <div className="w-full h-auto flex flex-col justify-center items-center">
    <img src="/svg/ashneerG.svg" />
    <div className="w-[205px] text-center text-slate-500 text-xl font-bold font-satoshi">Ansheer Grover <br />Co-Founder (BharatPe)
    </div>
    <img src="/svg/bharatPay.svg"/>
   </div>
   <div className="w-full h-auto flex justify-center">
   <img src="shark-logo 1.png" />
   </div>
   <div className="w-full h-auto flex flex-col justify-center items-center">
   <img src="/svg/amanG.svg" />
   <div className="w-[205px] text-center text-slate-500 text-xl font-bold font-satoshi">Aman Gupta <br />Co-Founder (Boat) 
   </div>
   <img src="/svg/boat.svg"/>
   </div>
    </div>
</div>
  </div>;
};
