import React from "react";

export const ApplyPageOptions = () => {

  return <div className="w-full">
    
<div className="w-full h-[98px] p-8 bg-white flex-col justify-start items-start gap-8 inline-flex">
    <div className="self-stretch px-4 py-2 bg-gray-100 justify-start items-center gap-1 inline-flex">
        <div className="text-slate-500 text-xs font-medium font-['Satoshi'] capitalize leading-[18px]">Job Details</div>
        <div className="w-3 h-3 relative">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <path d="M8.51531 6.26561L4.76531 10.0156C4.71287 10.0681 4.64602 10.1039 4.57324 10.1184C4.50046 10.1329 4.42501 10.1254 4.35645 10.097C4.28789 10.0686 4.22931 10.0205 4.1881 9.95879C4.1469 9.89707 4.12494 9.82451 4.125 9.7503V2.2503C4.12494 2.17608 4.1469 2.10352 4.1881 2.0418C4.22931 1.98008 4.28789 1.93197 4.35645 1.90356C4.42501 1.87515 4.50046 1.86773 4.57324 1.88222C4.64602 1.89672 4.71287 1.93248 4.76531 1.98498L8.51531 5.73498C8.55018 5.76981 8.57784 5.81117 8.59671 5.85669C8.61558 5.90222 8.62529 5.95101 8.62529 6.0003C8.62529 6.04958 8.61558 6.09837 8.59671 6.1439C8.57784 6.18942 8.55018 6.23078 8.51531 6.26561Z" fill="#667984"/>
</svg>
        </div>
        <div className="text-slate-500 text-xs font-black font-['Satoshi'] capitalize leading-[18px]">Screening Questions</div>
        <div className="w-3 h-3 relative">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <path d="M8.51531 6.26561L4.76531 10.0156C4.71287 10.0681 4.64602 10.1039 4.57324 10.1184C4.50046 10.1329 4.42501 10.1254 4.35645 10.097C4.28789 10.0686 4.22931 10.0205 4.1881 9.95879C4.1469 9.89707 4.12494 9.82451 4.125 9.7503V2.2503C4.12494 2.17608 4.1469 2.10352 4.1881 2.0418C4.22931 1.98008 4.28789 1.93197 4.35645 1.90356C4.42501 1.87515 4.50046 1.86773 4.57324 1.88222C4.64602 1.89672 4.71287 1.93248 4.76531 1.98498L8.51531 5.73498C8.55018 5.76981 8.57784 5.81117 8.59671 5.85669C8.61558 5.90222 8.62529 5.95101 8.62529 6.0003C8.62529 6.04958 8.61558 6.09837 8.59671 6.1439C8.57784 6.18942 8.55018 6.23078 8.51531 6.26561Z" fill="#667984"/>
</svg>
        </div>
        <div className="text-gray-400 text-xs font-medium font-['Satoshi'] capitalize leading-[18px]">Preview & Apply</div>
    </div>
</div>
  </div>;
};
