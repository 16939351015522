import React from "react";

export const OurAlumni = ({ courseDetails }) => {
  return (
    <div className="w-full h-auto flex flex-col gap-8">
      <div className="w-full h-auto text-center text-white text-2xl font-bold font-satoshi">
        Our Alumni works at
      </div>

      <div className="w-full flex justify-center items-center ">
        {courseDetails?.alumini?.map((image, index) => (
          <div
            key={index}
            className=" h-46px flex justify-center items-center overflow-scroll no-scrollbar m-4 "
          >
            <img
              src={image}
              alt={`Alumni ${index}`}
              className="h-[55.97px] w-full"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
