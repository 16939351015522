import React from "react";
import FillButton from "../../../../components/buttons/fillButton";

export const Banner4 = ({redirect}) => {
  return (
    <div className="w-full h-[558px] xl:px-[124px] py-8 md:py-[72px]  lg:px-[124px] bg-white justify-center items-center inline-flex">
      <div className="w-full justify-center items-center inline-flex">
        <div
          className="relative min-w-[1195px] h-[470px] inline-flex justify-start items-center pl-16 rounded-tr-[10px] rounded-bl-[10px]"
          style={{
            backgroundImage: 'url("sharkBanner.png")',
            backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
          }}
        >
          <div className="absolute rounded-xl flex-col justify-start items-start gap-6 inline-flex mt-[9rem]">
            {/* <div className="w-[519px] text-teal-950 text-[56px] font-bold font-satoshi leading-[61.60px]">
              Join the Sharks & Grow your career
            </div>
            <div className="text-[#334D5B] text-base font-bold font-satoshi leading-relaxed">
              Browse and join startups featured on Shark Tank India
            </div> */}
            <FillButton
            onClick={redirect}
              className="w-max px-8"
              color="black"
              textColor="white"
              text="View Opportunities"
            />
          </div>
          {/* <div className="relative w-[446px] h-[446px]">
           
            <img
              className="absolute  mt-[1.3rem] ml-[4.6rem]  "
              src="shark tank.png"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};
