import React, { useState, useEffect, useRef} from "react";
import Notifications from "../../../../../components/notifications_dialog_box/Notifications";
import { Link, useNavigate } from "react-router-dom";

export const ScreensHeading = ({activeSection,setActiveSection,setMenuOpen,menuOpen, jobPageSection, setJobPageSection}) => {

const [isNotificationClicked, setIsNotificationClicked ] = useState(false);
const navigate = useNavigate();
const modalRef = useRef(null);
  const handleBackClick = () => {
    

    // Set active section based on jobPageSection
    if (jobPageSection === "screenQue") {
      setActiveSection("jobDescription"); // Assuming job description is the previous section
    } else {
      // Handle other scenarios as needed
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (isNotificationClicked && !modalRef.current.contains(event.target)) {
        setIsNotificationClicked(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNotificationClicked]);

  return (
    <div className="w-full h-auto px-2 py-2 md:px-8 md:py-8  bg-white shadow-inner justify-between items-center inline-flex border-b border-gray-100">
     <div className="w-full flex flex-row items-center">  
     <div className="w-auto">
     <button
        className="sm:hidden  top-0 left-0 z-50 m-4"
        onClick={() => setMenuOpen(!menuOpen)}
      >
       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
  <path d="M13 17.0067H3C2.73478 17.0067 2.48043 17.1122 2.29289 17.3C2.10536 17.4879 2 17.7426 2 18.0083C2 18.2739 2.10536 18.5287 2.29289 18.7166C2.48043 18.9044 2.73478 19.0099 3 19.0099H13C13.2652 19.0099 13.5196 18.9044 13.7071 18.7166C13.8946 18.5287 14 18.2739 14 18.0083C14 17.7426 13.8946 17.4879 13.7071 17.3C13.5196 17.1122 13.2652 17.0067 13 17.0067ZM3 8.99352H21C21.2652 8.99352 21.5196 8.88799 21.7071 8.70014C21.8946 8.5123 22 8.25753 22 7.99188C22 7.72622 21.8946 7.47145 21.7071 7.28361C21.5196 7.09576 21.2652 6.99023 21 6.99023H3C2.73478 6.99023 2.48043 7.09576 2.29289 7.28361C2.10536 7.47145 2 7.72622 2 7.99188C2 8.25753 2.10536 8.5123 2.29289 8.70014C2.48043 8.88799 2.73478 8.99352 3 8.99352ZM21 11.9984H3C2.73478 11.9984 2.48043 12.104 2.29289 12.2918C2.10536 12.4797 2 12.7344 2 13.0001C2 13.2657 2.10536 13.5205 2.29289 13.7084C2.48043 13.8962 2.73478 14.0017 3 14.0017H21C21.2652 14.0017 21.5196 13.8962 21.7071 13.7084C21.8946 13.5205 22 13.2657 22 13.0001C22 12.7344 21.8946 12.4797 21.7071 12.2918C21.5196 12.104 21.2652 11.9984 21 11.9984Z" fill="black"/>
</svg>
      </button>
      </div>
      {/* back arrow */}
      {activeSection!="findjobs" && 
      <Link onClick={()=>navigate(-1)} className="px-4"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <g clip-path="url(#clip0_7931_30951)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66699 19.9997C6.66699 19.0792 7.41318 18.333 8.33366 18.333H31.667C32.5875 18.333 33.3337 19.0792 33.3337 19.9997C33.3337 20.9201 32.5875 21.6663 31.667 21.6663H8.33366C7.41318 21.6663 6.66699 20.9201 6.66699 19.9997Z" fill="#002032"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.15515 18.8212C7.80602 18.1703 8.8613 18.1703 9.51217 18.8212L19.5122 28.8212C20.163 29.472 20.163 30.5273 19.5122 31.1782C18.8613 31.8291 17.806 31.8291 17.1551 31.1782L7.15515 21.1782C6.50427 20.5273 6.50427 19.472 7.15515 18.8212Z" fill="#002032"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5122 8.82116C20.163 9.47204 20.163 10.5273 19.5122 11.1782L9.51217 21.1782C8.8613 21.8291 7.80602 21.8291 7.15515 21.1782C6.50427 20.5273 6.50427 19.472 7.15515 18.8212L17.1551 8.82116C17.806 8.17029 18.8613 8.17029 19.5122 8.82116Z" fill="#002032"/>
  </g>
  <defs>
    <clipPath id="clip0_7931_30951">
      <rect width="40" height="40" fill="white"/>
    </clipPath>
  </defs>
</svg></Link>}

      <div className="text-teal-950 sx:text-[25px] md:text-[40px] font-bold font-satoshi leading-[48px]">
        {activeSection === "findjobs" ? 
        "Find Opportunities"
        : activeSection === "messages" ?
        "Messages"
        : activeSection === "my-applications" ?
        " My Applications"
        : activeSection === "my-profile" ?
        "My Profile"
        :activeSection === "settings" ? 
        "Settings"
        : activeSection === "help-and-feedback" ?
        "Help & Feedback" 
        : activeSection === "jobDescription" ?
        "Job Description"
        : (activeSection === "screenQue" ) ?
        "Screening Questions"
        : (activeSection === "bookmarks" ) ?
        "Bookmarks"
        :""

    }
      </div>
      </div>
      {isNotificationClicked && (
        <div className="absolute top-16 right-5 w-full h-full">
          <div ref={modalRef}>
            <Notifications isNotificationClicked={isNotificationClicked} />
          </div>
        </div>
      )}
      <div className="h-10 justify-center items-center gap-8 inline-flex ">
        <div  onClick={() => setIsNotificationClicked(!isNotificationClicked)} className="w-10 h-10 relative">
          <div className="w-10 h-10 left-0 top-0 absolute bg-white flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.48243 19.2365C8.00043 19.8144 8.66543 20.132 9.35543 20.132H9.35643C10.0494 20.132 10.7174 19.8144 11.2364 19.2355C11.5144 18.928 11.9884 18.9029 12.2954 19.1804C12.6034 19.4579 12.6284 19.9336 12.3514 20.2411C11.5434 21.1396 10.4804 21.6344 9.35643 21.6344H9.35443C8.23343 21.6334 7.17243 21.1386 6.36743 20.2401C6.09043 19.9326 6.11543 19.4569 6.42343 19.1804C6.73143 18.9019 7.20543 18.927 7.48243 19.2365ZM9.40523 0.0991211C13.8502 0.0991211 16.8362 3.56681 16.8362 6.80511C16.8362 8.47084 17.2592 9.177 17.7082 9.92623C18.1522 10.6654 18.6552 11.5048 18.6552 13.0914C18.3062 17.1451 14.0812 17.4756 9.40523 17.4756C4.72923 17.4756 0.50323 17.1451 0.158216 13.1555C0.15523 11.5048 0.65823 10.6654 1.10223 9.92623L1.25897 9.66195C1.6449 8.99757 1.97423 8.27488 1.97423 6.80511C1.97423 3.56681 4.96023 0.0991211 9.40523 0.0991211ZM9.40523 1.60158C5.91023 1.60158 3.47423 4.34408 3.47423 6.80511C3.47423 8.88753 2.89723 9.85011 2.38723 10.6995C1.97823 11.3816 1.65523 11.9205 1.65523 13.0914C1.82223 14.9805 3.06723 15.9731 9.40523 15.9731C15.7082 15.9731 16.9922 14.9364 17.1582 13.0263C17.1552 11.9205 16.8322 11.3816 16.4232 10.6995C15.9132 9.85011 15.3362 8.88753 15.3362 6.80511C15.3362 4.34408 12.9002 1.60158 9.40523 1.60158Z" fill="#002032"/>
</svg>
          </div>
          <div className="w-6 h-6 left-[-3px] top-[0.93px] absolute flex items-center justify-center">
            <div className="w-1.5 h-1.5 left-[25px] top-[6px] absolute bg-red-400 rounded-full border border-white">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
