import React, { useEffect, useState } from "react";
import NavigateComponent from "./commonComponents/NavigateComponent";
import SettingHeading from "./commonComponents/SettingHeading";
import SearchComponent from "../../sign_up_flow/utils/tagSelect";
import BorderButton from "../../../components/buttons/borderButton";
import AppPreferences from "./appPreferences";
import AccountSetting from "./accountSetting";
import ReferAndEarn from "./referAndEarn";
import { getUserDetails } from "../../../services/settingsflow";
import Notifications from "../../../components/notifications_dialog_box/Notifications";
import Loader from "../../../components/loader/loader";

function SettingMain() {
  const [userData, setUserData] = useState({});
  const [activeSection, setActiveSection] = useState("App-Preference");
  const [toggle, setToggle] = useState(false);
  const [isNotificationClicked, setIsNotificationClicked] = useState(false);
  const [form, setForm] = useState({
    skills: [],
    designations: [],
    location: [],
    JobType: [],
    workplaceType: [],
  });
  const setFunc = () => {
    setTimeout(() => {
      setToggle(true);
    }, 500);
  };
  useEffect(() => {
    setFunc();
    const userFetched = async () => {
      const response = await getUserDetails();
      if (response) {
        setUserData(response);
        setForm({
          skills: response?.skills || [],
          location: response?.locations || [],
          JobType: response?.jobType || [],
          workplaceType: response?.workplace || [],
          designations: response?.designations || [],
        });
      }
    };
    userFetched();
  }, []);

  const [formAccountSetting, setFormAccountSetting] = useState({
    emailNotifications: [],
    smsNotifications: [],
  });

  const onJobTypeSelect = (selectedValue) => {
    const isAlreadySelected = form.JobType.includes(selectedValue);
    // remove if already selected
    if (isAlreadySelected) {
      setForm({
        ...form,
        JobType: form.JobType.filter((ele) => ele != selectedValue),
      });
    } else {
      // add if not selected
      setForm({
        ...form,
        JobType: [...form.JobType, selectedValue],
      });
    }
  };

  const onJobWorkplaceSelect = (workplace) => {
    const isAlreadySelected = form.workplaceType.includes(workplace);
    // remove if already selected
    if (isAlreadySelected) {
      setForm({
        ...form,
        workplaceType: form.workplaceType.filter((ele) => ele != workplace),
      });
    } else {
      // add if not selected
      setForm({
        ...form,
        workplaceType: [...form.workplaceType, workplace],
      });
    }
  };

  const onEmailMultiSelect = (email) => {
    setFormAccountSetting({
      ...formAccountSetting,
      emailNotifications: email,
    });
  };
  const onSmsMultiSelect = (sms) => {
    setFormAccountSetting({
      ...formAccountSetting,
      smsNotifications: sms,
    });
  };

  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };

    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount

  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader />;
  }
  return (
    <div
      className={` w-full h-auto flex-col justify-start items-start inline-flex`}
    >
      <div className="w-full">
        {/* <SettingHeading
          setIsNotificationClicked={setIsNotificationClicked}
          isNotificationClicked={isNotificationClicked}
        />

        <div className=" relative w-full self-stretch h-[0px] my-4 border border-neutral-300"></div>

        {isNotificationClicked && (
          <div className="absolute top-15 right-5 w-full h-full backdrop-filter backdrop-blur-sm">
            <Notifications
              setIsNotificationClicked={setIsNotificationClicked}
              isNotificationClicked={isNotificationClicked}
            />
          </div>
        )} */}
        <NavigateComponent
          setActiveSection={setActiveSection}
          activeSection={activeSection}
        />
        <div className="w-full self-stretch h-[0px]   border border-neutral-300"></div>
        <div className="w-full">
          {activeSection === "App-Preference" && toggle && (
            <AppPreferences
              onJobTypeSelect={onJobTypeSelect}
              onJobWorkplaceSelect={onJobWorkplaceSelect}
              setForm={setForm}
              form={form}
            />
          )}
        </div>
        <div className="w-full">
          {activeSection === "Account-Settings" && (
            <AccountSetting
              formAccountSetting={formAccountSetting}
              onEmailMultiSelect={onEmailMultiSelect}
              onSmsMultiSelect={onSmsMultiSelect}
            />
          )}
        </div>
        {/* <div className="w-full">
          {activeSection === "Refer-And-Earn" && <ReferAndEarn />}
        </div> */}
      </div>
    </div>
  );
}

export default SettingMain;
