import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  courseById,
  courseByIdWithoutToken,
  createRazorpayOrder,
  createRazorpayOrderLU,
  createRazorpayOrderNLU,
  orderVerify,
  orderVerifyLU,
  orderVerifyNLU,
} from "../../../../services/courses";
import Loader from "../../../../components/loader/loader";
import { Mixpanel } from "../../../../services/mixpanel";
import moment from "moment";
import { browserName, isMobileOnly } from "react-device-detect";
import { getUserDetails } from "../../../../services/loginOrSignUp";
import { Helmet } from "react-helmet-async";

export const PaymentScreen = () => {
  const location = useLocation();
  const [courseDetails, setCourseDetails] = useState({});
  const [userData, setUserData] = useState("");
  const auth = localStorage.getItem("auth");
  const queryParams = new URLSearchParams(location.search);
  const fname = queryParams.get("fname");
  const number = queryParams.get("number");
  const email = queryParams.get("email");
  const position = queryParams.get("qualification");
  console.log(fname, number, email, position);

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const handleClick = async () => {
    console.log("order");
    let orderData;
    try {
      try {
        if (auth) {
          console.log("order1");
          orderData = await createRazorpayOrderLU(
            courseDetails?._id,
            courseDetails?.productId,
          );
        } else {
          console.log("order2");
          orderData = await createRazorpayOrderNLU(
            courseDetails?._id,
            fname,
            number,
            email,
            position,
          );
        }
      } catch (error) {
        console.error("An error occurred while creating the order:", error);
      }

      console.log(orderData?.error?.code, "Order Data");
      console.log(orderData.data.amount, "Amount", orderData.data.id);
      const amount = orderData.data.amount;
      const gateway = "RAZORPAY";
      const options = {
        key: "rzp_live_XsHPpqaONsbIZ6",
        // key: "rzp_test_mNXuDFyfyKcaiN",
        // console.log("")
        amount,
        currency: "INR",
        name: "EventBeep Payment Portal",
        order_id: orderData.data.id,
        description: "Make Payment Instant",
        notes: {
          userId: orderData.data?.notes?.userId,
        },
        handler: async function (response) {
          console.log("Razorpay Payment response?", response);
          let verify;
          if (auth) {
            try {
              verify = await orderVerifyLU(
                gateway,
                courseDetails?._id,
                response.razorpay_payment_id,
              );
            } catch (error) {
              console.error("Error verifying order for logged user:", error);
              // Handle the error (e.g., show an error message to the user)
            }
          } else {
            try {
              verify = await orderVerifyNLU(
                response.razorpay_payment_id,
                orderData.data.id,
                fname,
                number,
                email,
                courseDetails?._id,
                position,
              );
            } catch (error) {
              console.error(
                "Error verifying order for non-logged user:",
                error,
              );
              // Handle the error (e.g., show an error message to the user)
            }
          }

          sessionStorage.setItem("paymentId", response.razorpay_payment_id);

          if (verify) {
            Mixpanel.track("complete_payment successfull", {
              date: moment().format(),
              distinct_id: localStorage.getItem("userId"),
              user_id: localStorage.getItem("userId"),
              course_Id: courseDetails?._id,
              number: userData?.number,
              email: userData?.email,
              browser: browserName,
              mobile_device: isMobileOnly,
              type: "webapp",
            });
            // Redirect to the 'Thank You' page
            console.log("Redirecting to Success page");
            if (auth) {
              navigate(
                `/register/payment-successfull?id=${courseDetails?._id}`,
              );
            } else {
              navigate(
                `/register/payment-successfull?id=${courseDetails?._id}`,
              );
            }
          } else {
            // Handle the failure case
            console.log("Redirecting to Failure page from handler");
            // navigate(`/register/payment?id=${courseDetails?._id}`);
            if (auth) {
              navigate(
                `/register/payment-failure?id=${courseDetails?._id}&fname=${fname}&email=${email}&number=${number}&qualification=${position}`,
              );
            } else {
              navigate(
                `/register/payment-failure?id=${courseDetails?._id}&fname=${fname}&email=${email}&number=${number}&qualification=${position}`,
              );
            }
          }
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();

      rzp.on("payment.failed", async function (response) {
        const verify = await orderVerifyLU(
          gateway,
          courseDetails?._id,
          response.razorpay_payment_id,
        );

        console.log(response?.error?.code);
        console.log(response?.error?.description);
        console.log(response?.error?.source);
        console.log(response?.error?.step);
        console.log(response?.error?.reason);
        console.log(response?.error?.metadata?.order_id);
        console.log(response?.error?.metadata?.payment_id);
        navigate(
          `/register/payment-failure?id=${courseDetails?._id}&fname=${fname}&email=${email}&number=${number}&qualification=${position}`,
        );
        // Close the Razorpay modal before redirecting
        rzp.close();

        // if (verify) {
        Mixpanel.track("payment failed/canceled", {
          date: moment().format(),
          distinct_id: localStorage.getItem("userId"),
          user_id: localStorage.getItem("userId"),
          course_Id: courseDetails?._id,
          number: userData?.number,
          email: userData?.email,
          browser: browserName,
          mobile_device: isMobileOnly,
          type: "webapp",
        });
        console.log("Redirecting to Failure page");
        navigate(
          `/register/payment-failure?id=${courseDetails?._id}&fname=${fname}&email=${email}&number=${number}&qualification=${position}`,
        );
        // }
      });
    } catch (error) {
      console.error("Error:", error.message);
      console.log("Redirecting to Failure page from catch block");
      navigate(
        `/register/payment-failure?id=${courseDetails?._id}&fname=${fname}&email=${email}&number=${number}&qualification=${position}&error=${orderData?.error?.code}`,
      );
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get("id");

    const fetchCourseDetails = async () => {
      try {
        if (auth) {
          const response = await courseById(courseId);
          console.log(response?.data);
          setCourseDetails(response?.data);
        } else {
          const response = await courseByIdWithoutToken(courseId);
          console.log(response?.data);
          setCourseDetails(response?.data);
        }
      } catch (error) {
        console.error("Error fetching course details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const user = async () => {
      const response = await getUserDetails();
      setUserData(response);
    };

    user();
    if (courseId) {
      fetchCourseDetails();
    }
  }, []);

  if (isLoading) {
    return <Loader />; // Show loader while loading
  }

  return (
    <>
      <Helmet>
        <title>Course Registration: Payment</title>
      </Helmet>
      <div className="min-w-screen max-w-full min-h-screen max-h-full px-2 sx:px-4 md:px-8 lg:p-[52px] flex flex-col lg:flex-row justify-center items-center bg-gradient-to-br from-black to-[#000000] ">
        <div className="w-full lg:w-[50%] h-auto flex-col justify-center items-start gap-6 inline-flex">
          <div className="hidden md:flex w-[50px] h-[50px] absolute top-12 rounded-xl">
            <Link to="/programs">
              <img src="/svg/whiteBeepLogo.svg" />
            </Link>
          </div>
          <div className="md:hidden w-[50px] h-[50px] rounded-xl">
            <Link to="/programs">
              <img src="/svg/whiteBeepLogo.svg" />
            </Link>
          </div>

          <div className="w-full md:mt-[200px] lg:mt-0 h-auto text-white opacity-80 text-[40px] font-bold font-satoshi flex flex-wrap">
            {courseDetails?.name}
          </div>
          <div className="w-full text-start text-slate-500 text-xl font-bold font-satoshi leading-normal flex flex-wrap">
            {courseDetails?.description}
          </div>
        </div>
        <div className="w-full lg:w-[50%] my-8 h-auto flex justify-center">
          <div className="w-full md:w-[450px] xl:w-[532px] h-auto px-2 sm:px-4 md:px-[62px] py-[99px] bg-white rounded-lg flex-col justify-start items-center gap-8 inline-flex">
            <Link to="/programs" className="w-full flex justify-start">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z"
                  fill="#667984"
                />
              </svg>
            </Link>
            <div className="w-full md:w-[408px] h-auto grow py-8 px-8 shrink basis-0 bg-gradient-to-br from-black via-teal-950 to-black rounded-xl border-2 border-slate-700/opacity-20 flex-col justify-center items-center gap-2 flex">
              <div className="w-full h-auto px-[22px] py-4 rounded-[20px] flex-col justify-center items-center gap-2.5 flex">
                <div className="w-full flex-col justify-center items-center gap-6 flex">
                  <div className="w-full justify-center items-center gap-[6.38px] inline-flex">
                    <div className="w-[31.11px] h-[31.11px] relative rounded">
                      <img className="w-[83.77px]" src="/svg/beepLogo.svg" />
                    </div>
                    <div className="w-[18.35px] text-center text-white text-xl font-normal font-satoshi">
                      x
                    </div>
                    {courseDetails?.type === "MICRO DEGREE" ? (
                      <img
                        className="w-[83.77px] h-[39.09px]"
                        src="/svg/edyoda.svg"
                      />
                    ) : (
                      <img className="h-[39.09px]" src="/svg/jp.svg" />
                    )}
                  </div>

                  <div className="w-full flex flex-col justify-center items-center">
                    <div className="text-neutral-50 text-2xl font-bold font-satoshi tracking-wide">
                      {courseDetails?.name}
                    </div>
                    <div className="w-max text-transparent bg-clip-text bg-gradient-to-r from-purple-500 via-white to-purple-500 text-[33.96px] font-black font-satoshi tracking-wider">
                      {courseDetails?.type}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full h-[0px] border border-white/opacity-10"></div>
              <div className="w-full h-auto py-4 flex-col justify-start items-start gap-2.5 flex">
                <div className="w-full self-stretch h-auto flex-col justify-start items-start gap-4 flex">
                  <div className="text-gray-200 text-sm font-bold font-satoshi">
                    Price
                  </div>
                  <div className="self-stretch justify-start items-start inline-flex">
                    <div className="grow shrink basis-0 text-gray-400 text-base font-bold font-satoshi">
                      Course Fee
                    </div>
                    <div>
                      <span className="text-slate-500 text-sm font-bold font-satoshi line-through">
                        {courseDetails?.actualPrice}
                      </span>
                      <span className="text-gray-400 text-sm font-bold font-satoshi">
                        {" "}
                      </span>
                      <span className="text-gray-400 text-base font-bold font-satoshi">
                        ₹{courseDetails?.finalPrice}
                      </span>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-start inline-flex">
                    <div className="grow shrink basis-0 text-gray-400 text-base font-bold font-satoshi">
                      Platform Fee
                    </div>
                    <div className="text-gray-400 text-base font-bold font-satoshi">
                      ₹{courseDetails?.platformFee}
                    </div>
                  </div>
                  <div className="self-stretch h-[0px] border border-white/opacity-10"></div>
                  <div className="self-stretch justify-start items-start inline-flex">
                    <div className="grow shrink basis-0 text-gray-200 text-base font-black font-satoshi">
                      Total
                    </div>
                    <div className="text-gray-200 text-base font-black font-satoshi">
                      ₹{courseDetails?.finalPrice + courseDetails?.platformFee}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              //   to={`/register/payment-successfull?id=${courseDetails?._id}`}
              onClick={handleClick}
              className="self-stretch h-[50px] flex-col justify-end items-center gap-[22px] flex cursor-pointer"
            >
              <div className="self-stretch px-6 py-3 bg-[#002032] hover:shadow-md hover:border hover:border-gray-300 hover:opacity-95 rounded-xl justify-center items-center gap-2.5 inline-flex ">
                <div className="text-center text-white text-base font-bold font-satoshi leading-relaxed">
                  continue
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
