import React, { useEffect, useState } from "react";
import InputFieldComp from "../../../../components/input_fields/inputFieldComp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserDetails, sendOTP } from "../../../../services/loginOrSignUp";
import { courseById, courseByIdWithoutToken } from "../../../../services/courses";
import Loader from "../../../../components/loader/loader";
import FillButton from "../../../../components/buttons/fillButton";

export const EnterNumberScreen = () => {
    const [missing, setMissing] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get('id');
    const [courseDetails, setCourseDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
   const [form, setForm]= useState({
    phoneNo:"",
   })
 
     // Onchange functions...
  const onSetFormData = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
     // function calls....
  const handleSendOTP = async () => {
    try {
      const missingField = {};
      if (
        form.phoneNo === "" ||
        form.phoneNo?.length < 10 ||
        !/^\d+$/.test(form.phoneNo)
      )
        missingField["phoneNo"] = true;
      setMissing(missingField);
      if (Object.keys(missingField).length === 0) {
        let inputValue = form.phoneNo;
        if (!inputValue.startsWith("+91")) {
          inputValue = "+91" + inputValue;
        }
        const response = await sendOTP(inputValue);
        localStorage.setItem("phoneNo", form?.phoneNo);
        if(response){
            navigate(`/join-waitlist/verify-otp?id=${courseId}&name=${courseDetails?.name}&desc=${courseDetails?.description}&type=${courseDetails?.type}`)
        }
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };
  useEffect(()=>{
    const fetchData = async ()=>{
      const user = await getUserDetails();
      if(user?.isPhoneVerified === true || user?.isEmailVerified === true){
        navigate('/programs');
      }
    }
    fetchData();
  }, [])

useEffect(()=>{
  
    const auth = localStorage.getItem("auth");
    const fetchCourseDetails = async () => {
        try {
if(auth){
    const response = await courseById(courseId);
    console.log(response?.data)
    setCourseDetails(response?.data);
} else {
            const response = await courseByIdWithoutToken(courseId);
            console.log(response?.data)
            setCourseDetails(response?.data);
}
        } catch (error) {
            console.error("Error fetching course details:", error);
        } finally {
setIsLoading(false);
        } 
    };

    
    if (courseId) {
        fetchCourseDetails();
    }
},[]);


if (isLoading) {
  return <Loader />; // Show loader while loading
}

  return (
  
      <div className="min-w-screen max-w-full min-h-screen max-h-full px-2 sx:px-4 md:px-8 lg:p-[52px] flex flex-col lg:flex-row justify-center items-center bg-gradient-to-br from-black to-[#000000] ">
       
       <div className="w-full lg:w-[50%] h-auto flex-col justify-center items-start gap-6 inline-flex ">
      
    <div className="hidden md:flex w-[50px] h-[50px] absolute top-12 rounded-xl">
    <Link to="/programs">
     <img src="/svg/whiteBeepLogo.svg"/>
     </Link>
    </div>
    <div className="md:hidden w-[50px] h-[50px] rounded-xl">
    <Link to="/programs">
     <img src="/svg/whiteBeepLogo.svg"/>
     </Link>
    </div>
  
      <div className="w-full md:mt-[200px] lg:mt-0 h-auto text-white opacity-80 text-[40px] font-bold font-satoshi flex flex-wrap">
        {courseDetails?.name}
      </div>
      <div className="w-full text-start text-slate-500 text-xl font-bold font-satoshi leading-normal flex flex-wrap">
        {courseDetails?.description}
      </div>
    </div>
        <div className="w-full lg:w-[50%] my-8 h-auto flex justify-center">
        <div className="w-full mt-16 lg:mt-0 md:w-[450px] xl:w-[532px] h-auto md:h-[600px] px-4 sm:px-8 md:px-[62px] py-[99px] bg-white rounded-lg flex-col justify-start items-center gap-8 inline-flex">
          <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-10 flex">
            <div className="self-stretch h-[54px] flex-col justify-start items-start gap-1 flex">
              <div className="self-stretch text-teal-950 text-xl font-black font-satoshi">
                Enter your details to join waitlist for this program
              </div>
            </div>
            <div className="self-stretch h-auto flex-col justify-start items-start gap-1 flex">
            <div className="w-full">
            <InputFieldComp
              type="text"
              name="phoneNo"
              labelText="Enter Mobile Number"
              hasError={missing.phoneNo}
              errorMsg={
                missing.phoneNo
                  ? "Phone number is required in valid format."
                  : ""
              }
              inputLeftText="+91"
              placeholder="9728371300"
              value={form.phoneNo}
              onChange={onSetFormData}
              maxLength={10}
            />
          </div>

            </div>
          </div>
          <FillButton 
          className="w-full"
           onClick={handleSendOTP} 
           textColor="white"
           color="black1"
           text="Continue"
          />
        </div>
        </div>
      </div>

  );
};
