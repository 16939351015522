import React, { useState } from "react";
import { ScreensHeading } from "./screens/heading";
import { MainFindJobsOpprComp } from "../../../jobs/findJobs";
import { MyApplication } from "./screens/myApplication";
import Chat from "../../../chat/chat";
import SettingMain from "../../settingPages/SettingMain";
import ProfilePage from "../../../profile/profile";
import { ScreeningQuestion } from "../../../apply_jobs/JobScreeningQuestions";
import { Bookmarks } from "./screens/bookmarks";

export const DashRightSection = ({
  activeSection,
  setAppliedDate,
  setActiveSection,
  userData,
  setMenuOpen,
  menuOpen,
  openPlacementReport,
  setId,
  setJobId,
  jobId,
  daysDifference,
  quickFilterData,
  setQuickFilterData,
}) => {
  const [jobPageSection, setJobPageSection] = useState("");
  const [applyId, setApplyId] = useState("");

  const handleSetActiveSection = (section) => {
    // Set activeSection to provided section
    setActiveSection(section);

    // If the jobPageSection is 'screenQue', set activeSection to empty string
    if (section === "screenQue") {
      setActiveSection("");
    }
  };

  return (
    <div className="w-auto h-screen">
      <div className="w-full sticky top-0" style={{ zIndex: 1 }}>
        <ScreensHeading
          activeSection={activeSection}
          setMenuOpen={setMenuOpen}
          menuOpen={menuOpen}
          setJobPageSection={setJobPageSection}
          jobPageSection={jobPageSection}
          setActiveSection={handleSetActiveSection}
        />
      </div>
      {activeSection === "findjobs" && (
        <MainFindJobsOpprComp
          setJobPageSection={setJobPageSection}
          jobPageSection={jobPageSection}
          setActiveSection={setActiveSection}
          activeSection={activeSection}
          setJobId={setJobId}
          jobId={jobId}
          setApplyId={setApplyId}
          quickFilterData={quickFilterData}
          setQuickFilterData={setQuickFilterData}
        />
      )}
      {activeSection === "my-applications" && (
        <MyApplication
          setActiveSection={setActiveSection}
          userData={userData}
          openPlacementReport={openPlacementReport}
          setId={setId}
          setJobId={setJobId}
          setAppliedDate={setAppliedDate}
          daysDifference={daysDifference}
        />
      )}
      {activeSection === "messages" && <Chat />}

      {activeSection === "settings" && <SettingMain />}

      {activeSection === "my-profile" && <ProfilePage />}
      {activeSection === "bookmarks" && <Bookmarks />}


      {jobPageSection === "screenQue" && (
        <ScreeningQuestion
          setJobPageSection={setJobPageSection}
          applyId={applyId}
        />
      )}
      {/* {jobPageSection === "prev&apply" &&
<ScreeningQuestion 
applyId={applyId}
/>
} */}
    </div>
  );
};
