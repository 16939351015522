import React, { useState, useEffect } from "react";
import ButtonChild from "../../../../components/buttons/buttonChild";
import { FilterJobSection } from "./filterJobSection";
import {
  categoriesWithJobCount,
  fetchJobs,
} from "../../../../services/landingPageApi";
import FillButton from "../../../../components/buttons/fillButton";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../components/loader/loader";
import { searchJobProfileApiCall } from "../../../../services/loginOrSignUp";
import { generateJobUrl } from "../../../../services/jobs";
import { usePath } from "../../../../routes/context/path/pathContext";

export const FindJobsComp = ({
  setActiveSection,
  setJobId,
  category,
  filters,
  setFilters,
  quickFilterData,
  locationFilteredData,
  inputFilter,
  setQuickFilterData,
  setInputFilter,
  searchText,


}) => {
  const { goToJobDetails } = usePath();
  const [allinternJob, setallInternJobs] = useState({ jobs: [] });
  const [count, setCount] = useState(0);
  const [isChanged, setIsChanged] = useState(false);

  const [next, setNext] = useState(true); // Assuming you have access to the 'next' value

  const [currentPage, setCurrentPage] = useState(1);

  const [totalJobs, setTotalJobs] = useState(0);

const navigator = useNavigate();
  const auth = localStorage.getItem("auth");
  const [Loading, setLoading] = useState(true);



useEffect(()=>{
setCurrentPage(1);
}, [filters])

  useEffect(() => {
    const fetchJob = async () => {
      let limit = 10;
      const skip = (currentPage - 1) * limit;
      let internJobs;

      const mergedFilters = {
        ...filters,
        ...quickFilterData,
        ...locationFilteredData,
        ...inputFilter,
        ...searchText,
    };
      // Constructing the filters object based on non-empty filter arrays
      const appliedFilters = {};
      for (const key in mergedFilters) {
        if (mergedFilters[key]?.length > 0) {
            appliedFilters[key] = mergedFilters[key];
        }
    }
      if (locationFilteredData?.data?.jobs?.length > 0) {
        console.log("Fetching data based on location filter");
        internJobs = locationFilteredData;
      } else if (Object.keys(appliedFilters)?.length === 0) {
        console.log("Fetching all data");
        internJobs = await fetchJobs({
          ...appliedFilters,
          skip,
          limit,
        });
      } else {
        console.log("Fetching filtered data");
        setLoading(true);
        setTimeout(()=>{
          setLoading(false)
        },1000)
        // setCurrentPage(1);
        internJobs = await fetchJobs({
          ...appliedFilters,
          skip,
          limit,
        });
      }
      setallInternJobs(internJobs?.data);
      setNext(internJobs?.data?.next);
      setTotalJobs(40);
      setCount(internJobs?.data?.next == true);

      // setCount(allinternJob?.find(index=>{index.next === true}))
    };
    fetchJob();
    if(allJobs){
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [filters, currentPage, quickFilterData, locationFilteredData, inputFilter]);

  const handleNextPage = () => {
    setIsChanged(true);
      if (currentPage < Math.ceil(totalJobs / 10) || next) {
        handlePageChange(currentPage + 1);
      }


  };

  function formatDate(dateString) {
    const postedDate = new Date(dateString);
    const currentDate = new Date();

    const differenceInTime = currentDate.getTime() - postedDate.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    const differenceInWeeks = Math.floor(differenceInDays / 7);
    const differenceInMonths = Math.floor(differenceInDays / 30);

    if (differenceInDays === 0) {
      return `Posted today`;
    } else if (differenceInDays < 7) {
      return `Posted ${differenceInDays} day${
        differenceInDays === 1 ? "" : "s"
      } ago`;
    } else if (differenceInDays < 30) {
      return `Posted ${differenceInWeeks} week${
        differenceInWeeks === 1 ? "" : "s"
      } ago`;
    } else {
      return `Posted ${differenceInMonths} month${
        differenceInMonths === 1 ? "" : "s"
      } ago`;
    }
  }


  // let allJobs = quickFilterData ? quickFilterData : allinternJob?.jobs;

  function checkObjectValues(obj) {
    for (const key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return true;
      } else if (typeof obj[key] === "string" && obj[key].trim() !== "") {
        return true;
      }
    }
    return false;
  }

  let allJobs = [];
  
  const isFilterApplied = checkObjectValues(filters);
  if (quickFilterData && quickFilterData.length > 0 && !isFilterApplied) {
    console.log("Using quickFilterData");
    allJobs = quickFilterData;
  } else if (
    !quickFilterData &&
    inputFilter &&
    inputFilter.length > 0 &&
    !isFilterApplied
  ) {
    console.log("for Not logged in users");
    allJobs = inputFilter; // handle for not logged in filter
  } else {
    console.log("Using allInternJob.jobs");
    allJobs = allinternJob?.jobs || [];
    
  }

    const handlePageChange = (page) => {
     
        setCurrentPage(page);   
    
    
    
    window.scrollTo({ top: 300, behavior: 'smooth' });
  };

const onClear =()=>{
  window.location.reload();
}
//   useEffect(()=>{
// if(allJobs?.length > 0){
//   setTimeout(() => {
//     setLoading(false);
//   }, 1000);
// }
//   },[])
  return (
    <div
      className={`${
        auth ? "p-8" : "py-[72px] h-auto px-8 md:px-16 xl:px-[124px]"
      } w-full  bg-white justify-start items-start gap-10 inline-flex`}
    >
      <div className="hidden xl:flex w-[20%] flex-col justify-start items-start gap-10 inline-flex">
        <FilterJobSection filters={filters} setFilters={setFilters} />
      </div>
      <div className=" w-full xl:w-[80%] flex-col justify-center items-center gap-8 inline-flex">
        <div className="w-full justify-between items-center inline-flex flex-col sm:flex-row">
          <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
            <div className="md:w-max text-teal-950 text-[40px] font-bold font-satoshi leading-[48px]">
              {auth ? "All Openings" : "Opportunities For You"}
            </div>
            {/* <div className="text-slate-500 text-base font-normal font-satoshi leading-relaxed">
              Showing 128 openings
            </div> */}
          </div>
          {/* <div className="w-full justify-end items-end flex">
            <div className="w-full justify-end items-end gap-3 inline-flex">
              <div className="hidden md:flex w-max text-right text-slate-500 text-base font-normal font-satoshi leading-relaxed">
                Sort by:
              </div>
              <div className=" justify-end items-center gap-2 flex">
                <div className="w-max text-teal-950 text-base font-medium font-satoshi leading-relaxed">
                  Most relevant
                </div>
                <div className="w-4 h-4 justify-center items-center flex">
                  <div className="w-4 h-4 px-[3.33px] justify-center items-center inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                    >
                      <path
                        d="M10.6673 1.66699L6.00065 6.33366L1.33398 1.66699"
                        stroke="#16C083"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="w-full flex-col justify-center items-center gap-8 inline-flex ">
          {Loading ? <Loader /> :
         
          <div className="w-full flex flex-col grid grid-cols-1 gap-8 overflow-scroll no-scrollbar cursor-pointer">
            {allJobs?.map((index) => (
              // {allinternJob?.jobs?.slice(0, 8).map((index) => (
              
              <div
               onClick={()=>{
                // navigator(generateJobUrl(index, category, auth) )
                goToJobDetails({index:index,category:category})
            }}
                key={index}
                className="w-auto self-stretch px-10 py-6 bg-white rounded-xl border border-neutral-300 justify-between items-start gap-6 flex-col md:flex-row inline-flex "
              >
                  
    
{/* <Link to={auth ? `/job/${index.job_id}` : `/job-desc/${index.job_id}`}
                    > */}
                <div className="w-auto justify-start items-start gap-6 flex-col lg:flex-row inline-flex ">
                  <div className="w-16 h-16 relative">
                    <div className="w-16 h-16 rounded-full flex items-center">
                      <img src={index.company_info.logo} />
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="justify-start items-start gap-[100px] inline-flex">
                      <div className="text-teal-950 text-xl font-bold font-satoshi leading-normal">
                        {index.job_info?.job_profile}
                      </div>
                    </div>
                    <div className="w-full h-auto justify-start items-center gap-2 inline-flex flex-wrap">
                      <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                        {index.company_info.name}
                      </div>
                      {index.job_info?.workplace_type &&
                      <>
                      <div className="w-1 h-1 bg-slate-700 rounded-full " />
                      <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                        {index.job_info?.workplace_type}
                      </div>
                      </>
}
{index.job_info?.location &&
                      <>
                      <div className="w-1 h-1 bg-slate-700 rounded-full " />
                      <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                        {index.job_info?.location}
                      </div>
                      </>
}
                    </div>
                    <div className="w-full justify-start items-start gap-2 inline-flex flex-wrap">
                      <div className="px-2.5 py-1.5 bg-emerald-300 bg-opacity-10 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                        <div className="w-max text-emerald-300 text-sm font-medium font-satoshi leading-snug">
                          {index.job_info?.job_type}
                        </div>
                      </div>
                      <div className=" self-stretch bg-neutral-300" />
                      <div className="px-2.5 py-1.5 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                        <div className="w-max text-emerald-500 text-sm font-medium font-satoshi leading-snug">
                          {index.job_info?.category &&
                            category.find(
                              (item) => item._id === index.job_info.category
                            )?.name}
                        </div>
                      </div>
                      <div className="px-2.5 py-1.5 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                        {index.job_info?.job_salary ||
                        index.job_info?.job_max_salary ? (
                          <>
                            <div className="w-5 h-5 relative">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M13.125 10C13.125 10.6181 12.9417 11.2223 12.5983 11.7362C12.255 12.2501 11.7669 12.6506 11.1959 12.8871C10.6249 13.1236 9.99653 13.1855 9.39034 13.065C8.78415 12.9444 8.22733 12.6467 7.79029 12.2097C7.35325 11.7727 7.05562 11.2158 6.93505 10.6097C6.81447 10.0035 6.87635 9.37513 7.11288 8.80411C7.3494 8.2331 7.74994 7.74504 8.26384 7.40166C8.77775 7.05828 9.38193 6.875 10 6.875C10.8288 6.875 11.6237 7.20424 12.2097 7.79029C12.7958 8.37634 13.125 9.1712 13.125 10ZM19.375 5V15C19.375 15.1658 19.3092 15.3247 19.1919 15.4419C19.0747 15.5592 18.9158 15.625 18.75 15.625H1.25C1.08424 15.625 0.925268 15.5592 0.808058 15.4419C0.690848 15.3247 0.625 15.1658 0.625 15V5C0.625 4.83424 0.690848 4.67527 0.808058 4.55806C0.925268 4.44085 1.08424 4.375 1.25 4.375H18.75C18.9158 4.375 19.0747 4.44085 19.1919 4.55806C19.3092 4.67527 19.375 4.83424 19.375 5ZM18.125 8.62109C17.4153 8.41125 16.7694 8.02719 16.2461 7.50389C15.7228 6.98059 15.3387 6.33468 15.1289 5.625H4.87109C4.66125 6.33468 4.27719 6.98059 3.75389 7.50389C3.23059 8.02719 2.58468 8.41125 1.875 8.62109V11.3789C2.58468 11.5887 3.23059 11.9728 3.75389 12.4961C4.27719 13.0194 4.66125 13.6653 4.87109 14.375H15.1289C15.3387 13.6653 15.7228 13.0194 16.2461 12.4961C16.7694 11.9728 17.4153 11.5887 18.125 11.3789V8.62109Z"
                                  fill="#16C083"
                                />
                              </svg>
                            </div>
                            <div className="w-max text-slate-500 text-sm font-medium font-satoshi leading-snug">
                              ₹
                              {index.job_info?.job_salary
                                ? index.job_info?.job_salary
                                : index.job_info?.job_max_salary}{" "}
                              / {index.job_info?.job_salary_frequency}
                            </div>
                          </>
                        ) : (
                          <div className="w-max text-slate-500 text-sm font-medium font-satoshi leading-snug">
                            Unpaid
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </Link> */}
                {/* <Link to={auth ? `/job/${index.job_id}` : `/job-desc/${index.job_id}`}> */}
                <div className="w-full md:max-w-[164px] h-full flex-col justify-between items-center inline-flex gap-4">
                  <div className="flex-col justify-start items-start gap-2 flex">
                    <div className="w-max text-center text-teal-950 text-sm font-medium font-satoshi leading-snug">
                      {formatDate(index.job_info?.created_at)}
                    </div>
                  </div>
                  <div className="w-full">
                    
                      <FillButton
                        className="w-full px-4 py-4"
                        color="green"
                        textColor="white"
                        textClass="w-max"
                        text="View Details"
                      />
                   
                  </div>
                </div>
                {/* </Link> */}
       
              </div>
           
              
            ))}
          </div>        
}

 {/* Conditional rendering for no data */}
 {(allJobs?.length === 0 && !Loading) &&(
      <div className="w-max flex flex-col items-center justify-center mt-8">
        <p className="text-red-500 text-lg">No jobs found</p>
        {/* <Link to="/" className="mt-4"> */}
          <FillButton
          className={`my-4`}
         onClick={() =>{ (filters && allJobs?.length === 0 && !currentPage > 1) ? onClear() : currentPage > 1 ? handlePageChange(currentPage - 1) : onClear()}}
            text="Go back"
            color="green"
            textColor="white"
            leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M5.80781 9.55781L12.0578 3.30781C12.1452 3.2203 12.2566 3.1607 12.3779 3.13654C12.4992 3.11238 12.625 3.12476 12.7392 3.17211C12.8535 3.21945 12.9512 3.29964 13.0198 3.40251C13.0885 3.50538 13.1251 3.62631 13.125 3.75L13.125 16.25C13.1251 16.3737 13.0885 16.4946 13.0198 16.5975C12.9512 16.7004 12.8535 16.7805 12.7392 16.8279C12.625 16.8752 12.4992 16.8876 12.3779 16.8635C12.2566 16.8393 12.1452 16.7797 12.0578 16.6922L5.80781 10.4422C5.7497 10.3841 5.7036 10.3152 5.67215 10.2393C5.6407 10.1635 5.62451 10.0821 5.62451 10C5.62451 9.91786 5.6407 9.83653 5.67215 9.76066C5.7036 9.68478 5.7497 9.61585 5.80781 9.55781Z" 
            fill="currentColor"/>
          </svg>}
          />
        {/* </Link> */}
      </div>
    )}
    
          {/* Pagination */}
          <div className={`${allJobs?.length === 0 ? "hidden" : "flex"} justify-center items-center gap-2 inline-flex`}>
            {/* Previous page button */}
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_7774_41811)">
                  <path
                    d="M15 6L9 12L15 18"
                    stroke={`${currentPage > 1 ? "#16C083" : "#002032"}`}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7774_41811">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            {/* ${!next ? 'pointer-events-none opacity-50' : ''} */}
            {[...Array(Math.ceil(totalJobs / 10 || 1))].map((_, index) => {
              if (index < 3 || index === Math.ceil(totalJobs / 10) - 1 || next) {
                return (
                  <div
                    key={index}
                    className={`px-3 py-2.5 rounded-lg justify-center items-center gap-2 flex cursor-pointer ${allJobs.length === 0 ? "hidden" : "flex"}  ${


                      currentPage === index + 1
            ? "bg-emerald-500"
            : currentPage > 3 && index === 3
                ? "bg-green-500"
                : "bg-green-50"
                    }  


                    `}

                    // ${!next ? "cursor-not-allowed pointer-events-none" : "cursor-pointer"}
                    // ${!next && index >= currentPage ? "hidden" : "cursor-pointer"}
                    onClick={() => handlePageChange(index + 1)}
                  > 
                    <div
                    
                      className={` w-[22px] text-center text-base font-semibold font-satoshi leading-relaxed ${

                        currentPage === index + 1
            ? "text-white"
            : currentPage > 3 && index === 3
                ? "text-white"
                : "text-black"

                        // index + 1 === currentPage ? "text-white" : "text-black"
                      }`}
                    >
                      {((index + 1 === Math.ceil(totalJobs / 10)) && (currentPage > 3)) ? currentPage : index + 1
                        }
                    </div>
                  </div>
                );
              }
              return null;
            })}

            {/* Next page button */}
            <button
              onClick={() => handleNextPage()}
              disabled={!next}
              className={``}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <g clip-path="url(#clip0_7774_41829)">
                  <path
                    d="M9.375 6L15.625 12L9.375 18"
                    stroke={`${next ? "#16C083" : "#002032"}`}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7774_41829">
                    <rect width="25" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
