import React, { useState, useEffect } from "react";
import { categoriesWithJobCount, fetchJobs } from "../../../services/landingPageApi";
import BorderButton from "../../../components/buttons/borderButton";
import FillButton from "../../../components/buttons/fillButton";
import ButtonChild from "../../../components/buttons/buttonChild";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../../../services/mixpanel";
import { isMobileOnly, browserName } from "react-device-detect";
import moment from "moment";
import { generateJobUrl } from "../../../services/jobs";
import { usePath } from "../../../routes/context/path/pathContext";


export const FeaturedOpportunity = ({redirect}) => {
  const { goToJobDetails } = usePath();
  const [allJobs, setallJobs] = useState([]);
  const [category, setCategory] = useState([]);

  const navigate = useNavigate();
  const auth = localStorage.getItem('auth');

  useEffect(() => {
    const fetchJob = async () => {
      const internJobs = await fetchJobs("jobs");
      console.log(internJobs?.data?.jobs);
      setallJobs(internJobs?.data);

      const categoriesJobs = await categoriesWithJobCount();
      setCategory(
        categoriesJobs?.data?.categories.map((index) => ({
          _id: index._id,
          name: index.name,
        }))
      );
    };
    fetchJob();
  }, []);

  const onJobSelect =(index, category, auth)=>{
    Mixpanel.track("Job_card_clicked", {
      date: moment().format(),
      distinct_id: localStorage.getItem("userId"),
      user_id: localStorage.getItem("userId"),
      browser: browserName,
      jobId:index?._id,
      mobile_device: isMobileOnly,
      type:"webapp",
    });
    goToJobDetails({index:index,category:category})
// if(auth){
//   navigate(`/job/${id}`)
// } else {
//   navigate(`/job-desc/${id}`)
// }


  }

  return (
    <div className="w-full h-auto px-2 sm:px-4 md:px-8 xl:px-[124px] py-8 md:py-[150px] bg-white flex-col justify-center items-start gap-12 inline-flex">
      <div className="w-full justify-between items-center inline-flex">
        <div>
          <span className="text-teal-950 text-[32px] md:text-[45px] xl:text-[56px] font-bold font-satoshi leading-[61.60px]">
            Featured{" "}
          </span>
          <span className="text-emerald-500 text-[32px] md:text-[45px] xl:text-[56px] font-bold font-satoshi leading-[61.60px]">
            Opportunities
          </span>
        </div>
        <div onClick={()=>{
            Mixpanel.track("Featured_clicked", {
              date: moment().format(),
              distinct_id: localStorage.getItem("userId"),
              user_id: localStorage.getItem("userId"),
              browser: browserName,
              mobile_device: isMobileOnly,
              type:"webapp",
            });
 redirect();
        }
         }>
        <ButtonChild
          className="hidden md:flex"
          textColor="green"
          textClass="w-max"
          text="View all"
          rightIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
            >
              <path
                d="M16.75 7.72559L1.75 7.72559"
                stroke="#16C083"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.7002 1.70124L16.7502 7.72524L10.7002 13.7502"
                stroke="#16C083"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
        />
        </div>
      </div>
      <div className="w-full justify-center items-center gap-8 inline-flex">
        <div className="w-full flex flex-row sm:flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-8 overflow-scroll no-scrollbar ">
          {allJobs?.jobs?.slice(0, 6).map((index) => (
            <div
            onClick={()=>{
              onJobSelect(index, category, auth)
            }}
              key={index}
              className="justify-between cursor-pointer min-w-[374px] sm:min-w-0 sm:max-w-[374px] p-4 m-3 bg-white rounded-tl-[28px] rounded-tr-[38px] rounded-bl-[28px] rounded-br-[28px] border-2 border-gray-200 flex-col justify-start items-start gap-6 inline-flex hover:transform hover:scale-105 hover:shadow-sm"
            >
              <div className="self-stretch justify-start items-start gap-4 inline-flex">
                <div className="grow shrink basis-0 pt-2 flex-col justify-start items-start inline-flex">
                  <div className="self-stretch text-teal-950 text-sm font-medium font-satoshi capitalize leading-tight">
                    {index.company_info?.name}
                  </div>
                  <div className="self-stretch text-teal-950 text-base font-black font-satoshi capitalize leading-normal">
                    {index.job_info?.job_profile}
                  </div>
                  <div className="self-stretch text-slate-500 text-xs font-medium font-satoshi capitalize leading-[18px]">
                    {index.job_info?.job_type}
                  </div>
                </div>
                <div className="w-11 h-11">
                  <img src={index.company_info?.logo} />
                </div>
              </div>
              <div className="self-stretch h-14 flex-col justify-start items-start gap-4 flex">
                <div className="w-full self-stretch justify-start items-start gap-4 inline-flex flex-wrap">
                  <div className="justify-start items-center gap-1 flex">
                    <div className="w-5 h-5 relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.25C8.17727 1.25207 6.42979 1.97706 5.14092 3.26592C3.85206 4.55479 3.12707 6.30227 3.125 8.125C3.125 14.0078 9.375 18.4508 9.64141 18.6367C9.74649 18.7103 9.87169 18.7498 10 18.7498C10.1283 18.7498 10.2535 18.7103 10.3586 18.6367C10.625 18.4508 16.875 14.0078 16.875 8.125C16.8729 6.30227 16.1479 4.55479 14.8591 3.26592C13.5702 1.97706 11.8227 1.25207 10 1.25ZM10 5.625C10.4945 5.625 10.9778 5.77162 11.3889 6.04633C11.8 6.32103 12.1205 6.71148 12.3097 7.16829C12.4989 7.62511 12.5484 8.12777 12.452 8.61273C12.3555 9.09768 12.1174 9.54314 11.7678 9.89277C11.4181 10.2424 10.9727 10.4805 10.4877 10.577C10.0028 10.6734 9.50011 10.6239 9.04329 10.4347C8.58648 10.2455 8.19603 9.92505 7.92133 9.51393C7.64662 9.1028 7.5 8.61945 7.5 8.125C7.5 7.46196 7.76339 6.82607 8.23223 6.35723C8.70107 5.88839 9.33696 5.625 10 5.625Z"
                          fill="#16C083"
                        />
                      </svg>
                    </div>
                    <div className="inline-flex">
                      <span className="text-teal-950 text-sm font-medium font-satoshi capitalize leading-tight">
                        {index.job_info?.location}{" "}
                      </span>
                      <span className="text-slate-500 text-sm font-medium font-satoshi capitalize leading-tight">
                        ( {index.job_info?.workplace_type})
                      </span>
                    </div>
                  </div>
                  <div className="justify-start items-center gap-2 flex">
                    <div className="w-5 h-5 relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M13.125 10C13.125 10.6181 12.9417 11.2223 12.5983 11.7362C12.255 12.2501 11.7669 12.6506 11.1959 12.8871C10.6249 13.1236 9.99653 13.1855 9.39034 13.065C8.78415 12.9444 8.22733 12.6467 7.79029 12.2097C7.35325 11.7727 7.05562 11.2158 6.93505 10.6097C6.81447 10.0035 6.87635 9.37513 7.11288 8.80411C7.3494 8.2331 7.74994 7.74504 8.26384 7.40166C8.77775 7.05828 9.38193 6.875 10 6.875C10.8288 6.875 11.6237 7.20424 12.2097 7.79029C12.7958 8.37634 13.125 9.1712 13.125 10ZM19.375 5V15C19.375 15.1658 19.3092 15.3247 19.1919 15.4419C19.0747 15.5592 18.9158 15.625 18.75 15.625H1.25C1.08424 15.625 0.925268 15.5592 0.808058 15.4419C0.690848 15.3247 0.625 15.1658 0.625 15V5C0.625 4.83424 0.690848 4.67527 0.808058 4.55806C0.925268 4.44085 1.08424 4.375 1.25 4.375H18.75C18.9158 4.375 19.0747 4.44085 19.1919 4.55806C19.3092 4.67527 19.375 4.83424 19.375 5ZM18.125 8.62109C17.4153 8.41125 16.7694 8.02719 16.2461 7.50389C15.7228 6.98059 15.3387 6.33468 15.1289 5.625H4.87109C4.66125 6.33468 4.27719 6.98059 3.75389 7.50389C3.23059 8.02719 2.58468 8.41125 1.875 8.62109V11.3789C2.58468 11.5887 3.23059 11.9728 3.75389 12.4961C4.27719 13.0194 4.66125 13.6653 4.87109 14.375H15.1289C15.3387 13.6653 15.7228 13.0194 16.2461 12.4961C16.7694 11.9728 17.4153 11.5887 18.125 11.3789V8.62109Z"
                          fill="#16C083"
                        />
                      </svg>
                    </div>
                    <div className="text-teal-950 text-sm font-medium font-satoshi leading-tight">
                      ₹
                      {index.job_info?.job_salary
                        ? index.job_info?.job_salary
                        : index.job_info?.job_max_salary}{" "}
                      / {index.job_info?.job_salary_frequency}
                    </div>
                  </div>
                  {/* <div className="justify-start items-center gap-1 flex">
                  <div className="w-5 h-5 relative" />
                  <div className="text-teal-950 text-sm font-medium font-satoshi capitalize leading-tight">
                    3 months
                  </div>
                </div> */}
                </div>
              </div>
              <div className="w-full self-stretch justify-start items-center gap-4 inline-flex flex-wrap lg:flex-nowrap">
                <BorderButton
                  textColor="gray"
                  color="gray"
                  leftIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15.375 2.5H4.625C4.29348 2.5 3.97554 2.6317 3.74112 2.86612C3.5067 3.10054 3.375 3.41848 3.375 3.75V17.5C3.37506 17.6115 3.40496 17.721 3.46161 17.8171C3.51826 17.9132 3.59959 17.9924 3.69716 18.0464C3.79473 18.1005 3.90498 18.1274 4.01648 18.1245C4.12798 18.1215 4.23666 18.0888 4.33125 18.0297L10 15.1117L15.6695 18.0297C15.7641 18.0886 15.8727 18.1212 15.9841 18.124C16.0955 18.1268 16.2056 18.0998 16.303 18.0458C16.4005 17.9918 16.4817 17.9127 16.5383 17.8167C16.5949 17.7208 16.6249 17.6114 16.625 17.5V3.75C16.625 3.41848 16.4933 3.10054 16.2589 2.86612C16.0245 2.6317 15.7065 2.5 15.375 2.5ZM15.375 16.3727L10.3305 13.8453C10.2311 13.7832 10.1164 13.7503 9.99922 13.7503C9.88208 13.7503 9.7673 13.7832 9.66797 13.8453L4.625 16.3727V3.75H15.375V16.3727Z"
                        fill="#667984"
                      />
                    </svg>
                  }
                />
                <BorderButton
                  textColor="gray"
                  color="gray"
                  leftIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M17.9422 8.56719L14.1922 12.3172C14.1048 12.4047 13.9934 12.4643 13.8721 12.4885C13.7508 12.5126 13.625 12.5002 13.5108 12.4529C13.3965 12.4055 13.2988 12.3254 13.2302 12.2225C13.1615 12.1196 13.1249 11.9987 13.125 11.875V8.75H12.8906C11.3661 8.74958 9.88475 9.25587 8.67941 10.1893C7.47407 11.1226 6.61315 12.4302 6.23203 13.9062C6.19059 14.0668 6.08706 14.2044 5.94421 14.2886C5.80136 14.3729 5.63089 14.3969 5.47031 14.3555C5.30973 14.314 5.17219 14.2105 5.08795 14.0676C5.0037 13.9248 4.97965 13.7543 5.02109 13.5938C5.47079 11.8489 6.48809 10.3031 7.91281 9.19986C9.33752 8.09664 11.0887 7.49863 12.8906 7.5H13.125V4.375C13.1249 4.25132 13.1615 4.13038 13.2302 4.02751C13.2988 3.92464 13.3965 3.84446 13.5108 3.79711C13.625 3.74977 13.7508 3.73739 13.8721 3.76154C13.9934 3.7857 14.1048 3.84531 14.1922 3.93281L17.9422 7.68281C18.0003 7.74086 18.0464 7.80979 18.0779 7.88566C18.1093 7.96154 18.1255 8.04287 18.1255 8.125C18.1255 8.20713 18.1093 8.28846 18.0779 8.36434C18.0464 8.44021 18.0003 8.50914 17.9422 8.56719ZM15 16.25H3.125V6.875C3.125 6.70924 3.05915 6.55027 2.94194 6.43306C2.82473 6.31585 2.66576 6.25 2.5 6.25C2.33424 6.25 2.17527 6.31585 2.05806 6.43306C1.94085 6.55027 1.875 6.70924 1.875 6.875V16.25C1.875 16.5815 2.0067 16.8995 2.24112 17.1339C2.47554 17.3683 2.79348 17.5 3.125 17.5H15C15.1658 17.5 15.3247 17.4342 15.4419 17.3169C15.5592 17.1997 15.625 17.0408 15.625 16.875C15.625 16.7092 15.5592 16.5503 15.4419 16.4331C15.3247 16.3158 15.1658 16.25 15 16.25Z"
                        fill="#667984"
                      />
                    </svg>
                  }
                />
                <FillButton
                  className="px-4"
                  textColor="white"
                  color="green"
                  textClass="w-max"
                  text="View Details"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
