import moment from 'moment';
import React from 'react'
import { browserName, isMobileOnly } from 'react-device-detect';
import { Link, useNavigate } from 'react-router-dom'
import { Mixpanel } from '../../../../services/mixpanel';
import FillButton from '../../../../components/buttons/fillButton';
import { usePath } from '../../../../routes/context/path/pathContext';

export const JobPlacement = ({courseData,userData, joinWaitingList, formatDate, redirectToLogin, redirectToProgramDetails}) => {
     // Extract keys of courseData.courseData only if it's an object
     const { goToCourseDetails } = usePath();
     const courseKeys = courseData && courseData.courseData ? Object.keys(courseData.courseData) : [];
     const auth = localStorage.getItem('auth')
     const navigate = useNavigate();

  return (
    <div className=" px-4 sm:px-8 lg:px-16 py-[70px] lg:px-[124px] w-full h-auto flex-col justify-center items-start gap-[62px] inline-flex " style={{
      backgroundImage: "url('/svg/bigGridDesign.svg')",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom right'
      
    }}>
    <div className="self-stretch h-auto flex-col justify-center items-start gap-8 flex">
        <div className="flex-col justify-center items-start gap-4 flex">
            <div className="flex-col justify-start items-start gap-1 flex">
                <div className="text-white text-[40px] font-black font-satoshi leading-[48px]">{courseData?.programsList?.[1]?.title}</div>
            </div>
            <div className="text-[#999183] text-xl font-bold font-satoshi leading-normal">Get placed in some of the top startups with our job placement guarantee programs</div>
        </div>
        {/* <div className="w-[902px] h-[0px] border-2 border-gray-200"></div> */}
        <div className="w-full md:min-w-[500px]  md:max-w-[902px] h-[2px] bg-gradient-to-r from-gray-500 to-[#000000]"></div>
    </div>
   

    <div className="self-stretch h-auto flex-col justify-start items-start gap-8 flex">
        <div className="self-stretch items-center justify-start gap-8 inline-flex flex-wrap">
        {courseKeys.length > 0 && (
    courseKeys.map((key) => {
      const course = courseData?.courseData[key];
      console.log(course?.name)
      if(course?.type === "JOB PLACEMENT") {
      return (
          <div key={key} className="w-full md:w-[376.33px] h-auto">
            <div onClick={()=>{
              Mixpanel.track("Job Placement Card Clicked", {
                date: moment().format(),
                distinct_id: localStorage.getItem("userId"),
                user_id: localStorage.getItem("userId"),
                name:userData?.profileInfo?.name,
                number:userData?.number,
                email:userData?.email,
                course_Id:course?._id,
                browser: browserName,
                mobile_device: isMobileOnly,
                type:"webapp",
              });
              // redirectToProgramDetails(key,course?.type, course?.userInWaitlist, course?.userRegistered)
              goToCourseDetails(course?.type.toLowerCase(), course?.description.toLowerCase(), key, course?.userInWaitlist, course?.userRegistered)
            }} 
              className="cursor-pointer w-full md:min-w-[376.33px] h-[371px] p-3 bg-white rounded-2xl flex-col justify-between items-start  inline-flex" style={{ backgroundImage: `url(${course.bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div className="flex-col justify-start items-start gap-12 flex">
                <div className="justify-start items-start gap-2 inline-flex">
                  <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
                    <div className="justify-start items-start gap-1.5 inline-flex">
                      <div className="rounded-lg px-1.5 py-[3px] bg-gradient-to-r from-rose-100 to-rose-50 justify-center items-center gap-1.5 flex">
                        <div className="text-red-600 text-xs font-black font-satoshi leading-tight">
                          LIVE
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                    <div className="justify-start items-start gap-1.5 inline-flex">
                      <div className="rounded-lg px-1.5 py-[3px] bg-gradient-to-r from-emerald-500 to-emerald-500 justify-center items-center gap-1.5 flex">
                        <div className="text-white text-xs font-black font-satoshi leading-tight">
                          NEW
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-start items-start gap-2.5 flex">
                  <div className="w-full text-[32px] font-bold font-satoshi text-white leading-normal">
                    {course?.name}
                  </div>
         
                   {course?.registrationStartTime && (
            <div>
              <span className="text-white text-[10px] font-bold font-satoshi leading-none">
                REGISTRATION FROM{" "}
              </span>
              <span className="text-white text-[10px] font-black font-satoshi leading-none">
                {formatDate(course?.registrationStartTime)} IST
              </span>
            </div>
          )}
                </div>
              </div>
              <div className="self-stretch p-3 bg-white rounded-xl justify-start items-center gap-[3px] inline-flex">
                <div className="w-[130px] h-[53px]">
                  <div className="w-[130px] h-[30px] flex-col justify-center items-start gap-2.5 inline-flex" style={{ backgroundImage: `url(/svg/bgGreenbox.svg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="justify-start items-center gap-1 inline-flex">
                      <div className="text-teal-950 text-xl font-black font-satoshi leading-none">
                        ₹{course?.finalPrice} 
                      </div>
                      {/* <div className="opacity-70 text-slate-700 text-xs font-bold font-satoshi line-through leading-none">
                        {course?.actualPrice} 
                      </div> */}
                      <div className='w-full h-full flex justify-center items-center'>
  <div className="w-max text-slate-700 text-xs font-bold font-satoshi leading-none">
    For registration
  </div>
</div>

                    </div>
                  </div>
                  <div className="w-[116px] h-5 justify-start items-center gap-[5px] inline-flex">
                    {/* <div className="w-5 h-5 pl-[3px] pr-0.5 pt-0.5 pb-[3px] justify-center items-center flex">
                                    <div className="w-[15px] h-[15px] relative flex-col justify-start items-start flex" />
                                </div> */}
                    <div className="text-slate-700 text-base font-bold font-satoshi leading-none">
                    {course?.waitlistBuffer + course?.waitlistCount} Joined
                    </div>
                  </div>
                </div>
                <div onClick={(e)=>e.stopPropagation()}  className="grow shrink basis-0 self-stretch justify-end items-center flex">
             

                  <FillButton 
                    onClick={() => {
                      if (course?.isRegistrationLive === false && !course?.bypassWaitlist && !auth) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                        redirectToLogin(course?._id);
                      } else if(course?.isRegistrationLive === false && auth && !userData?.profileInfo?.name && course?.userInWaitlist === false && !course?.bypassWaitlist) {
                        navigate(`/join-waitlist/fill-details?id=${course?._id}`);
                      } else if(course?.isRegistrationLive === false && auth && userData?.profileInfo?.name !== "" && course?.userInWaitlist === false && !course?.bypassWaitlist) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      joinWaitingList(course?._id);
                      console.log("h12")
                      } else if(course?.isRegistrationLive === true && !course?.bypassWaitlist && !auth) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                          redirectToLogin(course?._id);
                      } else if((auth && course?.isRegistrationLive === true && auth && course?.userInWaitlist === true && course?.userRegistered === false && !course?.bypassWaitlist)) {
                        Mixpanel.track("register CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      navigate(`/register/payment?id=${course?._id}`);
                      }else if((auth && course?.bypassWaitlist === true)) {
                        Mixpanel.track("register CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      navigate(`/register/payment?id=${course?._id}`);
                      } else if(!auth && course?.bypassWaitlist === true) {
                        Mixpanel.track("register CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      navigate(`/register/fill-details?id=${course?._id}`);
                      } else if(course?.isRegistrationLive === true && auth && !userData?.profileInfo?.name && course?.userInWaitlist === false && !course?.bypassWaitlist) {
                          navigate(`/join-waitlist/fill-details?id=${course?._id}`);
                      } else if(course?.isRegistrationLive === true && auth && userData?.profileInfo?.name !== "" && course?.userInWaitlist === false && !course?.bypassWaitlist) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:course?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                        joinWaitingList(course?._id);
                        console.log("h11")
                    } else {
                      Mixpanel.track("Micro Degree Card Clicked", {
                        date: moment().format(),
                        distinct_id: localStorage.getItem("userId"),
                        user_id: localStorage.getItem("userId"),
                        name:userData?.profileInfo?.name,
                        number:userData?.number,
                        email:userData?.email,
                        course_Id:course?._id,
                        browser: browserName,
                        mobile_device: isMobileOnly,
                        type:"webapp",
                      });
                        navigate(`/program-details?id=${course?._id}&type=${course?.type}`)
                    }
                    }} 
                  disabled={(!course?.bypassWaitlist && course?.userInWaitlist === true && course?.userRegistered === false && course?.isRegistrationLive === false) || (course?.userRegistered === true)  }
           className="w-max h-[53px]"
                  textColor="white"
                  color="black1"
                  text={((auth && course?.userInWaitlist === true && course?.userRegistered === false && !course?.bypassWaitlist) || (course?.bypassWaitlist === true && course?.userRegistered === false))
                    ? "Register"
                    : course?.userRegistered === true ? "Registered" : "Join Waitlist"}
                  />

                </div>
              </div>
            </div>
          </div>
          )
        }
        }))}

        </div>
  
    </div>
</div>
  )
}
