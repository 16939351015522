// ShareDialog.js

import React, { useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
  PinterestIcon,
  PinterestShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share'

const ShareDialog = ({ referralURL, onClose }) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyURL = () => {
    navigator.clipboard.writeText(referralURL);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-40"
        onClick={onClose}
      ></div>
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
        <div className="w-[350px]  md:w-[820px] md:h-[364px] px-11 py-[22px] bg-white rounded-2xl shadow flex-col justify-start items-center gap-[42px] inline-flex">
          <div className="flex-col w-full justify-start items-start gap-8 flex">
            <div className="flex-col w-full justify-start items-start gap-4 flex">
              <div className="w-full  justify-start items-center gap-[21px] inline-flex">
                <div className="w-full h-auto pt-2 flex-col justify-start items-start inline-flex">
                  <div className="self-stretch text-teal-950 text-base font-black font-satoshi capitalize leading-normal">
                    Share
                  </div>
                </div>
                <div className="w-6 h-6 relative">
                  <button onClick={onClose}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.15115 5.1515C5.37618 4.92654 5.68135 4.80016 5.99955 4.80016C6.31775 4.80016 6.62291 4.92654 6.84795 5.1515L11.9995 10.3031L17.1511 5.1515C17.2618 5.03689 17.3943 4.94547 17.5407 4.88258C17.6871 4.81969 17.8445 4.78659 18.0039 4.7852C18.1632 4.78382 18.3212 4.81418 18.4687 4.87452C18.6162 4.93485 18.7501 5.02396 18.8628 5.13663C18.9755 5.2493 19.0646 5.38328 19.1249 5.53076C19.1853 5.67823 19.2156 5.83625 19.2142 5.99558C19.2129 6.15492 19.1798 6.31238 19.1169 6.45879C19.054 6.60519 18.9626 6.73761 18.8479 6.8483L13.6963 11.9999L18.8479 17.1515C19.0665 17.3778 19.1875 17.6809 19.1848 17.9956C19.182 18.3102 19.0558 18.6112 18.8333 18.8337C18.6108 19.0562 18.3099 19.1824 17.9952 19.1851C17.6806 19.1878 17.3775 19.0669 17.1511 18.8483L11.9995 13.6967L6.84795 18.8483C6.62163 19.0669 6.3185 19.1878 6.00387 19.1851C5.68923 19.1824 5.38826 19.0562 5.16577 18.8337C4.94328 18.6112 4.81707 18.3102 4.81434 17.9956C4.81161 17.6809 4.93256 17.3778 5.15115 17.1515L10.3027 11.9999L5.15115 6.8483C4.92618 6.62327 4.7998 6.3181 4.7998 5.9999C4.7998 5.68171 4.92618 5.37654 5.15115 5.1515Z"
                        fill="#99A6AD"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="justify-start items-start gap-[14.42px] inline-flex flex-wrap">
                <div className="w-[60px] h-[60px] pb-[2.46px] justify-center items-center flex">
                  <FacebookShareButton url={referralURL}>
                    <FacebookIcon size={50} round />
                  </FacebookShareButton>
                </div>
                <div className="w-[60px] h-[60px] pb-[2.46px] justify-center items-center flex">
                  <TwitterShareButton url={referralURL}>
                    <TwitterIcon size={50} round />
                  </TwitterShareButton>
                </div>
                <div className="w-[60px] h-[60px] pb-[2.46px] justify-center items-center flex">
                  <WhatsappShareButton url={referralURL}>
                    <WhatsappIcon size={50} round />
                  </WhatsappShareButton>
                </div>
                <div className="w-[60px] h-[60px] pb-[2.46px] justify-center items-center flex">
                  <PinterestShareButton url={referralURL} media={referralURL}>
                    <PinterestIcon size={50} round />
                  </PinterestShareButton>
                </div>
                <div className="w-[60px] h-[60px] pb-[2.46px] justify-center items-center flex">
                  <EmailShareButton url={referralURL}>
                    <EmailIcon size={50} round />
                  </EmailShareButton>
                </div>
              </div>
            </div>
            <div className="w-full h-[0px] border border-gray-200"></div>
            <div className="h-32 flex-col w-full justify-start items-start gap-4 flex">
              <div className="self-stretch justify-center items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-6 text-teal-950 text-base font-black font-satoshi leading-normal">
                  Copy Link
                </div>
                {isCopied && (
                  <span className="text-green-550 text-md">URL Copied!</span>
                )}
              </div>
              <div className="self-stretch  h-auto p-2 rounded-2xl border-2 w-full border-gray-100 flex-col justify-start items-start gap-4 flex">
                <div className="self-stretch px-2 py-4  justify-start items-center gap-4 inline-flex">
                  <div className="w-full grow shrink  h-6 justify-start items-center gap-4 flex">
                    <input
                      className="w-full text-teal-950 text-opacity-50 text-[18px] font-medium font-satoshi leading-normal"
                      type="text"
                      value={referralURL}
                      readOnly
                    />
                  </div>
                  <div className="px-5 py-2.5 bg-black rounded-[28px] justify-start items-center gap-2.5 flex">
                    <button
                      className="text-white text-base font-bold font-satoshi capitalize leading-tight"
                      onClick={copyURL}
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareDialog;
