import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { courseById } from "../../../../services/courses";
import Loader from "../../../../components/loader/loader";

export const ConfirmSuccessScreen = () => {
  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get('id');
    const [courseDetails, setCourseDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    

    useEffect(()=>{
      const auth = localStorage.getItem("auth");
      const fetchCourseDetails = async () => {
          try {
      const response = await courseById(courseId);
      console.log(response?.data)
      setCourseDetails(response?.data);
  
          } catch (error) {
              console.error("Error fetching course details:", error);
          } finally {
            setIsLoading(false)
          }
      };
  
      
      if (courseId) {
          fetchCourseDetails();
      }
  },[]);

  if (isLoading) {
    return <Loader />; // Show loader while loading
  }

    return  <div className="min-w-screen max-w-full min-h-screen max-h-full px-2 sx:px-4 md:px-8 lg:p-[52px] flex flex-col lg:flex-row justify-center items-center bg-gradient-to-br from-black to-[#000000] ">
    <div className="w-full lg:w-[50%] h-auto flex-col justify-center items-start gap-6 inline-flex">
      
      <div className="hidden md:flex w-[50px] h-[50px] absolute top-12 rounded-xl">
      <Link to="/programs">
     <img src="/svg/whiteBeepLogo.svg"/>
     </Link>
      </div>
      <div className="md:hidden w-[50px] h-[50px] rounded-xl">
      <Link to="/programs">
     <img src="/svg/whiteBeepLogo.svg"/>
     </Link>
      </div>
    
        <div className="w-full md:mt-[200px] lg:mt-0 h-auto text-white opacity-80 text-[40px] font-bold font-satoshi flex flex-wrap">
          {courseDetails?.name}
        </div>
        <div className="w-full text-start text-slate-500 text-xl font-bold font-satoshi leading-normal flex flex-wrap">
          {courseDetails?.description}
        </div>
      </div>
    <div className="w-[50%] my-8 h-auto flex justify-end">
    <div className="w-[532px] h-[600px] px-4 sm:px-8 md:px-[62px] py-[99px] bg-white rounded-lg flex-col justify-between items-center gap-8 inline-flex">

    <div className="self-stretch grow shrink basis-0 flex-col justify-start items-center gap-10 flex">
        <div className="text-center text-teal-950 text-2xl font-bold font-satoshi leading-[28.80px]">Hurray! You are in our waitlist!</div>
        <div className="w-60 h-[194.20px] relative">
            <img src="/svg/waitlist.svg" />
        </div>
    </div>


    
      <Link to={`${(courseDetails?.isRegistrationLive === true && courseDetails?.userRegistered === false) ? `/register/payment?id=${courseDetails?._id}` : `/programs`}`} className="self-stretch h-[50px] flex-col justify-end items-center gap-[22px] flex cursor-pointer">
        <div className="self-stretch px-6 py-3 bg-[#002032] hover:shadow-md hover:border hover:border-gray-300 hover:opacity-95 rounded-xl justify-center items-center gap-2.5 inline-flex ">
          <div className="text-center text-white text-base font-bold font-satoshi leading-relaxed">
            {(courseDetails?.isRegistrationLive === true && courseDetails?.userRegistered === false) ?
            "Register"
            :
            "Return to Programs"
          }
          
          </div>
        </div>
      </Link>
    </div>
    </div>
  </div>;
};
