import React, { useEffect, useState } from "react";
import InfoBox from "../../../../components/input_fields/infoBox";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserDetails, verifyOTP } from "../../../../services/loginOrSignUp";
import { courseById, joinWaitlist } from "../../../../services/courses";
import FillButton from "../../../../components/buttons/fillButton";

export const VerifyOtpScreen = () => {
    const [missing, setMissing] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get('id');
    const courseName = queryParams.get('name');
    const courseType = queryParams.get('type');
    const [courseDetails, setCourseDetails] = useState({});
    

const phoneNo = localStorage.getItem('phoneNo');
    const courseDesc = queryParams.get('desc');

    const [otp, setOtp] = useState(['', '', '', '']);

  const handleChange = (value, index) => {
    if (value.length > 1) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to next input if not the last one
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };


  const joinWaitingList = async (id)=>{
    const response = await joinWaitlist(id)
    if(response){
        navigate(`/join-waitlist/success?id=${id}`)
        console.log(response)
    }
}

const handleVerifyOTP = async () => {
  const missingField = {};
  try {
    const otpString = otp.join('');

    if (otp.some(field => field === '') || otpString.length < 4 || !/^\d{4}$/.test(otpString)) {
      missingField["otp"] = "Please enter a valid 4-digit OTP.";
    }

    setMissing(missingField);
    console.log(missingField);

    if (Object.keys(missingField).length === 0) {
      let inputValue = phoneNo;

      if (!inputValue.startsWith("+91")) {
        inputValue = "+91" + inputValue;
      }

      try {
        const response = await verifyOTP(otpString, inputValue);

        if (response) {
          try {
            const user = await getUserDetails(courseId);
            console.log(user, "user");
            const course = await courseById(courseId);
            console.log(course, "course");

            if (course?.data?.userInWaitlist === false && (user?.status === 0 && !user?.profileInfo?.name)) {
              navigate(`/join-waitlist/fill-details?id=${courseId}&name=${courseName}&desc=${courseDesc}&type=${courseType}`);
            } else if (
              (user?.status === 1 || user?.status === 2 || user?.status === 3 || ((user?.status === 0) && (user?.profileInfo?.name !== ""))) &&
              course?.data?.userInWaitlist === true &&
              course?.data?.userRegistered === false &&
              course?.data?.isRegistrationLive === true
            ) {
              navigate(`/register/payment?id=${course?.data?._id}`);
            } else if (
              (user?.status === 1 || user?.status === 2 || user?.status === 3 || ((user?.status === 0) && (user?.profileInfo?.name !== ""))) &&
              course?.data?.userRegistered === true &&
              course?.data?.isRegistrationLive === false
            ) {
              navigate(`/program-details?id=${course?.data?._id}&type=${course?.data?.type}`);
            } else if((user?.status === 1 || user?.status === 2 || user?.status === 3 || ((user?.status === 0) && (user?.profileInfo?.name !== ""))) &&
            course?.data?.userInWaitlist === false
          ) {
            joinWaitingList(course?.data?._id);
            } else {
              navigate(`/program-details?id=${course?.data?._id}&type=${course?.data?.type}`);
            }
          } catch (apiError) {
            console.error("API error fetching user or course details:", apiError);
            missingField["apiError"] = "An error occurred while fetching user or course details.";
            setMissing(missingField);
          }
        } else {
          console.error("OTP verification failed.");
          missingField["otpError"] = "Invalid OTP. Please try again.";
          setMissing(missingField);
        }
      } catch (apiError) {
        console.error("API error verifying OTP:", apiError);
        missingField["apiError"] = "An error occurred while verifying the OTP.";
        setMissing(missingField);
      }
    }
  } catch (error) {
    console.error("Error verifying OTP:", error);
    missingField["generalError"] = "An unexpected error occurred.";
    setMissing(missingField);
  }
};

  

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    } else if (e.key === 'ArrowRight' && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    } else if (e.key === 'ArrowLeft' && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  useEffect(()=>{
    const fetchData = async ()=>{
      const user = await getUserDetails();
      if(user?.isPhoneVerified === true || user?.isEmailVerified === true){
        navigate('/programs');
      }
    }
    fetchData();
  }, [])
  
  return  <div className="min-w-screen max-w-full min-h-screen max-h-full px-2 sx:px-4 md:px-8 lg:p-[52px] flex flex-col lg:flex-row justify-center items-center bg-gradient-to-br from-black to-[#000000] ">
       
       <div className="w-full lg:w-[50%] h-auto flex-col justify-center items-start gap-6 inline-flex">
      
      <div className="hidden md:flex w-[50px] h-[50px] absolute top-12 rounded-xl">
      <Link to="/programs">
     <img src="/svg/whiteBeepLogo.svg"/>
     </Link>
      </div>
      <div className="md:hidden w-[50px] h-[50px] rounded-xl">
      <Link to="/programs">
     <img src="/svg/whiteBeepLogo.svg"/>
     </Link>
      </div>
    
        <div className="w-full md:mt-[200px] lg:mt-0 h-auto text-white opacity-80 text-[40px] font-bold font-satoshi flex flex-wrap">
          {courseDetails?.name || courseName}
        </div>
        <div className="w-full text-start text-slate-500 text-xl font-bold font-satoshi leading-normal flex flex-wrap">
          {courseDetails?.description || courseDesc}
        </div>
      </div>
      <div className="w-full lg:w-[50%] my-8 h-auto flex justify-center">
        <div className="w-full md:w-[450px] xl:w-[532px] h-auto md:h-[600px] px-4 sm:px-8 md:px-[62px] py-[99px] bg-white rounded-lg flex-col justify-start items-center gap-8 inline-flex">
          <Link to={`/join-waitlist/login?id=${courseId}`} className="w-full flex justify-start">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z" fill="#667984"/>
</svg>
</Link>

  <div className="w-full text-teal-950 text-[18px] sx:text-xl font-black font-satoshi leading-normal">
          Verify your Mobile Number
        </div>

        <div className="w-full">
          <InfoBox
            variant="standard"
            infoText={
              <div className="p-2 gap-1">
                <span className="font-satoshi">An OTP has been sent to</span>
                <p className="text-green-500 font-satoshi">+91-{phoneNo}</p>
              </div>
            }
            buttonLeftIcon={
              <div
onClick={()=>navigate(`/join-waitlist/login?id=${courseId}`)}
               >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M17.7586 6.23165L14.268 2.74024C14.1519 2.62414 14.0141 2.53204 13.8624 2.4692C13.7107 2.40637 13.5482 2.37402 13.384 2.37402C13.2198 2.37402 13.0572 2.40637 12.9056 2.4692C12.7539 2.53204 12.6161 2.62414 12.5 2.74024L2.86641 12.3746C2.74983 12.4903 2.65741 12.6279 2.59451 12.7796C2.5316 12.9313 2.49948 13.094 2.50001 13.2582V16.7496C2.50001 17.0811 2.6317 17.3991 2.86612 17.6335C3.10054 17.8679 3.41849 17.9996 3.75001 17.9996H7.24141C7.40563 18.0001 7.5683 17.968 7.71999 17.9051C7.87168 17.8422 8.00935 17.7498 8.12501 17.6332L17.7586 7.99962C17.8747 7.88354 17.9668 7.74573 18.0296 7.59405C18.0925 7.44238 18.1248 7.27981 18.1248 7.11563C18.1248 6.95145 18.0925 6.78889 18.0296 6.63721C17.9668 6.48554 17.8747 6.34772 17.7586 6.23165ZM15 8.99024L11.5086 5.49962L13.3836 3.62462L16.875 7.11524L15 8.99024Z"
                    fill="#16C083"
                  />
                </svg>
              </div>
            }
          />
        </div>
        <div className="w-full flex flex-col gap-[22px]">
        <div className="flex space-x-2">
        {otp.map((value, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          maxLength="1"
          value={value}
          placeholder="0"
          className={`${(missing['otp'] || missing['otpError'] || missing['apiError'] || missing['generalError']) ? "border-red-500" : "border-gray-300"} w-12 h-12 text-center border  rounded-xl focus:outline-none focus:ring-2 focus:ring-gray-200 shadow-sm`}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
      ))}
    
 
    </div>
    {/* {missing} */}
    {missing['otpError'] && 
    <div className="text-red-300 text-sm font-black font-satoshi leading-tight">
       {missing['otpError']}</div>}
       {missing['otp'] && 
    <div className="text-red-300 text-sm font-black font-satoshi leading-tight">
       {missing['otp']}</div>}
      {missing['apiError'] && 
      <div className="text-red-300 text-sm font-black font-satoshi leading-tight">
        {missing['apiError']}</div>}
      {missing['generalError'] && 
      <div className="text-red-300 text-sm font-black font-satoshi leading-tight">
        {missing['generalError']}</div>}
  

    <div className="text-gray-300 text-sm font-black font-satoshi leading-tight">Resend OTP in 1:23</div>

        </div>

        <FillButton 
          className="w-full"
          onClick={handleVerifyOTP} 
           textColor="white"
           color="black1"
           text="Continue"
          />
  </div>
  </div>
</div>;
};
