import React from "react";
import { Route, Routes } from "react-router-dom";
import { MainLoginOrSignUp } from "../pages/sign_up_flow/mainLoginOrSignUp";
import { VerifyMobOtp } from "../pages/sign_up_flow/right_section/verifyMobOtp";
import { LandingPage } from "../pages/landingPage";
import { MainFindJobsOpprComp } from "../pages/jobs/findJobs";
import { JobDescription } from "../pages/jobs/jobDescription/utils/jobDescription";
import { Dashboard } from "../pages/dashboard";
import ProfilePage from "../pages/profile/profile";
import { ApplyJob } from "../pages/apply_jobs/applyJob";
import { PreviewAndApply } from "../pages/apply_jobs/PreviewAndApply";
import ProfileWrapper from "../pages/profile/profileWrapper";
import SettingWrapper from "../pages/dashboard/settingPages/settingWrapper";
import ChatWrapper from "../pages/chat/chatWrapper";
import MyApplicationWrapper from "../pages/dashboard/LoggedInUsers/rightSection/screens/myApplicationsWrapper";
import JobDescriptionWrapper from "../pages/jobs/jobDescription/utils/jobDescriptionWrapper";
import ScreeningQueWrapper from "../pages/apply_jobs/screeningQuestionsWrapper";
import PreviewWrapper from "../pages/apply_jobs/previewWrapper";
import ApplicationSentWrapper from "../pages/apply_jobs/applicationsentWrapper";
import { Terms } from "../pages/landingPage/Terms&Privacy/terms";
import { Privacy } from "../pages/landingPage/Terms&Privacy/privacy";
import { Bookmarks } from "../pages/dashboard/LoggedInUsers/rightSection/screens/bookmarks";
import { ProgramDetails } from "../pages/programs/programDetails";
import { LoginThroughJoinWaitlist } from "../pages/programs/loginThroughJoinWaitlist";
import { VerifyOtpThroughJoinList } from "../pages/programs/verifyOtpThroughJoinList";
import { FillDetailsforWaiList } from "../pages/programs/fillDetailsforWaiList";
import { JoinListConfirm } from "../pages/programs/joinListConfirm";
import { PaymentScreen } from "../pages/programs/components/RegistrationFlow/paymentScreen";
import { PaymentSuccess } from "../pages/programs/components/RegistrationFlow/paymentSuccess";
import { PaymentFailure } from "../pages/programs/components/RegistrationFlow/paymentFailure";
import { UserDetailFormNLU } from "../pages/programs/components/RegistrationFlow/userDetailFormNLU";
import { ProgramContainer } from "../pages/programs/programsContainer";
import { AuthProvider } from "./context/auth/authContext";
import PrivateRoute from "./context/privateRoute/privateRoute";
import { AboutUs } from "../pages/landingPage/aboutUs";

export const MainRoutes = () => {
  return (
    <div className="w-full h-auto">
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login-or-signup" element={<MainLoginOrSignUp />} />
          <Route path="/verify-otp" element={<VerifyMobOtp />} />
          <Route
            path="/:jobType/:category"
            element={
              <PrivateRoute
                element={Dashboard}
                fallback={MainFindJobsOpprComp}
              />
            }
          />
          <Route
            path="/:jobType"
            element={
              <PrivateRoute
                element={Dashboard}
                fallback={MainFindJobsOpprComp}
              />
            }
          />
          <Route
            path="/:category"
            element={
              <PrivateRoute
                element={Dashboard}
                fallback={MainFindJobsOpprComp}
              />
            }
          />
          <Route
            path="/:jobType/:category/:jobName"
            element={
              <PrivateRoute
                element={JobDescriptionWrapper}
                fallback={JobDescription}
              />
            }
          />
          <Route path="/job" element={<JobDescriptionWrapper />} />

          <Route path="/login-or-signup" element={<MainLoginOrSignUp />} />
          <Route path="/verify-otp" element={<VerifyMobOtp />} />
          <Route path="/chat" element={<ChatWrapper />} />
          <Route path="/applications" element={<MyApplicationWrapper />} />
          <Route path="/apply-job" element={<ApplyJob />} />

          {/* Apply job */}
          <Route path="/screen-que/:jobId" element={<ScreeningQueWrapper />} />

          <Route
            path="/application_sent"
            element={<ApplicationSentWrapper />}
          />

          <Route path="/settings" element={<SettingWrapper />} />
          <Route path="/profile" element={<ProfileWrapper />} />
          <Route path="/preview" element={<PreviewWrapper />} />
          <Route path="/bookmarks" element={<Bookmarks />} />

          {/* terms and privacy */}
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/about-us" element={<AboutUs />} />


          {/* programs or courses */}
          <Route path="/programs" element={<ProgramContainer />} />

          {/* Course details */}
          <Route
            path="/programs/:programType/:programName"
            element={<ProgramDetails />}
          />
          <Route
            path="/join-waitlist/login"
            element={<LoginThroughJoinWaitlist />}
          />
          <Route
            path="/join-waitlist/verify-otp"
            element={<VerifyOtpThroughJoinList />}
          />
          <Route
            path="/join-waitlist/fill-details"
            element={<FillDetailsforWaiList />}
          />
          <Route path="/join-waitlist/success" element={<JoinListConfirm />} />

          {/* course Payment flow */}

          <Route path="/register/payment" element={<PaymentScreen />} />
          <Route
            path="/register/payment-successfull"
            element={<PaymentSuccess />}
          />
          <Route
            path="/register/payment-failure"
            element={<PaymentFailure />}
          />
          {/* for not logged in user */}

          <Route
            path="/register/fill-details"
            element={<UserDetailFormNLU />}
          />
        </Routes>
      </AuthProvider>
    </div>
  );
};
