import React, { useEffect, useState } from 'react';
import { ScreensHeading } from './heading';
import { DashLeftSection } from '../../leftSection';
import { MyApplication } from './myApplication';
import { PlacementReport } from './placementReport';
import { getUserDetails } from '../../../../../services/loginOrSignUp';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../../components/loader/loader';
import { Helmet } from 'react-helmet-async';


const MyApplicationWrapper = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const auth = localStorage.getItem("auth");
    const [showReportBox, setShowReportBox] = useState(false);
    const [userData, setUserData] = useState([]);

    useEffect(() => {
      const getData = async () => {
        const response = await getUserDetails();
        setUserData(response);
      };
      getData();
    }, []);
  
    const openPlacementReport = () => {
      setShowReportBox(true);
  
    };
    const closePlacementReport = () => {
      setShowReportBox(false);
    };

    const navigate = useNavigate();
    useEffect(()=>{
      if(!auth)
      navigate("/login-or-signup")
    },[])
  
    const [Loading, setLoading] = useState(true);
    useEffect(() => {
      // Simulating an asynchronous action
      const fakeAsyncAction = async () => {
        try {
          // Simulate an API call or any asynchronous action
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } finally {
          // Set isLoading to false once the asynchronous action is done
          setLoading(false);
        }
      };
  
      // Call the fakeAsyncAction when the component mounts
      fakeAsyncAction();
    }, []); // Empty dependency array means this effect runs once on mount
  
    // Render the loader if isLoading is true
    if (Loading && !auth) {
      return <Loader />;
    }
    return ( 
        <>
           <Helmet>
          <title>Dashboard: My Applications</title>
        </Helmet>
        <div className="flex max-h-screen">
            <div className={`min-w-[20%] lg:max-w-[25%] ${
          menuOpen ? "w-full sm:min-w-[20%]" : "hidden sm:flex"
        } h-screen transition-all duration-300`}
        style={{
          position: menuOpen ? "absolute" : "static",
          left: menuOpen ? 0 : "-100%",
          zIndex: menuOpen ? (window.innerWidth < 768 ? 5 : 999) : "auto",
        }}>
        <DashLeftSection
        
        activeSection={"my-applications"}
        setMenuOpen={setMenuOpen}
        menuOpen={menuOpen}

        /></div>
        <div className="flex flex-col w-full max-h-screen overflow-scroll no-scrollbar gap-2 relative">
        <ScreensHeading activeSection={"my-applications"} setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
        <div className="px-2 sm:px-4">
        <MyApplication  openPlacementReport={openPlacementReport} closePlacementReport={closePlacementReport} showReportBox={showReportBox}/>
        </div>
        
        
        </div>
        </div>
        
        </>
     );
}
 
export default MyApplicationWrapper;