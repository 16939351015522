import React, { useEffect, useState } from 'react';

import { DashLeftSection } from '../../leftSection';
import { ScreensHeading } from './heading';
import { FindJobsComp } from '../../../../jobs/findJobs/utils/findJobsComp';
import { categoriesWithJobCount, fetchJobs } from '../../../../../services/landingPageApi';
import { BookmarksJobList } from './bookmarksJobList';
import Loader from '../../../../../components/loader/loader';
import { useNavigate } from 'react-router-dom';
import { bookMark } from '../../../../../services/jobs';
import { Helmet } from 'react-helmet-async';

export const Bookmarks = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const auth = localStorage.getItem("auth");
    const [activeSection, setActiveSection] = useState("findJob");
    const [jobId, setJobId] = useState("");
    const [category, setCategory] = useState([]);
    const [jobByCategory, setJobByCategory] = useState([]);
    const [showFilterSection, setShowFilterSection] = useState(false);
    const [inputFilter, setInputFilter] = useState([]);
    const [cat, setCat] = useState('');
    const [InternData, setInternData] = useState("");
  
  
  
  
  
    const [filters, setFilters] = useState({
      type: [],
      profile: "",
      workplace_type: [],
      categories: [],
      jobLevel: [],
      salaryRange: "",
    });
    const [locationFilteredData, setLocationFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [showLoginBox, setShowLoginBox] = useState(false);
    const [isSaved, setIsSaved] = useState({});
  
    const onbookmark = async (id) => {
      if (auth) {
        setIsSaved((prevSavedJobs) => {
          const updatedIsSaved = {
            ...prevSavedJobs,
            [id]: !prevSavedJobs[id]
          };
    
          // Immediately call bookMark with the updated value
          bookMark(id, updatedIsSaved[id]).then((response) => {
            if (response) {
              window.location.reload();
            }
          });
    
          return updatedIsSaved;
        });
      } else {
        setShowLoginBox((prev) => !prev);
        // onLoginOpen()
        // navigate("/login-or-signup")
      }
    };
    

    useEffect(() => {
  
  
      const fetchJob = async () => {
  
        const decodeCategory = localStorage.getItem('selectedCategory')
        const typeIntern = localStorage.getItem('type')
        setInternData(typeIntern);
  
        setCat(decodeCategory);
    
        if(typeIntern){
          setFilters((prev)=>({
            ...prev,
            type:[typeIntern],
          }))
         
          localStorage.removeItem("type");
        } 
  
        if(decodeCategory){
          setFilters((prev)=>({
            ...prev,
            categories:[decodeCategory],
          }))
          localStorage.removeItem("selectedCategory");
         
        } 
  
        const internJobs = await fetchJobs({ categories: "Internship" });
        setJobByCategory(internJobs?.data);
  
        const categoriesJobs = await categoriesWithJobCount();
        setCategory(
          categoriesJobs?.data?.categories.map((index) => ({
            _id: index._id,
            name: index.name,
          }))
        );
      };
      fetchJob();
    }, []);
  
    useEffect(() => {
      // Simulating data loading with a setTimeout
      const timeout = setTimeout(() => {
        setLoading(false);
        // topRef.current.scrollIntoView({ behavior: "smooth" });
      }, 1000); // Adjust the timeout as needed
  
      // Cleanup function to clear timeout
      return () => clearTimeout(timeout);
    }, []);
  
    // filter part by location
    const handleFetchByLocation = async (searchResults) => {
      try {
        const response = await fetchJobs({ location: [searchResults] });
        if (response?.data) {
          setLocationFilteredData(response);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const navigate = useNavigate();
    useEffect(()=>{
      const auth = localStorage.getItem("auth");
      if(!auth)
      navigate("/login-or-signup")
    },[])
  
    // const [Loading, setLoading] = useState(true);
    useEffect(() => {
      // Simulating an asynchronous action
      const fakeAsyncAction = async () => {
        try {
          // Simulate an API call or any asynchronous action
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } finally {
          // Set isLoading to false once the asynchronous action is done
          setLoading(false);
        }
      };
  
      // Call the fakeAsyncAction when the component mounts
      fakeAsyncAction();
    }, []); // Empty dependency array means this effect runs once on mount
  
    // Render the loader if isLoading is true
    if (loading && !auth) {
      return <Loader />;
    }
    return ( 
        <>
           <Helmet>
          <title>Dashboard: Bookmarks</title>
        </Helmet>
        <div className="flex h-auto">
            <div className={`min-w-[20%] lg:max-w-[25%] ${
          menuOpen ? "w-full sm:min-w-[20%]" : "hidden sm:flex"
        } h-screen transition-all duration-300`}
        style={{
          position: menuOpen ? "absolute" : "static",
          left: menuOpen ? 0 : "-100%",
          zIndex: menuOpen ? (window.innerWidth < 768 ? 5 : 999) : "auto",
        }}>
        <DashLeftSection
        
        activeSection={"bookmarks"}
        setMenuOpen={setMenuOpen}
        menuOpen={menuOpen}

        /></div>
        <div className="flex flex-col w-full h-screen overflow-scroll no-scrollbar gap-2">
        <ScreensHeading activeSection={"bookmarks"} setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
        <div className="px-4 h-auto">
        <BookmarksJobList
              setJobId={setJobId}
              setActiveSection={setActiveSection}
              category={category}
              filters={filters}
              setFilters={setFilters}
              locationFilteredData={locationFilteredData}
            //   quickFilterData={quickFilterData}
              inputFilter={inputFilter}
              // decodedCategory={decodedCategory}
              loading = {loading}
              setLoading={setLoading}
              onbookmark={onbookmark}
              isSaved={isSaved}
            />
        </div>
        
        
        </div>
        </div>
        
        </>
     );
}
 
