import axios from "axios";

export const sendOTP = async (phoneNo) => {
  console.log(phoneNo);
  const url = `${process.env.REACT_APP_baseURL}/user/sendOTP`;
  const respose = await axios
    .post(
      url,
      {
        number: phoneNo,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .catch((error) => {
      console.log(error.respose?.data);
    });

  if (respose) {
    console.log(respose?.data);
    localStorage.setItem("phoneNo", phoneNo);
    console.log(phoneNo);
    return respose;
  }
};

export const verifyOTP = async (otp, phoneNo) => {
  console.log(phoneNo);
  const url = `${process.env.REACT_APP_baseURL}/user/verifyOTP`;
  const response = await axios
    .post(
      url,
      {
        number: phoneNo,
        otp: otp,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    console.log(response);
    localStorage.setItem("auth", response?.data?.token);
    localStorage.setItem("userId", response?.data?._id);

    return response;
  }
};

export const getUserDetails = async () => {
  const url = `${process.env.REACT_APP_baseURL}/user/verify`;
  const response = await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    localStorage.setItem("isPhoneVerified", response?.data?.isPhoneVerified);
    // console.log(response?.data)
    return response?.data;
  }
};

export const updateProfile = async (name, gender, upload) => {
  console.log(name, gender, upload);
  const url = `${process.env.REACT_APP_baseURL}/user/updatePersonalInfo`;
  const response = await axios
    .post(
      url,
      {
        name: name,
        gender: gender,
        upload: upload,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    console.log(response, "******************************");
    return response;
  }
};

export const getCampusData = async () => {
  const url = `${process.env.REACT_APP_baseURL}/campus/?skip=0&limit=20&name=patil`;
  const response = await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    console.log(response);
    return response;
  }
};

export const updateCampusInfo = async (
  campusId,
  course,
  end_date,
  start_date
) => {
  console.log(campusId, course, end_date, start_date);
  const url = `${process.env.REACT_APP_baseURL}/user/v2/updateCampusInfo`;
  const response = await axios
    .post(
      url,
      {
        campusId: campusId,
        end_date: end_date,
        start_date: start_date,
        courseName: course,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    console.log(response?.data);
    return response;
  }
};

export const updateSkills = async (skills) => {
  const url = `${process.env.REACT_APP_baseURL}/user/skills`;
  const response = await axios
    .post(
      url,
      {
        skills: skills,
        isOnboarding: true,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    console.log(response, "******************************");
    return response;
  }
};

export const deleteUser = async () => {
  const url = `${process.env.REACT_APP_baseURL}/user`;
  const response = await axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    console.log(response);
    localStorage.removeItem("auth");
    return response;
  }
};

export const logoutUser = async () => {
  const url = `${process.env.REACT_APP_baseURL}/user/logout`;
  const response = await axios
    .post(
      url,
      {
        fcmToken: localStorage.getItem("auth"),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
      localStorage.removeItem("auth");
      localStorage.removeItem("isPhoneVerified");
    });

  if (response) {
    localStorage.removeItem("auth");
    console.log(response);
    return response;
  }
};

export const getSkills = async (query) => {
  try {
    const url = `${process.env.REACT_APP_baseURL}/user/skills?skill=${query}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    });

    if (response.data?.skills) {
      console.log(
        response.data?.skills.map((index) => ({
          value: index._id,
          label: index.skill,
        }))
      );
      return response.data?.skills.map((index) => ({
        value: index._id,
        label: index.skill,
      }));
    }
  } catch (error) {
    console.error("Error fetching locations:", error);
    // You might want to handle the error here, depending on your use case
    // For example, you could throw the error or return an empty array
    throw error;
  }
};

export const getLocations = async (query) => {
  try {
    const url = `${process.env.REACT_APP_baseURL}/user/location?location=${query}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    });

    if (response.data?.locations) {
      console.log(
        response.data?.locations.map((index) => ({
          value: index._id,
          label: index.location,
        }))
      );
      return response.data?.locations.map((index) => ({
        value: index._id,
        label: index.location,
      }));
    }
  } catch (error) {
    console.error("Error fetching locations:", error);
    // You might want to handle the error here, depending on your use case
    // For example, you could throw the error or return an empty array
    throw error;
  }
};

export const searchJobProfileApiCall = async (query) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_baseURL}/user/job_profiles?query=${query}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    );
    if (response.data?.jobProfiles) {
      console.log(
        response.data?.jobProfiles.map((index) => ({
          value: index._id,
          label: index.job_profile,
        }))
      );
      return response.data?.jobProfiles.map((index) => ({
        value: index._id,
        label: index.job_profile,
      }));
    }
    console.log(response.data, "getJobProfiles");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const updateWorkPref = async (
  jobType,
  locations,
  workplace,
  designations
) => {
  const url = `${process.env.REACT_APP_baseURL}/user/preferance`;
  const response = await axios
    .post(
      url,
      {
        jobType: [jobType],
        locations: locations,
        workplace: [workplace],
        designations: designations,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    console.log(response?.data);
    return response;
  }
};

export const googleSSOLogin = async (authToken) => {
  const url = `${process.env.REACT_APP_baseURL}/user/verify_sso`;
  const response = await axios
    .post(
      url,
      {
        authToken: authToken,
        service: 0,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    console.log(response?.data);
    localStorage.setItem("userId", response?.data?._id);
    return response;
  }
};
// Add college

export const RequestCollege = (
  campusName,
  campusAlias,
  courseName,
  start_date,
  end_date
) => {
  const url = `${process.env.REACT_APP_baseURL}/campus/request`;
  const response = axios
    .post(
      url,
      {
        campusName: campusName,
        campusAlias: campusAlias,
        courseName: courseName,
        start_date: start_date,
        end_date: end_date,

        // locations: locations,
        // workplace: [workplace],
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    console.log(response?.data);
    return response;
  }
};
