import React, { useState } from "react";
import Notifications from "../../../components/notifications_dialog_box/Notifications";

function CommonBar() {
  const [isNotificationClicked, setIsNotificationClicked] = useState(false);
  return (
    <>
      {isNotificationClicked && (
        <div className="absolute top-16 right-5 w-full h-full backdrop-filter backdrop-blur-sm">
          <Notifications
            setIsNotificationClicked={setIsNotificationClicked}
            isNotificationClicked={isNotificationClicked}
          />
        </div>
      )}
      <div className="flex justify-between items-center mr-4 ">
        <div className="px-8 sm:max-w-[570px] pt-8 flex-col justify-start items-start gap-2.5 flex"></div>
        <div
          onClick={() => setIsNotificationClicked(!isNotificationClicked)}
          className="relative cursor-pointer"
        >
          <svg
            className="absolute top-0 right-0 opacity-100"
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="9"
            viewBox="0 0 8 9"
            fill="none"
          >
            <path
              d="M4 7.58016C5.93377 7.58016 7.5 6.01018 7.5 4.07524C7.5 2.14029 5.93377 0.570312 4 0.570312C2.06623 0.570312 0.5 2.14029 0.5 4.07524C0.5 6.01018 2.06623 7.58016 4 7.58016Z"
              fill="#FF6550"
              stroke="white"
            />
          </svg>
          <svg
            className="relative"
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="22"
            viewBox="0 0 19 22"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.48243 19.236C8.00043 19.814 8.66543 20.1315 9.35543 20.1315H9.35643C10.0494 20.1315 10.7174 19.8139 11.2364 19.235C11.5144 18.9275 11.9884 18.9025 12.2954 19.1799C12.6034 19.4574 12.6284 19.9331 12.3514 20.2406C11.5434 21.1391 10.4804 21.6339 9.35643 21.6339H9.35443C8.23343 21.6329 7.17243 21.1381 6.36743 20.2396C6.09043 19.9321 6.11543 19.4564 6.42343 19.1799C6.73143 18.9015 7.20543 18.9265 7.48243 19.236ZM9.40523 0.0986328C13.8502 0.0986328 16.8362 3.56632 16.8362 6.80463C16.8362 8.47036 17.2592 9.17651 17.7082 9.92574C18.1522 10.665 18.6552 11.5043 18.6552 13.0909C18.3062 17.1446 14.0812 17.4751 9.40523 17.4751C4.72923 17.4751 0.50323 17.1446 0.158216 13.155C0.15523 11.5043 0.65823 10.665 1.10223 9.92574L1.25897 9.66146C1.6449 8.99708 1.97423 8.27439 1.97423 6.80463C1.97423 3.56632 4.96023 0.0986328 9.40523 0.0986328ZM9.40523 1.6011C5.91023 1.6011 3.47423 4.34359 3.47423 6.80463C3.47423 8.88704 2.89723 9.84962 2.38723 10.699C1.97823 11.3811 1.65523 11.92 1.65523 13.0909C1.82223 14.98 3.06723 15.9727 9.40523 15.9727C15.7082 15.9727 16.9922 14.936 17.1582 13.0258C17.1552 11.92 16.8322 11.3811 16.4232 10.699C15.9132 9.84962 15.3362 8.88704 15.3362 6.80463C15.3362 4.34359 12.9002 1.6011 9.40523 1.6011Z"
              fill="#002032"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default CommonBar;
