import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { formatPrice, inWaitListUser, registeredUser } from "../../../../services/courses";
import { browserName, isMobileOnly } from "react-device-detect";
import moment from "moment";
import { Mixpanel } from "../../../../services/mixpanel";
import FillButton from "../../../../components/buttons/fillButton";

export const JoinWaitlistComp = ({courseDetails,userData, joinWaitingList, redirectToLogin, isRegister, inWaitlist}) => {
    const navigate = useNavigate();
 
    const auth = localStorage.getItem('auth');


  return (
    <div className="w-full h-auto backdrop-blur-[38.5px] bg-[#000000]/50 ">
      <div
        className="w-full px-16 py-6 justify-center items-center gap-[7.79px] inline-flex"
        style={{
          boxShadow:
            "0 -4px 6px 0 rgba(128, 128, 128, 0.5), 0 4px 6px 0 rgba(128, 128, 128, 0.5)",
        }}
      >
        <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-2 inline-flex">
          <div className="justify-start items-center gap-2 inline-flex">
            <div className="w-[30px] h-[30px] p-[3px] justify-center items-center flex">
              <div className="w-6 h-6 relative flex-col justify-start items-start flex">
                <div className="w-6 h-6 relative">
                  <img src="/svg/price.svg" />
                </div>
              </div>
            </div>
            <div className="w-full justify-center items-end gap-2 flex">
              <div className="w-max h-auto text-white text-[32px] font-black font-satoshi leading-none">
                ₹{formatPrice(courseDetails?.finalPrice)}
              </div>
              {courseDetails?.type === "MICRO DEGREE" &&
              <div className="opacity-40 text-white text-xl font-bold font-satoshi line-through leading-none">
                        {formatPrice(courseDetails?.actualPrice)}
                      </div>
}

{courseDetails?.type === "JOB PLACEMENT" &&
              <div className="text-white text-base font-bold font-satoshi leading-none">
                for Registration
              </div>
}
            </div>
          </div>
          <div className="justify-start items-center gap-2 inline-flex">
            <div className="w-[30px] h-[30px] p-[3px] justify-center items-center flex">
              <div className="w-6 h-6 relative flex-col justify-start items-start flex">
                <img src="/svg/candidates.svg" />
              </div>
            </div>
            <div className="text-white text-base font-bold font-satoshi leading-none">
            {courseDetails?.waitlistBuffer + courseDetails?.waitlistCount} Joined
            </div>
          </div>
        </div>

        {/* <button

  onClick={() => {
    if (courseDetails?.isRegistrationLive === false && !auth) {
      Mixpanel.track("Join Waitlist CTA Clicked", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        user_id: localStorage.getItem("userId"),
        name:userData?.profileInfo?.name,
        number:userData?.number,
        email:userData?.email,
        course_Id:courseDetails?._id,
        browser: browserName,
        mobile_device: isMobileOnly,
        type:"webapp",
      });
      redirectToLogin(courseDetails?._id);
    } else if(courseDetails?.isRegistrationLive === false && auth && !userData?.profileInfo?.name && courseDetails?.userInWaitlist === false) {
      navigate(`/join-waitlist/fill-details?id=${courseDetails?._id}`);
    } else if(courseDetails?.isRegistrationLive === false && auth && userData?.profileInfo?.name !== "" && courseDetails?.userInWaitlist === false) {
      Mixpanel.track("joining waitlist CTA Clicked", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        user_id: localStorage.getItem("userId"),
        name:userData?.profileInfo?.name,
        number:userData?.number,
        email:userData?.email,
        course_Id:courseDetails?._id,
        browser: browserName,
        mobile_device: isMobileOnly,
        type:"webapp",
      });
      joinWaitingList(courseDetails?._id);
    console.log("h12")
    } else if(courseDetails?.isRegistrationLive === true && !auth) {
      Mixpanel.track("Join Waitlist CTA Clicked", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        user_id: localStorage.getItem("userId"),
        name:userData?.profileInfo?.name,
        number:userData?.number,
        email:userData?.email,
        course_Id:courseDetails?._id,
        browser: browserName,
        mobile_device: isMobileOnly,
        type:"webapp",
      });
        redirectToLogin(courseDetails?._id);
    } else if(courseDetails?.isRegistrationLive === true && auth && courseDetails?.userInWaitlist === true && courseDetails?.userRegistered === false) {
      Mixpanel.track("register CTA Clicked", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        user_id: localStorage.getItem("userId"),
        name:userData?.profileInfo?.name,
        number:userData?.number,
        email:userData?.email,
        course_Id:courseDetails?._id,
        browser: browserName,
        mobile_device: isMobileOnly,
        type:"webapp",
      });
      navigate(`/register/payment?id=${courseDetails?._id}`);
    } else if(courseDetails?.isRegistrationLive === true && auth && !userData?.profileInfo?.name && courseDetails?.userInWaitlist === false) {
        navigate(`/join-waitlist/fill-details?id=${courseDetails?._id}`);
    } else if(courseDetails?.isRegistrationLive === true && auth && userData?.profileInfo?.name !== "" && courseDetails?.userInWaitlist === false) {
      Mixpanel.track("Joining waitlist CTA Clicked", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        user_id: localStorage.getItem("userId"),
        name:userData?.profileInfo?.name,
        number:userData?.number,
        email:userData?.email,
        course_Id:courseDetails?._id,
        browser: browserName,
        mobile_device: isMobileOnly,
        type:"webapp",
      });
      joinWaitingList(courseDetails?._id);
      console.log("h11")
  } else {
    
      navigate(`/program-details?id=${courseDetails?._id}&type=${courseDetails?.type}`)
  }
  }} 

  disabled={(courseDetails?.userInWaitlist === true && courseDetails?.userRegistered === false && courseDetails?.isRegistrationLive === false) || (courseDetails?.userRegistered === true)}
          className={`disabled:cursor-not-allowed disabled:opacity-50  w-[180px] h-[53px] px-4 py-3 bg-amber-300 rounded-xl justify-center items-center gap-2 flex`}
        >
          <div className="text-center text-teal-950 text-base font-black font-satoshi leading-tight">
          {(courseDetails?.userInWaitlist === true && courseDetails?.userRegistered === false)
              ? "Register"
              : courseDetails?.userRegistered === true ? "Registered" : "Join Waitlist"}
          </div>
        </button> */}
         <FillButton 
                    onClick={() => {
                      if (courseDetails?.isRegistrationLive === false && !courseDetails?.bypassWaitlist && !auth) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:courseDetails?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                        redirectToLogin(courseDetails?._id);
                      } else if(courseDetails?.isRegistrationLive === false && auth && !userData?.profileInfo?.name && courseDetails?.userInWaitlist === false && !courseDetails?.bypassWaitlist) {
                        navigate(`/join-waitlist/fill-details?id=${courseDetails?._id}`);
                      } else if(courseDetails?.isRegistrationLive === false && auth && userData?.profileInfo?.name !== "" && courseDetails?.userInWaitlist === false && !courseDetails?.bypassWaitlist) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:courseDetails?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      joinWaitingList(courseDetails?._id);
                      console.log("h12")
                      } else if(courseDetails?.isRegistrationLive === true && !courseDetails?.bypassWaitlist && !auth) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:courseDetails?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                          redirectToLogin(courseDetails?._id);
                      } else if((auth && courseDetails?.isRegistrationLive === true && auth && courseDetails?.userInWaitlist === true && courseDetails?.userRegistered === false && !courseDetails?.bypassWaitlist)) {
                        Mixpanel.track("register CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:courseDetails?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      navigate(`/register/payment?id=${courseDetails?._id}`);
                      }else if((auth && courseDetails?.bypassWaitlist === true)) {
                        Mixpanel.track("register CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:courseDetails?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      navigate(`/register/payment?id=${courseDetails?._id}`);
                      } else if(!auth && courseDetails?.bypassWaitlist === true) {
                        Mixpanel.track("register CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:courseDetails?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                      navigate(`/register/fill-details?id=${courseDetails?._id}`);
                      } else if(courseDetails?.isRegistrationLive === true && auth && !userData?.profileInfo?.name && courseDetails?.userInWaitlist === false && !courseDetails?.bypassWaitlist) {
                          navigate(`/join-waitlist/fill-details?id=${courseDetails?._id}`);
                      } else if(courseDetails?.isRegistrationLive === true && auth && userData?.profileInfo?.name !== "" && courseDetails?.userInWaitlist === false && !courseDetails?.bypassWaitlist) {
                        Mixpanel.track("Join Waitlist CTA Clicked", {
                          date: moment().format(),
                          distinct_id: localStorage.getItem("userId"),
                          user_id: localStorage.getItem("userId"),
                          name:userData?.profileInfo?.name,
                          number:userData?.number,
                          email:userData?.email,
                          course_Id:courseDetails?._id,
                          browser: browserName,
                          mobile_device: isMobileOnly,
                          type:"webapp",
                        });
                        joinWaitingList(courseDetails?._id);
                        console.log("h11")
                    } else {
                      Mixpanel.track("Micro Degree Card Clicked", {
                        date: moment().format(),
                        distinct_id: localStorage.getItem("userId"),
                        user_id: localStorage.getItem("userId"),
                        name:userData?.profileInfo?.name,
                        number:userData?.number,
                        email:userData?.email,
                        course_Id:courseDetails?._id,
                        browser: browserName,
                        mobile_device: isMobileOnly,
                        type:"webapp",
                      });
                        navigate(`/program-details?id=${courseDetails?._id}&type=${courseDetails?.type}`)
                    }
                    }} 
                  disabled={(!courseDetails?.bypassWaitlist && courseDetails?.userInWaitlist === true && courseDetails?.userRegistered === false && courseDetails?.isRegistrationLive === false) || (courseDetails?.userRegistered === true)  }
           className="w-max h-[53px]"
                  textColor="black"
                  color="yellow"
                  text={((auth && courseDetails?.userInWaitlist === true && courseDetails?.userRegistered === false && !courseDetails?.bypassWaitlist) || (courseDetails?.bypassWaitlist === true && courseDetails?.userRegistered === false))
                    ? "Register"
                    : courseDetails?.userRegistered === true ? "Registered" : "Join Waitlist"}
                  />
      </div>

    </div>
  );
};
