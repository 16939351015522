import React from 'react';

const SkillsBadge = ({skill}) => {
    return ( 
        <div className="w-fit h-[34px] px-3 py-1 bg-[#F2F4F5] rounded-[20px] justify-center items-center gap-4 inline-flex">
          <div className="text-[#16C083] text-base font-thin leading-relaxed">{skill}</div>
        </div>
   );
}
 
export default SkillsBadge;