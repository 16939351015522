import React, { useState } from "react";

export const Curriculum = ({courseDetails}) => {

  const [visibleText, setVisibleText] = useState(Array(12).fill(false)); // Assuming there are 12 questions

  // Function to toggle visibility of an answer
  const toggleText = (index) => {
    const newVisibleAnswers = [...visibleText];
    newVisibleAnswers[index] = !newVisibleAnswers[index];
    setVisibleText(newVisibleAnswers);
  };
  return (
    <div className="w-full h-auto">
      <div className="w-full self-stretch h-auto px-2 sm:px-4 md:px-16 xl:px-[124px] py-16 lg:py-[72px] flex-col justify-start items-start gap-10 flex">
        <div className="w-full self-stretch h-auto flex-col justify-start items-center gap-8 flex">
          <div className="max-w-[652px] h-[53px] text-center text-gray-100 text-2xl font-bold font-satoshi">
            Curriculum
          </div>
          <div className="min-h-[600px] w-full self-stretch justify-center items-start gap-10 inline-flex overflow-y-scroll no-scrollbar">
          <div className="hidden lg:flex flex-col justify-start items-start gap-10 inline-flex">
  {courseDetails?.modules?.map((module, index) => (
    index % 2 == 0 && (
      <div key={index} className={`w-[400px] h-auto p-8 
      
        ${courseDetails?.type === "MICRO DEGREE" ? 
          "bg-[#180B2B]" : 
          (courseDetails?.type === "JOB PLACEMENT" && courseDetails?.topSectionColors[1] === "#191772") ? 
          "bg-[#191772]/20" : 
          (courseDetails?.type === "JOB PLACEMENT" && courseDetails?.topSectionColors[1] === "#7A2D28") ? 
          "bg-[#7A2D28]/20" : 
          (courseDetails?.type === "JOB PLACEMENT" && courseDetails?.topSectionColors[1] === "#725017") ?
          "bg-[#725017]/20" : ""}
      ${courseDetails?.type === "MICRO DEGREE" ? "bg-gradient-to-br from-neutral-900 to-slate-900" : `bg-gradient-to-br from-neutral-900 via-neutral-800 to-${courseDetails?.topSectionColors[1]}`}  rounded-2xl border-2 border-white/10 flex-col justify-start items-start gap-4 flex`}>
        <div className="self-stretch h-auto flex-col justify-start items-start gap-8 flex">
        <div className="w-full self-stretch justify-between items-center inline-flex" onClick={() => toggleText(index)}>
            <div className="w-full text-gray-400 text-sm font-normal font-satoshi leading-[17.85px] tracking-wide">
              Module {index + 1}
            </div>
            <div className="w-[29px] h-[29px] relative transform rotate-[-180deg]">
                  <img src="/svg/faqarrow.svg" alt="arrow" style={{ transform: visibleText[index] ? 'rotate(-180deg)' : 'rotate(0deg)' }} />
                </div>
          </div>
          <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
            <div className="text-gray-100 text-base font-bold font-satoshi uppercase leading-snug tracking-wide">
              {module?.title}
            </div>
            <div className="w-[260px] text-gray-400 text-sm font-medium font-satoshi leading-normal">
            {visibleText[index] && (
              <ul className="flex flex-col gap-2" style={{ listStyleType: "disc", marginLeft: "20px" }}>
                {module?.points?.map((point, idx) => (
                  <li key={idx}>{point}</li>
                ))}
              </ul>
            )}
            </div>
          </div>
        </div>
      </div>
    )
  ))}
</div>


<div className="hidden lg:flex relative w-[15px] h-fit-content inline-block">
  <div class="absolute top-2 ml-3 transform -translate-x-1/2 h-full">
    <svg width="15" height="500.79" viewBox="0 0 15 500.79">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" style={{ stopColor: '#FFDA45', stopOpacity: 0.7 }} />
        <stop offset="100%" style={{ stopColor: 'grey', stopOpacity: 0.5 }} />
        </linearGradient>
      </defs>
      <polygon points="0,0 5,0 7.5,685.79" fill="url(#gradient)" />
    </svg>
  </div>
  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-[15px] h-[15px] bg-yellow-500 rounded-full shadow border-2 border-white/50"></div>
</div>


            <div className="hidden lg:flex flex-col justify-start items-start gap-10 inline-flex">
  {courseDetails?.modules?.map((module, index) => (
    index % 2 !== 0 && (
      <div key={index} className={`w-[400px] h-auto p-8 
        ${courseDetails?.type === "MICRO DEGREE" ? 
          "bg-[#180B2B]" : 
          (courseDetails?.type === "JOB PLACEMENT" && courseDetails?.topSectionColors[1] === "#191772") ? 
          "bg-[#191772]/20" : 
          (courseDetails?.type === "JOB PLACEMENT" && courseDetails?.topSectionColors[1] === "#7A2D28") ? 
          "bg-[#7A2D28]/20" : 
          (courseDetails?.type === "JOB PLACEMENT" && courseDetails?.topSectionColors[1] === "#725017") ?
          "bg-[#725017]/20" : ""}
      ${courseDetails?.type === "MICRO DEGREE" ? "bg-gradient-to-br from-neutral-900 to-slate-900" : `bg-gradient-to-br from-neutral-900 via-neutral-900 to-${courseDetails?.topSectionColors[1]}`} rounded-2xl border-2 border-white/10 flex-col justify-start items-start gap-4 flex`}>
        <div className="self-stretch h-auto flex-col justify-start items-start gap-8 flex">
        <div className="w-full self-stretch justify-between items-center inline-flex" onClick={() => toggleText(index)}>
            <div className="w-full text-gray-400 text-sm font-normal font-satoshi leading-[17.85px] tracking-wide">
              Module {index + 1}
            </div>
            <div className="w-[29px] h-[29px] relative transform rotate-[-180deg]">
                  <img src="/svg/faqarrow.svg" alt="arrow" style={{ transform: visibleText[index] ? 'rotate(-180deg)' : 'rotate(0deg)' }} />
                </div>
          </div>
          <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
            <div className="text-gray-100 text-base font-bold font-satoshi uppercase leading-snug tracking-wide">
              {module?.title}
            </div>
            <div className="w-[260px] text-gray-400 text-sm font-medium font-satoshi leading-normal">
            {visibleText[index] && (
              <ul className="flex flex-col gap-2" style={{ listStyleType: "disc", marginLeft: "20px" }}>
                {module?.points?.map((point, idx) => (
                  <li key={idx}>{point}</li>
                ))}
              </ul>
            )}
            </div>
          </div>
        </div>
      </div>
    )
  ))}
</div>


<div className="lg:hidden w-full h-auto inline-flex flex-wrap justify-center items-center gap-4 md:gap-10">
  {courseDetails?.modules?.map((module, index) => (
      <div key={index} className={`w-[300px] h-auto p-8 
        ${courseDetails?.type === "MICRO DEGREE" ? 
          "bg-[#180B2B]" : 
          (courseDetails?.type === "JOB PLACEMENT" && courseDetails?.topSectionColors[1] === "#191772") ? 
          "bg-[#191772]/20" : 
          (courseDetails?.type === "JOB PLACEMENT" && courseDetails?.topSectionColors[1] === "#7A2D28") ? 
          "bg-[#7A2D28]/20" : 
          (courseDetails?.type === "JOB PLACEMENT" && courseDetails?.topSectionColors[1] === "#725017") ?
          "bg-[#725017]/20" : ""}
      ${courseDetails?.type === "MICRO DEGREE" ? "bg-gradient-to-br from-neutral-900 to-slate-900" : `bg-gradient-to-br from-neutral-900 via-neutral-800 to-${courseDetails?.topSectionColors[1]}`}  rounded-2xl border-2 border-white/10 flex-col justify-start items-start gap-4 flex`}>
        <div className="self-stretch h-auto flex-col justify-start items-start gap-8 flex">
          <div className="w-full self-stretch justify-between items-center inline-flex" onClick={() => toggleText(index)}>
            <div className="w-full text-gray-400 text-sm font-normal font-satoshi leading-[17.85px] tracking-wide">
              Module {index + 1}
            </div>
            <div className="w-[29px] h-[29px] relative transform rotate-[-180deg]">
                  <img src="/svg/faqarrow.svg" alt="arrow" style={{ transform: visibleText[index] ? 'rotate(-180deg)' : 'rotate(0deg)' }} />
                </div>
          </div>
          <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
            <div className="text-gray-100 text-base font-bold font-satoshi uppercase leading-snug tracking-wide">
              {module?.title}
            </div>
            <div className="w-[260px] text-gray-400 text-sm font-medium font-satoshi leading-normal">
            {visibleText[index] && (
              <ul className="flex flex-col gap-2" style={{ listStyleType: "disc", marginLeft: "20px" }}>
                {module?.points?.map((point, idx) => (
                  <li key={idx}>{point}</li>
                ))}
              </ul>
            )}
            </div>
          </div>
        </div>
      </div>

  ))}
</div>
          </div>

        </div>
      </div>
    </div>
  );
};
