import React from "react";

export const CourseJourney = () => {
  return <div className="w-full h-auto px-4 sm:px-8 md:px-16 py-[124px] flex flex-col gap-16 justify-center items-center">
    <div className="w-full text-center text-white text-2xl font-bold font-satoshi">How your journey looks like</div>
<div className="w-full md:max-w-[848px] h-auto px-8 bg-stone-950 rounded-2xl border-2 border-white/opacity-10 inline-flex justify-between items-center gap-4 inline-flex">

<div className="self-stretch h-auto flex-col justify-center items-center gap-1.5 flex">
    <div className="self-stretch text-gray-100 text-base font-bold font-satoshi uppercase leading-snug tracking-wide">1.Join WAITLIST FOR Program</div>
    <div className="self-stretch text-gray-400 text-sm font-medium font-satoshi leading-normal">Select the program with skills you want to master </div>
</div>
  <div className="rotate-180 flex-col justify-center items-center flex">
    <img  src="gridDesign.png" />
</div>
</div>
<div className="w-full md:max-w-[848px] h-auto px-8 bg-stone-950 rounded-2xl border-2 border-white/opacity-10 inline-flex justify-between items-center gap-4 inline-flex">

  <div className="self-stretch h-auto flex-col justify-center items-center gap-1.5 flex">
      <div className="self-stretch text-gray-100 text-base font-bold font-satoshi uppercase leading-snug tracking-wide">2.Apply once Registration opens</div>
      <div className="self-stretch text-gray-400 text-sm font-medium font-satoshi leading-normal">You will be notified once the registeration opens </div>
  </div>
    <div className="rotate-180 flex-col justify-center items-center flex">
      <img  src="gridDesign.png" />
  </div>
</div>
<div className="w-full md:max-w-[848px] h-auto px-8 bg-stone-950 rounded-2xl border-2 border-white/opacity-10 inline-flex justify-between items-center gap-4 inline-flex">

  <div className="self-stretch h-auto flex-col justify-center items-center gap-1.5 flex">
      <div className="self-stretch text-gray-100 text-base font-bold font-satoshi uppercase leading-snug tracking-wide">3.PAY registration fee only</div>
      <div className="self-stretch text-gray-400 text-sm font-medium font-satoshi leading-normal">For Job Placement Programs, you have to pay only ₹999 to register </div>
  </div>
    <div className="rotate-180 flex-col justify-center items-center flex">
      <img  src="gridDesign.png" />
  </div>
</div>
<div className="w-full md:max-w-[848px] h-auto px-8 bg-stone-950 rounded-2xl border-2 border-white/opacity-10 inline-flex justify-between items-center gap-4 inline-flex">

  <div className="self-stretch h-auto flex-col justify-center items-center gap-1.5 flex">
      <div className="self-stretch text-gray-100 text-base font-bold font-satoshi uppercase leading-snug tracking-wide">4.Complete the program</div>
      <div className="self-stretch text-gray-400 text-sm font-medium font-satoshi leading-normal">You will be guided by industry experts to complete your course. </div>
  </div>
    <div className="rotate-180 flex-col justify-center items-center flex">
      <img  src="gridDesign.png" />
  </div>
</div>
<div className="w-full md:max-w-[848px] h-auto px-8 bg-stone-950 rounded-2xl border-2 border-white/opacity-10 inline-flex justify-between items-center gap-4 inline-flex">

  <div className="self-stretch h-auto flex-col justify-center items-center gap-1.5 flex">
      <div className="self-stretch text-gray-100 text-base font-bold font-satoshi uppercase leading-snug tracking-wide">5.Get placed at top companies</div>
      <div className="self-stretch text-gray-400 text-sm font-medium font-satoshi leading-normal">Our programs comes with Placement support and Internships to kickstart your career. </div>
  </div>
    <div className="rotate-180 flex-col justify-center items-center flex">
      <img  src="gridDesign.png" />
  </div>
</div>
  </div>
};
