import React from "react";
import FillButton from "../../../../components/buttons/fillButton";

export const PlacementProgramFlow = ({scrollToTop}) => {
  return <div className="bg-white w-full h-auto py-[74px] px-4 sm:px-8 lg:px-16 ">
       <div className="self-stretch h-auto px-4 md:px-8 lg:px-16 py-[70px] bg-gradient-to-br from-violet-200 via-violet-500 to-violet-950 rounded-2xl flex-col justify-center items-center flex">
                <div className="w-[33.86px] h-[18.96px] relative" />
                <div className="w-full flex-col justify-start items-center gap-[54px] flex">
                    <div className="text-white text-base font-bold font-satoshi leading-[18.69px]">How do Placement Programs work?</div>
                    <div className="w-full flex-col justify-start items-center gap-8 flex">
                        <div className="hidden md:flex w-[410px] h-[23px] relative">
                            <div className="w-[173px] h-[0px] left-[20.50px] top-[11.04px] absolute border border-gray-200"></div>
                            <div className="w-[168px] h-[0px] left-[216.50px] top-[11.04px] absolute border border-gray-200"></div>
                            <div className="w-[23px] h-[23px] left-[-0.50px] top-[0.04px] absolute">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.1523" r="10.7333" fill="#16C083" fill-opacity="0.5" stroke="#16C083" stroke-width="1.53333"/>
    <path d="M9 12.5L11 14.5L15 10.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                            </div>
                            <div className="w-[23px] h-[23px] left-[193.50px] top-[0.04px] absolute">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.1523" r="10.7333" fill="#16C083" fill-opacity="0.5" stroke="#16C083" stroke-width="1.53333"/>
    <path d="M9 12.5L11 14.5L15 10.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                            </div>
                            <div className="w-[23px] h-[23px] left-[384.50px] top-0 absolute">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.1523" r="10.7333" fill="#16C083" fill-opacity="0.5" stroke="#16C083" stroke-width="1.53333"/>
    <path d="M9 12.5L11 14.5L15 10.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                            </div>
                        </div>
                        <div className="w-full justify-center items-start gap-8 flex flex-col md:flex-row">
                            <div className="w-full md:w-[163px] gap-2 text-center text-white text-xl font-bold font-satoshi leading-[35px] inline-flex justify-center items-center">
                            <div className="flex md:hidden w-[23px] h-[23px]">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.1523" r="10.7333" fill="#16C083" fill-opacity="0.5" stroke="#16C083" stroke-width="1.53333"/>
    <path d="M9 12.5L11 14.5L15 10.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                            </div>
                                Join our waitlist for FREE</div>
                            <div className="w-full md:w-[163px] gap-2 text-center text-white text-xl font-bold font-satoshi leading-[35px] inline-flex justify-center items-center">
                            <div className="flex md:hidden w-[23px] h-[23px]">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.1523" r="10.7333" fill="#16C083" fill-opacity="0.5" stroke="#16C083" stroke-width="1.53333"/>
    <path d="M9 12.5L11 14.5L15 10.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                            </div>
                            Register & pay only ₹999</div>
                            <div className="w-full md:w-[163px] gap-2 text-center text-white text-xl font-bold font-satoshi leading-[35px] inline-flex justify-center items-center">
                            <div className="flex md:hidden w-[23px] h-[23px]">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.1523" r="10.7333" fill="#16C083" fill-opacity="0.5" stroke="#16C083" stroke-width="1.53333"/>
    <path d="M9 12.5L11 14.5L15 10.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                            </div>Pay remaining once enrolled</div>
                        </div>
                    </div>
                    <div className="w-full flex-col justify-center items-center gap-6 flex">
                        <div className="w-full justify-center items-center gap-6 flex flex-col md:flex-row">
                            <FillButton 
                            className="max-w-[288px] h-auto md:min-h-[52px] bg-white hover:bg-[#E5E9EA] active:bg-[#E5E9EA]"
                            onClick={()=>scrollToTop('jobPlacement')}
                            textColor="black"
                            // color="gray"
                            text="Explore Job Placement Programs"
                            />
                              <FillButton 
                               className="max-w-[288px] h-auto md:min-h-[52px] bg-white hover:bg-[#E5E9EA] active:bg-[#E5E9EA]"
                            onClick={()=>scrollToTop('microDegree')}
                            textColor="black"
                            //  color=""
                            text="Explore Micro Degree Programs"
                            />
                            {/* <div onClick={()=>scrollToTop('jobPlacement')} className=" cursor-pointer h-[52px] p-4 bg-gray-200 rounded-lg justify-center items-center gap-2.5 flex">
                                <div className="text-teal-950 text-base font-black font-satoshi leading-tight">Explore Job Placement Programs</div>
                            </div>
                            <div onClick={()=>scrollToTop('microDegree')} className="cursor-pointer h-[52px] p-4 bg-gray-200 rounded-lg justify-center items-center gap-2.5 flex">
                                <div className="text-teal-950 text-base font-black font-satoshi leading-tight">Explore Micro Degree Programs</div>
                            </div> */}
                        </div>
                        <div className="h-[22.33px] justify-start items-center gap-[5px] inline-flex">
                            <div className="w-5 h-5 pl-[3px] pr-0.5 pt-0.5 pb-[3px] justify-center items-center flex">
                                <div className="w-[15px] h-[15px] relative flex-col justify-start items-start flex" >
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_12475_34253)">
<path d="M7.60171 10.0319C8.23065 9.61418 8.70837 9.00503 8.96419 8.29464C9.22001 7.58426 9.24032 6.81039 9.02213 6.08756C8.80393 5.36473 8.35882 4.73135 7.75267 4.28116C7.14652 3.83097 6.41154 3.58789 5.6565 3.58789C4.90146 3.58789 4.16648 3.83097 3.56033 4.28116C2.95418 4.73135 2.50907 5.36473 2.29087 6.08756C2.07268 6.81039 2.093 7.58426 2.34881 8.29464C2.60463 9.00503 3.08235 9.61418 3.71129 10.0319C2.63914 10.4275 1.7125 11.1394 1.0542 12.0736C1.00478 12.1438 0.975589 12.2262 0.969812 12.3119C0.964036 12.3975 0.981896 12.4831 1.02145 12.5593C1.06099 12.6355 1.12071 12.6994 1.19409 12.744C1.26746 12.7886 1.35167 12.8122 1.43753 12.8122L9.87528 12.8118C9.96114 12.8118 10.0453 12.7883 10.1187 12.7437C10.1921 12.6991 10.2518 12.6352 10.2913 12.559C10.3309 12.4828 10.3487 12.3972 10.3429 12.3115C10.3372 12.2258 10.308 12.1434 10.2585 12.0732C9.60024 11.1393 8.67371 10.4274 7.60171 10.0319Z" fill="white"/>
<path d="M15.0342 12.0732C14.3759 11.1393 13.4494 10.4274 12.3774 10.032C13.0744 9.56799 13.5829 8.8705 13.8114 8.065C14.04 7.2595 13.9735 6.39888 13.624 5.638C13.2746 4.87713 12.665 4.26596 11.9051 3.91448C11.1451 3.563 10.2847 3.49429 9.47856 3.7207C9.40538 3.74128 9.3383 3.77936 9.28311 3.83163C9.22792 3.88391 9.18627 3.94883 9.16176 4.02079C9.13725 4.09275 9.1306 4.16959 9.1424 4.24469C9.15419 4.31979 9.18408 4.39089 9.22948 4.45187C9.75937 5.16366 10.064 6.01782 10.1041 6.90428C10.1442 7.79075 9.91797 8.66893 9.45452 9.42567C9.39332 9.52547 9.37168 9.64456 9.39385 9.75951C9.41603 9.87446 9.48041 9.97695 9.57434 10.0468C9.75207 10.1791 9.92306 10.3202 10.0867 10.4696C10.0951 10.4787 10.1036 10.4879 10.113 10.4965C10.7386 11.0749 11.2457 11.7696 11.6059 12.5418C11.6436 12.6226 11.7036 12.6909 11.7789 12.7388C11.8541 12.7867 11.9415 12.8122 12.0306 12.8122L14.651 12.8119C14.7369 12.8118 14.8211 12.7883 14.8944 12.7437C14.9678 12.6991 15.0275 12.6352 15.067 12.559C15.1066 12.4828 15.1244 12.3972 15.1186 12.3115C15.1128 12.2258 15.0836 12.1434 15.0342 12.0732Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_12475_34253">
<rect width="15" height="15" fill="white" transform="translate(0.5 0.777222)"/>
</clipPath>
</defs>
</svg>

                                </div>
                            </div>
                            <div><span className="text-white text-sm font-bold font-satoshi leading-none">4332 </span><span className="text-white opacity-80 text-sm font-bold font-satoshi leading-none">in Waitlist</span></div>
                        </div>
                    </div>
                </div>
            </div>
  </div>;
};
