import React, { useEffect, useRef, useState } from "react";
import { convoTime, getAConversation, getAllConversations, getAvatarImageUrl, getChatToken, getConversationId, getFileSizeText, getPresignedUrl, putObjectInUrl, readConversation, searchConversations, sendMessage, trimFileName } from "../../services/chat";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";

const Chat = () => {

  const [textInputSize, setTextInputsize] = useState(1);
  const lastMsgRef = useRef();
  const [latestMsg, setLatestMsg] = useState({});
  const userId = window.localStorage.getItem('userId');

  const lastMessageRef = useRef();
  const fileBUttonRef = useRef();  
  const attachmentRef = useRef();
  const [conversations, setConversations] = useState({ conversations: [] });
  const [filteredConversations, setFiltredConversations] = useState({ conversations: [] });
  
//   const [conversationIdsMap, setConversationIdsMap] = useState({});
  const [filteredConversationIdsMap, setFiltredConversationIdsMap] = useState({});

  const [activeFile, setActiveFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [currentSocket, setCurrentSocket] = useState(null);
  const [activeConvo, setActiveConvo] = useState("");

  const [textFiled, setTextField] = useState("");
  const navigate = useNavigate();

  const connectSocet = async () => {
    const token = await getChatToken();
    const url = `${process.env.REACT_APP_socketURL}/?token=${token}`;
    const socket = new WebSocket(url);


    socket.addEventListener("open", () => {
      console.log("WebSocket connection established");
    });

    socket.onmessage = (event, activeConvo) => {
      setLatestMsg(JSON.parse(event.data));
    };

    socket.addEventListener("close", (event) => {
      console.log("WebSocket connection closed", event.code, event.reason);
    });
  };

  const textInput = (value)=>{

    // setTextInputsize(prev=>{
    //     const noOfLines = value.split("\n").length
    //     if(noOfLines>5) return 5;
    //     return noOfLines;
    // })

    setTextField(value);

  }

  const [conversationMessage, setConversationMessage] = useState({
    messages: [],
  });
  const [activeUserInfo, setActiveUserInfo] = useState();

//   const [userId, setUserId] = useState();


  const onRecivingMsg = async () => {
    // printState(data);
    let msg = latestMsg;
    if (msg.conversationId == null || msg.conversationId == undefined) return;

    //check in convo,
    // load new convo

    if (msg.conversationId == activeConvo) {
      setConversationMessage((prev) => {
        let messages =
          prev.messages.length == 0 || prev.messages[prev.messages.length - 1]._id != msg._id
            ? [...prev.messages, msg]
            : [...prev.messages];

        //---------update read msg
        readConversation(msg.conversationId, msg._id);
        return {
          ...prev,
          messages,
        };
      });
    } else {
      const conv = await getAllConversations();
      if (conv) setConversations(conv);

      let convoIdsMap = {};
      conv.conversations.map((data, index) => {
        convoIdsMap[data.conversationId] = index;
      });

      setFiltredConversationIdsMap(convoIdsMap);
    }
  };

  const clearNewMsg = () => {
    setActiveFile(null);
  };

  useEffect(()=>{onRecivingMsg()}, [latestMsg]);
 
  const onConversationSelect = async (convoId) => {
    setActiveConvo(convoId);
   
    if (
      filteredConversations?.conversations != undefined &&
      filteredConversationIdsMap[convoId] != undefined &&
      filteredConversations?.conversations[filteredConversationIdsMap[convoId]] != undefined &&
      filteredConversations?.conversations[filteredConversationIdsMap[convoId]]?.userInfo
    )
      setActiveUserInfo(filteredConversations?.conversations[filteredConversationIdsMap[convoId]]?.userInfo);

    setFiltredConversations((prev) => {
      let temp = { ...prev };
      temp.conversations[filteredConversationIdsMap[convoId]].unreadMessageCount = 0;
      return temp;
    });
    setConversationMessage(await getAConversation(convoId));
  };


  const downloadWithName = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
  };

  const openForthisUser = async () => {
    if (localStorage.getItem("openChat")) {
      let user = JSON.parse(localStorage.getItem("openChat"));
      try {
        user.avatar = { small: user.avatar };
      } catch (e) {}
      setActiveUserInfo(user);
      const convoIdObj = await getConversationId(user._id);

      if (convoIdObj && convoIdObj.conversationId) {
        setActiveConvo(convoIdObj.conversationId);
        const msgsTemp = await getAConversation(convoIdObj.conversationId);
        setConversationMessage(msgsTemp);
      }
      localStorage.removeItem("openChat");
    }
  };

  const initializeAll = async()=>{
    connectSocet();
    // selectUser(localStorage.getItem("userId"));
    const conv = await getAllConversations();
    if (conv) {
      setConversations(conv);
      openForthisUser();
    }
  }

  useEffect(()=>{
        setFiltredConversations(conversations);
    }, [conversations])

useEffect(()=>{       
     let convoIdsMap = {};
filteredConversations.conversations.map((data, index) => {
  convoIdsMap[data.conversationId] = index;
});

setFiltredConversationIdsMap(convoIdsMap);
}, [filteredConversations])
  

  useEffect(()=>{initializeAll()}, []);

  // Received message from server: {"createdAt":"2023-07-07T04:37:30.412Z",
  // "localId":""
  // "_id":"64a7968af5053b1f7ac0b092",
  // "messageType":"text",
  // "text":"yes",
  // "from":"647872448cc6099087b62ca7",
  // "conversationType":"private",
  // "conversationId":"64a7967ff5053b1f7ac0b08d",
  // "senderInfo":{"_id":"647872448cc6099087b62ca7","name":"Hari  Prasath"}}

  const scrollToElement = () => {
    lastMessageRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToElement();
  }, [latestMsg, conversationMessage]);

  useEffect(() => {
    clearNewMsg(null);
    setTextField("");
  }, [activeConvo]);

  const sendMsg = async () => {
    setIsUploading(true);
    let data = {
      conversationType: "private",
      messageType: "text", //"audio, video, image, file, gif, text"
      text: textFiled,
      localId: "",
    };

    if (activeFile) {
      let mimeType = activeFile.type;
      let name = activeFile.name;
      let extenstion = activeFile.name.split(".")[activeFile.name.split(".").length - 1];

      let messageType;
      if (activeFile.type.slice(0, 5) == "video") {
        messageType = "video";
      } else if (activeFile.type.slice(0, 5) == "image") {
        messageType = "image";
      } else messageType = "file";

      const urlResponse = await getPresignedUrl(messageType, mimeType, extenstion);
      const url = urlResponse.url;

      await putObjectInUrl(url, activeFile);

      data.messageType = messageType;
      data.mediaUrl = url;
      data.metadata = {
        name: activeFile.name,
        size: activeFile.size,
      };
    }

    data["toUser"] = activeUserInfo._id;

    await sendMessage(data);
    setTextField("");
    if (activeFile) clearNewMsg();
    setIsUploading(false);
  };

  useEffect(() => {
    if (attachmentRef) attachmentRef.current?.scrollIntoView();
  }, [activeFile]);

  useEffect(() => {
    const handleBackButton = (event) => {
      // Your custom back button handling logic goes here
      // For example, you can navigate to a specific route or perform an action
      // For demonstration purposes, let's just go back to the previous page
    //   window.history.back();
      
      // Prevent the default behavior of the browser back button
      event.preventDefault();
      setActiveConvo("")
    };
    // Add event listener for the browser back button
    window.addEventListener('popstate', handleBackButton);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []); // Empty dependency array ensures the effect runs only once


const searchOnConvoList=(input)=>{

    
    const tempFiltred = conversations.conversations.filter((convo)=>convo.userInfo.name.toLowerCase().search(input)!=-1)
    setFiltredConversations({conversations:tempFiltred})
    
}

const [Loading, setLoading] = useState(true);
useEffect(() => {
  // Simulating an asynchronous action
  const fakeAsyncAction = async () => {
    try {
      // Simulate an API call or any asynchronous action
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } finally {
      // Set isLoading to false once the asynchronous action is done
      setLoading(false);
    }
  };

  // Call the fakeAsyncAction when the component mounts
  fakeAsyncAction();
}, []); // Empty dependency array means this effect runs once on mount

// Render the loader if isLoading is true
if (Loading) {
  return <Loader />;
}
  return (
    <div className="w-full overflow-scroll no-scrollbar flex-col h-screen">
      <div className=" w-full h-full bg-white justify-start items-start gap-px grow self-stretch flex">
        {/* Left side - convo list */}

        <div className={`p-8 ${filteredConversations?.conversations?.length<=0 && 'min-w-[300px] md:w-max'} w-3/8 h-full  bg-white  flex-col justify-start items-start gap-7 inline border-r border-gray-100 ${activeUserInfo?'hidden':null} md:inline`}>
          <div className="self-stretch px-4 mb-4 py-3 bg-white rounded-xl border border-[#C9CFD4] justify-start items-center gap-4 flex w-full">
            <div className="w-6 h-6 pl-[2.78px] pr-[2.46px] pt-[2.78px] pb-0.5 justify-center items-center flex">
              <div className="w-[18.76px] h-[19.22px] relative">
                <svg
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Search">
                    <circle
                      id="Ellipse_739"
                      cx="10.7669"
                      cy="10.7664"
                      r="8.98856"
                      stroke="#99A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Line_181"
                      d="M17.0186 17.4849L20.5426 20.9997"
                      stroke="#99A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <input
            onChange={(e)=>searchOnConvoList(e.target.value?.toLowerCase())}
              type="text"
              placeholder="Search messages"
              className="text-gray-400 text-base font-normal  leading-relaxed border-none outline-none w-full"
            />
            {/* Search messages */}
            {/* </div> */}
          </div>
          <div className="flex-col flex-1 justify-center items-center flex">
            
            {filteredConversations?.conversations.map((convo, index) => (
              <div
                className={`py-4 w-2/8  justify-start items-center gap-4 inline-flex ${convo.conversationId == activeConvo && ' bg-emerald-500/10 text-white '} ${index != conversations.conversations.length - 1 &&"border-b border-gray-100"}  cursor-pointer`}
                ref={index==filteredConversations.conversations.length-1?lastMsgRef:null}
                onClick={()=>onConversationSelect(convo.conversationId)}
              >
                <div className="w-12 h-12 relative">
                  <div className="w-12 h-12 left-0 top-0 absolute bg-stone-300 rounded-full " />
                  <div className="w-[49px] h-[49px] left-0 top-0 absolute bg-violet-100 rounded-full" />
                  <img
                    className="w-12 h-12 left-0 top-0 absolute rounded-full"
                    src={getAvatarImageUrl(convo.userInfo).small}
                  />
                </div>
                <div className="flex-col justify-start items-start gap-1 inline-flex">
                  <div className="w-64 justify-between items-center inline-flex">
                    <div className="flex">
                    <div className="text-teal-950 text-base font-bold  leading-relaxed">
                      {convo.userInfo.name}
                    </div>
                    {convo.unreadMessageCount > 0 && <div className="inline mx-2 px-1 rounded-full bg-emerald-500 ">
                        {convo.unreadMessageCount}
                    </div>}
                    </div>
                    <div className="text-right text-slate-500 text-base font-normal  leading-relaxed">
                      { convoTime(convo.lastMessage.createdAt)}
                    </div>
                  </div>
                  <div className="w-64 h-6 text-slate-700 text-base font-normal  leading-relaxed">
                    {convo.lastMessage.text || convo.lastMessage.messageType}
                  </div>
                </div>
              </div>
            ))}
            {filteredConversations?.conversations.length==0 && <div className="font-[700] text-center py-8">No conversations</div>}
          </div>
        </div>
        <div className="min-w-[300px] md:w-full h-screen flex justify-center items-center">
        {activeConvo?
        
        (<>{/* Right side - message list */}
        <div className=" w-screen md:w-3/4 flex-col justify-start items-center flex h-screen">
          {/* Conversation header */}
          <div className="self-stretch px-8 pt-8 pb-6 bg-white  h-1/8 justify-between items-center inline-flex border-b border-gray-100 w-full">
            <div className="justify-center items-center gap-4 flex">
              <div className="w-14 h-14 relative">
                <img
                  className="w-14 h-14 left-0 top-[-7px] absolute rounded-full"
                  src={ getAvatarImageUrl(activeUserInfo).small}
                />
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-1 inline-flex">
                <div className="w-[123px] h-6 text-teal-950 text-[20px] font-[700]  leading-normal">
                  {activeUserInfo.name}
                </div>
                <div className="w-[235px] h-6 text-slate-700 text-[16px] font-[400]  leading-relaxed">
                  Recruiter
                </div>
              </div>
            </div>
            <div className="justify-start items-start gap-6 flex">
              <div className="w-6 h-6 relative opacity-50" />
            </div>
          </div>
        <div className="overflow-y-auto w-full h-6/8 relative" id="messageContiner">
          <div className="self-stretch h-[232px] p-8 bg-white flex-col justify-start items-center gap-2 flex">
            <div className="flex-col justify-start items-center gap-2 flex">
              <div className="w-[88px] h-[88px] relative">
                <img
                  className="w-[88px] h-[88px] left-0 top-0 absolute rounded-full"
                  src={ getAvatarImageUrl(activeUserInfo).small}
                />
              </div>
              <div className="text-center text-teal-950 text-[32px] font-[700]  leading-[38.40px]">
                {activeUserInfo.name}
              </div>
            </div>
            <div>
              <span className={"text-[#667984] text-[16px] font-[400]  "}>
                This is the very beginning of your direct message with
              </span>

              <span className={"text-teal-950   "}>{" "+activeUserInfo.name}</span>
            </div>
          </div>
          {/* date spliter */}
          <div className="self-stretch px-8 pb-4 justify-center items-center flex">
            <div className="grow shrink  border-b border-[#C9CFD4]"></div>
            {/* <div className="px-4 py-3 bg-white rounded-xl  shadow border border-[#C9CFD4] justify-center items-center gap-2 flex">
              <div className="text-teal-950 text-[16px] font-[400] leading-relaxed">
                Today
              </div>
            </div> */}
            <div className="grow shrink border-b border-[#C9CFD4]"></div>
          </div>
          <div className="w-3/4 items-center m-auto">
            
          </div>
          <div className="px-4 md:w-3/4 m-auto">
            {conversationMessage.messages.map((message, index)=>{
                return message.senderInfo._id != userId?
      
              (  <div className="" ref={conversationMessage.messages.length-1 ==index?lastMsgRef:null}>
                                  {/* Message sent */}
                <div className=" left-[241px] top-[200px]  justify-end items-start gap-4 flex ml-auto mr-0">
                  <div className="flex-col justify-center items-end gap-2 inline-flex">
                    <div className="w-[92px] h-6 text-right text-teal-950 text-[17px] font-[700]  leading-relaxed">
                      You{" "}
                    </div>
                    <div className=" w-full px-4 py-3 bg-gray-100 rounded-tl-lg rounded-bl-lg rounded-br-lg justify-start items-start gap-2.5 ">
                      <div className=" w-full text-slate-700 text-[16px] font-[500] leading-relaxed">
                        {message.text}
                      </div>
                      {(message?.messageType != "text" && message?.messageType != "image") && (
                                  // activeFile.type.slice(0, 5) != "video" &&
                                  <div className="flex w-full">
                                    <div className="py-2  rounded rounded-l-none px-2 ">
                                
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-10 h-10"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                        />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="py-1  rounded rounded-r-none px-2 ">
                                        {trimFileName(message.metadata.name)}
                                      </div>

                                      <div className="pb-2  rounded rounded-r-none px-2 ">
                                        {getFileSizeText(message.metadata.size)}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                

        {message?.messageType == "image" && (
        <div className="px-4 py-3   gap-2.5">
          <div className="text-slate-700 text-[16px] font-[500]  leading-relaxed">
        
                                    <img
                                      src={message?.mediaUrl}
                                      className="max-w-full max-h-[100px]"
                                    />
                                    
          </div>
        </div>)}  
                    </div>
                    
                    <div className="opacity-70 text-right text-slate-500 text-[16px] font-[500]  leading-relaxed">
                      {convoTime(message.createdAt)}
                    </div>
                  </div>
                  <div className="w-12 h-12 ">
                    <img
                      className="w-12 h-12 left-0 top-0  rounded-full"
                      src={ getAvatarImageUrl(message.senderInfo).small}
                    />
                  </div>
                </div>
                
                </div>)
    :(
    <div ref={conversationMessage.messages.length-1 ==index?lastMsgRef:null}>{/* Recived message */}
    <div className=" left-0 top-0  justify-start items-start gap-[15px] flex py-2">
      <div className="w-12 h-12 ">
        <img
          className="w-12 h-12 left-0 top-0  rounded-full"
          src={ getAvatarImageUrl(message.senderInfo).small }
        />
      </div>
      <div className="flex-col justify-start items-start gap-2 inline-flex">
        <div className="w-fit h-6 text-teal-950 text-[17px] font-[700] leading-relaxed">
          {message.senderInfo.name}
        </div>
        <div className="px-4 py-3 bg-white border border-[#C9CFD4] rounded-tr-lg rounded-bl-lg rounded-br-lg justify-start items-start gap-2.5 w-fit inline-block">
          <div className="w-fit text-[#334D5B] text-[16px] font-[500]  leading-relaxed">
            {message.text}
          </div>
          {(message?.messageType != "text" && message?.messageType != "image") && (
                                  // activeFile.type.slice(0, 5) != "video" &&
                                  <div className="flex">
                                    <div className="py-2  rounded rounded-l-none px-2 ">
                                
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-10 h-10"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                        />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="py-1  rounded rounded-r-none px-2  text">
                                        {trimFileName(message.metadata.name)}
                                      </div>

                                      <div className="pb-2  rounded rounded-r-none px-2 ">
                                        {getFileSizeText(message.metadata.size)}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                

        {message?.messageType == "image" && (
        <div className="px-4 py-3  gap-2.5 ml-0  inline-flex">
          <div className="text-slate-700 text-[16px] font-[500] inline-flex leading-relaxed">
        
                                    <img
                                      src={message?.mediaUrl}
                                      className="max-w-fit max-h-[100px]"
                                    />
                                    
          </div>
        </div>)}  

        </div>

        
        <div className="text-right text-slate-500 text-[16px] font-[500]   leading-relaxed">
          {convoTime(message.createdAt)}
        </div>
      </div>
    </div>
    </div>)
            })}
            
            {activeFile && (
                
                        <div className="w-full" ref={attachmentRef}>
                          <div className="flex flex-reverse mx-2 my-2 w-fit ml-auto mr-0 gap-2">
                            
                            <div className="flex flex-col ">
                            <div className="flex mx-2 gap-2">
                              {/* <div className="time text-gray-900 text-opacity-60 text-[14px] mr-2 ">
                            {moment().format("hh:mm A")}
                          </div> */}
                              <div className="name h-6 text-right text-teal-950 mr-0 ml-auto text-[17px] font-[700]  leading-relaxed">
                                You
                              </div>
                            </div>

                            <span className="Text text-teal-950 text-[14px] font-medium leading-tight bg-gray-100 ionline-block p-2 rounded-b-xl rounded-tr-xl relative">
                              <button
                                className="absolute mt-[-20px] ml-[-20px] bg-white p-1 px-2 cursor-pointer rounded-full text-gray-900 shadow"
                                onClick={clearNewMsg}
                              >
                                x
                              </button>
                              <div>
                                {" "}
                                {activeFile.type.slice(0, 5) != "image" && (
                                  // activeFile.type.slice(0, 5) != "video" &&
                                  <div className="flex">
                                    <div className="py-2  rounded rounded-l-none px-2 ">
                                
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-10 h-10"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                        />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="py-1  rounded rounded-r-none px-2  text">
                                        {trimFileName(activeFile.name)}
                                      </div>

                                      <div className="pb-2  rounded rounded-r-none px-2 ">
                                        {getFileSizeText(activeFile.size)}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {/* {(activeFile.type.slice(0, 5) == "image" ||
                              activeFile.type.slice(0, 5) == "video") && (
                              <div className="py-2">
                                {trimFileName(activeFile.name)}
                              </div>
                            )} */}
                                {/* <div>{activeFile.type}</div> */}
                                {activeFile.type.slice(0, 5) == "image" && (
                                  <img
                                    src={URL.createObjectURL(activeFile)}
                                    className="max-w-sm max-h-sm"
                                  />
                                )}
                            
                              </div>
                              <div className="py-2 px-1">{textFiled}</div>
                            </span>
                            </div>

                            <div className="">
                            <div className="w-12 h-12 ">
        <img
          className="w-12 h-12 left-0 top-0  rounded-full"
          src={getAvatarImageUrl({avatar:null, name:"You"}).small}
        />
      </div>
                            </div>

                          </div>
                        </div>
                      )}
            
          </div>
          </div>
          {/* Input */}
          <div className=" w-full  md:w-[687px] h-1/8 items-center px-4 left-[1px] top-[361px] min-h-[50px] border border-[#C9CFD4] flex rounded-tl-lg rounded-bl-lg rounded-tr-lg rounded-br-lg mb-3 mx-4">
              <div className="w-1/8">
                <label onClick={()=>{fileBUttonRef.current.click()}} className="cursor-pointer">
                <svg
                  className="w-6 h-6 relative opacity-50"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Icon" opacity="0.5" clip-path="url(#clip0_6860_4105)">
                    <path
                      id="Vector"
                      d="M14.9997 7.00045L8.4997 13.5005C8.10188 13.8983 7.87838 14.4378 7.87838 15.0005C7.87838 15.5631 8.10188 16.1026 8.4997 16.5005C8.89753 16.8983 9.43709 17.1218 9.9997 17.1218C10.5623 17.1218 11.1019 16.8983 11.4997 16.5005L17.9997 10.0005C18.7954 9.2048 19.2423 8.12567 19.2423 7.00045C19.2423 5.87523 18.7954 4.7961 17.9997 4.00045C17.2041 3.2048 16.1249 2.75781 14.9997 2.75781C13.8745 2.75781 12.7954 3.2048 11.9997 4.00045L5.4997 10.5005C4.30623 11.6939 3.63574 13.3126 3.63574 15.0005C3.63574 16.6883 4.30623 18.307 5.4997 19.5005C6.69318 20.6939 8.31188 21.3644 9.9997 21.3644C11.6875 21.3644 13.3062 20.6939 14.4997 19.5005L20.9997 13.0005"
                      stroke="#002032"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6860_4105">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                </label>
                <input
                            type="file"
                            name="file"
                            id="file"
                            onChange={(e) => {
                              setActiveFile(e.target?.files[0]);
                            }}
                            className="hidden"
                            hidden
                            ref={fileBUttonRef}
                    />
              </div>

              <textarea value={textFiled} className="grow px-4  text-slate-500 text-base font-normal  my-2 outline-none border-none"  onChange={(e)=>textInput(e.target.value)} rows={textInputSize}/>
                
              
              <div className="w-1/8 h-10 left-[566px] top-[8px]  justify-start items-center gap-4 inline-flex">
                {/* <div className="w-6 h-6 relative opacity-50">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5" clip-path="url(#clip0_6860_4093)">
                      <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="#002032"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 10H9.01"
                        stroke="#002032"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 10H15.01"
                        stroke="#002032"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 15C9.82588 15.3326 10.2148 15.5968 10.6441 15.7772C11.0734 15.9576 11.5344 16.0505 12 16.0505C12.4656 16.0505 12.9266 15.9576 13.3559 15.7772C13.7852 15.5968 14.1741 15.3326 14.5 15"
                        stroke="#002032"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6860_4093">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div> */}
                <button className="h-10 p-2.5 bg-emerald-500 rounded-[10px] justify-center items-center gap-2.5 flex cursor-pointer" onClick={sendMsg} disabled={isUploading}>
                  <div className="w-5 h-5 ">
                    {/* <div className="origin-top-left rotate-45 w-[15.27px] h-[15.27px] left-[7.37px] top-[-0.66px] absolute"></div> */}
                    {isUploading ?<Loader />:(<svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Group 1017">
                        <path
                          id="Vector"
                          d="M5.69751 10.1335L10.4992 10.0002"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          id="Vector_2"
                          d="M18.6619 10.1336L4.22512 16.91C4.14286 16.9406 4.05354 16.9469 3.9678 16.9282C3.88205 16.9094 3.80348 16.8665 3.74142 16.8045C3.67936 16.7424 3.63643 16.6638 3.61772 16.5781C3.59901 16.4923 3.60531 16.403 3.63587 16.3208L5.69826 10.1336L3.63587 3.94638C3.60531 3.86411 3.59901 3.7748 3.61772 3.68905C3.63643 3.60331 3.67936 3.52474 3.74142 3.46268C3.80348 3.40062 3.88205 3.35769 3.9678 3.33898C4.05354 3.32026 4.14286 3.32656 4.22512 3.35713L18.6619 10.1336Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>)}
                  </div>
                </button>
              </div>
            </div>
        
        </div></>):(<><div className="flex flex-col justify-center items-center h-screen w-full">
        <svg
                      width="100"
                      height="100"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto"
                    >
                      <g id="Input Field Icons / ChatTeardropText">
                        <path
                          id="Vector"
                          d="M10.3125 1.875C8.24119 1.87727 6.25538 2.70111 4.79074 4.16574C3.32611 5.63038 2.50227 7.61619 2.5 9.6875V16.2758C2.50041 16.6003 2.62953 16.9115 2.85902 17.141C3.08852 17.3705 3.39966 17.4996 3.72422 17.5H10.3125C12.3845 17.5 14.3716 16.6769 15.8368 15.2118C17.3019 13.7466 18.125 11.7595 18.125 9.6875C18.125 7.6155 17.3019 5.62836 15.8368 4.16323C14.3716 2.6981 12.3845 1.875 10.3125 1.875ZM12.5 11.875H7.5C7.33424 11.875 7.17527 11.8092 7.05806 11.6919C6.94085 11.5747 6.875 11.4158 6.875 11.25C6.875 11.0842 6.94085 10.9253 7.05806 10.8081C7.17527 10.6908 7.33424 10.625 7.5 10.625H12.5C12.6658 10.625 12.8247 10.6908 12.9419 10.8081C13.0592 10.9253 13.125 11.0842 13.125 11.25C13.125 11.4158 13.0592 11.5747 12.9419 11.6919C12.8247 11.8092 12.6658 11.875 12.5 11.875ZM12.5 9.375H7.5C7.33424 9.375 7.17527 9.30915 7.05806 9.19194C6.94085 9.07473 6.875 8.91576 6.875 8.75C6.875 8.58424 6.94085 8.42527 7.05806 8.30806C7.17527 8.19085 7.33424 8.125 7.5 8.125H12.5C12.6658 8.125 12.8247 8.19085 12.9419 8.30806C13.0592 8.42527 13.125 8.58424 13.125 8.75C13.125 8.91576 13.0592 9.07473 12.9419 9.19194C12.8247 9.30915 12.6658 9.375 12.5 9.375Z"
                          fill="#002032"
                          fill-opacity="0.4"
                        />
                      </g>
                    </svg>
            <div className="w-max text-center font-[700]">
                Select Conversation
                </div>
            </div></>)}
        </div>
      </div>
    </div>
  );
};

export default Chat;
