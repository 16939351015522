import React, { Suspense, useState } from "react";
import { Navbar } from "./navbar/navbar";
import { Link } from "react-router-dom";
import YoutubeVideo from "../programs/components/AllPrograms/youtubeSharkTank";


export const AboutUs = () => {
  return (
    <>
      <div className="min-w-full sx:w-auto sm:w-full">
        <div className="text-xs min-w-full sx:w-full sm:w-full overflow-auto no-scrollbar">
          {/* HEADING */}
          <Navbar textColor={'light'} />
          {/* body.................. */}
          <div className="w-full h-auto bg-white rounded-xl flex-col justify-start items-end inline-flex">
            <div className="w-full self-stretch h-auto flex-col justify-start items-start gap-2.5 flex">
              <div className="w-full h-auto relative">
                <img src="attachment.png" alt="background img" loading="lazy" />
              </div>
            </div>
            <div className="w-full self-stretch h-auto px-8 md:px-16 py-8 md:py-16 lg:py-[120px] flex-col justify-start items-start gap-2.5 flex">
              <div className="w-full flex-col justify-start items-center gap-[37px] flex">
                <div className="text-teal-950 text-xl sm:text-4xl md:text-5xl font-extrabold font-satoshi">
                  The Sharks love Beep!
                </div>
                <div className="w-full mx-auto mt-4">
                <Suspense fallback={<div>Loading...</div>}>
                    <YoutubeVideo videoId="_nYVSo8Tg7o" thumbnailUrl="attachment2.png" />
                  </Suspense>
                </div>
              </div>
            </div>
            <div className="w-full h-auto justify-center items-center inline-flex">
              <div className="w-full h-auto py-16 inline-flex flex-col gap-4 sm:gap-0 sm:flex-row justify-between items-center px-4 md:px-16 xl:px-64 bg-orange-200">
                <div className=" text-center">
                  <span className="text-teal-950 text-5xl font-bold font-satoshi leading-normal">
                    450K+
                    <br />
                  </span>
                  <span className="text-teal-950 text-[27px] font-bold font-satoshi leading-normal">
                    Students
                  </span>
                </div>
                <div className=" text-center">
                  <span className="text-teal-950 text-5xl font-bold font-satoshi leading-normal">
                    1000+
                    <br />
                  </span>
                  <span className="text-teal-950 text-[27px] font-bold font-satoshi leading-normal">
                    Students hired
                  </span>
                </div>
                <div className=" text-center">
                  <span className="text-teal-950 text-5xl font-bold font-satoshi leading-normal">
                    10K+
                    <br />
                  </span>
                  <span className="text-teal-950 text-[27px] font-bold font-satoshi leading-normal">
                    Opportunities
                  </span>
                </div>
                <div className="text-center">
                  <span className="text-teal-950 text-5xl font-bold font-satoshi leading-normal">
                    1000+
                    <br />
                  </span>
                  <span className="text-teal-950 text-[27px] font-bold font-satoshi leading-normal">
                    Companies
                  </span>
                </div>
              </div>
            </div>
            <div className="mx-auto w-full py-52 justify-center items-center inline-flex">
              <div className="w-full self-stretch flex-col justify-center items-center gap-[54px] inline-flex">
                <div className="flex-col justify-start items-center gap-6 flex">
                  <div className="text-center text-teal-950 text-5xl font-bold font-satoshi">
                    Supported by{" "}
                  </div>
                  <div className="max-w-[848px] text-center text-slate-500 text-2xl font-semibold font-satoshi">
                    Beep is supported by institutions that are aligned with our
                    mission, from leaders in education to top recruiting firms.
                  </div>
                </div>
            
                 <div className="w-full h-auto p-4 md:px-16 lg:px-[124px] py-8  md:py-12 bg-white flex-col justify-start items-start gap-8 inline-flex">

      <div className="w-full self-stretch justify-between flex-col gap-8 items-start inline-flex overflow-scroll no-scrollbar">
        <div className="w-full flex flex-row justify-between gap-8 items-center">
       
        <div className="w-[79.46px] h-[87px] flex items-center"><img
          
          src="Bhau College.png"
        /></div>
        <div className="w-[79.46px] h-[87px] flex items-center scale-75"><img
          
          src="image4.png"
        /></div>
        <div className="w-[79.46px] h-[87px] flex items-center"><img
          
          src="image1.png"
        /></div>
        <div className="w-[79.46px] h-[87px] flex items-center"> <img
          
          src="Microsoft for startups.png"
        /></div>
        <div className="w-[79.46px] h-[87px] flex items-center"> <img
          
          src="appscale_academy.png"
        /></div>
       <div className="w-[79.46px] h-[87px] flex items-center">  <img 
         
         src="image2.png" 
         /></div>
</div>
<div className="w-full flex flex-row  gap-8 items-center justify-between">
          
          <div className="w-[79.46px] h-[87px] flex items-center"><img
          className=""
          src="ten_thousand_startups.png"
        /></div>
          
          <div className="w-[79.46px] h-[87px] flex items-center"><img
          className=""
          src="secseed_indovation.png"
        /></div>
          
          <div className="w-[79.46px] h-[87px] flex items-center"><img
          className=""
          src="posiview_consulting_partners.png"
        /></div>
          
          <div className="w-[79.46px] h-[87px] flex items-center"><img
          className=""
          src="ULincept.png"
        /></div>
          
          <div className="w-[79.46px] h-[87px] flex items-center"><img
          className=""
          src="iwin.png"
        />  </div>
        <div className="w-[79.46px] h-[87px] flex items-center"><img
        className=""
        src="headstart.png"
      />  </div>
      <div className="w-[79.46px] h-[87px] flex items-center"><img
      className=""
      src="startup_leadership_program.png"
    /></div>
    </div>
      </div>
    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
