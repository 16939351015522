import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../../../../components/loader/loader";
import InputFieldComp from "../../../../components/input_fields/inputFieldComp";
import { courseByIdWithoutToken } from "../../../../services/courses";
import SingleSelectComp from "../../../../components/select_fields/singleSelectComp";
import { Helmet } from "react-helmet-async";

export const UserDetailFormNLU = () => {
  const navigate = useNavigate();
  const [courseDetails, setCourseDetails] = useState("");
  const location = useLocation();
  const [missing, setMissing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedQual, setSelectedQual] = useState("");
  const [form, setForm] = useState({
    fname: "",
    email: "",
    number: "",
    qualification: "",
  });

  const onSetFormData = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChange = (e) => {
    setSelectedQual(e.target.value);
    setForm({
      ...form,
      qualification: e.target.value,
    });
  };

  const onSubmit = async () => {
    try {
      const missingField = {};
      if (!form.fname) missingField["fname"] = true;
      if (!form.email && form.email === "") missingField["email"] = true;
      if (!form.number && form.number === "") missingField["number"] = true;
      if (!form.qualification && form.qualification === "")
        missingField["qualification"] = true;

      setMissing(missingField);

      if (Object.keys(missingField).length === 0) {
        navigate(
          `/register/payment?id=${courseDetails?._id}&fname=${form?.fname}&email=${form?.email}&number=${form?.number}&qualification=${form?.qualification}`,
        );
      }
    } catch (error) {
      console.error("Error updating profile data:", error);
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get("id");

    const fetchCourseDetails = async () => {
      try {
        const response = await courseByIdWithoutToken(courseId);
        console.log(response?.data);
        setCourseDetails(response?.data);
      } catch (error) {
        console.error("Error fetching course details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (courseId) {
      fetchCourseDetails();
    }
  }, []);

  if (isLoading) {
    return <Loader />; // Show loader while loading
  }

  return (
    <>
      <Helmet>
        <title>Registration: Enter Details</title>
      </Helmet>

      <div className="min-w-screen max-w-full min-h-screen max-h-full px-2 sx:px-4 md:px-8 lg:p-[52px] flex flex-col lg:flex-row justify-center items-center bg-gradient-to-br from-black to-[#000000] ">
        <div className="w-full lg:w-[50%] h-auto flex-col justify-center items-start gap-6 inline-flex">
          <div className="hidden md:flex w-[50px] h-[50px] absolute top-12 rounded-xl">
            <Link to="/programs">
              <img src="/svg/whiteBeepLogo.svg" />
            </Link>
          </div>
          <div className="md:hidden w-[50px] h-[50px] rounded-xl">
            <Link to="/programs">
              <img src="/svg/whiteBeepLogo.svg" />
            </Link>
          </div>

          <div className="w-full md:mt-[200px] lg:mt-0 h-auto text-white opacity-80 text-[40px] font-bold font-satoshi flex flex-wrap">
            {courseDetails?.name}
          </div>
          <div className="w-full text-start text-slate-500 text-xl font-bold font-satoshi leading-normal flex flex-wrap">
            {courseDetails?.description}
          </div>
        </div>
        <div className="w-full sm:w-[50%] px-8 sm:px-0 h-auto my-8 h-auto flex justify-end">
          <div className="w-full sm:w-[532px] h-auto px-4 sm:px-8 md:px-[62px] py-[62px] bg-white rounded-lg flex-col justify-between items-center gap-8 inline-flex overflow-scroll no-scrollbar">
            <>
              <div className="w-full text-teal-950 text-[18px] sx:text-xl font-black font-satoshi leading-normal">
                Enter your details to register
              </div>
              <div className="w-full flex flex-col gap-2">
                <div className="w-full">
                  <InputFieldComp
                    className="w-full"
                    type="text"
                    labelText="Full Name"
                    placeholder="Enter Your Name"
                    hasError={missing.fname}
                    errorMsg={missing.fname ? "Full name is required." : ""}
                    name="fname"
                    value={form.fname}
                    onChange={onSetFormData}
                  />
                </div>
                <div className="w-full">
                  <InputFieldComp
                    className="w-full"
                    type="text"
                    labelText="Email"
                    placeholder="Enter Your Email"
                    hasError={missing.email}
                    errorMsg={missing.email ? "Email is required." : ""}
                    name="email"
                    value={form.email}
                    onChange={onSetFormData}
                  />
                </div>
                <div className="w-full">
                  <InputFieldComp
                    className="w-full"
                    type="text"
                    labelText="Mobile Number"
                    placeholder="Enter Your Mobile Number"
                    hasError={missing.number}
                    errorMsg={missing.number ? "Number is required." : ""}
                    name="number"
                    value={form.number}
                    onChange={onSetFormData}
                  />
                </div>

                <div className="w-full mt-4">
                  <SingleSelectComp
                    className="w-full"
                    options={[
                      "Completed Post Graduation",
                      "Passed Graduation",
                      "In graduation",
                      "class 12th or below",
                    ]}
                    labelText="Highest Qualification"
                    absoluteClass="w-[10rem]"
                    hasError={missing.qualification}
                    errorMsg={
                      missing.qualification ? "Qualification is required." : ""
                    }
                    selectedOption={selectedQual}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div
                onClick={onSubmit}
                className="self-stretch h-[50px] flex-col justify-end items-center gap-[22px] flex cursor-pointer"
              >
                <div className="self-stretch px-6 py-3 bg-[#002032] hover:shadow-md hover:border hover:border-gray-300 hover:opacity-95 rounded-xl justify-center items-center gap-2.5 inline-flex ">
                  <div className="text-center text-white text-base font-bold font-satoshi leading-relaxed">
                    Register
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
