import React, { useState, useEffect } from "react";

export const TextCheckDropDown = ({ parentText, onSelect, isSelectedOptions, children }) => {

  const [isOpen, setIsOpen] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);



  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionToggle = (value) => {
    const isSelected = selectedOptions.includes(value);
    let newSelectedOptions;

    if (isSelected) {
      newSelectedOptions = selectedOptions.filter((item) => item !== value);
    } else {
      newSelectedOptions = [...selectedOptions, value];
    }

    setSelectedOptions(newSelectedOptions);

    if (onSelect) {
      onSelect(newSelectedOptions);
    }
  };
  useEffect(() => {
    if (isSelectedOptions) {
      // Ensure that isSelectedOptions is an array
      const selectedOptionsArray = Array.isArray(isSelectedOptions) ? isSelectedOptions : [isSelectedOptions];
      setSelectedOptions(selectedOptionsArray);
    }
  }, [isSelectedOptions]);

  return (
    <div className="w-full self-stretch h-auto flex-col justify-start items-start gap-4 flex">
      <div onClick={handleToggleDropdown} className="w-full self-stretch justify-between items-center inline-flex gap-4 cursor-pointer">
        <div className="w-max text-teal-950 text-base font-bold font-satoshi leading-normal">
          {parentText}
        </div>
        <div className={`${isOpen ? "" : "rotate-180"}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
            <path d="M1.16634 6.91675L6.99967 1.08341L12.833 6.91675" stroke="#002032" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
      {isOpen && (
        <div className="flex flex-col gap-4">
          {React.Children.map(children, (child, index) => (
            <label key={index} className="inline-flex cursor-pointer gap-4">
              <input
                type="checkbox"
                className="w-5 h-5 border-none rounded-sm border-2 focus:border-emerald-500 accent-emerald-500 text-white"
                value={child.props.value} // Send the value to the state
                checked={selectedOptions.includes(child.props.value)}
                onChange={(e) => handleOptionToggle(e.target.value)}
              />
              {child}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};
