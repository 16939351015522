import React, { useEffect, useRef, useState} from "react";
import { Helmet } from 'react-helmet-async';
import { Footer } from "./footer/footer";
import { Navbar } from "./navbar/navbar";
import { Banner1 } from "./mainPage/banners/banner1";
import { Banner4 } from "./mainPage/banners/banner4";
import { InternOpportunity } from "./mainPage/internOpportunity";
import { Banner3 } from "./mainPage/banners/banner3";
import { BackedBy } from "./mainPage/backedBy";
import { ExploreByCateg } from "./mainPage/exploreByCateg";
import { FeaturedOpportunity } from "./mainPage/featuredOpportunity";
import { Banner2 } from "./mainPage/banners/banner2";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";

export const LandingPage = () => {
  const auth = localStorage.getItem("auth");
  const navigate = useNavigate();
  const topRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const redirect = () => {
    if (auth) {
      navigate("/internships");
    } else {
      navigate("/internships");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};

  useEffect(() => {
    // Simulating data loading with a setTimeout
    const timeout = setTimeout(() => {
      setLoading(false);
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }, 1000); // Adjust the timeout as needed

    // Cleanup function to clear timeout
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
    <Helmet>
      <title>Beep Careers: College Internships & Fresher Jobs</title>
    </Helmet>
    <div ref={topRef} className="w-screen h-screen overflow-scrollbar no-scrollbar">
      {loading ? (
        // Loader component while loading
        <div className="flex justify-center items-center h-full">
          <Loader/>
        </div>
      ) : (
      <>
      <Navbar 
      // textColor={activeSection === "jobDescription" ? "" : "textBlack"}
      />
      
      <div>
        <Banner1 
        
        />
        <ExploreByCateg redirect={redirect} />
        <Banner2 />
        <FeaturedOpportunity redirect={redirect} />

        <Banner3 />
        <InternOpportunity redirect={redirect} />
        <div className="hidden lg:flex">
          <Banner4 redirect={redirect} />
        </div>
        <BackedBy />
        
      </div>
      <Footer scrollToTop={scrollToTop}  redirect={redirect}/>
      </>
      )}
    </div>
    </>
  );
};
