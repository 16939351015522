// import React from "react";

// const YoutubeVideo = ({ videoId, thumbnailUrl }) => {
//   const handleClick = () => {
//     window.open(`https://www.youtube.com/watch?v=${videoId}`, "_blank");
//   };

//   return (
//     <div className="relative">
//       <img src={thumbnailUrl} alt="Video Thumbnail" className="w-full h-auto" />
//       <button
//         onClick={handleClick}
//         className="absolute inset-0 flex items-center justify-center w-full h-full  text-white font-bold text-lg hover:bg-opacity-75 focus:bg-opacity-75"
//       >
//      <svg xmlns="http://www.w3.org/2000/svg" width="101" height="102" viewBox="0 0 101 102" fill="none">
//   <ellipse cx="50.2049" cy="50.9497" rx="50.2049" ry="50.9497" fill="#D9D9D9" fill-opacity="0.8"/>
//   <path d="M73.9719 43.3777C79.7575 46.7676 79.7575 55.131 73.9719 58.5209L45.074 75.4525C39.2239 78.8802 31.8622 74.6613 31.8622 67.881V34.0176C31.8622 27.2372 39.2239 23.0183 45.074 26.446L73.9719 43.3777Z" fill="#1B1A1A" fill-opacity="0.5"/>
// </svg>
//       </button>
//     </div>
//   );
// };

// export default YoutubeVideo;



import React, { useState } from "react";
import { Mixpanel } from "../../../../services/mixpanel";
import { browserName, isMobileOnly } from "react-device-detect";
import moment from "moment";

const YoutubeVideo = ({ videoId, thumbnailUrl, userData }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => {
    Mixpanel.track("sharktank_video played", {
      date: moment().format(),
      distinct_id: localStorage.getItem("userId"),
      user_id: localStorage.getItem("userId"),
      browser: browserName,
      mobile_device: isMobileOnly,
      type:"webapp",
    });
    setIsPlaying(true);
  };

  return (
    <div className="bg-white relative px-4 sm:px-8 lg:px-16 py-[70px] xl1:px-[124px]">
      {!isPlaying ? (
        <>
          <img src={thumbnailUrl} alt="Video Thumbnail" className="w-full h-auto" />
          <button
            onClick={handleClick}
            className="absolute inset-0 flex items-center justify-center w-full h-full text-white font-bold text-lg hover:bg-opacity-75 focus:bg-opacity-75"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="101" height="102" viewBox="0 0 101 102" fill="none">
              <ellipse cx="50.2049" cy="50.9497" rx="50.2049" ry="50.9497" fill="#D9D9D9" fill-opacity="0.8" />
              <path d="M73.9719 43.3777C79.7575 46.7676 79.7575 55.131 73.9719 58.5209L45.074 75.4525C39.2239 78.8802 31.8622 74.6613 31.8622 67.881V34.0176C31.8622 27.2372 39.2239 23.0183 45.074 26.446L73.9719 43.3777Z" fill="#1B1A1A" fill-opacity="0.5" />
            </svg>
          </button>
        </>
      ) : (
        <iframe
          width="100%"
          height="500"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video player"
        ></iframe>
      )}
    </div>
  );
};

export default YoutubeVideo;
