import axios from "axios";
import moment from "moment";

// All jobs with filter option
export const fetchJobs = async ({
  type,
  profile,
  location,
  skills,
  workplace_type,
  categories,
  jobLevel,
  salaryRange,
  skip,
  limit,
}) => {
  const url = `${process.env.REACT_APP_baseURL}/user/fetch_jobs`;

  // Build the request payload dynamically
  const requestBody = {
    ...(type && { type }),
    ...(profile && { profile }),
    ...(location && Array.isArray(location) ? { location: location } : location && { location: [location] }),
    ...(skills && { skills }),
    ...(workplace_type && { workplace_type }),
    ...(categories && { categories }),
    ...(jobLevel && { jobLevel }),
    ...(salaryRange && { salaryRange }),
    skip,
    limit,
};


  const response = await axios.post(url, requestBody).catch((error) => {
    console.log(error.response?.data);
  });

  if (response) {
    console.log(response, "******************************");
    return response;
  }
};


export const findJobs = async (filters) => {
  try {
    const response = await axios.post('/user/open/find_jobs', filters);
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error; // Re-throw the error for handling in the caller function
  }
};
//   Categories job list
export const categoriesWithJobCount = async () => {
  const url = `${process.env.REACT_APP_baseURL}/user/categories/jobs`;
  const response = await axios.get(url).catch((error) => {
    console.log(error.response?.data);
  });

  if (response) {
    console.log(response, "******************************");
    return response;
  }
};



export const jobDescbyId = async (jobId) => {
  const url = `${process.env.REACT_APP_baseURL}/user/open/job/${jobId}`;
  const response = await axios.get(url).catch((error) => {
    console.log(error.response?.data);
  });

  if (response) {
    console.log(response, "******************************");
    return response;
  }
};

export const jobDescbyIdLoggedInUser = async (jobId) => {
  const url = `${process.env.REACT_APP_baseURL}/user/open/job/${jobId}`;
  console.log(url);
  const response = await axios.get(url, {headers: {
    Authorization: "Bearer " + localStorage.getItem("auth"),
  },}).catch((error) => {
    console.log(error.response?.data);
  });

  if (response) {
    console.log(response, "******************************");
    return response;
  }
};

export const canUserApply = (jobData)=>{

  if(!jobData)return{
    canApply: false,
    reason: "Invalid Job"
  }

  const {status, applied, job_date_of_closing} = jobData;

  if(status !=1) return {
    canApply: false,
    reason: "Currently not accepting applications"
  }

  if(applied) return {
    canApply: false,
    reason: "User already applied"
  }

  if(job_date_of_closing && moment().isAfter(moment(job_date_of_closing)))return {
    canApply: false,
    reason: "Closed"
  }
console.log({status, applied, job_date_of_closing}, 'momnet', moment(new Date(job_date_of_closing),).diff(moment(), 'days'))
  return {
    canApply: true,
    reason: job_date_of_closing ? moment(new Date(job_date_of_closing),).diff(moment(), 'days'): ""
  }
}