import React from "react";
import { JoinWaitlistFillDetails } from "./components/JoinWaitlistFlow/joinWaitlistFillDetails";
import { Helmet } from "react-helmet-async";

export const FillDetailsforWaiList = () => {
  return (
    <>
      <Helmet>
        <title>Join Waitlist: Fill details</title>
      </Helmet>
      <div className="w-full h-auto">
        <JoinWaitlistFillDetails />
      </div>
    </>
  );
};
