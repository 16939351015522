import React, { useEffect, useState } from "react";
import { courseById, courseByIdWithoutToken } from "../../../../services/courses";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Mixpanel } from "../../../../services/mixpanel";
import moment from "moment";
import { browserName, isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet-async";

export const PaymentFailure = () => {
const location = useLocation();
const [courseDetails, setCourseDetails] = useState({});
const navigate = useNavigate();

const queryParams = new URLSearchParams(location.search);
const fname = queryParams.get('fname');
const number = queryParams.get('number');
const email = queryParams.get('email');
const position = queryParams.get('qualification');
const error = queryParams.get('error');
console.log(fname,number,email,position)
    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search);
        const courseId = queryParams.get('id');
        const auth = localStorage.getItem('auth');

        const fetchCourseDetails = async () => {
            try {
   let response;
   if (auth){
    response = await courseById(courseId);
   } else {
    response = await courseByIdWithoutToken(courseId);
   }
        console.log(response?.data)
        setCourseDetails(response?.data);
            } catch (error) {
                console.error("Error fetching course details:", error);
            }
        };
    
        
        if (courseId) {
            fetchCourseDetails();
        }
    

    },[])

    useEffect(() => {
        // Check if the session flag is set
        const isRedirected = sessionStorage.getItem('isRedirected');
    
        try {
         const elements = document.getElementsByClassName("razorpay-container");
   
         elements[0].parentNode.removeChild(elements[0]);
        } catch (error) {
         
        }
        // If the flag is not set, redirect to another page
        // if (!isRedirected) {
        // navigate(`/register/payment?id=${courseDetails?._id}`);// You can redirect to any other page
        // }
        if(sessionStorage.getItem("failureRefreshPending") == true){
         sessionStorage.removeItem('failureRefreshPending');
         window.location.reload();
        } else {
         sessionStorage.removeItem('isRedirected');
        
        }
        // Clean up by removing the session flag
        
      }, []); 
 
  return (
    <>
    <Helmet>
      <title>
        Payment Failed
      </title>
    </Helmet>
  <div className="min-w-screen max-w-full min-h-screen max-h-full px-2 sx:px-4 md:px-8 lg:p-[52px] flex flex-col lg:flex-row justify-center items-center bg-gradient-to-br from-black to-[#000000] ">
       
       <div className="w-full lg:w-[50%] h-auto flex-col justify-center items-start gap-6 inline-flex">
      
      <div className="hidden md:flex w-[50px] h-[50px] absolute top-12 rounded-xl">
        <Link to="/programs">
     <img src="/svg/whiteBeepLogo.svg"/>
     </Link>
      </div>
      <div className="md:hidden w-[50px] h-[50px] rounded-xl">
      <Link to="/programs">
     <img src="/svg/whiteBeepLogo.svg"/>
     </Link>
      </div>
    
        <div className="w-full md:mt-[200px] lg:mt-0 h-auto text-white opacity-80 text-[40px] font-bold font-satoshi flex flex-wrap">
          {courseDetails?.name}
        </div>
        <div className="w-full text-start text-slate-500 text-xl font-bold font-satoshi leading-normal flex flex-wrap">
          {courseDetails?.description}
        </div>
      </div>
  <div className="w-[50%] h-auto my-8 h-auto flex justify-end">
  <div className="w-[532px] h-auto px-4 sm:px-8 md:px-[62px] py-[62px] bg-white rounded-lg flex-col justify-between items-center gap-8 inline-flex overflow-scroll no-scrollbar">

  <Link to={ error === "ALREADY_PAID" ? `/programs` : `/register/payment?id=${courseDetails?._id}&fname=${fname}&email=${email}&number=${number}&qualification=${position}`} className="w-full flex justify-start">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z" fill="#667984"/>
</svg>
</Link>
 
    <div className="w-[408px] h-auto py-4 justify-between items-center flex flex-col">
            <div className="h-auto">
            <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35 70C54.3288 70 70 54.3288 70 35C70 15.6712 54.3288 0 35 0C15.6712 0 0 15.6712 0 35C0 54.3288 15.6712 70 35 70ZM39.375 39.375C39.375 40.5353 38.9141 41.6481 38.0936 42.4686C37.2731 43.2891 36.1603 43.75 35 43.75C33.8397 43.75 32.7269 43.2891 31.9064 42.4686C31.0859 41.6481 30.625 40.5353 30.625 39.375V17.5C30.625 16.3397 31.0859 15.2269 31.9064 14.4064C32.7269 13.5859 33.8397 13.125 35 13.125C36.1603 13.125 37.2731 13.5859 38.0936 14.4064C38.9141 15.2269 39.375 16.3397 39.375 17.5V39.375ZM35 48.055C35.5747 48.0551 36.1437 48.1685 36.6746 48.3885C37.2055 48.6086 37.6878 48.931 38.094 49.3375C38.5003 49.744 38.8225 50.2265 39.0423 50.7574C39.2621 51.2884 39.3751 51.8575 39.375 52.4322C39.3749 53.0069 39.2615 53.5759 39.0415 54.1068C38.8214 54.6376 38.499 55.12 38.0925 55.5262C37.686 55.9325 37.2035 56.2547 36.6726 56.4745C36.1416 56.6943 35.5725 56.8073 34.9978 56.8072C33.8372 56.8069 32.7242 56.3456 31.9038 55.5247C31.0833 54.7038 30.6225 53.5906 30.6228 52.43C30.6231 51.2694 31.0844 50.1564 31.9053 49.336C32.7262 48.5155 33.8394 48.0547 35 48.055Z" fill="#FFB941"/>
</svg>

            </div>
            <div className="w-[302px] grow shrink basis-0 flex-col justify-start items-center gap-6 flex">
            {error === "ALREADY_PAID" ?
            <div className="flex-col justify-start items-center gap-6 flex">
            <div className="text-slate-700 text-base font-bold font-satoshi">Already Enrolled Course</div>
        </div>
        :
        <div className="flex-col justify-start items-center gap-6 flex">
            <div className="text-slate-700 text-base font-bold font-satoshi">Your payment failed</div>
        </div>
}
        {error === "ALREADY_PAID" ?
        <div className="w-[302px] text-center text-teal-950 text-xl font-black font-satoshi">You have already enrolled for this course, please enroll for other courses.</div>
:
<div className="w-[302px] text-center text-teal-950 text-xl font-black font-satoshi">Try again. You are one step away from growth! 🚀</div>
  
        }
         </div>
    </div>
   
    <div
    onClick={()=>{
      if(error === "ALREADY_PAID"){
        navigate('/programs')
      }else {
        Mixpanel.track("payment_tryAgain CTA clicked", {
          date: moment().format(),
          distinct_id: localStorage.getItem("userId"),
          user_id: localStorage.getItem("userId"),
          course_Id:courseDetails?._id,
          browser: browserName,
          mobile_device: isMobileOnly,
          type:"webapp",
        });
        navigate(`/register/payment?id=${courseDetails?._id}&fname=${fname}&email=${email}&number=${number}&qualification=${position}`)
      }
        
    }}
  className="self-stretch h-[50px] flex-col justify-start items-start gap-[22px] flex">
        <div className="self-stretch px-6 py-3 bg-teal-950 rounded-xl justify-center items-center gap-2.5 inline-flex">
            <div className="text-center text-white text-base font-bold font-satoshi leading-relaxed">Continue</div>
        </div>
    </div>


  </div>

  </div>
</div>
</>
  )
};
