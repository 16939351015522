import React from "react";
import { RelativeTime } from "../../../../services/courses";


export const Reviews = ({courseDetails}) => {
    // const createdBy = relativeTime(courseDetails?.createdAt)
  return <div className="w-full h-auto">
        <div className="self-stretch h-auto py-[72px] flex-col justify-start items-start gap-10 flex">
        <div className="self-stretch h-auto flex-col justify-start items-center gap-8 flex">
            <div className="max-w-[652px] h-[53px] text-center text-gray-100 text-2xl font-bold font-satoshi">What they say about us</div>
            <div className="w-full justify-center items-center gap-4 inline-flex overflow-scroll no-scrollbar">
                {courseDetails?.type === "MICRO DEGREE" &&
                <>
                {courseDetails?.reviews_1?.map((index)=>(
  <div key={index} className="w-[342px] px-[11px] py-4 bg-white rounded-md flex-col justify-start items-center gap-2.5 inline-flex">
  <div className="flex-col justify-start items-start gap-[13px] flex">
      <div className="w-[299px] justify-start items-center inline-flex">
          <div className="grow shrink basis-0 h-9 justify-start items-end gap-4 flex">
              <div className="w-[35px] h-[35px] relative">
                  <div className="w-[35px] h-[35px] left-0 top-0 absolute bg-blue-300 rounded-[26px]" />
                  <div className="left-[14px] top-[8px] absolute text-black text-xs font-bold font-satoshi leading-[17.88px]">{index?.name?.charAt(0).toUpperCase()}</div>
              </div>
              <div><span className="text-black/opacity-70 text-xs font-bold font-satoshi leading-[17.88px] tracking-tight">{index?.name}<br/>
              </span><span className="text-black/opacity-50 text-xs font-medium font-satoshi leading-[17.88px] tracking-tight"> </span></div>
          </div>
          <div className="w-[3px] h-[13px] relative">
              <div className="w-[3px] h-[3px] left-0 top-0 absolute bg-zinc-400 rounded-full" />
              <div className="w-[3px] h-[3px] left-0 top-[5px] absolute bg-zinc-400 rounded-full" />
              <div className="w-[3px] h-[3px] left-0 top-[10px] absolute bg-zinc-400 rounded-full" />
          </div>
      </div>
      <div className="justify-start items-end gap-3.5 inline-flex">
          <div className="w-[97px] h-[17px] relative">
            {index?.stars == 4 ?
          <img src="/svg/4star.svg" />  
          : index?.stars == 5 ?
          <img src="/svg/5star.svg" />
          : ""
        }

          </div>
          <div className="text-black/opacity-50 text-xs font-bold font-satoshi leading-[17.88px]"><RelativeTime createdAt={index?.createdAt} /></div>
      </div>
      <div className="min-w-[299px] text-black/opacity-50 text-xs font-bold font-satoshi leading-[17.88px]">{index?.text}</div>
  </div>
</div>
                ))}
                </>
            }


                {courseDetails?.type === "JOB PLACEMENT" &&
                <>
{courseDetails?.reviews_2?.map((index)=>(
            <div key={index} className="max-w-[400px] grow shrink basis-0 px-5 py-[22px] bg-white rounded-2xl border-2 border-gray-200 flex-col justify-end items-start inline-flex">
                <div className="h-[91px] pr-[13px] py-3 flex-col justify-end items-start gap-2.5 flex">
                    <div className="w-[275px] justify-start items-start gap-4 inline-flex">
                        <img className="w-[53.13px] h-[54px] rounded-[35px]" src={index?.image} />
                        <div className="flex-col justify-start items-start gap-[9px] inline-flex">
                            <div className="justify-start items-center gap-1.5 inline-flex">
                                <div className="p-[5px] bg-emerald-100 rounded-[7px] justify-start items-start gap-2.5 flex">
                                    <div className="text-emerald-500 text-xs font-bold font-satoshi leading-[17.88px]">{index?.from}</div>
                                </div>
                                <div className="text-slate-500 text-xs font-bold font-satoshi leading-[17.88px]"> {index?.to} at</div>
                            </div>
                            <img className=" h-[30px]" src={index?.companyImage} />
                        </div>
                    </div>
                </div>
                <div className="flex-col justify-end items-start gap-4 flex">
                    <div className="flex-col justify-start items-start gap-2 flex">
                        <div className="w-8 h-8 relative">
                        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 13.6523H10.0146C10.9433 10.9628 11.7363 9.67484 14.375 8.57524C14.8154 8.39164 15.0645 7.92294 14.9707 7.45514C14.877 6.98824 14.4668 6.65234 13.9902 6.65234H13.9882C8.4833 6.66214 5.3212 8.86914 3.079 14.2636C2.3633 15.958 2 17.7714 2 19.6523C2 22.5449 2.875 24.1201 4.209 26.1933C4.3926 26.4794 4.71 26.6523 5.0498 26.6523H12C13.6543 26.6523 15 25.3066 15 23.6523V16.6523C15 14.998 13.6543 13.6523 12 13.6523ZM27 13.6523H25.0146C25.9433 10.9628 26.7363 9.67484 29.375 8.57524C29.8154 8.39164 30.0645 7.92294 29.9707 7.45514C29.877 6.98824 29.4668 6.65234 28.9902 6.65234H28.9882C23.4833 6.66214 20.3212 8.86914 18.079 14.2636C17.3633 15.958 17 17.7714 17 19.6523C17 22.5449 17.875 24.1201 19.209 26.1933C19.3926 26.4794 19.71 26.6523 20.0498 26.6523H27C28.6543 26.6523 30 25.3066 30 23.6523V16.6523C30 14.998 28.6543 13.6523 27 13.6523Z" fill="#CCD2D6"/>
</svg>

                        </div>
                        <div className="w-[279px] text-black opacity-50 text-base font-black font-satoshi leading-[17.88px]">{index?.text}</div>
                    </div>
                    <div className="text-black opacity-70 text-xs font-bold font-satoshi leading-[17.88px] tracking-tight">-{index?.name}</div>
                </div>
            </div>
            ))}
                      </>
            }
            </div>
        </div>
    </div>
  </div>;
};
