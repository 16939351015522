import axios from "axios";
import { usePath } from "../routes/context/path/pathContext";

export const appliedJobs = async () => {
    const url = `${process.env.REACT_APP_baseURL}/user/applied_jobs`;
    const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };


  export const placementReports = async (id) => {
    const url = `${process.env.REACT_APP_baseURL}/user/placementReport/${id}`;
    const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };

  export const applyJob = async (applyId, requestBody) => {
    try {
        const url = `${process.env.REACT_APP_baseURL}/user/apply_jobv2/${applyId}`;
      const response = await axios.post(url, requestBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error("Failed to apply for the job: " + error.message);
    }
  };


  export const uploadFiles = async (file,fileType, extension, question) => {
    console.log(file, question, "ex");
    console.log(fileType, extension, "extension");

    try {
        console.log(file, question, "nt");
        const url = `${process.env.REACT_APP_baseURL}/user/upload/questions`;
      const formData = new FormData();
      formData.append("upload", file);
      formData.append("extenstion", extension);
      formData.append("type", fileType);

  
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      });
  console.log(response, "xyz")
      return {
        question: question,
        answer: response
      };
    } catch (error) {
      throw new Error("Failed to upload file: " + error.message);
    }
  };
  
  // user/save_job/:id



  export const bookMark = async (id, isSaved) => {
    try {
      const url = `${process.env.REACT_APP_baseURL}/user/save_job/${id}`;
      const response = await axios.post(url, 
        {
          saved:isSaved,
        },
         {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      });
      return response.data;
      
    } catch (error) {
      throw new Error("Failed to apply for the job: " + error.message);
    }
  };

  // get - user/saved_jobs

  
  export const getSavedJobs = async () => {
    const url = `${process.env.REACT_APP_baseURL}/user/saved_jobs`;
    const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };

  export const generateJobUrl = (index, category, auth) => {
    const { goToJobDetails } = usePath()
    const jobType = encodeURIComponent(index?.job_info?.job_type?.replace(/[\s&]+/g, '-').replace(/,/g, '-')).toLowerCase();
    const jobCategory = encodeURIComponent(category.find(item => item._id === index.job_info.category)?.name?.replace(/[\s&]+/g, '-').replace(/,/g, '-')).toLowerCase();
    const jobProfile = encodeURIComponent(index.job_info?.job_profile?.replace(/[\s&/]+/g, '-').replace(/,/g, '-')).toLowerCase();
  
    const jobId = index?.job_id;

    goToJobDetails(jobType, jobCategory, jobProfile,jobId)
  
    if (auth) {
      return {
        pathname: `/job/${jobType}/${jobCategory}/${jobProfile}`,
        search: `?jobId=${jobId}`,
        state: { jobId } // Pass jobId in state
      };
    } else {
      return {
        pathname: `/job-desc/${jobType}/${jobCategory}/${jobProfile}`,
        search: `?jobId=${jobId}`,
        state: { jobId } // Pass jobId in state
      };
    }
  };
  
  
  
  export const generateUrl = (filters, category) => {
    let baseUrl = '';
    let url = '';
    console.log(filters, 'filters');
  
    const idToCategoryMap = {};
    category?.forEach(cat => {
      idToCategoryMap[cat._id] = cat.name.replace(/[\s&]+/g, '-').replace(/,/g, '-').toLowerCase();
    });
  
    const names = filters?.categories?.map(filter => idToCategoryMap[filter]);
  
    // Determine updatedJobType based on types
    let updatedJobType = 'jobs' // Default to 'jobs'
  
    if (filters.type.includes('Full Time') || filters.type.includes('Part Time')) {
      updatedJobType = 'jobs';
    }
  
    if (filters.type.includes('Internship')) {
      if (filters.type.includes('Full Time') || filters.type.includes('Part Time')) {
        updatedJobType = 'jobs-internships';
      } else {
        updatedJobType = 'internships';
      }
    }
  let workplace;
    // Assume only one type can be selected at a time, either 'internships' or 'jobs'
    if (filters.workplace_type?.length > 0) {
      workplace = filters?.workplace_type?.map(workplaceType =>
        workplaceType.toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/[^a-z0-9-]/g, '')
      ).join('-');
      url += `/${updatedJobType}/${workplace}`;
    } else {
      url += `/${updatedJobType}`;
    }
  
   
    if (filters.categories.length > 0) {
      const categories = names?.join('-');
      url += `${workplace ? "-" : '/'}${categories}-${updatedJobType}`;
    }
    
    if (filters.profile) {
      const profile = filters?.profile?.toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-]/g, '');
      url += `${workplace || filters.categories.length > 0 ? "-" : '/'}${profile}-${updatedJobType}`;
    }
  
    // Append location to URL if selected and if it's a string
  if (typeof filters.location === 'string' && filters.location.trim() !== '') {
    const location = filters.location.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
    url += filters?.profile || filters?.categories ? `-in-${location}` : `-${updatedJobType}-in-${location}`;
  }
  
    return `${baseUrl}${url}`;
  };
  
  
  export const extractFiltersFromUrl = (url, categories) => {
    const urlSegments = url.split('/').filter(Boolean);
    console.log(url,"mainURl")
    const filters = {
      type: [],
      workplace_type: ['Remote', 'In-Office','Hybrid'],
      categories: [],
      profile: '',
      location: ''
    };

    if(url.includes('internships')){
filters.type.push('Internship')
    } else {
      filters.type.push('Full Time','Part Time')
    }
    const pathSegments = url.split('/');

    // Assuming the workplace types are always in the last segment
    const workplaceTypes = pathSegments[pathSegments.length - 1].split('-');
    // filters.workplace_type.push(workplaceTypes)
    console.log(workplaceTypes, "helllo")
    
    // if(url.includes('remote')){
    //   filters.workplace_type.push('Remote')
    // } else if(url.includes('in-office')){
    //   filters.workplace_type.push('In-Office')
    // } else if(url.includes('hybrid')){
    //   filters.workplace_type.push('Hybrid')
    // }

    // if (urlSegments.length > 0) {
    //   // Extract job type
    //   // const jobTypeSegment = urlSegments[0];
    //   // if (jobTypeSegment.includes('internships')) {
    //   //   filters.type.push('Internship');
    //   //   if (jobTypeSegment.includes('jobs')) {
    //   //     filters.type.push('Full Time');
    //   //   }
    //   // } else if (jobTypeSegment === 'jobs') {
    //   //   filters.type.push('Full Time');
    //   // }

    //   // Extract workplace type
    //   // const workplaceTypes = ['remote', 'in-office', 'hybrid'];
    //   // urlSegments.forEach(segment => {
    //   //   workplaceTypes.forEach(type => {
    //   //     if (segment.includes(type)) {
    //   //       filters.workplace_type.push(type);
    //   //       // if(type='remote'){
    //   //       //   filters.workplace_type.push('Remote');
    //   //       // } else  if(type='in-office'){
    //   //       //   filters.workplace_type.push('In-Office');
    //   //       // }  if(type='hybrid'){
    //   //       //   filters.workplace_type.push('Hybrid');
    //   //       // } 
    //   //     }
    //   //   });
    //   // });

    //   // Extract categories and profile
    //   const idToCategoryMap = {};
    //   categories.forEach(cat => {
    //     idToCategoryMap[cat.name.replace(/[\s&]+/g, '-').replace(/,/g, '-').toLowerCase()] = cat._id;
    //   });

    //   console.log(idToCategoryMap, "ID to Category Map");

    //   urlSegments.forEach(segment => {
    //     console.log(segment, "Segment");
    //     Object.keys(idToCategoryMap).forEach(categoryName => {
    //       console.log(categoryName, "Category Name");
    //       if (segment.includes(categoryName)) {
    //         filters.categories.push(idToCategoryMap[categoryName]);
    //       }
    //     });

    //     if (segment.includes('in-')) {
    //       const parts = segment.split('-in-');
    //       if (parts.length === 2) {
    //         filters.profile = parts[0].replace(/-/g, ' ');
    //         filters.location = parts[1].replace(/-/g, ' ');
    //       }
    //     }
    //   });

    //   console.log(filters.categories, "Extracted Categories");
    // }

    return filters;
};
