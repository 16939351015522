import React, { useState } from "react";

function NavigateComponent({ setActiveSection, activeSection }) {
  return (
    <div className="w-full self-stretch h-[69px] px-8 pt-8 flex-col justify-start items-start gap-2.5 flex overflow-scroll no-scrollbar">
      <div className="w-full justify-start items-start gap-10 inline-flex">
        <div className="rounded-lg flex-col justify-start items-center gap-[7px] inline-flex">
          <div
            onClick={() => setActiveSection("App-Preference")}
            className={` w-max cursor-pointer  text-slate-500 text-base  font-satoshi leading-relaxed ${
              activeSection === "App-Preference"
                ? "font-bold text-slate-950"
                : ""
            }`}
          >
            App Preferences
            {activeSection === "App-Preference" && (
              <div className="mt-[7px]">
                <svg
                  className=" "
                  xmlns="http://www.w3.org/2000/svg"
                  width="126"
                  height="4"
                  viewBox="0 0 126 4"
                  fill="none"
                >
                  <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H122C124.209 0 126 1.79086 126 4H0Z"
                    fill="#16C083"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
        <div className="rounded-lg flex-col justify-start items-center gap-[7px] inline-flex">
          <div
            onClick={() => setActiveSection("Account-Settings")}
            className={`w-max cursor-pointer text-slate-500 text-base  font-satoshi leading-relaxed ${
              activeSection === "Account-Settings"
                ? "font-bold text-slate-950"
                : ""
            }`}
          >
            Account Settings
            {activeSection === "Account-Settings" && (
              <div className="mt-[7px]">
                <svg
                  className=" "
                  xmlns="http://www.w3.org/2000/svg"
                  width="126"
                  height="4"
                  viewBox="0 0 126 4"
                  fill="none"
                >
                  <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H122C124.209 0 126 1.79086 126 4H0Z"
                    fill="#16C083"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
        {/* <div className="rounded-lg flex-col justify-start items-center gap-[7px] inline-flex">
          <div
            onClick={() => setActiveSection("Refer-And-Earn")}
            className={`w-max cursor-pointer text-slate-500 text-base  font-satoshi leading-relaxed ${
              activeSection === "Refer-And-Earn"
                ? "font-bold text-slate-950"
                : ""
            }`}
          >
            Refer and Earn
            {activeSection === "Refer-And-Earn" && (
              <div className="mt-[7px]">
                <svg
                  className=" "
                  xmlns="http://www.w3.org/2000/svg"
                  width="126"
                  height="4"
                  viewBox="0 0 126 4"
                  fill="none"
                >
                  <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H122C124.209 0 126 1.79086 126 4H0Z"
                    fill="#16C083"
                  />
                </svg>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default NavigateComponent;
