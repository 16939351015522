import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  categoriesWithJobCount,
  fetchJobs,
  jobDescbyId,
} from '../../../../services/landingPageApi';
import FillButton from '../../../../components/buttons/fillButton';
import ButtonChild from '../../../../components/buttons/buttonChild';
import {
  appliedJobs,
  bookMark,
  generateJobUrl,
  getSavedJobs,
} from '../../../../services/jobs';
import Loader from '../../../../components/loader/loader';
import { Navbar } from '../../../landingPage/navbar/navbar';
import { Footer } from '../../../landingPage/footer/footer';
import ShareDialog from '../../../../components/share_dailog_box/ShareDialog';
import LogInToggle from '../../../landingPage/utils/loginOrSignUpComp';
import { Mixpanel } from '../../../../services/mixpanel';
import moment from 'moment';
import { isMobileOnly, browserName } from 'react-device-detect';
import { usePath } from '../../../../routes/context/path/pathContext';
import { Helmet } from 'react-helmet-async';

export const JobDescription = ({ jobId, setActiveSection, activeSection }) => {
  const auth = localStorage.getItem('auth');
  const params = useParams();
  const { goToJobDetails } = usePath();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  console.log(isSaved, 'saved');
  const [showLoginBox, setShowLoginBox] = useState(false);

  const onLoginOpen = () => {
    setShowLoginBox((prev) => !prev);
  };
  const handleClick = () => {
    // Set isLoading to true to show the loader
    setIsLoading(true);
  };

  const location = useLocation();
  const jobid = new URLSearchParams(location.search).get('id');
  const { jobType, jobName } = useParams();

  console.log('Job ID:', jobid);

  const [jobDetails, setJobDetails] = useState([]);
  const [category, setCategory] = useState([]);
  const [jobByCategory, setJobByCategory] = useState([]);
  const [profile, setProfile] = useState('');
  const [decJobId, setDescJobId] = useState('');
  const [applJobId, setApplJobId] = useState('');
  const [Loading, setLoading] = useState(true);
  const topRef = useRef(null);
  const navigate = useNavigate();
  console.log(applJobId, jobid, 'id');

  console.log(jobDetails, 'details');

  const onbookmark = async () => {
    if (auth) {
      setIsSaved((prev) => !prev); // Toggle isSaved
      const updatedIsSaved = !isSaved; // Get the updated value of isSaved

      const response = await bookMark(jobDetails?._id, updatedIsSaved); // Call bookMark with the updated value
      console.log(response);
    } else {
      setShowLoginBox((prev) => !prev);
      // onLoginOpen()
      // navigate("/login-or-signup")
    }
  };

  useEffect(() => {
    const fetchJob = async () => {
      const categoriesJobs = await categoriesWithJobCount();
      const savedJobs = await getSavedJobs();

      setCategory(
        categoriesJobs?.data?.categories.map((index) => ({
          _id: index._id,
          name: index.name,
        })),
      );

      const jobDesc = await jobDescbyId(jobid);
      if (jobDesc) {
        setLoading(false);
      }
      console.log(
        savedJobs?.data?.saved_jobs.map((index) => index.job_id),
        jobDesc?.data?._id,
        'savedJobs',
      );
      if (
        savedJobs?.data?.saved_jobs.some(
          (savedJob) => savedJob.job_id == jobDesc?.data?._id,
        )
      ) {
        setIsSaved(true);
      }
      console.log(jobDesc?.data);
      setJobDetails(jobDesc?.data);
      setProfile(jobDetails?.job_profile);
      setDescJobId(jobDetails?._id);

      topRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };
    fetchJob();
  }, [jobId]);

  useEffect(() => {
    const fetchCatJob = async () => {
      console.log('Job Profile:', profile);
      const internJobs = await fetchJobs({ profile: profile });
      console.log(internJobs?.data);
      setJobByCategory(internJobs?.data);
    };
    fetchCatJob();
  }, []);

  const redirect = () => {
    if (auth) {
      navigate('/internships');
    } else {
      navigate('/internships');
    }
  };

  // useEffect(()=>{
  //   if(jobDetails?._id !== jobId  && !auth) {
  //     navigate("/find-jobs");
  //   }
  // },[])

  useEffect(() => {
    const fetch = async () => {
      try {
        const appliedJobList = await appliedJobs();
        console.log(appliedJobList);
        const filteredJobIds = appliedJobList?.data?.applied_jobs?.filter(
          (job) => job.job_id === jobid,
        );
        console.log(filteredJobIds);
        if (filteredJobIds) {
          // Extracting only the IDs
          const jobIds = filteredJobIds.map((job) => job.job_id);
          setApplJobId(jobIds);
        }
      } catch (error) {
        console.error('Error fetching applied jobs:', error);
      }
    };
    fetch();
  }, [jobId]); // Added jobId to the dependency array to trigger the effect whenever jobId changes

  const [showShareDialog, setShowShareDialog] = useState(false);
  const [referralURL, setReferralURL] = useState('');
  const onShareClick = async () => {
    setReferralURL(jobDetails?.link);
    setShowShareDialog(true);
  };
  const onCloseDialogBox = () => {
    setShowShareDialog(false);
  };
  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        // if(jobDetails){
        // setLoading(false);
        // }
      }
    };

    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount

  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{`${jobType} : ${jobName}`}</title>
      </Helmet>
      {Loading ? (
        <Loader />
      ) : (
        <div id="top" ref={topRef}>
          <div
            className={`${activeSection === 'jobDescription' ? 'hidden' : 'flex'}`}
          >
            {!auth && <Navbar />}
          </div>

          <div className="w-full flex flex-col bg-white justify-center items-center">
            {showShareDialog && (
              <ShareDialog
                referralURL={referralURL}
                onClose={onCloseDialogBox}
              />
            )}
            {showLoginBox && (
              <LogInToggle close={() => setShowLoginBox((prev) => !prev)} />
            )}

            {(activeSection === 'jobDescription' || !auth) && (
              <div className="w-full bg-gray-100 px-4 md:px-16 lg:px-[124px] pt-4 h-auto justify-start items-center inline-flex">
                <Link to="/">
                  <div className="text-slate-500 text-base font-normal font-satoshi leading-relaxed">
                    Home
                  </div>
                </Link>
                <div className="w-5 h-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M14.8602 10.4422L8.61016 16.6922C8.52275 16.7797 8.41134 16.8393 8.29004 16.8635C8.16873 16.8876 8.04299 16.8752 7.92872 16.8279C7.81446 16.7805 7.71681 16.7004 7.64814 16.5975C7.57947 16.4946 7.54287 16.3737 7.54297 16.25V3.75C7.54287 3.62632 7.57947 3.50539 7.64814 3.40252C7.71681 3.29964 7.81446 3.21946 7.92872 3.17211C8.04299 3.12477 8.16873 3.11239 8.29004 3.13655C8.41134 3.1607 8.52275 3.22031 8.61016 3.30782L14.8602 9.55782C14.9183 9.61586 14.9644 9.68479 14.9958 9.76067C15.0273 9.83654 15.0435 9.91787 15.0435 10C15.0435 10.0821 15.0273 10.1635 14.9958 10.2393C14.9644 10.3152 14.9183 10.3841 14.8602 10.4422Z"
                      fill="#99A6AD"
                    />
                  </svg>
                </div>
                {activeSection === 'jobDescription' ? (
                  <div
                    onClick={() => setActiveSection('findJob')}
                    className="text-slate-500 text-base font-normal font-satoshi leading-relaxed"
                  >
                    Jobs
                  </div>
                ) : (
                  <Link to="/jobs">
                    <div className="text-slate-500 text-base font-normal font-satoshi leading-relaxed">
                      Jobs
                    </div>
                  </Link>
                )}

                <div className="w-5 h-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M14.8602 10.4422L8.61016 16.6922C8.52275 16.7797 8.41134 16.8393 8.29004 16.8635C8.16873 16.8876 8.04299 16.8752 7.92872 16.8279C7.81446 16.7805 7.71681 16.7004 7.64814 16.5975C7.57947 16.4946 7.54287 16.3737 7.54297 16.25V3.75C7.54287 3.62632 7.57947 3.50539 7.64814 3.40252C7.71681 3.29964 7.81446 3.21946 7.92872 3.17211C8.04299 3.12477 8.16873 3.11239 8.29004 3.13655C8.41134 3.1607 8.52275 3.22031 8.61016 3.30782L14.8602 9.55782C14.9183 9.61586 14.9644 9.68479 14.9958 9.76067C15.0273 9.83654 15.0435 9.91787 15.0435 10C15.0435 10.0821 15.0273 10.1635 14.9958 10.2393C14.9644 10.3152 14.9183 10.3841 14.8602 10.4422Z"
                      fill="#99A6AD"
                    />
                  </svg>
                </div>
                <div className="text-slate-500 text-base font-normal font-satoshi leading-relaxed">
                  {jobDetails?.job_profile}
                </div>
              </div>
            )}
            <div
              className={`w-full flex bg-gray-100 justify-center items-center p-4 md:px-16 py-8 ${auth ? 'w-[98%] xl:w-[95%] mx-4 mt-8' : 'w-full mx-0 lg:px-[124px]'}`}
            >
              <div className="w-full h-auto p-6 bg-white rounded-xl border border-neutral-300 justify-between items-center inline-flex flex-col lg:flex-row gap-2">
                <div className="w-full justify-center items-center gap-6 flex flex-col sm:flex-row">
                  <Link
                    target="_blank"
                    to={jobDetails?.company_info?.webUrls[0]}
                  >
                    <div className="hidden sm:flex w-[88px] h-auto">
                      <div className="w-[88px] h-auto rounded-full">
                        <img
                          className="w-20 h-[82.94px] "
                          src={jobDetails?.company_info?.logo}
                        />
                      </div>
                    </div>
                  </Link>
                  <div className="sm:hidden w-full h-auto justify-between items-center inline-flex">
                    <Link
                      target="_blank"
                      to={jobDetails?.company_info?.webUrls[0]}
                    >
                      <div className="w-14 h-[58.06px]">
                        <div className="w-14 h-[58.06px] rounded-full">
                          <img
                            className="w-max-[50.91px]"
                            src={jobDetails?.company_info?.logo}
                          />
                        </div>
                      </div>
                    </Link>
                    <div className="h-[33.18px] inline-flex gap-4 justify-center items-center">
                      <div
                        onClick={onbookmark}
                        className="w-5 h-5 cursor-pointer"
                      >
                        {isSaved == true ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M14.375 2.5H5.625C5.29348 2.5 4.97554 2.6317 4.74112 2.86612C4.5067 3.10054 4.375 3.41848 4.375 3.75V17.5C4.37506 17.6115 4.40496 17.721 4.46161 17.8171C4.51826 17.9132 4.59959 17.9924 4.69716 18.0464C4.79473 18.1005 4.90498 18.1274 5.01648 18.1245C5.12798 18.1215 5.23666 18.0888 5.33125 18.0297L10 15.1117L14.6695 18.0297C14.7641 18.0886 14.8727 18.1212 14.9841 18.124C15.0955 18.1268 15.2056 18.0998 15.303 18.0458C15.4005 17.9918 15.4817 17.9127 15.5383 17.8167C15.5949 17.7208 15.6249 17.6114 15.625 17.5V3.75C15.625 3.41848 15.4933 3.10054 15.2589 2.86612C15.0245 2.6317 14.7065 2.5 14.375 2.5Z"
                              fill="#16C083"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                          >
                            <path
                              d="M16.043 3.11743H5.29297C4.96145 3.11743 4.64351 3.24913 4.40909 3.48355C4.17466 3.71797 4.04297 4.03591 4.04297 4.36743V18.1174C4.04302 18.229 4.07293 18.3385 4.12958 18.4345C4.18622 18.5306 4.26755 18.6098 4.36512 18.6638C4.4627 18.7179 4.57295 18.7448 4.68445 18.7419C4.79595 18.739 4.90463 18.7062 4.99922 18.6471L10.668 15.7291L16.3375 18.6471C16.4321 18.706 16.5407 18.7386 16.6521 18.7414C16.7634 18.7443 16.8736 18.7173 16.971 18.6633C17.0684 18.6092 17.1497 18.5301 17.2063 18.4342C17.2629 18.3382 17.2928 18.2289 17.293 18.1174V4.36743C17.293 4.03591 17.1613 3.71797 16.9269 3.48355C16.6924 3.24913 16.3745 3.11743 16.043 3.11743ZM16.043 16.9901L10.9984 14.4627C10.8991 14.4007 10.7843 14.3677 10.6672 14.3677C10.55 14.3677 10.4353 14.4007 10.3359 14.4627L5.29297 16.9901V4.36743H16.043V16.9901Z"
                              fill="#667984"
                            />
                          </svg>
                        )}
                      </div>
                      <div onClick={onShareClick}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="34"
                          viewBox="0 0 32 34"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_6888_21047)">
                            <path
                              d="M8 21.1764C10.2091 21.1764 12 19.3197 12 17.0294C12 14.739 10.2091 12.8823 8 12.8823C5.79086 12.8823 4 14.739 4 17.0294C4 19.3197 5.79086 21.1764 8 21.1764Z"
                              stroke="#667984"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M24 12.8823C26.2091 12.8823 28 11.0256 28 8.73519C28 6.44484 26.2091 4.58813 24 4.58813C21.7909 4.58813 20 6.44484 20 8.73519C20 11.0256 21.7909 12.8823 24 12.8823Z"
                              stroke="#667984"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M24 29.4706C26.2091 29.4706 28 27.6139 28 25.3236C28 23.0332 26.2091 21.1765 24 21.1765C21.7909 21.1765 20 23.0332 20 25.3236C20 27.6139 21.7909 29.4706 24 29.4706Z"
                              stroke="#667984"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.5996 15.2322L20.3996 10.5322"
                              stroke="#667984"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.5996 18.8264L20.3996 23.5264"
                              stroke="#667984"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6888_21047">
                              <rect
                                width="32"
                                height="33.1765"
                                fill="white"
                                transform="translate(0 0.441162)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex-col justify-center items-start inline-flex">
                    <Link
                      target="_blank"
                      to={jobDetails?.company_info?.webUrls[0]}
                    >
                      <div className="w-full text-teal-950 text-[40px] font-bold font-satoshi leading-[48px]">
                        {jobDetails?.job_profile}
                      </div>
                    </Link>
                    <div className="w-full flex-wrap justify-start items-center gap-2 inline-flex">
                      <div className="w-full text-slate-700 text-xl font-medium font-satoshi leading-loose">
                        {jobDetails?.company_info?.name}
                      </div>
                      {jobDetails?.location && (
                        <>
                          <div className="w-1 h-1 bg-slate-700 rounded-full" />
                          <div className="">
                            <div className="w-max text-slate-700 text-xl font-medium font-satoshi leading-loose">
                              {jobDetails?.location}
                            </div>
                          </div>
                        </>
                      )}
                      {jobDetails?.job_type && (
                        <>
                          <div className="w-1 h-1 bg-slate-700 rounded-full" />
                          <div className="w-max text-slate-700 text-xl font-medium font-satoshi leading-loose">
                            {jobDetails?.job_type}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-max justify-center items-center gap-4 xl:gap-[30px] flex flex-col xl:flex-row">
                  <div className="w-full gap-[30px] inline-flex hidden sm:flex">
                    <div className="p-2 rounded-xl justify-center items-center gap-1 flex">
                      <div
                        onClick={onbookmark}
                        className="w-5 h-5 cursor-pointer"
                      >
                        {isSaved == true ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M14.375 2.5H5.625C5.29348 2.5 4.97554 2.6317 4.74112 2.86612C4.5067 3.10054 4.375 3.41848 4.375 3.75V17.5C4.37506 17.6115 4.40496 17.721 4.46161 17.8171C4.51826 17.9132 4.59959 17.9924 4.69716 18.0464C4.79473 18.1005 4.90498 18.1274 5.01648 18.1245C5.12798 18.1215 5.23666 18.0888 5.33125 18.0297L10 15.1117L14.6695 18.0297C14.7641 18.0886 14.8727 18.1212 14.9841 18.124C15.0955 18.1268 15.2056 18.0998 15.303 18.0458C15.4005 17.9918 15.4817 17.9127 15.5383 17.8167C15.5949 17.7208 15.6249 17.6114 15.625 17.5V3.75C15.625 3.41848 15.4933 3.10054 15.2589 2.86612C15.0245 2.6317 14.7065 2.5 14.375 2.5Z"
                              fill="#16C083"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                          >
                            <path
                              d="M16.043 3.11743H5.29297C4.96145 3.11743 4.64351 3.24913 4.40909 3.48355C4.17466 3.71797 4.04297 4.03591 4.04297 4.36743V18.1174C4.04302 18.229 4.07293 18.3385 4.12958 18.4345C4.18622 18.5306 4.26755 18.6098 4.36512 18.6638C4.4627 18.7179 4.57295 18.7448 4.68445 18.7419C4.79595 18.739 4.90463 18.7062 4.99922 18.6471L10.668 15.7291L16.3375 18.6471C16.4321 18.706 16.5407 18.7386 16.6521 18.7414C16.7634 18.7443 16.8736 18.7173 16.971 18.6633C17.0684 18.6092 17.1497 18.5301 17.2063 18.4342C17.2629 18.3382 17.2928 18.2289 17.293 18.1174V4.36743C17.293 4.03591 17.1613 3.71797 16.9269 3.48355C16.6924 3.24913 16.3745 3.11743 16.043 3.11743ZM16.043 16.9901L10.9984 14.4627C10.8991 14.4007 10.7843 14.3677 10.6672 14.3677C10.55 14.3677 10.4353 14.4007 10.3359 14.4627L5.29297 16.9901V4.36743H16.043V16.9901Z"
                              fill="#667984"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    <div className="p-2 rounded-xl justify-center items-center gap-1 flex">
                      <div
                        onClick={onShareClick}
                        className="w-5 h-5  cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                        >
                          <path
                            d="M18.6102 9.18462L14.8602 12.9346C14.7727 13.0221 14.6613 13.0817 14.54 13.1059C14.4187 13.13 14.293 13.1177 14.1787 13.0703C14.0645 13.023 13.9668 12.9428 13.8981 12.8399C13.8295 12.7371 13.7929 12.6161 13.793 12.4924V9.36744H13.5586C12.0341 9.36701 10.5527 9.8733 9.34738 10.8067C8.14204 11.7401 7.28112 13.0476 6.9 14.5237C6.85856 14.6843 6.75503 14.8218 6.61218 14.9061C6.46933 14.9903 6.29886 15.0143 6.13828 14.9729C5.9777 14.9315 5.84016 14.8279 5.75592 14.6851C5.67167 14.5422 5.64762 14.3718 5.68906 14.2112C6.13876 12.4663 7.15606 10.9205 8.58077 9.8173C10.0055 8.71408 11.7567 8.11607 13.5586 8.11744H13.793V4.99244C13.7929 4.86875 13.8295 4.74782 13.8981 4.64495C13.9668 4.54208 14.0645 4.46189 14.1787 4.41455C14.293 4.3672 14.4187 4.35482 14.54 4.37898C14.6613 4.40314 14.7727 4.46274 14.8602 4.55025L18.6102 8.30025C18.6683 8.35829 18.7144 8.42722 18.7458 8.5031C18.7773 8.57897 18.7935 8.6603 18.7935 8.74244C18.7935 8.82457 18.7773 8.9059 18.7458 8.98177C18.7144 9.05765 18.6683 9.12658 18.6102 9.18462ZM15.668 16.8674H3.79297V7.49244C3.79297 7.32668 3.72712 7.1677 3.60991 7.05049C3.4927 6.93328 3.33373 6.86744 3.16797 6.86744C3.00221 6.86744 2.84324 6.93328 2.72603 7.05049C2.60882 7.1677 2.54297 7.32668 2.54297 7.49244V16.8674C2.54297 17.199 2.67466 17.5169 2.90909 17.7513C3.14351 17.9857 3.46145 18.1174 3.79297 18.1174H15.668C15.8337 18.1174 15.9927 18.0516 16.1099 17.9344C16.2271 17.8172 16.293 17.6582 16.293 17.4924C16.293 17.3267 16.2271 17.1677 16.1099 17.0505C15.9927 16.9333 15.8337 16.8674 15.668 16.8674Z"
                            fill="#667984"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="w-[45.06px] h-[0px] rotate-90 border border-neutral-300 hidden xl:flex"></div>
                  <div className="w-full">
                    {!auth ? (
                      <div
                        onClick={() => {
                          Mixpanel.track('Appy_now_CTA_clicked', {
                            date: moment().format(),
                            distinct_id: localStorage.getItem('userId'),
                            user_id: localStorage.getItem('userId'),
                            browser: browserName,
                            jobId: jobDetails?._id,
                            mobile_device: isMobileOnly,
                            type: 'webapp',
                          });
                          navigate(`/login-or-signup?jobId=${jobDetails?._id}`);
                        }}
                        // to={`/login-or-signup?jobId=${jobDetails?._id}`}
                      >
                        <FillButton
                          className="w-full"
                          onClick={() => {
                            // setJobPageSection("screenQue");
                            // setApplyId(jobDetails?._id);
                            // setActiveSection("");
                          }}
                          // disabled={jobId == applJobId ? true : false}
                          color="green"
                          textColor="white"
                          textClass="w-max"
                          text={'Apply Now'}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="w-full">
                          {jobDetails?.questions?.length <= 0 && (
                            <Link to={`/preview?jobId=${jobDetails?._id}`}>
                              <FillButton
                                className="w-full"
                                onClick={() => {
                                  // setJobPageSection("screenQue");
                                  // setApplyId(jobDetails?._id);
                                  // setActiveSection("");
                                }}
                                disabled={jobid == applJobId ? true : false}
                                color="green"
                                textColor="white"
                                textClass="w-max"
                                text={
                                  jobid == applJobId ? 'Applied' : 'Apply Now'
                                }
                              />
                            </Link>
                          )}
                        </div>
                        <div className="w-full">
                          {jobDetails?.questions?.length > 0 && (
                            <Link to={`/screen-que/${jobDetails?._id}`}>
                              <FillButton
                                onClick={() => {
                                  // setJobPageSection("screenQue");
                                  // setApplyId(jobDetails?._id);
                                  // setActiveSection("");
                                }}
                                disabled={jobid == applJobId ? true : false}
                                color="green"
                                textColor="white"
                                textClass="w-max"
                                text={
                                  jobid == applJobId ? 'Applied' : 'Apply Now'
                                }
                              />
                            </Link>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`w-full h-auto p-4 md:px-1  ${!auth ? 'md:px-16 xl:px-[124px]' : 'lg:px-8'} py-[72px] bg-white justify-center items-start gap-16 inline-flex flex-col lg:flex-row border-b`}
          >
            <div className="w-full lg:w-[60%] flex-col justify-start items-start gap-10 inline-flex">
              <div className="w-full self-stretch h-auto flex-col justify-start items-start gap-4 flex">
                <div className="w-full text-teal-950 text-[40px] font-bold font-satoshi leading-[48px]">
                  Description
                </div>
                <div
                  className="
              "
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: jobDetails?.job_description,
                    }}
                  />
                </div>
              </div>
              {/* <div className="w-full self-stretch h-auto flex-col justify-start items-start gap-4 flex">
                <div className="w-full text-teal-950 text-[40px] font-bold font-satoshi leading-[48px]">
                  Responsibilities
                </div>
                <div className="w-full self-stretch h-auto flex-col justify-start items-start gap-2 flex">
                  <div className="self-stretch justify-start items-start gap-2 inline-flex">
                    <div className="w-full text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                    
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="w-full self-stretch h-auto flex-col justify-start items-start gap-4 flex">
                <div className="text-teal-950 text-[40px] font-bold font-satoshi leading-[48px]">
                  Nice-To-Haves
                </div>
                <div className="self-stretch h-auto flex-col justify-start items-start gap-2 flex">
                  <div className="justify-start items-start gap-2 inline-flex">
                    <div className="w-5 h-5 relative" />
                    <div className="w-auto text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                      Fluent in English
                    </div>
                  </div>
                  <div className="justify-start items-start gap-2 inline-flex">
                    <div className="w-5 h-5 relative" />
                    <div className="w-auto text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                      Project management skills
                    </div>
                  </div>
                  <div className="justify-start items-start gap-2 inline-flex">
                    <div className="w-5 h-5 relative" />
                    <div className="w-auto text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                      Copy editing skills
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="w-full lg:w-[40%] grow shrink basis-0 flex-col justify-start items-start gap-10 inline-flex">
              <div className="w-full self-stretch h-auto flex-col justify-start items-start gap-[23px] flex">
                <div className="self-stretch text-teal-950 text-[40px] font-bold font-satoshi leading-[48px]">
                  About this role
                </div>
                <div className="self-stretch justify-between items-start inline-flex">
                  <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                    Apply Before
                  </div>
                  <div className="text-teal-950 text-base font-bold font-satoshi leading-relaxed">
                    {new Date(
                      jobDetails?.job_date_of_closing,
                    ).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </div>
                </div>
                <div className="self-stretch justify-between items-start inline-flex">
                  <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                    Job Posted On
                  </div>
                  <div className="text-teal-950 text-base font-bold font-satoshi leading-relaxed">
                    {new Date(jobDetails?.created_at).toLocaleDateString(
                      'en-US',
                      { year: 'numeric', month: 'long', day: 'numeric' },
                    )}
                  </div>
                </div>
                <div className="self-stretch justify-between items-start inline-flex">
                  <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                    {jobDetails?.job_type}
                  </div>
                  <div className="text-teal-950 text-base font-bold font-satoshi leading-relaxed">
                    Full-Time
                  </div>
                </div>
                <div className="self-stretch justify-between items-start inline-flex">
                  <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                    Stipend (Negotiable)
                  </div>
                  <div className="text-gray-800 text-base font-bold font-satoshi leading-relaxed">
                    ₹{' '}
                    {(jobDetails?.job_salary
                      ? jobDetails?.job_salary
                      : jobDetails?.job_min_salary +
                        '-' +
                        jobDetails?.job_max_salary) +
                      ' / ' +
                      jobDetails?.job_salary_frequency}
                  </div>
                </div>
              </div>

              <div className="w-full flex-col justify-start items-start gap-2 flex border-b py-8 border-t">
                <div className="text-teal-950 text-[40px] font-bold font-satoshi leading-[48px]">
                  Categories
                </div>
                <div className="justify-start items-start gap-2 inline-flex">
                  <div className="px-2.5 py-1.5 bg-emerald-300 bg-opacity-10 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                    <div className="text-emerald-300 text-sm font-medium font-satoshi leading-snug">
                      {jobDetails?.category &&
                        category.find(
                          (item) => item._id === jobDetails?.category,
                        )?.name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full h-auto relative flex gap-2 flex-wrap">
                <div className="w-full text-teal-950 text-[40px] font-bold font-satoshi leading-[48px] mb-2">
                  Required Skills
                </div>
                {jobDetails?.skills?.map((skill, index) => (
                  <div
                    key={index}
                    className="w-auto h-auto px-3 py-1 bg-gray-100 rounded-[20px] justify-center items-center gap-4 inline-flex flex-wrap"
                  >
                    <div className="text-emerald-500 text-base font-normal font-satoshi leading-relaxed">
                      {skill}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={`w-full h-auto p-4 md:px-1 ${!auth ? 'md:px-16 xl:px-[124px]' : 'lg:px-8'} py-8 xl:py-16 bg-white flex-col justify-start items-start gap-8 inline-flex`}
          >
            <div className="w-full flex-col justify-start items-start gap-2 flex">
              <div className="w-max text-teal-950 text-[40px] font-bold font-satoshi leading-[48px]">
                Perks & Benefits
              </div>
              <div className="w-full text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                This job comes with several perks and benefits for you
              </div>
            </div>
            <div className="w-full self-stretch justify-start items-start gap-2 inline-flex flex-wrap">
              {jobDetails?.perks?.map((perks, index) => (
                <div
                  key={index}
                  className="w-auto h-auto px-3 py-1  bg-gray-100 rounded-2xl border-2 border-gray-200 justify-start items-center flex flex-wrap"
                >
                  <div className="text-teal-950 text-sm font-medium font-satoshi leading-tight">
                    {perks}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className={`w-full h-auto px-4 md:px-8 py-8 ${!auth ? 'md:px-16 xl:px-[124px]' : 'lg:px-8'} bg-white flex-col justify-center items-start gap-6 inline-flex`}
          >
            <div className="w-full justify-between items-center inline-flex">
              <div>
                <span className="text-teal-950 text-[40px] font-bold font-satoshi leading-[48px] ">
                  Similar Openings
                </span>
              </div>
              <div onClick={redirect}>
                <ButtonChild
                  className="hidden md:flex"
                  textColor="green"
                  textClass="w-max"
                  text="Show all openings"
                  rightIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                    >
                      <path
                        d="M16.75 7.72559L1.75 7.72559"
                        stroke="#16C083"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.7002 1.70124L16.7502 7.72524L10.7002 13.7502"
                        stroke="#16C083"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                />
              </div>
            </div>
            <div className="w-full justify-center items-center gap-8 inline-flex">
              <div className="w-full flex flex-col sm:grid sm:grid-cols-2 gap-8 ">
                {jobByCategory?.jobs?.slice(0, 8).map((index) => (
                  <div
                    // target="#top"

                    onClick={() => {
                      // navigate(auth ? `/job/${index.job_info._id}` : `/job-desc/${index.job_info._id}`);
                      // navigate(generateJobUrl(index, category, auth))
                      goToJobDetails({ index: index, category: category });

                      window.location.reload();
                    }}
                    key={index}
                    className="w-auto self-stretch px-10 py-6 bg-white rounded-xl border border-neutral-300 justify-start items-start gap-6 flex-col lg:flex-row inline-flex hover:transform hover:scale-105 hover:shadow-md cursor-pointer"
                  >
                    <div className="w-16 h-16 relative">
                      <div className="w-16 h-16 rounded-full flex items-center">
                        <img src={index.company_info.logo} />
                      </div>
                    </div>
                    <div className="flex-col justify-start items-start gap-2 inline-flex">
                      <div className="justify-start items-start gap-[100px] inline-flex">
                        <div className="text-teal-950 text-xl font-bold font-satoshi leading-normal">
                          {index.job_info?.job_profile}
                        </div>
                      </div>
                      <div className="w-full h-auto justify-start items-center gap-2 inline-flex flex-wrap">
                        <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                          {index.company_info.name}
                        </div>
                        {index.job_info?.workplace_type && (
                          <>
                            <div className="w-1 h-1 bg-slate-700 rounded-full" />
                            <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                              {index.job_info?.workplace_type}
                            </div>
                          </>
                        )}
                        {index.job_info?.location && (
                          <>
                            <div className="w-1 h-1 bg-slate-700 rounded-full" />
                            <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                              {index.job_info?.location}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="w-full justify-start items-start gap-2 inline-flex flex-wrap">
                        <div className="px-2.5 py-1.5 bg-emerald-300 bg-opacity-10 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                          <div className="w-max text-emerald-300 text-sm font-medium font-satoshi leading-snug">
                            {index.job_info?.job_type}
                          </div>
                        </div>
                        <div className=" self-stretch bg-neutral-300" />
                        <div className="px-2.5 py-1.5 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                          <div className="w-max text-emerald-500 text-sm font-medium font-satoshi leading-snug">
                            {index.job_info?.category &&
                              category.find(
                                (item) => item._id === index.job_info.category,
                              )?.name}
                          </div>
                        </div>
                        <div className="px-2.5 py-1.5 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                          {index.job_info?.job_salary ||
                          index.job_info?.job_max_salary ? (
                            <>
                              <div className="w-5 h-5 relative">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M13.125 10C13.125 10.6181 12.9417 11.2223 12.5983 11.7362C12.255 12.2501 11.7669 12.6506 11.1959 12.8871C10.6249 13.1236 9.99653 13.1855 9.39034 13.065C8.78415 12.9444 8.22733 12.6467 7.79029 12.2097C7.35325 11.7727 7.05562 11.2158 6.93505 10.6097C6.81447 10.0035 6.87635 9.37513 7.11288 8.80411C7.3494 8.2331 7.74994 7.74504 8.26384 7.40166C8.77775 7.05828 9.38193 6.875 10 6.875C10.8288 6.875 11.6237 7.20424 12.2097 7.79029C12.7958 8.37634 13.125 9.1712 13.125 10ZM19.375 5V15C19.375 15.1658 19.3092 15.3247 19.1919 15.4419C19.0747 15.5592 18.9158 15.625 18.75 15.625H1.25C1.08424 15.625 0.925268 15.5592 0.808058 15.4419C0.690848 15.3247 0.625 15.1658 0.625 15V5C0.625 4.83424 0.690848 4.67527 0.808058 4.55806C0.925268 4.44085 1.08424 4.375 1.25 4.375H18.75C18.9158 4.375 19.0747 4.44085 19.1919 4.55806C19.3092 4.67527 19.375 4.83424 19.375 5ZM18.125 8.62109C17.4153 8.41125 16.7694 8.02719 16.2461 7.50389C15.7228 6.98059 15.3387 6.33468 15.1289 5.625H4.87109C4.66125 6.33468 4.27719 6.98059 3.75389 7.50389C3.23059 8.02719 2.58468 8.41125 1.875 8.62109V11.3789C2.58468 11.5887 3.23059 11.9728 3.75389 12.4961C4.27719 13.0194 4.66125 13.6653 4.87109 14.375H15.1289C15.3387 13.6653 15.7228 13.0194 16.2461 12.4961C16.7694 11.9728 17.4153 11.5887 18.125 11.3789V8.62109Z"
                                    fill="#16C083"
                                  />
                                </svg>
                              </div>
                              <div className="w-max text-slate-500 text-sm font-medium font-satoshi leading-snug">
                                ₹
                                {index.job_info?.job_salary
                                  ? index.job_info?.job_salary
                                  : index.job_info?.job_max_salary}{' '}
                                / {index.job_info?.job_salary_frequency}
                              </div>
                            </>
                          ) : (
                            <div className="w-max text-slate-500 text-sm font-medium font-satoshi leading-snug">
                              Unpaid
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={`${activeSection === 'jobDescription' ? 'hidden' : 'flex'}`}
          >
            {!auth && <Footer />}
          </div>
        </div>
      )}
    </>
  );
};
