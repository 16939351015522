
import React from "react";

import { useNavigate } from "react-router-dom";
import ButtonChild from "../../../../components/buttons/buttonChild";
import BorderButton from "../../../../components/buttons/borderButton";
import FillButton from "../../../../components/buttons/fillButton";
import { joinWaitlist } from "../../../../services/courses";

const ConfirmJoinWailist = ({courseDetails, close}) => {

    const navigate = useNavigate();

    const joinWaitingList = async (id)=>{
        const response = await joinWaitlist(id)
        if(response){
            navigate('/join-waitlist/success')
            console.log(response)
        }
}
  return (
    <div
      class="relative z-11"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="fixed inset-0  bg-black bg-opacity-80 backdrop-blur-[4px] blur-md transition-opacity"></div>
    
      <div class="fixed inset-0 z-10 overflow-y-auto no-scrollbar">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative m-auto w-full transform overflow-scroll no-scrollbar rounded-lg bg-white text-left shadow-xl transition-all px-8 py-8 sm:my-8 sm:w-full sm:max-w-lg w-4/12">
            <div className="flex justify-end items-center w-screen sm:w-full">
            {/* <div className="w-max text-teal-950 text-2xl font-black font-['Satoshi Variable'] leading-[28.80px]">Sign Out</div> */}
              
              <div onClick={close}>
              <ButtonChild 
              className="px-1"
              
              textColor="gray"
              leftIcon={
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.0673 15.1829C16.1254 15.241 16.1714 15.3099 16.2028 15.3858C16.2343 15.4617 16.2505 15.543 16.2505 15.6251C16.2505 15.7072 16.2343 15.7885 16.2028 15.8644C16.1714 15.9403 16.1254 16.0092 16.0673 16.0673C16.0092 16.1254 15.9403 16.1714 15.8644 16.2028C15.7885 16.2343 15.7072 16.2505 15.6251 16.2505C15.543 16.2505 15.4617 16.2343 15.3858 16.2028C15.3099 16.1714 15.241 16.1254 15.1829 16.0673L10.0001 10.8837L4.81729 16.0673C4.70002 16.1846 4.54096 16.2505 4.3751 16.2505C4.20925 16.2505 4.05019 16.1846 3.93292 16.0673C3.81564 15.95 3.74976 15.791 3.74976 15.6251C3.74976 15.4593 3.81564 15.3002 3.93292 15.1829L9.11651 10.0001L3.93292 4.81729C3.81564 4.70002 3.74976 4.54096 3.74976 4.3751C3.74976 4.20925 3.81564 4.05019 3.93292 3.93292C4.05019 3.81564 4.20925 3.74976 4.3751 3.74976C4.54096 3.74976 4.70002 3.81564 4.81729 3.93292L10.0001 9.11651L15.1829 3.93292C15.3002 3.81564 15.4593 3.74976 15.6251 3.74976C15.791 3.74976 15.95 3.81564 16.0673 3.93292C16.1846 4.05019 16.2505 4.20925 16.2505 4.3751C16.2505 4.54096 16.1846 4.70002 16.0673 4.81729L10.8837 10.0001L16.0673 15.1829Z" 
              fill="currentColor"/>
            </svg>}
              />
              </div>
            </div>


{/* <div className="form w-screen sx:w-full px-11 py-8 bg-white rounded-2xl shadow flex-col justify-start items-center inline-flex"> */}
    <div className=" flex-col justify-start items-center gap-8 flex">
        <div className="flex-col justify-start items-center gap-4 flex">
            <div className="text-center text-black text-2xl font-bold font-satoshi leading-[28.80px]">Join Waitlist</div>
            <div className="w-auto text-center text-slate-700 text-base font-mediumfont-satoshi leading-relaxed">Are you sure you want to join waitlist?</div>
        </div>
        <div className="w-full inline-flex justify-center items-center gap-8">
        <div onClick={close} className="justify-center items-center gap-4 inline-flex">
          <BorderButton 
          textColor="gray"
          color="gray"
          text="cancel"
          />
        </div>

        <div onClick={()=>{joinWaitingList(courseDetails?._id)}} className="justify-center items-start gap-4 inline-flex">
          <FillButton
          textColor="black"
          color="yellow"
          text="Join Waitlist"
          />
        </div>
        </div>
       
    </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmJoinWailist;
