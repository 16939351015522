import React from "react";

const Loader = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-[134px] h-[182px] px-2.5 pt-2.5 pb-12 bg-transparent flex-col justify-center items-center flex">
        <div className="self-stretch flex-col justify-center items-center gap-7 inline-flex">
          <div className="w-16 h-16 p-[6.90px] justify-center items-center inline-flex">
            <div className="w-16 h-16 relative">
              <div className="spinner" />
            </div>
          </div>
          <div className="w-[114px] text-center text-black text-[11px] font-medium font-['Satoshi Variable'] leading-none">
            Please wait while we set things up!
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
