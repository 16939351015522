import React from "react";

export const IndustryRecognizedCertificate = ({courseDetails}) => {
  return <div className="w-full h-auto">
    <div className="w-full flex-wrap px-4 sm:px-8 md:px-16 lg:px-[124px] py-[72px] h-auto flex-col justify-center items-center gap-8 inline-flex ">
    <div className="flex-col justify-start items-center gap-4 flex">
        <div className="w-full h-[53px] text-center text-white text-2xl font-bold font-satoshi">Industry Recognised Certificates that helps you</div>
        <div className="max-w-[376px] h-[0px] border-2 border-black"></div>
    </div>
    <div className="w-full self-stretch h-auto flex-col justify-center items-center gap-8 flex">
        <div className="max-w-[800px] py-[9px] md:grid md:grid-cols-2 justify-center items-center gap-8 inline-flex flex-wrap">
{courseDetails?.certificationPoints?.map((point, index)=>(
    <div className="w-full flex justify-center items-center h-full">
        <div key={index} className="w-[344px] p-8 bg-gradient-to-br from-neutral-900 to-slate-900 rounded-2xl border-2 border-white/opacity-10 justify-start items-start flex h-full">
                    <div className="justify-start items-start flex">
                        <div className="flex-col justify-start items-start gap-4 inline-flex">
                            <div className="justify-start items-center gap-3 inline-flex">
                                <div className="p-[5px] bg-emerald-100 rounded-[5px] border-2 border-black/opacity-10 justify-start items-start gap-[12.12px] flex">
                                    <div className="w-[25px] h-[25px] justify-start items-start gap-[12.12px] flex">
                                        <div className="w-[24.22px] h-[24.23px] relative">
                                            <img src={point?.image} />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-100 text-base font-bold font-satoshi tracking-wide">{point?.title}</div>
                            </div>
                            <div className="w-[280px] text-gray-400 text-base font-medium font-satoshi tracking-wide">{point?.description}</div>
                        </div>
                    </div>
                </div>
                </div>
                ))}
         

        </div>
    </div>
</div>
  </div>;
};
