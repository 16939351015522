import React from "react";
import { EnterNumberScreen } from "./components/JoinWaitlistFlow/enterNumberScreen";
import { Helmet } from "react-helmet-async";

export const LoginThroughJoinWaitlist = () => {
  return (
    <>
      <Helmet>
        <title>Join Waitlist: Login</title>
      </Helmet>
      <div className="w-full h-auto">
        <EnterNumberScreen />
      </div>
    </>
  );
};
