import React from "react";
import FillButton from "../../../../components/buttons/fillButton";
import { Link } from "react-router-dom";

export const Banner2 = () => {
  return (
    <div className="w-full h-[558px] p-4 xl:px-[124px] py-8 md:py-[72px] bg-white justify-center items-center gap-[30px] inline-flex">
      <div className="w-full sx:max-w-[578px] h-max relative bg-orange-200 rounded-[32px]">
        <div className=" w-full flex-col justify-start items-start gap-4 inline-flex px-8 py-[25px]">
          <div className="w-full sx:max-w-[380px] text-teal-950 text-[40px] md:text-[56px] font-bold font-satoshi leading-[61.60px]">
            Level up your college game
          </div>
          <div className="w-full sx:max-w-[364px] text-slate-700 text-base font-medium font-satoshi leading-relaxed">
            Start applying for jobs today.
          </div>
          <Link to="/login-or-signup">
          <FillButton
            className="w-max px-8"
            color="black"
            textColor="white"
            text="Sign Up For Free"
          />
           </Link>
        </div>
        <img className=" rounded-2xl" src="Top-banner-1a.png" />
      </div>
      <div className="hidden lg:flex flex-col justify-start items-start gap-[31px] inline-flex">
        <div className="w-[578px] h-[279px] relative rounded-[32px]">
          <img src="Frame 5200 1.png" width="578" height="279" />
        </div>
        <div className="w-[578px] h-[279px] relative rounded-[32px]">
          <img src="Frame 5201 2b.png" width="578" height="279" />
        </div>
      </div>
    </div>
  );
};
