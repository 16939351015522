import React, { useEffect, useState } from "react";
import { TextCheckDropDown } from "../../../../components/textChecksDropDown/textCheckDropDown";
import ButtonChild from "../../../../components/buttons/buttonChild";
import {
  categoriesWithJobCount,
  fetchJobs,
} from "../../../../services/landingPageApi";
import FillButton from "../../../../components/buttons/fillButton";
import { useLocation, useNavigate } from "react-router-dom";
import { usePath } from "../../../../routes/context/path/pathContext";
import { generateUrl } from "../../../../services/jobs";

export const FilterJobSection = ({
  filters,
  setFilters,
  showFilterSection,
  setShowFilterSection,
  decodedCategory,
 
}) => {
  const [category, setCategory] = useState([]);

  const { updatePath } = usePath();
  const navigate = useNavigate();

// category has id and name, how to fetch category
const handleFilterChange = (
  filterType,
  selectedFilters,
) => {
  setFilters({
      ...filters,
      [filterType]: selectedFilters,
  });

};


const onFilterChange = ({ jobType, categ, workplace }) => {
  let updatedJobType = jobType || filters?.type;
  const types = jobType;

  const idToCategoryMap = {};
  category?.forEach(cat => {
    idToCategoryMap[cat._id] = cat.name.replace(/[\s&]+/g, '-').replace(/,/g, '-').toLowerCase();
  });

  const names = categ?.map(filter => idToCategoryMap[filter]);

  if (types?.includes('Full Time') || types?.includes('Part Time')) {
    updatedJobType = 'jobs';
  }
  
  if (types?.includes('Internship')) {
    if (types?.includes('Full Time') || types?.includes('Part Time')) {
      updatedJobType = 'jobs&internships';
    } else {
      updatedJobType = 'internships';
    }
  } else {
    updatedJobType = 'jobs';
  }
  
  // navigate(generateUrl(filters, category))
};

  const [filterApplied, setFilterApplied] = useState(false);

  const handleFilterApply = () => {
    setFilterApplied(true);
    setShowFilterSection(false);
  
  };
  
  useEffect(() => {
    
    const fetch =async()=>{
    const categoriesJobs = await categoriesWithJobCount();
    setCategory(
      categoriesJobs?.data?.categories.map((index) => ({
        _id: index._id,
        name: index.name,
      }))
    );
  }

  fetch();
  }, [])

  useEffect(() => {
    const url = generateUrl(filters, category); // Replace `category` with your actual category data
    navigate(url); // Use navigate to update URL
    
  }, [filters, category, navigate]);

  return (
    <div className={`w-full`}>
      <div className="xl:hidden p-4 w-full h-auto justify-between items-start inline-flex">
        <div className="text-black text-2xl font-bold font-satoshi leading-[28.80px]">
          More Filters
        </div>
        <div
          onClick={() => setShowFilterSection(!showFilterSection)}
          className="w-6 h-6 relative cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.15213 5.1515C5.37716 4.92654 5.68233 4.80016 6.00052 4.80016C6.31872 4.80016 6.62389 4.92654 6.84892 5.1515L12.0005 10.3031L17.1521 5.1515C17.2628 5.03689 17.3952 4.94547 17.5416 4.88258C17.688 4.81969 17.8455 4.78659 18.0048 4.7852C18.1642 4.78382 18.3222 4.81418 18.4697 4.87452C18.6171 4.93485 18.7511 5.02396 18.8638 5.13663C18.9765 5.2493 19.0656 5.38328 19.1259 5.53076C19.1862 5.67823 19.2166 5.83625 19.2152 5.99558C19.2138 6.15492 19.1807 6.31238 19.1178 6.45879C19.055 6.60519 18.9635 6.73761 18.8489 6.8483L13.6973 11.9999L18.8489 17.1515C19.0675 17.3778 19.1885 17.6809 19.1857 17.9956C19.183 18.3102 19.0568 18.6112 18.8343 18.8337C18.6118 19.0562 18.3108 19.1824 17.9962 19.1851C17.6816 19.1878 17.3784 19.0669 17.1521 18.8483L12.0005 13.6967L6.84892 18.8483C6.6226 19.0669 6.31948 19.1878 6.00484 19.1851C5.69021 19.1824 5.38923 19.0562 5.16674 18.8337C4.94425 18.6112 4.81805 18.3102 4.81532 17.9956C4.81258 17.6809 4.93354 17.3778 5.15213 17.1515L10.3037 11.9999L5.15213 6.8483C4.92716 6.62327 4.80078 6.3181 4.80078 5.9999C4.80078 5.68171 4.92716 5.37654 5.15213 5.1515Z"
              fill="#002032"
            />
          </svg>
        </div>
      </div>
      <div className="w-full px-16 py-8 xl:px-0 xl:py-0 md:w-full flex-col justify-center md:justify-start items-start gap-10 inline-flex background-blur-md overflow-scroll no-scrollbar">
        <TextCheckDropDown
          parentText="Type of Employment"
          onSelect={(selectedFilters) =>{
            handleFilterChange("type", selectedFilters),
            onFilterChange({jobType:selectedFilters, categ:filters?.categories,workplace:filters?.workplace_type}) 
           }        
          }
          
          isSelectedOptions={filters.type}
        >
          <ButtonChild
            textColor="gray"
            value="Internship"
            text={`Internship`}
            
          />
          <ButtonChild
            textColor="gray"
            value="Full Time"
            text={`Full-time`}
          />
          <ButtonChild
            textColor="gray"
            value="Part Time"
            text={`Part-Time`}
          />
        </TextCheckDropDown>

        <TextCheckDropDown
          parentText="Workplace"
          onSelect={(selectedFilters) =>{
            handleFilterChange("workplace_type", selectedFilters),
            onFilterChange({jobType:filters?.type, categ:filters?.categories, workplace:selectedFilters}) 
          }
          }
        >
          <ButtonChild
            textColor="gray"
            value="Remote"
            text={`Remote`}
          />
          <ButtonChild
            textColor="gray"
            value="In-Office"
            text={`In-Office`}
          />
          <ButtonChild
            textColor="gray"
            value="Hybrid"
            text={`Hybrid`}
          />
        </TextCheckDropDown>

        <TextCheckDropDown
  parentText="Categories"
  onSelect={(selectedFilters) =>{
    handleFilterChange("categories", selectedFilters),
    onFilterChange({jobType:filters?.type,categ:selectedFilters, workplace:filters?.workplace_type})
  }
  }
  isSelectedOptions={filters.categories}
>
  <ButtonChild
    textColor="gray"
    value="64be7ce0bd9a0d37e3f3bcb2"
    text={`Development & IT `}
  />
  <ButtonChild
    textColor="gray"
    value="64be7d26bd9a0d37e3f3bcb3"
    text={`Design & Creative `}
  />
  <ButtonChild
    textColor="gray"
    value="64be7d42bd9a0d37e3f3bcb4"
    text={`Sales & Marketing `}
  />
  <ButtonChild
    textColor="gray"
    value="64be7d78bd9a0d37e3f3bcb6"
    text={`Management & Research `}
  />
  <ButtonChild
    textColor="gray"
    value="64be7da2bd9a0d37e3f3bcb8"
    text={`Finance & Legal`}
  />
  <ButtonChild
    textColor="gray"
    value="64be7dd1bd9a0d37e3f3bcba"
    text={`Human Resource`}
  />
  <ButtonChild
    textColor="gray"
    value="64be7df1bd9a0d37e3f3bcbb"
    text={`Engineering`}
  />
  <ButtonChild
    textColor="gray"
    value="64be7d8dbd9a0d37e3f3bcb7"
    text={`Admin & Customer Support`}
  />
  <ButtonChild
    textColor="gray"
    value="64be7d61bd9a0d37e3f3bcb5"
    text={"Content Writing & Translation"}
  />
</TextCheckDropDown>


        {/* <TextCheckDropDown
        className={`disabled`}
          parentText="Job Level"
          // onSelect={(selectedFilters) =>
          //   handleFilterChange("jobLevel", selectedFilters)
          // }
        >
          <ButtonChild
            textColor="gray"
            value="Entry Level"
            text="Entry Level"
          />
          <ButtonChild textColor="gray" value="Mid Level" text="Mid Level" />
          <ButtonChild
            textColor="gray"
            value="Senior Level"
            text="Senior Level"
          />
        </TextCheckDropDown> */}

        {/* <TextCheckDropDown
          parentText="Salary Range"
          // onSelect={(selectedFilters) =>
          //   handleFilterChange("salaryRange", selectedFilters)
          // }
        >
          <ButtonChild
            textColor="gray"
            value="700-1000"
            text={`0 - 3 Lakhs`}
          />
          <ButtonChild
            textColor="gray"
            value="1000-1500"
            text={`4 - 6 Lakhs`}
          />
          <ButtonChild
            textColor="gray"
            value="1500-2000"
            text={`7 - 10 Lakhs`}
          />
          <ButtonChild
            textColor="gray"
            value="3000-"
            text={`above 10 Lakhs`}
          />
        </TextCheckDropDown> */}
      </div>

      {/* Filter Button */}
      <div className="xl:hidden flex justify-center px-4 py-2 my-8">
        <FillButton
          onClick={handleFilterApply}
          textColor="white"
          color="green"
          text="Filter"
        />
      </div>
    </div>
  );
};
