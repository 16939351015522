import React, { useEffect, useState, useRef } from "react";
import { DashLeftSection } from "./LoggedInUsers/leftSection";
import { DashRightSection } from "./LoggedInUsers/rightSection";
import { getUserDetails } from "../../services/loginOrSignUp";
import { PlacementReport } from "./LoggedInUsers/rightSection/screens/placementReport";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchJobs } from "../../services/landingPageApi";
import Loader from "../../components/loader/loader";
import { Helmet } from "react-helmet-async";

export const Dashboard = () => {
  const [userData, setUserData] = useState("");
  const [appliedDate, setAppliedDate] = useState("");
  const [menuOpen, setMenuOpen] = useState(false); // State to track menu open/close
  const [quickFilterData, setQuickFilterData] = useState([]);

  const menuRef = useRef(null);
  const location = useLocation();
  const searchText = location?.state || "";
  useEffect(() => {
    if (searchText !== "") {
      fetchJobs({ profile: searchText })
        .then((res) => {
          setQuickFilterData(res?.data?.jobs);
        })
        .catch((e) => console.log(e));
    }
  }, [searchText]);
  const [id, setId] = useState("");
  const [jobId, setJobId] = useState("");
  const [showReportBox, setShowReportBox] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const response = await getUserDetails();
      setUserData(response);
    };
    getData();
  }, []);

  const openPlacementReport = () => {
    setShowReportBox(true);
  };
  const closePlacementReport = () => {
    setShowReportBox(false);
  };
  const [activeSection, setActiveSection] = useState("findjobs");
  // Close the menu when clicking outside of it

  const appliedDateTime = new Date(appliedDate).getTime();
  const currentTime = new Date().getTime();
  const daysDifference = Math.floor(
    (currentTime - appliedDateTime) / (1000 * 60 * 60 * 24),
  );
  const millisecondsDifference = currentTime - appliedDateTime;
  const hoursDifference = Math.floor(millisecondsDifference / (1000 * 60 * 60));
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const remainingMilliseconds = millisecondsInDay * 7 - millisecondsDifference;
  const remainingDays = Math.floor(remainingMilliseconds / millisecondsInDay);
  const remainingHours = Math.floor(
    (remainingMilliseconds % millisecondsInDay) / (1000 * 60 * 60),
  );
  const remainingMinutes = Math.floor(
    (remainingMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
  );
  const remainingSeconds = Math.floor(
    (remainingMilliseconds % (1000 * 60)) / 1000,
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (!auth) navigate("/login-or-signup");
  }, []);

  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };

    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount

  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard: Find Jobs</title>
      </Helmet>
      <div className="w-screen h-auto inline-flex overflow-scroll no-scrollbar">
        <div
          className={`min-w-[20%] lg:max-w-[25%] overflow-scroll no-scrollbar ${
            menuOpen ? "w-full sm:min-w-[20%]" : "hidden sm:flex"
          } h-screen transition-all duration-300`}
          style={{
            position: menuOpen ? "absolute" : "static",
            left: menuOpen ? 0 : "-100%",
            zIndex: menuOpen ? (window.innerWidth < 768 ? 5 : 999) : "auto",
          }}
          ref={menuRef}
        >
          <DashLeftSection
            setActiveSection={setActiveSection}
            activeSection={activeSection}
            userData={userData}
            setMenuOpen={setMenuOpen}
            menuOpen={menuOpen}
          />
        </div>
        <div
          className="w-full h-auto overflow-scroll no-scrollbar"
          style={{ marginLeft: menuOpen ? "20%" : 0 }}
        >
          <DashRightSection
            userData={userData}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            setMenuOpen={setMenuOpen}
            menuOpen={menuOpen}
            openPlacementReport={openPlacementReport}
            setId={setId}
            setJobId={setJobId}
            jobId={jobId}
            setAppliedDate={setAppliedDate}
            daysDifference={daysDifference}
            quickFilterData={quickFilterData}
            setQuickFilterData={setQuickFilterData}
          />
        </div>
      </div>
      {/* {showReportBox && (
        <div className="fixed top-0 left-0 w-full h-full bg-opacity-50 flex justify-center items-center z-50">
          <PlacementReport 
          remainingDays={remainingDays} 
          remainingHours={remainingHours} 
          remainingSeconds={remainingSeconds}
          remainingMinutes={remainingMinutes}
          daysDifference={daysDifference} 
          hoursDifference={hoursDifference}

          appliedDate={appliedDate} 
          setActiveSection={setActiveSection} 
          closePlacementReport={closePlacementReport} 
          id={id} 
          jobId={jobId} 
          userData={userData}
          />
        </div>
      )} */}
    </>
  );
};
