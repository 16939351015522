import React, { useState, useEffect, useRef } from "react";
import { Navbar } from "../../landingPage/navbar/navbar";
import { SearchJobComp } from "./utils/searchJobComp";
import { FindJobsComp } from "./utils/findJobsComp";
import { Footer } from "../../landingPage/footer/footer";
import { JobDescription } from "../jobDescription/utils/jobDescription";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  categoriesWithJobCount,
  fetchJobs,
} from "../../../services/landingPageApi";
import { FilterJobSection } from "./utils/filterJobSection";
import Loader from "../../../components/loader/loader";
import { Helmet } from "react-helmet-async";
import { extractFiltersFromUrl, generateUrl } from "../../../services/jobs";

export const MainFindJobsOpprComp = ({
  jobPageSection,
  setApplyId,
  setJobPageSection,
  setQuickFilterData,
  quickFilterData,
 
}) => {
  
  const auth = localStorage.getItem("auth");
  const [activeSection, setActiveSection] = useState("findJob");
  const [jobId, setJobId] = useState("");
  const [category, setCategory] = useState([]);
  const [jobByCategory, setJobByCategory] = useState([]);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [inputFilter, setInputFilter] = useState([]);
  const [cat, setCat] = useState('');
  const [InternData, setInternData] = useState("");

  const {profile, workplace, type} = useParams();
  console.log(profile, workplace,type, 'params')





  const [filters, setFilters] = useState({
    type: [],
    profile: "",
    location:"",
    workplace_type: [],
    categories: [],
    jobLevel: [],
    salaryRange: "",
  });
  console.log(filters, 'kiran')
  const [locationFilteredData, setLocationFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);


const url = window.location.pathname;
console.log(url, 'urrrl');

useEffect(() => {
  const extractedFilters = extractFiltersFromUrl(url, category);
  console.log(extractedFilters, "extract");


  setFilters((filters) => ({
    ...filters,
    type: extractedFilters.type,
    // categories: extractedFilters.categories,
    // location: extractedFilters.location,
    // profile: extractedFilters.profile,
    // workplace_type: extractedFilters.workplace_type,
  }));
}, []);



  useEffect(() => {


    const fetchJob = async () => {

      const decodeCategory = localStorage.getItem('selectedCategory')
      const typeIntern = localStorage.getItem('type')
      setInternData(typeIntern);

      setCat(decodeCategory);
  
      if(typeIntern){
        setFilters((prev)=>({
          ...prev,
          type:[typeIntern],
        }))
       
        localStorage.removeItem("type");
      } 

      if(decodeCategory){
        setFilters((prev)=>({
          ...prev,
          categories:[decodeCategory],
        }))
        localStorage.removeItem("selectedCategory");
       
      } 

      const internJobs = await fetchJobs({ categories: "Internship" });
      setJobByCategory(internJobs?.data);

      const categoriesJobs = await categoriesWithJobCount();
      setCategory(
        categoriesJobs?.data?.categories.map((index) => ({
          _id: index._id,
          name: index.name,
        }))
      );
    };
    fetchJob();
  }, []);

  useEffect(() => {
    // Simulating data loading with a setTimeout
    const timeout = setTimeout(() => {
      setLoading(false);
      // topRef.current.scrollIntoView({ behavior: "smooth" });
    }, 1000); // Adjust the timeout as needed

    // Cleanup function to clear timeout
    return () => clearTimeout(timeout);
  }, []);

  const location = useLocation();
    // Extract category name from URL parameters

  
    
 



  // landing page quick filter data
  const searchText = location?.state || "";
  useEffect(() => {
    if (searchText) {
      fetchJobs({ profile: searchText })
        .then((res) => {
          // setQuickFilterData(res?.data?.jobs);
          if (quickFilterData === undefined) {
            setInputFilter(res?.data?.jobs);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [searchText]);


  // filter part by location
  const handleFetchByLocation = async (searchResults) => {
    try {
      const response = await fetchJobs({ location: [searchResults] });
      if (response?.data) {
        setLocationFilteredData(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
 

  // const activePages = window.location.pathname === "/find-jobs";
  const activePages = 
  location.pathname.includes('/find-jobs');

  const finJobRef = useRef(); // Reference to the FinJobComponent

  // Other state variables and useEffects...

  const scrollToFinJob = () => {
    if (finJobRef.current) {
      window.scrollTo({
        top: finJobRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };
  const navigate = useNavigate();
  // useEffect(()=>{
  //   const auth = localStorage.getItem("auth");
  //   if(!auth)
  //   navigate("/login-or-signup")
  // },[])

  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };

    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount

  // Render the loader if isLoading is true
  if (loading) {
    return <Loader />;
  }

  return (
    <>
    <Helmet>
      <title>
         Browse Internships
      </title>
      </Helmet>
      {/* {loading ? (
        // Loader component while loading
        <div className="flex justify-center items-center h-full">
          <Loader/>
        </div>
      ) : ( */}
    <div
      className={`${auth ? "bg-white" : "bg-black"} ${
        showFilterSection ? "" : ""
      } relative ${
        activeSection === "jobDescription" ? "bg-white" : "bg-black"
      }`}
    >
      {!auth && (
        <Navbar
        InternData={InternData}
          activePages={activePages}
          activeSection={activeSection}
          textColor={activeSection === "jobDescription" ? "" : "textBlack"}
        />
      )}
      {activeSection == "findJob" && (

   
      <>
          <SearchJobComp
          searchText={searchText}
            filters={filters}
            setFilters={setFilters}
            handleLocation={handleFetchByLocation}
            activePages={activePages}
          />
          <div className={`w-full flex flex-col justify-center`}>
            <div
              onClick={() => setShowFilterSection(!showFilterSection)}
              className="xl:hidden cursor-pointer w-full sm:max-w-[500px] h-[50px] px-4 py-3 bg-white rounded-xl mx-auto justify-center items-center gap-2 inline-flex"
            >
              <div className="w-6 h-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_7774_46660)">
                    <path
                      d="M4 6H20"
                      stroke="#99A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 12H18"
                      stroke="#99A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 18H16"
                      stroke="#99A6AD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7774_46660">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="text-gray-400  text-base font-medium font-['Inter'] leading-relaxed">
                More Filters
              </div>
            </div>
            <div
              className="xl:hidden fixed left-0 top-0 bg-white "
              style={{ zIndex: 1 }}
            >
              {showFilterSection && (
                <div className="h-screen overflow-scroll no-scrollbar">
                  <FilterJobSection
                    setShowFilterSection={setShowFilterSection}
                    showFilterSection={showFilterSection}
                    filters={filters}
                    setFilters={setFilters}
                    // isSelectedOptions={decodedCategory}
                    decodedCategory={cat}
                    category={category}
                  />
                </div>
              )}
            </div>
            <div ref={finJobRef}>
            <FindJobsComp
            searchText={searchText}
              setJobId={setJobId}
              setActiveSection={setActiveSection}
              category={category}
              filters={filters}
              setFilters={setFilters}
              locationFilteredData={locationFilteredData}
              quickFilterData={quickFilterData}
              inputFilter={inputFilter}
              // decodedCategory={decodedCategory}
              loading = {loading}
              setLoading={setLoading}
              setQuickFilterData={setQuickFilterData}
              setInputFilter={setInputFilter}
            />
            </div>
          </div>
        </>
   
 
      )}
      {activeSection === "jobDescription" && (
        <JobDescription
          jobId={jobId}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          category={category}
        />
      )}

      {!auth && <Footer scrollToFinJob={scrollToFinJob} 
      setShowFilterSection={setShowFilterSection}
      showFilterSection={showFilterSection}
      filters={filters}
      setFilters={setFilters}
      />}
    </div>
       {/* )} */}
    </>
  );
};
