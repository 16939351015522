// 9c9abf48a07198f52392bec0f39f76c9

import mixpanel from "mixpanel-browser";

mixpanel.init("9c9abf48a07198f52392bec0f39f76c9");

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};


export let Mixpanel = actions;
