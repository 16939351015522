import React from "react";

export const CertificateBlurrImg = ({courseDetails}) => {
  return <div className="w-full h-auro">
    <div className="w-full h-auto px-8 sm:px-16 md:px-[124px] md:py-[72px] flex-col justify-start items-center gap-10 inline-flex">
    <div className="flex-col  justify-start items-center gap-[34.18px] flex">
        <div className="text-white text-center text-[22.79px] font-bold font-satoshi leading-relaxed tracking-wide">Your certificate will look like</div>
        <img className="sm:w-[544px] h-auto rounded-[22.79px] relative blur-[2px]" src={courseDetails?.certificateImage} />
    </div>
</div>
  </div>;
};
