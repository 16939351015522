import React, { useEffect, useRef, useState } from "react";
import { Footer } from "../footer/footer";
import { Navbar } from "../navbar/navbar";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";

export const Privacy = () => {
    const auth = localStorage.getItem("auth");
    const navigate = useNavigate();
     const topRef = useRef(null);
    const [loading, setLoading] = useState(true);
  
    const redirect = () => {
      if (auth) {
        navigate("/internships");
      } else {
        navigate("/internships");
      }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    useEffect(() => {
        // Simulating data loading with a setTimeout
        const timeout = setTimeout(() => {
          setLoading(false);
          topRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 1000); // Adjust the timeout as needed
    
        // Cleanup function to clear timeout
        return () => clearTimeout(timeout);
      }, []);
  return (
    <div className="w-full h-full">
    {loading ? (
   // Loader component while loading
   <div className="flex justify-center items-center h-full">
     <Loader/>
   </div>
 ) : (
 <>
    <Navbar redirect={redirect} />
    <div className="w-full h-auto p-[24px] md:px-16 lg:px-[124px] flex-col justify-start items-start gap-4 inline-flex">
      <div className="text-teal-950 text-[40px] font-bold font-satoshi leading-[48px]">
        Privacy Policy
      </div>
      <div className="self-stretch">
        <span className="text-slate-700 text-base font-bold font-satoshi leading-relaxed">
          <br />
          Effective Date: 30th March, 2024
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          Beep ("Beep," "we," "us," or "our") respects the privacy of our users
          ("you" or "your"). This Privacy Policy describes how we collect, use,
          and disclose information about you when you use our website (the
          "Website") and associated services (collectively, the "Services").
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          1. Information We Collect
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          We collect the following information about you:
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          Information you provide: This includes information you provide when
          you create an account, such as your name, email address, phone number,
          college information, and resume. It may also include information you
          provide when you search for jobs, apply for internships, or interact
          with employers on the platform.
          <br />
          Usage Data: We collect information about how you use the Services,
          such as the pages you visit, the jobs you search for, and the
          companies you apply to.
          <br />
          Device Data: We collect information about the device you use to access
          the Services, such as your IP address, browser type, and operating
          system.
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          2. How We Use Your Information
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          We use the information we collect about you to:
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          Provide and improve the Services
          <br />
          Connect you with internship and job opportunities
          <br />
          Send you important information about the Services, such as job alerts
          and account updates
          <br />
          Respond to your inquiries and requests
          <br />
          Analyze how the Services are used
          <br />
          Comply with legal requirements
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          3. Sharing Your Information
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          We may share your information with the following third parties:
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          Employers: We may share your information with employers that you apply
          to for internships or jobs.
          <br />
          Service Providers: We may share your information with service
          providers who help us operate the Services, such as cloud storage
          providers and email marketing providers.
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          We will only share your information with third parties who have agreed
          to comply with our privacy standards.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          4. Your Choices
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          You have choices about your information:
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          Access and Update Your Information: You can access and update your
          information in your account settings.
          <br />
          Control Your Communications: You can unsubscribe from promotional
          emails by clicking the "unsubscribe" link in any email you receive
          from us.
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          5. Security
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          We take reasonable steps to protect your information from unauthorized
          access, disclosure, alteration, or destruction. However, no website or
          internet transmission is completely secure.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          6. Children's Privacy
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          The Services are not directed to children under the age of 18. We do
          not knowingly collect personal information from children under 18. If
          you are a parent or guardian and you believe your child has provided
          us with personal information, please contact us.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          7. International Transfers
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          Your information may be transferred to and processed in countries
          other than your own, where data protection laws may be different from
          those in your country.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          8. Changes to this Privacy Policy
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          We may update this Privacy Policy from time to time. We will notify
          you of any material changes by posting the new Privacy Policy on the
          Website or by sending you an email.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          9. Contact Us
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          If you have any questions about this Privacy Policy, please contact us
          at contact@eventbeep.com
        </span>
      </div>
    </div>
    <Footer scrollToTop={scrollToTop} redirect={redirect} />
    </>
 )}
    </div>
  );
};
