import React, { useState, useEffect, useRef } from "react";
import { LeftBanner } from "./left_section/leftBanner";
import { Login } from "./right_section/login";
import { VerifyMobOtp } from "./right_section/verifyMobOtp";
import { ProfileSetUp } from "./right_section/profileSetUp";
import { Mixpanel } from "../../services/mixpanel";
import { isMobileOnly, browserName } from "react-device-detect";
import {
  getUserDetails,
  sendOTP,
  verifyOTP,
  updateProfile,
  getCampusData,
  updateCampusInfo,
  updateSkills,
  updateWorkPref,
  RequestCollege,
} from "../../services/loginOrSignUp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useAuth } from "../../routes/context/auth/authContext";
import { Helmet } from "react-helmet-async";

export const MainLoginOrSignUp = ({ leftIcon }) => {
  const { login } = useAuth();
  const [activeSection, setActiveSection] = useState("login");
  const [activeSubSection, setActiveSubSection] = useState("setprofile");

  const [selectedGender, setSelectedGender] = useState("");
  const [otp, setOtp] = useState("");
  const [campusData, setCampusData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [missing, setMissing] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jobId = queryParams.get("jobId");
  console.log(jobId, "...................");

  const [form, setForm] = useState({
    phoneNo: "",
    isWhatsApp: "",
    fname: "",
    gender: { value: "", label: "" },
    profileLogo: null,
    campusName: { value: "", label: "", campusAlias: "" },
    designations: [],
    courseName: "",
    start_date: "",
    end_date: "",
    skills: [],
    jobType: "",
    workPlace: [],
    location: [],
  });

  const options = [
    { value: 0, label: "Female" },
    { value: 1, label: "Male" },
  ];

  console.log(campusData);
  console.log(form);
  const [selectedCampus, setSelectedCampus] = useState("");

  // Onchange functions...
  const onSetFormData = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const onJobTypeChange = (jobType) => {
    setForm({
      ...form,
      jobType: jobType,
    });
  };

  // const onWorkPlaceChange = (workPlaces) => {
  //   setForm({
  //     ...form,
  //     workPlace: workPlaces,
  //   });
  // };
  

  const onWorkPlaceChange = (workplace) => {
    const isAlreadySelected = form.workPlace && form.workPlace.includes(workplace);
    
    // Initialize workPlace as an empty array if it's undefined
    const updatedWorkPlace = form.workPlace || [];
  
    if (isAlreadySelected) {
      // Remove if already selected
      setForm({
        ...form,
        workPlace: updatedWorkPlace.filter((ele) => ele !== workplace),
      });
    } else {
      // Add if not selected
      setForm({
        ...form,
        workPlace: [...updatedWorkPlace, workplace],
      });
    }
  };
  

  const onPhoneNoChange = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      phoneNo: e.target.value,
    }));
  };

  const onOptionChange = (selectedSkills) => {
    setForm({
      ...form,
      skills: selectedSkills,
    });
  };

  const onLocationChange = (locations) => {
    setForm({
      ...form,
      location: locations,
    });
  };

  const onLogoChange = async (file) => {
    setForm((prev) => {
      return {
        ...prev,
        profileLogo: file,
      };
    });
  };
  const onGenderChange = (e) => {
    setSelectedGender(e.target.value);
    setForm({
      ...form,
      gender: e.target.value,
    });
  };

  const onCampusChange = (e) => {
    setSelectedCampus(e.target.value);
    setForm({
      ...form,
      campusName: e.target.value,
    });
  };

  const handleStartDateChange = (date) => {
    setForm({
      ...form,
      start_date: date,
    });
  };

  const handleEndDateChange = (date) => {
    setForm({
      ...form,
      end_date: date,
    });
  };
  //

  const [searchQuery, setSearchQuery] = useState("");
  // const [selectedCompanyId, setSelectedCompanyId] = useState(companyData?._id);

  const [selectedCompanyId, setSelectedCompanyId] = useState(
    form.campusName ? form.campusName.value : ""
  );
  const [selectedCompanyName, setSelectedCompanyName] = useState(
    form.campusName ? form.campusName.label : ""
  );

  const [filteredCompanies, setFilteredCompanies] = useState([]);

  const handleOptionClick = (e) => {
    setSelectedCampus(e.value);
    setForm({
      ...form,
      campusName: e,
    });
    setSelectedCompanyId(e.value);
    setSelectedCompanyName(e.label);
    setSearchQuery("");
    setIsOpen(false);
    // Pass the selected company ID to another state or function.
  };

  const handleCompanySelect = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    // Filter companies that start with the first letter of the query
    const filtered = campusData.filter((company) =>
      company.label.toLowerCase().startsWith(query.toLowerCase())
    );
    setFilteredCompanies(filtered);
    setIsOpen(true); // Open the dropdown when typing
  };

  const remove = () => {
    console.log("Before clearing - searchQuery:", searchQuery);
    console.log("Before clearing - campusName:", form?.campusName?.label);
    console.log("Before clearing - selectedCampus:", selectedCampus?.label);

    setSelectedCampus("");
    setSelectedCompanyId("");
    setSelectedCompanyName("");
    setSearchQuery("");

    setForm({
      ...form,
      campusName: { value: "", label: "", campusAlias: "" },
    });

    console.log("After clearing - searchQuery:", searchQuery);
    console.log("After clearing - campusName:", form?.campusName?.label);
    console.log("After clearing - selectedCampus:", selectedCampus?.label);
  };

  const remove1 = () => {
    console.log("Before clearing - searchQuery:", searchQuery);
    console.log("Before clearing - campusName:", form?.campusName?.label);
    console.log("Before clearing - selectedCampus:", selectedCampus?.label);

    setSelectedCampus("");
    setSelectedCompanyId("");
    setSelectedCompanyName("");
    setSearchQuery("");

    setForm({
      ...form,
      campusName: "",
    });

    console.log("After clearing - searchQuery:", searchQuery);
    console.log("After clearing - campusName:", form?.campusName?.label);
    console.log("After clearing - selectedCampus:", selectedCampus?.label);
  };

  const handleInputClick = () => {
    setIsOpen(true); // Open the dropdown when clicking on the input
    setSearchQuery("");
  };
  // function calls....
  const handleSendOTP = async () => {
    try {
      const missingField = {};
      if (
        form.phoneNo === "" ||
        form.phoneNo?.length < 10 ||
        !/^\d+$/.test(form.phoneNo)
      )
        missingField["phoneNo"] = true;
      setMissing(missingField);
      if (Object.keys(missingField).length === 0) {
        let inputValue = form.phoneNo;
        if (!inputValue.startsWith("+91")) {
          inputValue = "+91" + inputValue;
        }
        const response = await sendOTP(inputValue);
        if (response?.data) {
          Mixpanel.track("Sign_up_created", {
            date: moment().format(),
            // distinct_id: localStorage.getItem("userId"),
            // user_id: localStorage.getItem("phoneNo"),
            signInType:"phoneNo LogIn",
            number:form.phoneNo,
            browser: browserName,
            mobile_device: isMobileOnly,
            type:"webapp",
          });
          console.log("OTP sent successfully:", response);
          setActiveSection("verifyMobOtp");
        }
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const missingField = {};
      if (otp === "" || otp.length < 4 || !/^\d+$/.test(otp))
        missingField["otp"] = true;
      setMissing(missingField);
      if (Object.keys(missingField).length === 0) {
        let inputValue = form.phoneNo;
        if (!inputValue.startsWith("+91")) {
          inputValue = "+91" + inputValue;
        }
        const response = await verifyOTP(otp, inputValue);

        if (response?.data) {
          login();
          Mixpanel.track("Sign_up_succesful", {
            date: moment().format(),
            distinct_id: response?.data?.userProfile?._id,
            user_id: response?.data?.userProfile?._id,
           phoneNo:form.phoneNo,
           isVerified:response?.data?.userProfile?.isPhoneVerified,
            browser: browserName,
            mobile_device: isMobileOnly,
            type:"webapp",
          });
          console.log("OTP verify successfully:", response);
          localStorage.setItem("userId", response?.data?.userProfile?._id)
          localStorage.setItem("email", response?.data?.userProfile?.email);
          localStorage.setItem("name", response?.data?.userProfile?.profileInfo?.name);
          localStorage.setItem("profile", response?.data?.userProfile?.profileInfo?.avatar);
          setActiveSection("profileSetUp");
          setActiveSubSection("setprofile");
          const userData = await getUserDetails();
          console.log(userData);
          if (userData) {
            if (userData.userStatus === 0) {
              setActiveSection("profileSetUp");
              setActiveSubSection("setprofile");
              window.location.reload();
            } else if (userData.userStatus === 1) {
              setActiveSection("profileSetUp");
              setActiveSubSection("education");
              window.location.reload();
            } else if (userData.userStatus === 2) {
              setActiveSection("profileSetUp");
              setActiveSubSection("skills");
              window.location.reload();
            } else if (userData.userStatus === 3) {
              if (
                userData.jobType ||
                userData.workplace ||
                userData.locations ||
                userData.designations
              ) {
                if (jobId) {
                  navigate(`/job/?id=${jobId}`);
                } else {
                  localStorage.setItem('type', "Internship");  
                  navigate("/internships");
                }
                // Redirect to workPref if any field is empty
              } else {
                console.log(userData, "userData");
                setActiveSection("profileSetUp");
                setActiveSubSection("workpref");
                window.location.reload();
              }

              // Check if page is redirected to workpref and any field is empty
              // const urlParams = new URLSearchParams(window.location.search);
            } else {
              localStorage.setItem('type', "Internship");  
              navigate("/internships");
            }
          }
        }
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  const [isCollegeNotFound, setIsCollegeNotFound] = useState(false);
  const OnUpdateProfile = async () => {
    try {
      const missingField = {};
      if (!form.fname) missingField["fname"] = true;
      if (!form.gender && form.gender === "") missingField["gender"] = true;
      // if (SSOID && !form.phoneNo) missingField["phoneNo"] = true;

      setMissing(missingField);

      if (Object.keys(missingField).length === 0) {
        const response = await updateProfile(
          form.fname,
          form.gender ? form.gender?.value : form.gender,
          form.profileLogo
        );

        if (response?.data) {
          Mixpanel.track("Profile_setup_completed", {
            date: moment().format(),
            distinct_id: localStorage.getItem("userId"),
            user_id: localStorage.getItem("userId"),
            Name:form?.fname,
            Number:form?.phoneNo,
            Email: localStorage.getItem("email"),
            browser: browserName,
            mobile_device: isMobileOnly,
            type:"webapp",
          });
          console.log("profile update successfully:", response);
          setActiveSection("profileSetUp");
          setActiveSubSection("education");
          const userData = await getUserDetails();
          setUserData(userData);
          console.log(userData);
        } else {
          console.log("error");
        }
      }
    } catch (error) {
      console.error("Error updating profile data:", error);
    }
  };

  const OnUpdateCampusInfo = async () => {
    try {
      const missingField = {};
      if (!form.campusName) missingField["campusName"] = true;
      if (!form.courseName) missingField["courseName"] = true;
      if (isCollegeNotFound && !form.campusName?.campusAlias)
        missingField["campusAlias"] = true;
      if (!form.start_date) missingField["start_date"] = true;
      if (!form.end_date) missingField["end_date"] = true;

      setMissing(missingField);

      if (Object.keys(missingField).length === 0) {
        if (isCollegeNotFound || form.campusName?.campusAlias) {
          const response = await RequestCollege(
            searchQuery,
            form.campusName?.campusAlias,
            form.courseName,
            form.start_date,
            form.end_date
          );
          if (response?.data) {
            Mixpanel.track("Profile_setup_completed", {
              date: moment().format(),
              distinct_id: localStorage.getItem("userId"),
              user_id: localStorage.getItem("userId"),
              Name:form?.fname,
              Number:form?.phoneNo,
              Email: localStorage.getItem("email"),
              browser: browserName,
              mobile_device: isMobileOnly,
              type:"webapp",
            });
            console.log("Campus data update successfully:", response);
            setActiveSection("profileSetUp");
            setActiveSubSection("skills");
            const userData = await getUserDetails();
            setUserData(userData);
            console.log(userData);
          } else {
            console.log("error");
          }
        } else {
        const response = await updateCampusInfo(
          form.campusName?.value,
          form.courseName,
          form.start_date,
          form.end_date
        );
      
        if (response?.data) {
          Mixpanel.track("College_added", {
            date: moment().format(),
            distinct_id: localStorage.getItem("userId"),
            user_id: localStorage.getItem("userId"),
            campusName:form.campusName,
            browser: browserName,
            mobile_device: isMobileOnly,
            type:"webapp",
          });
          console.log("Campus data update successfully:", response);
          setActiveSection("profileSetUp");
          setActiveSubSection("skills");
          const userData = await getUserDetails();
          setUserData(userData);
          console.log(userData);
        } else {
          console.log("error");
        }
        }
      }
    } catch (error) {
      console.error("Error updating campus data:", error);
    }
  };

  const onUpdateSkills = async () => {
    try {
      const missingField = {};
      if (form.skills?.length < 3) missingField["skills"] = true;

      setMissing(missingField);

      if (Object.keys(missingField).length === 0) {
        const response = await updateSkills(form.skills);
        if (response?.data) {
          Mixpanel.track("Skills_added", {
            date: moment().format(),
            distinct_id: localStorage.getItem("userId"),
            user_id: localStorage.getItem("userId"),
            skills:form.skills,
            browser: browserName,
            mobile_device: isMobileOnly,
            type:"webapp",
          });
          console.log("skills update successfully:", response);
          setActiveSection("profileSetUp");
          setActiveSubSection("workpref");
          const userData = await getUserDetails();
          setUserData(userData);
          console.log(userData);
        }
      }
    } catch (error) {
      console.error("Error updating campus data:", error);
    }
  };

  const onUpdateWorkPref = async () => {
    try {
      const missingField = {};
      if (form.jobType === "") missingField["jobType"] = true;
      if (form.workPlace === "") missingField["workPlace"] = true;
      if (form.location?.length < 0) missingField["location"] = true;
      if (form.designations?.length < 0) missingField["designations"] = true;

      setMissing(missingField);

      if (Object.keys(missingField).length === 0) {
        const response = await updateWorkPref(
          form.jobType,
          form.location,
          form.workPlace,
          form.designations
        );
        if (response?.data) {
          Mixpanel.track("Work_preference_added", {
          date: moment().format(),
          distinct_id: localStorage.getItem("userId"),
          user_id: localStorage.getItem("userId"),
          jobType:form.jobType,
          workPlace:form.workPlace,
          location:form.location,
          browser: browserName,
          mobile_device: isMobileOnly,
          type:"webapp",
        });
          console.log("Work preference update successfully:", response);
          const userData = await getUserDetails();
          if(userData?.userStatus === 3){
            Mixpanel.track("Onboarding_completed", {
              date: moment().format(),
              distinct_id: localStorage.getItem("userId"),
              user_id: localStorage.getItem("userId"),
              browser: browserName,
              mobile_device: isMobileOnly,
              type:"webapp",
            });
          }
          setUserData(userData);
          console.log(userData);
          navigate("/internships");
        }
      }
    } catch (error) {
      console.error("Error updating campus data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCampusData();
        console.log(data, "ssssssss");
        const auth = localStorage.getItem("auth");
        const userData = await getUserDetails();
        setUserData(userData);
        console.log(userData);
        console.log(localStorage.getItem("auth"));

        if (auth) {
          if (userData?.status === 0) {
            setActiveSection("profileSetUp");
            setActiveSubSection("setprofile");
          } else if (userData?.status === 1) {
            setActiveSection("profileSetUp");
            setActiveSubSection("education");
          } else if (userData?.status === 2) {
            setActiveSection("profileSetUp");
            setActiveSubSection("skill");
          } else if (userData?.status === 3) {
            setActiveSection("profileSetUp");
            setActiveSubSection("workpref");
          }
        }
        setSelectedCampus(form.campusName);
        console.log(form.campusName);

        if (userData) {
          const selectedGenderOption = options.find(
            (option) => option.value === userData.profileInfo?.gender
          );
          setSelectedGender(selectedGenderOption);
          setForm((prev) => ({
            ...prev,
            fname: userData?.profileInfo?.name,
            phoneNo: userData.number,
            gender: selectedGender,
            profileLogo: userData.profileInfo?.avatar,
            campusName: {
              label:
                userData?.campus_info?.campusName !== undefined
                  ? userData?.campus_info?.campusName
                  : selectedCampus,
              value:
                userData?.campus_info?._id !== undefined
                  ? userData?.campus_info?._id
                  : selectedCampus,
            },
            courseName: userData.course,
            start_date: userData.education?.map((index) => index.startDate),
            end_date: userData.education?.map((index) => index.endDate),
            skills: userData.skills,
            jobType: userData.jobType,
            workPlace: userData.workplace,
            location: userData.locations,
          }));
        }
        const processedData = data.data?.campusList?.map((item) => ({
          value: item._id,
          label: item.campusName,
        }));
        setCampusData(processedData);
        if (
          userData?.campus_info?.campusName == undefined ||
          form?.campusName?.label == undefined ||
          form?.campusName?.value == undefined ||
          selectedCampus?.label == undefined
        ) {
          remove1();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false); // State for dropdown open/close
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    if(auth){
      navigate("/");
    }
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleIcon = isOpen ? (
    leftIcon && text ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.8273 12.7391C16.7801 12.8533 16.7 12.9509 16.5972 13.0196C16.4944 13.0883 16.3736 13.125 16.25 13.125H3.75C3.62632 13.1251 3.50539 13.0885 3.40252 13.0198C3.29964 12.9512 3.21946 12.8535 3.17211 12.7392C3.12477 12.625 3.11239 12.4992 3.13655 12.3779C3.1607 12.2566 3.22031 12.1452 3.30782 12.0578L9.55782 5.80782C9.61586 5.74971 9.68479 5.70361 9.76067 5.67215C9.83654 5.6407 9.91787 5.62451 10 5.62451C10.0821 5.62451 10.1635 5.6407 10.2393 5.67215C10.3152 5.70361 10.3841 5.74971 10.4422 5.80782L16.6922 12.0578C16.7796 12.1453 16.8391 12.2567 16.8631 12.3779C16.8872 12.4992 16.8747 12.6249 16.8273 12.7391Z"
          fill="currentColor"
        />
      </svg>
    ) : leftIcon ? (
      ""
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.8273 12.7391C16.7801 12.8533 16.7 12.9509 16.5972 13.0196C16.4944 13.0883 16.3736 13.125 16.25 13.125H3.75C3.62632 13.1251 3.50539 13.0885 3.40252 13.0198C3.29964 12.9512 3.21946 12.8535 3.17211 12.7392C3.12477 12.625 3.11239 12.4992 3.13655 12.3779C3.1607 12.2566 3.22031 12.1452 3.30782 12.0578L9.55782 5.80782C9.61586 5.74971 9.68479 5.70361 9.76067 5.67215C9.83654 5.6407 9.91787 5.62451 10 5.62451C10.0821 5.62451 10.1635 5.6407 10.2393 5.67215C10.3152 5.70361 10.3841 5.74971 10.4422 5.80782L16.6922 12.0578C16.7796 12.1453 16.8391 12.2567 16.8631 12.3779C16.8872 12.4992 16.8747 12.6249 16.8273 12.7391Z"
          fill="currentColor"
        />
      </svg>
    )
  ) : leftIcon && text ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.6922 7.94219L10.4422 14.1922C10.3841 14.2503 10.3152 14.2964 10.2393 14.3279C10.1635 14.3593 10.0821 14.3755 10 14.3755C9.91787 14.3755 9.83654 14.3593 9.76067 14.3279C9.68479 14.2964 9.61586 14.2503 9.55782 14.1922L3.30782 7.94219C3.22031 7.85478 3.1607 7.74337 3.13655 7.62207C3.11239 7.50076 3.12477 7.37502 3.17211 7.26076C3.21946 7.14649 3.29964 7.04884 3.40252 6.98017C3.50539 6.91151 3.62632 6.8749 3.75 6.875H16.25C16.3737 6.8749 16.4946 6.91151 16.5975 6.98017C16.7004 7.04884 16.7805 7.14649 16.8279 7.26076C16.8752 7.37502 16.8876 7.50076 16.8635 7.62207C16.8393 7.74337 16.7797 7.85478 16.6922 7.94219Z"
        fill="currentColor"
      />
    </svg>
  ) : leftIcon ? (
    ""
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.6922 7.94219L10.4422 14.1922C10.3841 14.2503 10.3152 14.2964 10.2393 14.3279C10.1635 14.3593 10.0821 14.3755 10 14.3755C9.91787 14.3755 9.83654 14.3593 9.76067 14.3279C9.68479 14.2964 9.61586 14.2503 9.55782 14.1922L3.30782 7.94219C3.22031 7.85478 3.1607 7.74337 3.13655 7.62207C3.11239 7.50076 3.12477 7.37502 3.17211 7.26076C3.21946 7.14649 3.29964 7.04884 3.40252 6.98017C3.50539 6.91151 3.62632 6.8749 3.75 6.875H16.25C16.3737 6.8749 16.4946 6.91151 16.5975 6.98017C16.7004 7.04884 16.7805 7.14649 16.8279 7.26076C16.8752 7.37502 16.8876 7.50076 16.8635 7.62207C16.8393 7.74337 16.7797 7.85478 16.6922 7.94219Z"
        fill="currentColor"
      />
    </svg>
  );

  return (
    <>
       <Helmet>
      <title>
       {` Beep: ${
        activeSection === "login" ? "Login" :
        activeSection === "verifyMobOtp" ? "Verify OTP" :
        activeSection === "profileSetUp" ? "Profile Details" : ""       
       }`}
      </title>
    </Helmet>
      {}
      <div className="w-full h-[100vh] bg-white inline-flex justify-center items-center overflow-auto no-scrollbar">
        <div className="w-[50%] h-[100vh] p-16 hidden xl:flex">
          <LeftBanner />
        </div>
        <div
          className={`w-[100%] xl:w-[50%] h-[100vh] px-4 py-4 md:px-16 md:py-20 flex ${
            activeSection ? "justify-center" : "justify-center items-center"
          } overflow-auto no-scrollbar`}
        >
          {activeSection === "login" && (
            <Login
              onSetFormData={onPhoneNoChange}
              form={form}
              setForm={setForm}
              handleSendOTP={handleSendOTP}
              missing={missing}
              activeSubSection={activeSubSection}
              setActiveSubSection={setActiveSubSection}
              setActiveSection={setActiveSection}
              jobId={jobId}
            />
          )}

          {activeSection === "verifyMobOtp" && (
            <VerifyMobOtp
              otp={otp}
              onOtpChange={(e) => {
                setOtp(e.target.value);
              }}
              onVerify={handleVerifyOTP}
              missing={missing}
              setActiveSection={setActiveSection}
            />
          )}

          {activeSection === "profileSetUp" && (
            <ProfileSetUp
              options={options}
              missing={missing}
              form={form}
              onLogoChange={onLogoChange}
              onGenderChange={onGenderChange}
              selectedGender={selectedGender}
              onSetFormData={onSetFormData}
              activeSubSection={activeSubSection}
              setActiveSubSection={setActiveSubSection}
              OnUpdateProfile={OnUpdateProfile}
              onPhoneNoChange={onPhoneNoChange}
              onCampusChange={onCampusChange}
              selectedCampus={selectedCampus}
              campusData={campusData}
              OnUpdateCampusInfo={OnUpdateCampusInfo}
              onUpdateSkills={onUpdateSkills}
              onJobTypeChange={onJobTypeChange}
              onWorkPlaceChange={onWorkPlaceChange}
              onUpdateWorkPref={onUpdateWorkPref}
              setForm={setForm}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              setUserData={setUserData}
              setCampusData={setCampusData}
              setSelectedGender={setSelectedGender}
              setSelectedCampus={setSelectedCampus}
              userData={userData}
              searchQuery={searchQuery}
              selectedCompanyId={selectedCompanyId}
              selectedCompanyName={selectedCompanyName}
              filteredCompanies={filteredCompanies}
              handleOptionClick={handleOptionClick}
              handleCompanySelect={handleCompanySelect}
              remove={remove}
              handleInputClick={handleInputClick}
              isOpen={isOpen}
              menuRef={menuRef}
              toggleIcon={toggleIcon}
              isCollegeNotFound={isCollegeNotFound}
              setIsCollegeNotFound={setIsCollegeNotFound}
            />
          )}
        </div>
      </div>
    </>
  );
};
