import React, { useState, useEffect } from "react";
import DropDownButton from "../../../components/buttons/dropDownButton";
import TextInput from "../../../components/input_fields/textInput";
import {
  getLocations,
  getSkills,
  searchJobProfileApiCall,
} from "../../../services/loginOrSignUp";

const SearchComponent = ({
  type, //type for skill or location
  placeholder, //add desire placeholder
  setForm, //setform is to set selected data to the parents state
  labelText, //title label
  form, //to show initial data from the state
  className,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedTags, setSelectedTags] = useState(
    type === "skill"
      ? form.skills
      : type === "location"
      ? form.location
      : type === "jobprofilePref"
      ? form.designations
      : []
  );

  console.log(selectedTags);

  useEffect(() => {
    // Function to make the API call with the searchValue and authToken
    const searchLocApiCall = async () => {
      try {
        // Assuming your API endpoint is 'your-search-api-endpoint'

        const data = await getLocations(searchValue);
        console.log(data);
        setSearchResults(data); // Assuming the API returns an array of search results
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    const searchSkillApiCall = async () => {
      try {
        // Assuming your API endpoint is 'your-search-api-endpoint'

        const data = await getSkills(searchValue);
        console.log(data);
        setSearchResults(data); // Assuming the API returns an array of search results
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };
    const searchGetJobProfile = async () => {
      try {
        const data = await searchJobProfileApiCall(searchValue);
        console.log(data);
        setSearchResults(data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };
    if (type === "location") {
      if (searchValue !== "") {
        // Call the API only if searchValue is not empty
        searchLocApiCall();
      } else {
        // Clear the search results if searchValue is empty
        setSearchResults([]);
      }
    } else if (type === "skill") {
      if (searchValue !== "") {
        // Call the API only if searchValue is not empty
        searchSkillApiCall();
      } else {
        // Clear the search results if searchValue is empty
        setSearchResults([]);
      }
    } else if (type === "jobprofilePref") {
      if (searchValue !== "") {
        // Call the API only if searchValue is not empty
        searchGetJobProfile();
      } else {
        // Clear the search results if searchValue is empty
        setSearchResults([]);
      }
    }

    if (type === "location") {
      if (selectedTags?.length > 0) {
        setForm((prev) => ({
          ...prev,
          location: selectedTags,
        }));
      }
    } else if (type === "skill") {
      if (selectedTags?.length > 0) {
        setForm((prev) => ({
          ...prev,
          skills: selectedTags,
        }));
      }
    } else if (type === "jobprofilePref") {
      if (selectedTags?.length > 0) {
        setForm((prev) => ({
          ...prev,
          designations: selectedTags,
        }));
      }
    }
  }, [searchValue]);

  const handleTagSelect = (tag) => {
    setSelectedTags((prevTags) => {
      // Ensure prevTags is an array or initialize it as an array
      const tagsArray = Array.isArray(prevTags) ? prevTags : [];

      // Add the selected tag to the array of selectedTags
      return [...tagsArray, tag];
    });

    // Clear the search results and searchValue
    setSearchResults([]);
    setSearchValue("");
  };

  const handleTagRemove = (tag) => {
    // Remove the selected tag from the array of selectedTags
    setSelectedTags((prevTags) => prevTags.filter((t) => t !== tag));
  };

  return (
    <div>
      {selectedTags?.length > 0 && (
        <div
          className={`border border-gray rounded-xl p-2 w-full w-auto flex flex-wrap ${className}`}
        >
          {selectedTags?.map((tag) => (
            <div
              key={tag}
              className={`w-max mr-1 my-[2px] ${
                type === "skill" ? "bg-gray-100" : ""
              } rounded-2xl border-2 border-gray-200 justify-start items-center flex`}
            >
              <div className="px-2 py-1 justify-start items-center gap-1 flex flex-wrap">
                <div className="text-teal-950 text-sm font-medium font-['Satoshi Variable'] leading-tight">
                  {tag}
                </div>
              </div>
              <div className="p-1 justify-start items-center flex cursor-pointer">
                <div className="w-5 h-5 " onClick={() => handleTagRemove(tag)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M10 2.375C8.39303 2.375 6.82214 2.85152 5.486 3.74431C4.14985 4.6371 3.10844 5.90605 2.49348 7.3907C1.87852 8.87535 1.71762 10.509 2.03112 12.0851C2.34463 13.6612 3.11846 15.1089 4.25476 16.2452C5.39106 17.3815 6.8388 18.1554 8.4149 18.4689C9.99099 18.7824 11.6247 18.6215 13.1093 18.0065C14.594 17.3916 15.8629 16.3502 16.7557 15.014C17.6485 13.6779 18.125 12.107 18.125 10.5C18.1227 8.34581 17.266 6.28051 15.7427 4.75727C14.2195 3.23403 12.1542 2.37727 10 2.375ZM12.9422 12.5578C13.0003 12.6159 13.0463 12.6848 13.0777 12.7607C13.1092 12.8366 13.1254 12.9179 13.1254 13C13.1254 13.0821 13.1092 13.1634 13.0777 13.2393C13.0463 13.3152 13.0003 13.3841 12.9422 13.4422C12.8841 13.5003 12.8152 13.5463 12.7393 13.5777C12.6634 13.6092 12.5821 13.6253 12.5 13.6253C12.4179 13.6253 12.3366 13.6092 12.2607 13.5777C12.1848 13.5463 12.1159 13.5003 12.0578 13.4422L10 11.3836L7.94219 13.4422C7.88412 13.5003 7.81518 13.5463 7.73931 13.5777C7.66344 13.6092 7.58213 13.6253 7.5 13.6253C7.41788 13.6253 7.33656 13.6092 7.26069 13.5777C7.18482 13.5463 7.11588 13.5003 7.05782 13.4422C6.99975 13.3841 6.95368 13.3152 6.92226 13.2393C6.89083 13.1634 6.87466 13.0821 6.87466 13C6.87466 12.9179 6.89083 12.8366 6.92226 12.7607C6.95368 12.6848 6.99975 12.6159 7.05782 12.5578L9.11641 10.5L7.05782 8.44219C6.94054 8.32491 6.87466 8.16585 6.87466 8C6.87466 7.83415 6.94054 7.67509 7.05782 7.55781C7.17509 7.44054 7.33415 7.37465 7.5 7.37465C7.66586 7.37465 7.82492 7.44054 7.94219 7.55781L10 9.61641L12.0578 7.55781C12.1159 7.49974 12.1848 7.45368 12.2607 7.42225C12.3366 7.39083 12.4179 7.37465 12.5 7.37465C12.5821 7.37465 12.6634 7.39083 12.7393 7.42225C12.8152 7.45368 12.8841 7.49974 12.9422 7.55781C13.0003 7.61588 13.0463 7.68482 13.0777 7.76069C13.1092 7.83656 13.1254 7.91788 13.1254 8C13.1254 8.08212 13.1092 8.16344 13.0777 8.23931C13.0463 8.31518 13.0003 8.38412 12.9422 8.44219L10.8836 10.5L12.9422 12.5578Z"
                      fill="#667984"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="">
        <TextInput
          labelText={labelText}
          textColor="gray"
          type="text"
          className={className}
          textType="input"
          placeholder={placeholder}
          rightIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.7394 18.5777C17.8152 18.5462 17.8842 18.5001 17.9422 18.442C18.0003 18.384 18.0464 18.315 18.0779 18.2391C18.1093 18.1633 18.1255 18.0819 18.1255 17.9998C18.1255 17.9177 18.1093 17.8363 18.0779 17.7605C18.0464 17.6846 18.0003 17.6157 17.9422 17.5576L14.0313 13.6467C15.164 12.2876 15.7291 10.5442 15.6092 8.77906C15.4893 7.01393 14.6935 5.36298 13.3873 4.16962C12.0812 2.97626 10.3653 2.33235 8.59657 2.37183C6.82781 2.41132 5.14236 3.13115 3.89079 4.38161C2.63923 5.63207 1.9179 7.31689 1.87685 9.08561C1.83581 10.8543 2.4782 12.5708 3.67041 13.878C4.86262 15.1851 6.51286 15.9824 8.27788 16.1039C10.0429 16.2254 11.7868 15.6618 13.1469 14.5303L17.0578 18.442C17.1159 18.5001 17.1848 18.5462 17.2607 18.5777C17.3366 18.6091 17.4179 18.6253 17.5 18.6253C17.5822 18.6253 17.6635 18.6091 17.7394 18.5777ZM11.8751 13.9268C10.9501 14.5449 9.86254 14.8748 8.75002 14.8748C7.25869 14.8732 5.82891 14.28 4.77438 13.2255C3.71984 12.1709 3.12668 10.7411 3.12502 9.24981C3.12502 8.13729 3.45492 7.04975 4.07301 6.12473C4.69109 5.1997 5.5696 4.47873 6.59743 4.05299C7.62526 3.62725 8.75626 3.51585 9.84741 3.73289C10.9386 3.94994 11.9408 4.48566 12.7275 5.27233C13.5142 6.05901 14.0499 7.06128 14.2669 8.15243C14.484 9.24357 14.3726 10.3746 13.9468 11.4024C13.5211 12.4302 12.8001 13.3087 11.8751 13.9268Z"
                fill="#667984"
              />
            </svg>
          }
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
        <ul
          className={`w-auto max-h-[200px] overflow-scroll no-scrollbar bg-white border-l border-r rounded-xl ${className}`}
        >
          {searchResults?.map((result) => (
            <li
              key={result.value}
              onClick={() => handleTagSelect(result.label)}
            >
              <DropDownButton
                textColor="gray"
                color="gray"
                text={result.label}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SearchComponent;
