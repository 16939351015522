import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "./compnanets/avatar";
import EditButtonWithPenIcon from "./compnanets/editButton";
import AddButtonWithPlusIcon from "./compnanets/addButton";
import SkillsBadge from "./compnanets/skills";
import MultiSelectComp from "../../components/select_fields/multiSelectComp";
import SearchComponent from "../sign_up_flow/utils/tagSelect";
import TagSelect from "../../components/select_fields/tagSelect";
import axios from "axios";
import Calendar from "../../components/calender/calender";
import InputFieldComp from "../../components/input_fields/inputFieldComp";
import SingleSelectComp from "../../components/select_fields/singleSelectComp";
import { getCampusData } from "../../services/loginOrSignUp";
import TextInput from "../../components/input_fields/textInput";
import moment from "moment";
import SocialComponent from "./social_component";
import ContactComponent from "./contact_component";
import FillButton from "../../components/buttons/fillButton";
import Loader from "../../components/loader/loader";

const ProfilePage = ({isEdited}) => {
  const [isAboutMeEdit, setIsAboutMeEdit] = useState(false);
  const [aboutMeHeight, setAboutMeHeight] = useState(5);
  //   const [isEducationOnEdit, setIsEducatiOnEdit] = useState([]);
  //   const [isExperienceOnEdit, setIsExperienceOnEdit] = useState([]);
  const [isSkillEdit, setIsSkillEdit] = useState(false);
  const [campusData, setCampusData] = useState([]);
  const [isMainProfileEdit, setIsMainProfileEdit] = useState(false);
//   const [locations, setLocations] = useState({location:[]})
  const [skills, setSkills] = useState({skills: []})

  const [userData, setUserData] = useState({});

  const [userEducationData, setUserEducationData] = useState([]);
  const [userExperienceData, setUserExperienceData] = useState([]);
  const [experienceExpanded, setExperienceExpanded] = useState(false);
  const [educationExpanded, setEducationExpanded] = useState(false);
  const [userSkills, setUserSkills] = useState([]);
  const [userContactData, setUserContactData] =  useState({});
  const [userSocialData, setUserSocailData] = useState([]);

  const [isSocaislEditing, setIsSocialEditing] = useState([]);
  const [isSocialEditOn, setIsSocaislEditOn] = useState(false);
  const [missingEducation, setMissingEducation] = useState([]);
  const [missingExperience, setMissingExperience] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [avatarForm, setAvatarForm] =  useState(null);
  const [avatarDisplay, setAvatarDisplay] =  useState(null);
  const [anyEdit, setAnyEdit] = useState(false);


  const [contactDetails, setContactDetails] = useState({
    phoneNo:"", isPhoneVerified:false, email:"", isEmailVerified:false
  });

  useEffect(()=>{
    if(isEdited){
      isEdited(anyEdit);
    }
  }, [anyEdit])

  useEffect(()=>{
    if(isSkillEdit) setAnyEdit(true)
  }, [isSkillEdit])
  const [missingValues, setMissingValues] = useState({});


  const avatarUploadRef = useRef();


  const handleImageChange = (e) => {
    setAnyEdit(true)
    if(e.target.files.length>0){
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      setAvatarForm(e.target.files[0]);
      reader.onload = (e) => {
        setAvatarDisplay(e.target.result);
      };
      reader.readAsDataURL(file);
    }}
    else {setAvatarDisplay(null); setAvatarForm(null);}
  };


  const loadpROFILE = async () => {
    const url = `${process.env.REACT_APP_baseURL}/user/work_profile`;
    console.log(url);
    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .catch((error) => {
        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
          // setAlertData(error.response?.data.message);
          console.log(error.response?.data.message);
      });
if(response?.data){
  setTimeout(()=>{
    setLoading(false);
  },1000)

    setAboutMeHeight(countNoOfLines(response?.data?.work_profile?.bio));

    let education = response?.data?.work_profile?.education ?? [];
    let experience = response?.data?.work_profile?.experience ?? [];

    // education = education?.map((edu)=>({id: Date.now(), ...edu}))
    if (education) {
      // education = [{ id: Date.now(), ...education }];
      console.log(education, "education")
      setUserEducationData(education);
  } else {
      console.error("education is not defined:", education);
      education = []; // Set education to an empty array or another default value
  }
    experience = experience?.map((exp)=>({id: Date.now(), ...exp}))

    //   let educationEdit = new Array(education.length).fill(false);
    //   let experienceEdit = new Array(experience.length).fill(false);

    //   setIsEducatiOnEdit(educationEdit)
    //   setIsExperienceOnEdit(experienceEdit)
    
    console.log(education)

    
    setUserExperienceData(experience);
    setIsMainProfileEdit(false)
    setMissingValues(false)
    setIsAboutMeEdit(false)
    setAnyEdit(false)
    setIsSkillEdit(false)
    setMissingEducation(new Array(education.length).fill({}));
    setMissingExperience(new Array(experience.length).fill({}));

    setUserContactData(response?.data?.work_profile?.userData);
    setUserSocailData(response?.data?.work_profile?.portfolioUrls);
    setIsSocialEditing(new Array(response?.data?.work_profile?.portfolioUrls?.length ?? 0))
    
    const userDataTemp = response?.data?.work_profile?.userData;
    if(userDataTemp){
        let phoneNo = userDataTemp.tempNumber?userDataTemp.tempNumber:userDataTemp.number;
        let isPhoneVerified = userDataTemp.tempNumber?false:userDataTemp.isPhoneVerified;

        let email = userDataTemp.tempEmail?userDataTemp.tempEmail:userDataTemp.email;
        let isEmailVerified = userDataTemp.tempEmail?false: userDataTemp.isEmailVerified;

        setContactDetails({phoneNo, isEmailVerified,isPhoneVerified, email})
    }

    console.log(response, experience);

    setUserData(response?.data?.work_profile);
    // setLocations({location:response?.data?.work_profile?.userData?.locations})
    setSkills({skills:response?.data?.work_profile?.skills})

    const data = await getCampusData();
    const processedData = data?.data?.campusList?.map((item) => ({
      value: item._id,
      label: item.campusName,
    }));
    console.log(processedData, "prcess");
    setCampusData(processedData);
  }
  };

  const addNewEducation = () => {
    setEducationExpanded(true);
    setUserEducationData((prev) => [{ isEditing: true, id: Date.now() }, ...prev]);
    setMissingEducation((prev) => [{ }, ...prev]);
    setAnyEdit(true)
  };

  const removeUserEducation = (deleteIndex) => {
    setUserEducationData((prev) =>
      prev.filter((data, index) => index != deleteIndex)
    );
    setMissingEducation((prev) =>
    prev.filter((data, index) => index != deleteIndex))
    setAnyEdit(true)
  };

  const editUserEducation = (editIndex) => {
    setUserEducationData((prev) => {
      const temp = [...prev];
      temp[editIndex]["isEditing"] = true;
      return temp;
    });
    setAnyEdit(true)
  };

  const editUserExperience = (editIndex) => {
    setUserExperienceData((prev) => {
      const temp = [...prev];
      temp[editIndex]["isEditing"] = true;
      return temp;
    });
    setAnyEdit(true)
  };

  const removeUserExperience = (deleteIndex) => {
    setUserExperienceData((prev) =>
      prev.filter((data, index) => index != deleteIndex)
    );
    setMissingExperience((prev) =>
    prev.filter((data, index) => index != deleteIndex))
    setAnyEdit(true)
  };

  const updateUserExperience = (name, data, index) => {
    setUserExperienceData((prev) => {
      const temp = [...prev];
      temp[index][name] = data;
      return temp;
    });
    setAnyEdit(true)
  };
  const updateUserEducation = (name, data, index) => {
    setUserEducationData((prev) => {
      const temp = [...prev];
      temp[index][name] = data;
      return temp;
    });
    setAnyEdit(true)
  };

  const addNewExperience = () => {
    setExperienceExpanded(true);
    setUserExperienceData((prev) => [{ isEditing: true, id: Date.now() }, ...prev]);
    setMissingExperience((prev) => [{ }, ...prev]);
    setAnyEdit(true)
  };

  const finalProcessData = () => {
    education = userEducationData.map((edu) => {
      delete edu.isEditing;
      return edu;
    });

    experience = userExperienceData.map((exp) => {
      delete exp.isEditing;
      return exp;
    });

    return { education, experience };
  };

  useEffect(() => {
    loadpROFILE();

    // setUserData(temp);
  }, []);

  const countNoOfLines = (text) => {
    return (text??"").split("\n").length;
  };

  const removeSocial =(deleteIndex)=>{
    setUserSocailData(prev=>{
        return prev.filter((_,index)=>index!=deleteIndex);
    })
    setAnyEdit(true)

    setIsSocialEditing(prev=>{
        return prev.filter((_,index)=>index!=deleteIndex);
    })
  }
  const updateSocaialData = (url, index) => {
    setUserSocailData((prev) => {
      const temp = [...prev];
      temp[index] = url;
      return temp;
    });
    setAnyEdit(true)
  };


  const addSocialData = () => {
    setUserSocailData((prev) => {
      // Check if prev is defined and is an array
      if (Array.isArray(prev)) {
        return [...prev, ""];
      } else {
        // Handle the case when prev is not an array
        console.error("Previous state is not an array:", prev);
        return []; // Or handle it differently based on your requirements
      }
    });
  
    setIsSocialEditing((prev) => {
      if (Array.isArray(prev)) {
        return [...prev, 1];
      } else {
        console.error("Previous state is not an array:", prev);
        return [];
      }
    });
  
    setAnyEdit(true);
  };
  

  

  const checkExperience = ()=>{
    let missing =  false;
    setMissingExperience(userExperienceData.map(({companyName,
        title,
        description,
        location,
        startDate,
        endDate,
        workPlace,}, index)=>{ 
            let temp   ={};

        if(!companyName) {temp['companyName']= true; missing=true}
        if(!title) {temp['title']= true; missing=true}
        // if(!description) {temp['description']= true; missing=true}
        // if(!location ) {temp['location']= true; missing=true}
        if(!startDate ) {temp['startDate']= true; missing=true}
        if(!endDate && endDate!=='' ) {temp['endDate']= true; missing=true}
        if(!workPlace) {temp['workPlace']= true; missing=true}
        return temp;
    }))
    return missing;

  }

  const checkEducation = ()=>{
    let missing =  false;
    setMissingEducation(userEducationData.map(({
        institute,
        course,
        startDate,
        endDate})=>{ 
            let temp   ={};

        if(!institute) {temp['institute']= true; missing=true}
        if(!course) {temp['course']= true; missing=true}
        if(!startDate ) {temp['startDate']= true; missing=true}
        if(!endDate && endDate!=='' ) {temp['endDate']= true; missing=true}
        return temp;
    }))
    return missing;

  }

  const preProcessEducationAndExperience =()=>({

    education: userEducationData.map((edu)=>{
        delete edu.isEditing;
        delete edu.id;
        return edu;
    }),
    experience: userExperienceData.map((exp)=>{
        delete exp.isEditing;
        delete exp.id;
        return exp;
    }),
    
  })

  const onSubmit =async()=>{
    if(checkExperience() || checkEducation() || checkMissingValues()) {
        console.log("Error", "onsubmit")
        return;
    }
    
    
    

    let temp ={ ...userData, ...preProcessEducationAndExperience(), portfolioUrls:userSocialData, skills: skills.skills};
    let userProfile = temp.userData.profileInfo;

    delete temp.userData;
    delete temp.email
    
    //user/professional
    //user/work_profile
    //user/updateUserPersonInfo
    const url = `${process.env.REACT_APP_baseURL}/user/work_profile`;
    
    const response1 = axios
      .post(url,temp, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .catch((error) => {
        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
          // setAlertData(error.response?.data.message);
          console.log(error.response?.data.message);
      });

      let formData = new FormData();

      if(avatarForm)
      formData.append('upload', avatarForm);
      formData.append('name', userProfile.name);

      const url2 = `${process.env.REACT_APP_baseURL}/user/updatePersonalInfo`;
    
      const response2 = axios
        .post(url2,formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .catch((error) => {
          console.log(error.response?.data);
          if (error.response && error.response?.data.message)
            // setAlertData(error.response?.data.message);
            console.log(error.response?.data.message);
        });

        await Promise.all([response1, response2])
        if(response1 || response2) {
          setLoading(true);
        }

      loadpROFILE()
  }

  const updateName = (name)=>{

    setUserData(prev=>{
        console.log(prev)
        const {userData} = {...prev};
        userData["profileInfo"]['name']=name;
        return {...prev, userData:{...userData}}
    })
    setAnyEdit(true)

  }

  const updateUserData= (bio, field)=>{
    console.log(bio, field)
    setUserData(prev=>{
        
        const temp = {...prev};
        temp[field] =bio;
        return {...temp};
    })
    setAnyEdit(true)
  }

  const updateLocation = (e)=>{
    console.log(e, "update loc")
  }

  const checkMissingValues = ()=>{
    let temp={};
    // console.log(skills, "skill", skills.skills?.length<1)
    
    // if(!userData.currentLocation) temp['currentLocation']=true;
    if(skills.skills?.length<1) temp['skills']=true;
    // if(!userData?.currentlyAt) temp['currentlyAt']=true;
    // if(!userData?.bio) temp['bio']=true;
    if(!userData?.userData?.profileInfo.name) temp['name']=true;
    setMissingValues(temp)
    
    return Object.keys(temp).length!=0
  }


  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };

    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount

  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader />;
  }

  return (
    <div className="w-full flex justify-center items-center mx-auto">
      <div className="w-full mx-auto   bg-white flex justify-start items-start gap-6 ">
        {/* <div className="self-stretch h-[0px] "></div> */}
        {/* large sec */}
        <div className="w-full flex-col justify-start items-start gap-6 flex">
          {/* <div className="self-stretch h-[58px] flex-col justify-start items-start gap-1 flex">
            <div className="w-[228px] h-7 text-gray-800 text-lg font-semibold    leading-[28.80px]">
              Preview & Apply
            </div>
            <div className="text-slate-700 text-base font-normal  leading-relaxed">
              This is how the recruiters will view your profile. Review and edit
              it below before applying.
            </div>
          </div> */}

          {/* start */}

          {/* profile */}
          <div className="flex flex-col w-full items-end justify-center gap-[24px] pt-0 pb-[24px] px-0 relative bg-neutralswhite border border-solid border-neutrals-300">
            <header className="relative w-full  bg-transparent bg-[url(https://test12345647872448cc6099.blob.core.windows.net/test12345647872448cc6099/795e8007fd6cfb71065a3f4e130c59af.webp)] bg-top bg-[100%_auto]">
              <div className="relative w-fit  top-[70px] left-[48px]">
                <Avatar
                  username="ten"
                  className="!h-[156px] !left-[-8px] !w-[156px] !top-[-8px]"
                  imageSrc={avatarDisplay? avatarDisplay:( userData?.userData?.profileInfo?.avatar || `https://ui-avatars.com/api/?name=${userData?.userData?.profileInfo?.name}`)}
                  onClick={()=>avatarUploadRef.current.click()}
                />
                <input className="hidden" accept="image/*" type="file"  ref={avatarUploadRef} onChange={handleImageChange}/>
              </div>
            </header>
            <div className=" pt-16 md:pt-8 xl:pt-0 flex flex-col sx:flex-row  w-full flex ml-0 justify-start items-start sx:justify-between sx:items-center px-2 md:px-0">
              <div className="hidden xl:flex md:w-1/4 w-full"></div>
              <div
                className={`${
                  isMainProfileEdit ? " w-full " : " ml-0 sm:ml-3 md:ml-0 sm:3/4 md:w-2/4 "
                } px-2 inline-flex flex-col items-start gap-[16px] relative `}
              >
                
                <div className="w-auto sm:w-max pl-0 sm:pl-6 text-teal-950 text-[32px] font-bold  leading-[38.40px]">
                  
                  {isMainProfileEdit ? (
                    
                    <TextInput
                      textType="input"
                      textColor="gray"
                      type="text"
                      labelText="Name"
                      hasError={missingValues.name}
                      value={userData?.userData?.profileInfo.name}
                      onChange={(e)=>updateName(e.target.value)}
                      placeholder="Current Location"
                    />
                  ) : (
                    userData?.userData?.profileInfo.name
                  )}
                </div>

                <div className=" min-h-5 justify-start items-center gap-2 inline-flex">
                  <div className="w-5  pl-[3.12px] pr-[3.13px] pt-[0.62px] pb-[0.63px] justify-center items-center flex">
                    <svg
                      width="14"
                      height="20"
                      viewBox="0 0 14 20"
                      className="w-[20px]"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="Vector"
                        d="M13.7066 9.80175L4.95662 19.1768C4.86389 19.2757 4.7415 19.3418 4.6079 19.3651C4.4743 19.3884 4.33676 19.3676 4.21601 19.3059C4.09527 19.2441 3.99788 19.1448 3.93855 19.0229C3.87921 18.9009 3.86115 18.763 3.88709 18.6299L5.0324 12.901L0.530055 11.2103C0.433361 11.1742 0.347132 11.1146 0.279073 11.037C0.211013 10.9594 0.163243 10.8661 0.140029 10.7655C0.116815 10.6649 0.118881 10.5601 0.146042 10.4605C0.173203 10.3609 0.224613 10.2696 0.29568 10.1947L9.04568 0.819721C9.13841 0.720764 9.2608 0.654652 9.3944 0.631359C9.52799 0.608067 9.66554 0.628858 9.78628 0.690597C9.90703 0.752335 10.0044 0.851671 10.0637 0.973613C10.1231 1.09555 10.1411 1.23349 10.1152 1.3666L8.96677 7.10175L13.4691 8.79003C13.5651 8.82645 13.6506 8.88594 13.7182 8.96326C13.7857 9.04058 13.8331 9.13334 13.8563 9.23334C13.8795 9.33335 13.8777 9.43753 13.851 9.53666C13.8243 9.6358 13.7737 9.72684 13.7035 9.80175H13.7066Z"
                        fill="#16C083"
                      />
                    </svg>
                  </div>
                  <div className="w-full text-slate-700 text-md md:text-lg font-medium capitalize leading-tight">
                    {isMainProfileEdit ? (
                      <TextInput
                        textType="input"
                        textColor="gray"
                        type="text"
                        hasError={missingValues.currentlyAt}
                        value={userData?.currentlyAt}
                        labelText="Status"
                          onChange={(e)=>updateUserData(e.target.value, "currentlyAt")}
                        placeholder="Current status"
                      />
                    ) : (
                      userData?.currentlyAt ? userData?.currentlyAt : "Add Status"
                    )}
                  </div>
                </div>

                <div className="min-h-5 justify-start items-center gap-2 inline-flex">
                  <div
                    className={`w-5 h-5 pl-[3.12px] pr-[3.13px] pt-[0.62px] pb-[0.63px] justify-center items-center flex `}
                  >
                    <svg
                      width="14"
                      height="18"
                      viewBox="0 0 14 18"
                      className="w-[20px]"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="Vector"
                        d="M7 0.25C5.17727 0.252068 3.42979 0.97706 2.14092 2.26592C0.85206 3.55479 0.127068 5.30227 0.125 7.125C0.125 13.0078 6.375 17.4508 6.64141 17.6367C6.74649 17.7103 6.87169 17.7498 7 17.7498C7.12831 17.7498 7.25351 17.7103 7.35859 17.6367C7.625 17.4508 13.875 13.0078 13.875 7.125C13.8729 5.30227 13.1479 3.55479 11.8591 2.26592C10.5702 0.97706 8.82273 0.252068 7 0.25ZM7 4.625C7.49445 4.625 7.9778 4.77162 8.38893 5.04633C8.80005 5.32103 9.12048 5.71148 9.3097 6.16829C9.49892 6.62511 9.54843 7.12777 9.45196 7.61273C9.3555 8.09768 9.1174 8.54314 8.76777 8.89277C8.41814 9.2424 7.97268 9.4805 7.48773 9.57696C7.00277 9.67343 6.50011 9.62392 6.04329 9.4347C5.58648 9.24548 5.19603 8.92505 4.92133 8.51393C4.64662 8.1028 4.5 7.61945 4.5 7.125C4.5 6.46196 4.76339 5.82607 5.23223 5.35723C5.70107 4.88839 6.33696 4.625 7 4.625Z"
                        fill="#16C083"
                      />
                    </svg>
                  </div>
                  <div className="w-full text-slate-700 text-md md:text-lg font-medium capitalize leading-tight">
                    {!isMainProfileEdit ? (
                      userData?.currentLocation ? userData?.currentLocation : "Add Location"
                    ) : (
                      <TextInput
                        textType="input"
                        textColor="gray"
                        type="text"
                        labelText="Location"
                        hasError={missingValues.currentLocation}
                        value={userData?.currentLocation}
                        onChange={(e)=>updateUserData(e.target.value, "currentLocation")}
                        placeholder="Current Location"
                      />
                    
                    )}
                  </div>
                </div>
              </div>
              {!isMainProfileEdit && (
                <div className="w-1/4 flex justify-center mx-10 sx:mx-4 my-2 sx:my-0">
                  <div
                    onClick={() => setIsMainProfileEdit(true)}
                    className=" h-[50px] px-6 py-3 rounded-[10px] border border-neutral-300 justify-center items-center inline-flex"
                  >
                    <div className="text-center text-emerald-500 font-bold text-[12px] leading-relaxed">
                      Edit Profile
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* end */}

          <div className="flex-col gap-6 flex md:hidden w-full ">
          <ContactComponent contactDetails={contactDetails}/>
          <SocialComponent 
          socailData={userSocialData} removeSocial={removeSocial} updateSocaialData={updateSocaialData} addSocialData={addSocialData} 
          isSocaislEditing ={isSocaislEditing}
            setIsSocialEditing ={setIsSocialEditing}
            isSocialEditOn ={isSocialEditOn}
            setIsSocaislEditOn ={setIsSocaislEditOn}
          />
        </div>

          {/* About me */}
          <div className=" w-full p-2 sm: p-6 bg-white border border-neutral-300 flex-col justify-start items-start gap-4 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-start items-center gap-2 flex">
                <div className="w-5 h-5 pl-[0.30px] pr-[0.32px] pt-[4.38px] pb-[4.37px] justify-center items-center flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
  <path fill="#16C083" d="M10.925 6.252a.625.625 0 0 1 .625-.625h7.5a.625.625 0 0 1 0 1.25h-7.5a.625.625 0 0 1-.625-.625Zm8.125 3.125h-7.5a.625.625 0 0 0 0 1.25h7.5a.625.625 0 0 0 0-1.25Zm0 3.75h-5.625a.625.625 0 1 0 0 1.25h5.625a.625.625 0 0 0 0-1.25ZM8.213 11.096a3.75 3.75 0 1 0-4.576 0c-1.611.682-2.88 2.055-3.317 3.75a.625.625 0 0 0 .605.781h10a.626.626 0 0 0 .606-.781c-.437-1.696-1.707-3.069-3.318-3.75Z"/>
</svg>

                </div>
                <div className="text-teal-950 text-xl font-bold  leading-normal">
                  About Me
                </div>
              </div>
              {!isAboutMeEdit && (
                <EditButtonWithPenIcon
                  onClick={() => {
                    setIsAboutMeEdit((prev) => !prev);
                    setAnyEdit(true)
                    console.log("called");
                  }}
                />
              )}
            </div>
            <div className="w-full text-slate-700 text-base font-normal    leading-relaxed">
              {isAboutMeEdit ? (
                <div className="w-full">
                  <TextInput
                    // labelText={"Description"}
                    
                    className="inputBox"
                    textColor="gray"
                    textType="textarea"
                    disabled={false}
                    leftTextClass={`w-max`}
                    placeClass="text-black w-max"
                    hasError={missingValues.bio}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setUserData((prev) => ({
                        ...prev,
                        bio: e.target.value,
                      }));
                      setAboutMeHeight(countNoOfLines(e.target.value));
                    }}
                    placeholder={`Enter text....`}
                    //   hasError={missing.courseName}
                    value={userData.bio}
                    //   onChange={onSetFormData}
                  />
                </div>
              ) : (
                // <textarea
                //   rows={aboutMeHeight}
                //   onChange={(e) => {

                //   }}
                //   className="h-fit outline-none p-3 block w-full h-100 border  border-gray-200"
                //   value={userData.bio}
                // ></textarea>
                <div className="w-full text-slate-700 flex flex-wrap text-base font-normal p-3 border border-white  leading-relaxed ">
                  {userData?.bio ? userData?.bio : "Add a summary about you"}
                </div>
              )}
            </div>
          </div>

          {/* Experience */}
          <div className="self-stretch px-px py-6 bg-white border border-neutral-300 flex-col justify-start items-center flex">
            <div className="self-stretch px-6 justify-between items-center inline-flex">
              <div className="justify-start items-center gap-2 flex">
                <div className="w-5 h-5 px-[1.88px] pt-[1.88px] pb-[3.12px] justify-center items-center flex">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.875 7.75C10.875 7.91576 10.8092 8.07473 10.6919 8.19194C10.5747 8.30915 10.4158 8.375 10.25 8.375H7.75C7.58424 8.375 7.42527 8.30915 7.30806 8.19194C7.19085 8.07473 7.125 7.91576 7.125 7.75C7.125 7.58424 7.19085 7.42527 7.30806 7.30806C7.42527 7.19085 7.58424 7.125 7.75 7.125H10.25C10.4158 7.125 10.5747 7.19085 10.6919 7.30806C10.8092 7.42527 10.875 7.58424 10.875 7.75ZM17.125 4.625V14.625C17.125 14.9565 16.9933 15.2745 16.7589 15.5089C16.5245 15.7433 16.2065 15.875 15.875 15.875H2.125C1.79348 15.875 1.47554 15.7433 1.24112 15.5089C1.0067 15.2745 0.875 14.9565 0.875 14.625V4.625C0.875 4.29348 1.0067 3.97554 1.24112 3.74112C1.47554 3.5067 1.79348 3.375 2.125 3.375H5.25V2.75C5.25 2.25272 5.44754 1.77581 5.79917 1.42417C6.15081 1.07254 6.62772 0.875 7.125 0.875H10.875C11.3723 0.875 11.8492 1.07254 12.2008 1.42417C12.5525 1.77581 12.75 2.25272 12.75 2.75V3.375H15.875C16.2065 3.375 16.5245 3.5067 16.7589 3.74112C16.9933 3.97554 17.125 4.29348 17.125 4.625ZM6.5 3.375H11.5V2.75C11.5 2.58424 11.4342 2.42527 11.3169 2.30806C11.1997 2.19085 11.0408 2.125 10.875 2.125H7.125C6.95924 2.125 6.80027 2.19085 6.68306 2.30806C6.56585 2.42527 6.5 2.58424 6.5 2.75V3.375ZM15.875 7.87578V4.625H2.125V7.87578C4.23455 9.02404 6.59819 9.62543 9 9.625C11.4018 9.62543 13.7655 9.02404 15.875 7.87578Z"
                      fill="#16C083"
                    />
                  </svg>
                </div>
                <div className="text-teal-950 text-xl font-bold  leading-normal">
                  Experiences
                </div>
              </div>
              <AddButtonWithPlusIcon onClick={() => addNewExperience()} />
            </div>

            {(experienceExpanded
              ? userExperienceData
              : userExperienceData.slice(
                  0,
                  Math.min(userExperienceData.length, 2)
                )
            ).map((experience, index) => (
              <>
                {experience.isEditing ? (
                  <div key={experience.id} className="w-full flex flex-col px-6">
                    <div className="flex flex-col-reverse  md:flex-row md:gap-2  justify-center items-end md:items-center">
                      <div className="w-full">
                        <InputFieldComp
                          type="text"
                          labelText="Company Name"
                          placeholder="Amazon"
                          name="companyName"
                            hasError={missingExperience[index]['companyName']}
                            errorMsg={missingExperience[index]['companyName'] ? "Please enter Your experience details":""}
                          value={experience.companyName}
                          onChange={(e) =>
                            updateUserExperience(
                              "companyName",
                              e.target.value,
                              index
                            )
                          }
                        />
                      </div>

                      <div
                        className="w-fit h-7 p-1 pt-8 rounded-xl justify-center items-center gap-1 md:flex-row-reverse inline-flex cursor-pointer"
                        onClick={() => removeUserExperience(index)}
                      >
                        <div className="text-red-600 text-sm font-black  leading-tight">
                          Remove
                        </div>
                        <div className="w-5 h-5 relative">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Binary / Minus Circle">
                              <path
                                id="Vector"
                                d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.125 10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z"
                                fill="#DB1717"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div className="md:flex md:gap-2">
                      <div className="w-full">
                        <InputFieldComp
                          type="text"
                          labelText="Designation"
                          placeholder="Product Manager"
                          name="title"
                          hasError={missingExperience[index]['title']}
                          value={experience.title}
                          onChange={(e) =>
                            updateUserExperience("title", e.target.value, index)
                          }
                        />
                      </div>

                      <div className="w-full py-spaceBase">
                        <SingleSelectComp
                          // errorMsg={"Optional"}
                          options={["offfice", "hybrid", "remote"]}
                            hasError={missingExperience[index]["workPlace"]}
                          labelText="Workplace Type"
                          absoluteClass="w-[10rem]"
                          selectedOption={experience.workPlace}
                          onChange={(e) => {
                            updateUserExperience(
                              "workPlace",
                              e.target.value,
                              index
                            );
                          }}
                        />
                      </div>
                    </div>

                    {
                      <>
                        <div className="md:flex gap-2">
                          <div className="w-full ">
                            <InputFieldComp
                              type="text"
                              labelText="Location"
                              placeholder="Delhi"
                              name="location"
                              inforText={"Optional"}
                              hasError={missingExperience[index]['location']}
                              value={experience.location}
                              onChange={(e) =>
                                updateUserExperience(
                                  "location",
                                  e.target.value,
                                  index
                                )
                              }
                            />
                          </div>
                          <div className="flex-col w-full justify-center items-center space-between gap-2 py-spaceBase">
                            <div className="opacity-0">k</div>
                            <div className="flex">
                              <label className="label cursor-pointer">
                                <input
                                  type="checkbox"
                                  checked={experience.endDate == ""}
                                  // onChange={(e)=>updateUserExperience("endDate", e, index)}
                                  onChange={(e) => {
                                    updateUserExperience(
                                      "endDate",
                                      e.target.checked
                                        ? ""
                                        : new Date().toISOString(),
                                      index
                                    );
                                  }}
                                  className="w-5 h-5 border-none rounded-sm border-2 focus:border-emerald-500 accent-emerald-500 text-white"
                                />
                                <span className="text-teal-950 text-sm font-medium font-satoshi leading-tight mx-3 ">
                                  Currently Working
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="w-full flex flex-col md:flex-row gap-3">
                          <div className="w-full">
                            <Calendar
                              labelText="From"
                              hasError={missingExperience[index]['startDate']}
                              onSelectDate={(e)=>updateUserExperience(
                                "startDate",
                                e.toISOString(),
                                index
                              )}
                              initialDate={experience.startDate}
                              dateFormat={{ month: "short", year: "numeric" }}
                              inputPlaceholder="Select start Date"
                            />
                          </div>
                          <div className="w-full">
                            <Calendar
                              labelText="To"
                              hasError={missingExperience[index]['endDate']}
                              onSelectDate={(e)=>updateUserExperience(
                                "endDate",
                                e.toISOString(),
                                index
                              )}
                              initialDate={experience.endDate}
                              dateFormat={{ month: "short", year: "numeric" }}
                              inputPlaceholder="Select end Date"
                            />
                          </div>
                        </div>

                        <div className="w-full">
                          <TextInput
                            labelText={"Description"}
                            className="inputBox"
                            textColor="gray"
                            textType="textarea"
                            disabled={false}
                            leftTextClass={`w-max`}
                            placeClass="text-black w-max"
                            //   hasError={hasError}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              console.log(e);
                              updateUserExperience(
                                "description",
                                e.target.value,
                                index
                              );
                            }}
                            placeholder={`Select Date....`}
                            //   hasError={missing.courseName}
                            value={experience.description}
                            //   onChange={onSetFormData}
                          />
                        </div>
                      </>
                    }
                    {/* <div className={index<userExperienceData.length-1&& `border-n border-[#C9CFD4]`}></div> */}
                    {index !=
                      (experienceExpanded
                        ? userExperienceData.length - 1
                        : 1) && (
                      <div className="w-full mx-auto h-px bg-neutral-300 mt-4" />
                    )}
                  </div>
                ) : (
                  <div key={experience.id} className="self-stretch p-6 bg-white rounded-xl justify-center items-center gap-6 flex-col">
                    <div className="w-full flex-col justify-start items-start gap-[11px] inline-flex">
                      <div className="self-stretch  flex-col justify-start items-start gap-1.5 flex">
                        <div className="self-stretch justify-between items-center inline-flex">
                          <div className="text-teal-950 text-lg font-semibold    leading-[28.80px]">
                            {experience.title}
                          </div>

                          <EditButtonWithPenIcon
                            onClick={() => editUserExperience(index)}
                          />
                        </div>
                        <div className="self-stretch h-fit justify-start items-center gap-px inline-flex">
                          <div className="justify-center items-start md:items-center  gap-2 flex flex-col md:flex-row">
                            <div className="w-full flex-wrap text-teal-950 text-base font-medium  leading-relaxed">
                              {experience.companyName}
                            </div>
                            <div className="w-1 h-1 bg-gray-400 rounded-full hidden md:inline" />
                            <div className="w-full flex flex-wrap text-slate-700 text-base font-normal  leading-relaxed capitalize">
                              {experience.workPlace}
                            </div>
                            <div className="w-1 h-1 bg-gray-400 rounded-full hidden md:inline" />
                            <div className="text-slate-700 text-base font-normal  leading-relaxed">
                              {moment(experience.startDate).format("MMM YYYY")}{" "}
                              {" - "}{" "}
                              {experience.endDate
                                ? moment(experience.endDate).format("MMM YYYY")
                                : "Present"}
                            </div>
                          </div>
                        </div>
                        <div className="w-full flex flex-wrap text-slate-500 text-base font-normal  leading-relaxed">
                          {experience.location}
                        </div>
                      </div>
                      <div className="w-full flex flex-wrap text-teal-950 text-base font-normal  leading-relaxed">
                        {experience.description}
                      </div>
                    </div>
                    {/* <div className={`border h-px border-[#C9CFD4]`}></div> */}
                    {(index !=
                      (experienceExpanded
                        ? userExperienceData.length - 1
                        : Math.min(userExperienceData.length - 1,1)) )&& (
                      <div className="w-7/8 mx-auto h-px bg-neutral-300 mt-4" />
                    )}
                  </div>
                )}
              </>
            ))}

            {!experienceExpanded && userExperienceData.length > 2 && (
              <div
                className="justify-end items-center gap-4 inline-flex"
                onClick={() => setExperienceExpanded(true)}
              >
                <div className="w-full text-center text-emerald-500 text-base font-bold  leading-relaxed">
                  Show {userExperienceData.length - 2} more experiences
                </div>
              </div>
            )}
          </div>

          {/* Education */}
          <div className="self-stretch px-px py-6 bg-white border border-neutral-300 flex-col justify-start items-center flex">
            <div className="self-stretch px-6 justify-between items-center inline-flex">
              <div className="justify-start items-center gap-2 flex">
                <div className="w-5 h-5 pt-[1.88px] pb-[0.62px] justify-center items-center flex">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7498 16.1905C14.1809 16.0207 14.5988 15.8188 14.9998 15.5866V18.7499C14.9998 18.9157 14.934 19.0746 14.8167 19.1918C14.6995 19.3091 14.5406 19.3749 14.3748 19.3749C14.209 19.3749 14.0501 19.3091 13.9329 19.1918C13.8157 19.0746 13.7498 18.9157 13.7498 18.7499V16.1905ZM14.6686 9.28193L10.2936 6.94834C10.1476 6.87378 9.97818 6.85955 9.82182 6.9087C9.66546 6.95786 9.53466 7.06647 9.45762 7.21114C9.38057 7.35581 9.36344 7.52495 9.40991 7.68213C9.45638 7.83931 9.56273 7.97194 9.70606 8.05146L13.3592 9.9999L14.6873 9.29209L14.6686 9.28193ZM19.6686 6.94834L10.2936 1.94833C10.2031 1.90018 10.1023 1.875 9.99981 1.875C9.89736 1.875 9.79648 1.90018 9.70606 1.94833L0.331055 6.94834C0.231058 7.00163 0.14743 7.0811 0.0891172 7.17825C0.0308042 7.27541 0 7.38659 0 7.4999C0 7.61321 0.0308042 7.72439 0.0891172 7.82154C0.14743 7.91869 0.231058 7.99817 0.331055 8.05146L2.4998 9.20849V12.9913C2.49916 13.2983 2.61214 13.5947 2.81699 13.8233C3.84043 14.9632 6.1334 16.8749 9.99981 16.8749C11.2818 16.8855 12.5542 16.6533 13.7498 16.1905V10.2085L13.3592 9.9999L9.99981 11.7913L3.42402 8.28115L1.95293 7.4999L9.99981 3.20849L18.0467 7.4999L16.5795 8.28115H16.5748L14.6873 9.29209C14.7823 9.34695 14.8612 9.42587 14.9161 9.52091C14.971 9.61595 14.9998 9.72376 14.9998 9.83349V15.5866C15.8148 15.1161 16.5513 14.5212 17.1826 13.8233C17.3875 13.5947 17.5005 13.2983 17.4998 12.9913V9.20849L19.6686 8.05146C19.7686 7.99817 19.8522 7.91869 19.9105 7.82154C19.9688 7.72439 19.9996 7.61321 19.9996 7.4999C19.9996 7.38659 19.9688 7.27541 19.9105 7.17825C19.8522 7.0811 19.7686 7.00163 19.6686 6.94834Z"
                      fill="#16C083"
                    />
                  </svg>
                </div>
                <div className="text-teal-950 text-xl font-bold  leading-normal">
                  Education
                </div>
              </div>
              <AddButtonWithPlusIcon onClick={() => addNewEducation()} />
            </div>
            {
            (
                educationExpanded?
               userEducationData
              : userEducationData.slice(
                  0,
                  Math.min(userEducationData.length, 2)
                )
            )
            .map((edu, index) => (
              <div key={edu.id+index} className="w-full px-6">
                {edu.isEditing ? (
                  <div className="w-full flex flex-col">
                    <div className="w-full flex gap-1 items-center justify-center">

                    <div className="w-full">
                      {/* <SingleSelectComp
                      options={campusData}
                      //   hasError={missing.campusName}
                      labelText="Select your college"
                      absoluteClass="w-[10rem]"
                      selectedOption={edu.institute}
                      //   onChange={onCampusChange}
                    /> */}
                      <InputFieldComp
                        type="text"
                        labelText="Select your college"
                        placeholder="IIT Madras"
                        name="institute"
                          hasError={missingEducation[index]["institute"]}
                          errorMsg={missingEducation[index]["institute"] ? "Please add your Education Details.": ""}
                        value={edu.institute}
                        onChange={(e) =>
                          updateUserEducation(
                            "institute",
                            e.target.value,
                            index
                          )
                        }
                      />
                    </div>

                    <div
                        className="w-fit h-7 p-1 pt-8 rounded-xl justify-center items-center gap-1 md:flex-row-reverse inline-flex cursor-pointer"
                        onClick={() => removeUserEducation(index)}
                      >
                        <div className="text-red-600 text-sm font-black  leading-tight">
                          Remove
                        </div>
                        <div className="w-5 h-5 relative">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Binary / Minus Circle">
                              <path
                                id="Vector"
                                d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.125 10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z"
                                fill="#DB1717"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>

                    </div>
                    

                    {
                      <>
                        <div className="w-full">
                          <InputFieldComp
                            type="text"
                            labelText="Course Name"
                            placeholder="B.Tech in Computer Science"
                            name="courseName"
                            hasError={missingEducation[index]["course"]}
                            value={edu.course}
                            onChange={(e) =>
                              updateUserEducation(
                                "course",
                                e.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div className="flex">
                          <label className="label cursor-pointer">
                            <input
                              type="checkbox"
                              checked={edu.endDate == ""}
                              onClick={(e) =>
                                updateUserEducation(
                                  "endDate",
                                  e.target.checked
                                    ? ""
                                    : new Date().toISOString(),
                                  index
                                )
                              }
                              className="w-5 h-5 border-none rounded-sm border-2 focus:border-emerald-500 accent-emerald-500 text-white"
                            />
                            <span className="text-teal-950 text-sm font-medium font-satoshi leading-tight mx-3 ">
                              Currently Pursuing
                            </span>
                          </label>
                        </div>
                        <div className="w-full flex flex-col md:flex-row gap-3">
                          <div className="w-full">
                            <Calendar
                              labelText="From"
                              hasError={missingEducation[index]["startDate"]}
                              onSelectDate={(e)=>updateUserEducation(
                                "startDate",
                                e.toISOString(),
                                index
                              )}
                              initialDate={edu.startDate}
                              dateFormat={{ month: "short", year: "numeric" }}
                              inputPlaceholder="Select start Date"
                            />
                          </div>
                          <div className="w-full">
                            <Calendar
                              labelText="To"
                              hasError={missingEducation[index]["endDate"]}
                              onSelectDate={(e)=>updateUserEducation(
                                "endDate",
                                e.toISOString(),
                                index
                              )}
                              initialDate={edu.endDate}
                              dateFormat={{ month: "short", year: "numeric" }}
                              inputPlaceholder="Select end Date"
                            />
                          </div>
                        </div>
                      </>
                    }
                  </div>
                ) : (
                  <div className="w-full py-6 bg-white rounded-xl justify-start items-start gap-6 inline-flex">
                    <div className="w-full flex-col justify-start items-start gap-[11px] inline-flex">
                      <div className="self-stretch h-[104px] flex-col justify-start items-start gap-1.5 flex">
                        <div className="self-stretch justify-between items-center inline-flex">
                          <div className="text-teal-950 text-lg font-semibold    leading-[28.80px]">
                            {edu.institute}
                          </div>
                          <EditButtonWithPenIcon
                            onClick={() => editUserEducation(index)}
                          />
                        </div>
                        <div className="text-slate-500 text-base font-normal  leading-relaxed">
                          {edu.course}
                        </div>

                        <div className="text-slate-500 text-base font-normal  leading-relaxed">
                          {new Date(edu.startDate).getFullYear()} {" - "}{" "}
                          {edu.endDate
                            ? new Date(edu.endDate).getFullYear()
                            : "Present"}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(index <
                  (educationExpanded ? userEducationData.length - 1 : Math.min(userEducationData.length - 1,1)) )&& (
                  <div className="w-7/8 px-8 mx-auto h-px bg-neutral-300 mt-4 " />
                )}
              </div>
            ))}

            <div className="w-full053px] h-px bg-neutral-300" />

            {!educationExpanded && userEducationData.length > 2 && (
              <div
                className="justify-end items-center gap-4 inline-flex"
                onClick={() => setExperienceExpanded(true)}
              >
                <div className="w-full text-center text-emerald-500 text-base font-bold  leading-relaxed">
                  Show {userEducationData.length - 2} more educations
                </div>
              </div>
            )}
          </div>

          {/* Skills */}
          <div className="self-stretch h-fit p-6 bg-white border border-neutral-300 flex-col justify-start items-start gap-4 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="h-6 justify-start items-center gap-2 flex">
                <div className="w-5 h-5 px-[1.87px] pt-[1.88px] pb-[1.87px] justify-center items-center flex">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1249 7.49984C18.1258 8.43533 17.8934 9.35627 17.4488 10.1793C17.0041 11.0023 16.3612 11.7015 15.5782 12.2134C14.7952 12.7254 13.8969 13.034 12.9647 13.1113C12.0324 13.1885 11.0955 13.0321 10.239 12.6561L6.17177 17.3608C6.16239 17.3717 6.15146 17.3834 6.1413 17.3936C5.67241 17.8625 5.03645 18.1259 4.37333 18.1259C3.71022 18.1259 3.07426 17.8625 2.60536 17.3936C2.13647 16.9247 1.87305 16.2887 1.87305 15.6256C1.87305 14.9625 2.13647 14.3266 2.60536 13.8577C2.6163 13.8475 2.62724 13.8366 2.63896 13.8272L7.34364 9.76078C6.92213 8.79657 6.77927 7.73353 6.93126 6.69224C7.08325 5.65096 7.52401 4.6731 8.20354 3.8696C8.88308 3.0661 9.77418 2.46912 10.7758 2.14637C11.7774 1.82361 12.8494 1.78801 13.8702 2.04359C13.9754 2.06997 14.072 2.12328 14.1504 2.19829C14.2288 2.27329 14.2863 2.36741 14.3172 2.47138C14.3482 2.57534 14.3516 2.68559 14.3271 2.79126C14.3025 2.89693 14.2509 2.99439 14.1772 3.07406L11.2499 6.24984L11.6921 8.30844L13.7499 8.74984L16.9257 5.81859C17.0053 5.74496 17.1028 5.69333 17.2085 5.66878C17.3142 5.64424 17.4244 5.64762 17.5284 5.6786C17.6323 5.70957 17.7264 5.76708 17.8015 5.84546C17.8765 5.92384 17.9298 6.02039 17.9561 6.12563C18.0683 6.57509 18.125 7.0366 18.1249 7.49984Z"
                      fill="#16C083"
                    />
                  </svg>
                </div>
                <div className="text-teal-950 text-xl font-bold  leading-normal">
                  Skills
                </div>
              </div>
              <div className="justify-start items-center gap-2 flex">
                {/* <AddButtonWithPlusIcon /> */}
                {!isSkillEdit && (
                  <EditButtonWithPenIcon onClick={() => setIsSkillEdit(true)} />
                )}
              </div>
            </div>
            <div className="flex flex-wrap justify-start gap-2 w-full">
              {!isSkillEdit ? (
                userData?.skills?.map((skill) => (
                  <SkillsBadge className="" skill={skill} />
                ))
              ) : (
                <TagSelect
                  type="skill"
                  className="w-full block"
                  placeholder="Add skills"
                  form={skills}
                  hasError={missingValues.skills}
                  setForm={setSkills}
                />
              )}
            </div>
          </div>

          {/* <div className="self-stretch h-[0px] border"></div> */}
          {/* <div className="self-stretch h-[92px] py-2 flex-col justify-center items-start gap-2 flex">
            <div className="px-6 py-3 bg-emerald-500 rounded-xl justify-center items-center gap-2.5 inline-flex">
              <div className="text-center text-white text-base font-bold  leading-relaxed">
                Send your application
              </div>
            </div>
            <div className="text-red-600 text-xs font-black font-['Poppins'] leading-[18px]">
              • Closes in 2 Days •
            </div>
          </div> */}
          {(!isEdited || anyEdit) && <FillButton color={'green'} onClick={onSubmit} 
          textColor="white"
          
          disabled={!anyEdit}
          text={"Save changes"}
          />}
         
        </div>

        {/* small sec */}
        <div className="w-full flex-col gap-6 hidden md:flex">
          <ContactComponent contactDetails={contactDetails}/>
          <SocialComponent 
          socailData={userSocialData} removeSocial={removeSocial} updateSocaialData={updateSocaialData} addSocialData={addSocialData} 
          isSocaislEditing ={isSocaislEditing}
            setIsSocialEditing ={setIsSocialEditing}
            isSocialEditOn ={isSocialEditOn}
            setIsSocaislEditOn ={setIsSocaislEditOn}
          />
        </div>
        
      </div>
      
    </div>
  );
};

export default ProfilePage;
