import React from "react";
import { deactivateAccount } from "../../services/settingsflow";
import { useNavigate } from "react-router-dom";

function DeleteMyAccount({ onClose }) {
  const navigate = useNavigate();
  const handleDeactivateaccount = async () => {
    const response = await deactivateAccount();
    if (response.message === "User deleted") {
      localStorage.clear();
      navigate("/");
    }
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 w-full h-full backdrop-filter backdrop-blur-sm"
        onClick={onClose}
      ></div>
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center ">
        <div className="w-full max-w-xs md:max-w-2xl px-4 py-14 bg-white rounded-2xl shadow flex-col justify-start items-center gap-6 ">
          <div className="flex flex-col max-w-lg mx-auto mb-8 justify-start items-start gap-4 ">
            <div className="w-full justify-center items-center gap-2 flex">
              <h2 className="self-stretch text-teal-950 text-xl font-black font-satoshi capitalize leading-normal">
                Deactivate Account
              </h2>
            </div>
            <div className="w-full text-center">
              <p className="text-slate-700 text-sm font-normal font-satoshi leading-normal">
                This will <span className="font-bold">permanently delete</span>{" "}
                all your account details stored with us. But, It will{" "}
                <span className="font-bold">NOT delete</span> your details that
                Recruiters already have.
              </p>
            </div>
          </div>
          <div className="w-full max-w-lg mx-auto  flex flex-col md:flex-row justify-between items-center gap-4 ">
            <button
              onClick={onClose}
              className="hover:bg-teal-900 px-6 py-3 w-full  bg-teal-950 rounded-xl text-white font-satoshi"
            >
              Keep my account
            </button>
            <button
              onClick={handleDeactivateaccount}
              className="px-6 py-3 w-full hover:bg-red-400 bg-red-300 rounded-xl text-white font-satoshi"
            >
              Delete my account
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteMyAccount;
