import React, { Suspense, useEffect, useRef, useState } from "react";
import { Navbar } from "../landingPage/navbar/navbar";
import YoutubeVideo from "./components/AllPrograms/youtubeSharkTank";
import { JobPlacement } from "./components/AllPrograms/jobPlacement";
import { MicroDegree } from "./components/AllPrograms/microDegree";

import { ProgramComp } from "./components/AllPrograms/program";
import { Footer } from "../landingPage/footer/footer";
import { PlacementStories } from "./components/AllPrograms/placementStories";
import { PlacementProgramFlow } from "./components/AllPrograms/placementProgramFlow";
import { BackupByTheBest } from "./components/AllPrograms/backupByTheBest";
import { PlacedStudents } from "./components/AllPrograms/placedStudents";
import { allCoursesForLoggedUser, allCoursesForNotLogged, courseReview, joinWaitlist } from "../../services/courses";
import { useNavigate } from "react-router";
import Loader from "../../components/loader/loader";
import { getUserDetails } from "../../services/loginOrSignUp";
import { UserCount } from "./components/AllPrograms/userCount";
import { FeaturedLogos } from "./components/AllPrograms/featuredLogos";
import { Helmet } from "react-helmet-async";



export const ProgramContainer = () => {
    const [courseData, setCourseData] = useState([]);
    const [reviewData, setReviewData] = useState([]);
    const [userData, setUserData] = useState("");
    const microDegreeRef = useRef(null);
    const jobPlacementRef = useRef(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);




    const formatDate = (dateString) => {
        const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'Asia/Kolkata' };
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('en-US', options).toUpperCase();
        const [monthDay, time] = formattedDate.split(',');
        return `${monthDay} | ${time}`;
      };

      const scrollToTop = (section) => {
        if (section === "microDegree" && microDegreeRef.current) {
          microDegreeRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (section === "jobPlacement" && jobPlacementRef.current) {
          jobPlacementRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };

      const redirectToProgramDetails = (id, type, userInWaitlist, userIsRegister) => {
        // Redirect to program-details page
        navigate(`/program-details?id=${id}&type=${type}&waitlist=${userInWaitlist}&register=${userIsRegister}`); // Replace with your actual route
      };
    
      const redirectToLogin = (id) => {
        navigate(`/join-waitlist/login?id=${id}`); // Redirect to login page
      };

      const joinWaitingList = async (id)=>{
        const response = await joinWaitlist(id)
        if(response){
            navigate(`/join-waitlist/success?id=${id}`)
            console.log(response)
        }
    }

    useEffect(()=>{
     const auth = localStorage?.getItem('auth')
        const CourseList = async ()=>{
          try {
            let response;
            if (auth) {
                response = await allCoursesForLoggedUser();
            } else {
                response = await allCoursesForNotLogged();
            }
            console.log(response?.data);
            setCourseData(response?.data);
        } catch (error) {
            console.error("Error fetching course list:", error);
        } finally {
            setIsLoading(false); // Set loading to false after the fetch is done
        }
        }
        const data = async ()=>{
          const user = await getUserDetails();
          console.log(user, "tttt")
          setUserData(user)
          }
          data();
        const ReviewList = async ()=>{
            const response = await courseReview();
            console.log(response?.data?.data);
            setReviewData(response?.data?.data)
        }
        CourseList();
        ReviewList();
        window.scrollTo(0, 0);
    },[])

    if (isLoading) {
      return <Loader />; // Show loader while loading
  }

  return (
<>
<Helmet>
      <title>
      Programs
      </title>
      </Helmet>
  <div className="w-screen h-auto bg-[#000000] ">
       <div className="w-full">
     <Navbar textColor="textBlack" />
    <ProgramComp 
    reviewData={reviewData}
    />
    </div>
    <div className=" flex flex-col justify-center items-center">
        <div className="w-full h-auto" ref={microDegreeRef}>
    <MicroDegree 
    redirectToProgramDetails={redirectToProgramDetails}
    redirectToLogin={redirectToLogin}
courseData={courseData}
formatDate={formatDate}
userData={userData}
joinWaitingList={joinWaitingList}
    />
    </div>
    <div className="w-full h-auto" ref={jobPlacementRef}>
   <JobPlacement 
   joinWaitingList={joinWaitingList}
   userData={userData}
   redirectToProgramDetails={redirectToProgramDetails}
   redirectToLogin={redirectToLogin}
   courseData={courseData}
   formatDate={formatDate}
   />
    </div>
    <div className="w-full h-auto">
      <UserCount />
    </div>
    <div className="w-full h-auto">
      <FeaturedLogos />
    </div>
       <div className="w-full mx-auto">
                <Suspense fallback={<div>Loading...</div>}>
                    <YoutubeVideo videoId="_nYVSo8Tg7o" thumbnailUrl="attachment2.png" userData={userData} />
                  </Suspense>
                </div> 
                <PlacedStudents />
    <PlacementStories 

    reviewData={reviewData}

    />
    <PlacementProgramFlow 
    scrollToTop={scrollToTop}
    />
    <BackupByTheBest />
    
 
    </div>
  
    <div>
        <Footer />
    </div>
  </div>
  </>
  )
};
