import React, { useState, useEffect } from "react";

const ButtonChild = ({
  leftIcon,
  text,
  rightIcon,
  buttonClass,
  isLoading,
  disabled,
  className,
  textColor,
  leftIconClass,
  RightIconClass,
  textClass,
  rightText,
  rightTextClass,
  leftText,
  leftTextClass,
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const colorMap = {
    red: {
      default:
        "text-red-500 hover:cursor:pointer active:cursor-auto hover:text-red-400 active:text-red-550",
      hover: "hover:text-red-400",
      active: "active:text-red-550",
      pressed: "text-red-550",
      disabled: "cursor-not-allowed text-red-100 ",
      loading: "text-red-300 ",
    },
    green: {
      default:
        "text-green-500 hover:cursor:pointer active:cursor-auto hover:text-green-400 active:text-green-550",
      hover: "hover:text-green-400",
      active: "active:text-green-550",
      pressed: "text-green-550",
      disabled: "cursor-not-allowed text-green-100 ",
      loading: "text-green-300 ",
    },
    orange: {
      default:
        "text-orange-500 hover:cursor:pointer active:cursor-auto hover:text-orange-400 active:text-orange-550",
      hover: "hover:text-orange-400",
      active: "active:text-orange-550",
      pressed: "text-orange-550",
      disabled: "cursor-not-allowed text-orange-100",
      loading: "text-orange-300 ",
    },
    yellow: {
      default:
        "text-yellow-500 text-black hover:cursor-pointer active:cursor-auto hover:border-yellow-450 hover:text-yellow-400 active:text-yellow-550 hover:shadow-[rgba(250, 190, 88, 0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset] active:shadow-[rgba(250, 190, 88, 0.6)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]",
      pressed:
        "text-yellow-550 shadow-[rgbargba(250, 190, 88, 0.4)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]",
      loading: "text-yellow-300 text-black cursor-not-allowed ",
      disabled: "cursor-not-allowed  text-yellow-100 text-black",
    },
    gray: {
      default:
        "text-gray-500 hover:cursor:pointer active:cursor-auto hover:text-gray-400 active:text-gray-600",
      hover: "hover:text-gray-400",
      active: "active:text-gray-550",
      pressed: "text-gray-800",
      disabled: "cursor-not-allowed text-gray-100",
      loading: "text-gray-400 ",
    },
    white: {
      default: "text-white",
      pressed: "text-white",
    },
    lightGray: {
      default: "text-gray-200",
      pressed: "text-gray-200",
    },
    black: {
      default: "text-black",
      pressed: "text-black",
    },
  };

  // const textColorClass = isPressed
  //   ? colorMap[textColor].pressed
  //   : disabled
  //   ? `${colorMap[textColor].disabled}`
  //   : isLoading
  //   ? `${colorMap[textColor].loading} ${colorMap[textColor].disabled}`
  //   : ` ${colorMap[textColor].default} ${colorMap[textColor].hover} ${colorMap[textColor].active}}`;

  // const buttonClasses = `flex inline-flex justify-center items-center py-1 ${
  //   disabled
  //     ? `${colorMap[textColor].disabled} cursor-not-allowed `
  //     : isLoading
  //     ? `${colorMap[textColor].loading} ${colorMap[textColor].disabled}`
  //     : `${colorMap[textColor].default} ${colorMap[textColor].hover}`
  // } ${className}`;



  const textColorClass = isPressed
  ? colorMap[textColor]?.pressed || ""
  : disabled
  ? `${colorMap[textColor]?.disabled || ""}`
  : isLoading
  ? `${colorMap[textColor]?.loading || ""} ${colorMap[textColor]?.disabled || ""}`
  : ` ${colorMap[textColor]?.default || ""} ${colorMap[textColor]?.hover || ""} ${colorMap[textColor]?.active || ""}`;

const buttonClasses = `flex inline-flex justify-center items-center py-1 ${
  disabled
    ? `${colorMap[textColor]?.disabled || ""} cursor-not-allowed `
    : isLoading
    ? `${colorMap[textColor]?.loading || ""} ${colorMap[textColor]?.disabled || ""}`
    : `${colorMap[textColor]?.default || ""} ${colorMap[textColor]?.hover || ""}`
} ${className}`;


  return (
    <>
      <div
        className={`${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        } ${buttonClasses} ${
          (leftIcon || rightIcon) && text
            ? "gap-1"
            : leftIcon || rightIcon || text
            ? "gap-0"
            : "gap-0"
        } ${textColorClass} ${buttonClass}`}
        disabled={isLoading || disabled}
      >
        <div className="inline-flex gap-4">
          {leftText && (
            <div
              className={`w-max text-sm text-gray-400 font-bold font-satoshi disabled:cursor-not-allowed ${leftTextClass}`}
            >
              {leftText}
            </div>
          )}
          {leftIcon && (
            <div
              className={`w-auto h-auto inherit disabled:cursor-not-allowed ${leftIconClass}`}
            >
              {isLoading ? (
                <div className="animate-spin">
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.794A7.962 7.962 0 014 12H0c0 3.042.75 5.879 2 8.206l4-4.412z"
                    ></path>
                  </svg>
                </div>
              ) : (
                leftIcon
              )}
            </div>
          )}
        </div>

        {text && (
          <div
            className={`w-full capitalize text-sm font-bold font-satoshi disabled:cursor-not-allowed ${textClass}`}
          >
            {isLoading ? "Loading..." : text}
          </div>
        )}

        <div className=" inline-flex gap-4">
          {rightText && (
            <div
              className={`w-max text-sm text-gray-400 font-bold font-satoshi disabled:cursor-not-allowed ${rightTextClass}`}
            >
              {rightText}
            </div>
          )}
          {rightIcon && (
            <div
              className={`w-auto h-auto inherit disabled:cursor-not-allowed ${RightIconClass}`}
            >
              {isLoading ? "" : rightIcon}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ButtonChild;
