import axios from "axios";
import { formatDistanceToNow } from "date-fns";

// for not logged in users
export const allCoursesForNotLogged = async () => {
    const url = `${process.env.REACT_APP_baseURL}/course/list`;
    const response = await axios.get(url).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };

// coursedetails by id
export const courseByIdWithoutToken = async (id) => {
    const url = `${process.env.REACT_APP_baseURL}/course/id/details/${id}`;
    const response = await axios.get(url).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };

 

//   course by id with token

export const courseById = async (id) => {
    const url = `${process.env.REACT_APP_baseURL}/course/id/${id}`;
    const response = await axios.get(url,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };
  // for logged in users
export const allCoursesForLoggedUser = async () => {
    const url = `${process.env.REACT_APP_baseURL}/course`;
    const response = await axios.get(url,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };

    // for course reviews
export const courseReview = async () => {
    const url = `${process.env.REACT_APP_baseURL}/course/reviews`;
    const response = await axios.get(url).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };



//   timer

export function calculateTimeLeft(registrationStartTime) {
  const difference = new Date(registrationStartTime) - new Date();

  if (difference <= 0) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }

  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / (1000 * 60)) % 60);
  const seconds = Math.floor((difference / 1000) % 60);

  return { hours, minutes, seconds };
}




  export const RelativeTime = ({ createdAt }) => {
    const date = new Date(createdAt);
    const relativeTime = formatDistanceToNow(date, { addSuffix: true });
  
    return <span>{relativeTime}</span>;
  };


// join waitlist
  export const joinWaitlist = async (courseId, subtype) => {
    const url = `${process.env.REACT_APP_baseURL}/course/join/waitlist`;
    const response = await axios.post(url, {
        courseId:courseId,
    }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };


  
  export const registeredUser = async () => {
    const url = `${process.env.REACT_APP_baseURL}/course/registered`;
    const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };

  export const inWaitListUser = async () => {
    const url = `${process.env.REACT_APP_baseURL}/course`;
    const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };


//   create order for course
export const createRazorpayOrderLU = async (id) => {
    const url = `${process.env.REACT_APP_baseURL}/course/order?courseId=${id}&gateway=RAZORPAY`;
    const response = await axios.get(url,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };

  export const createRazorpayOrderNLU = async (id,uname,number,email,position) => {
    console.log(id,uname,number,email,position)
    const url = `${process.env.REACT_APP_baseURL}/course/anon/order?courseId=${id}&name=${uname}&number=91${number}&email=${email}&position=${position}`;
    // const response = await axios.get(url).catch((error) => {
    //   console.log(error.response?.data);
    // });
  
    // if (response) {
    //   console.log(response, "******************************");
    //   return response;
    // }
    try {
      const response = await axios.get(url);
      console.log(response, "******************************");
      return response;
    } catch (error) {
      console.error("An error occurred while creating the order:", error.response?.data || error.message);
      return { error: error.response?.data || error.message };
    }
  };

//   check order verify
export const orderVerifyLU = async (gateway, courseId, paymentId, ) => {
    const url = `${process.env.REACT_APP_baseURL}/course/verify`;
    const response = await axios.post(url,
        {
            gateway:gateway,
            courseId:courseId,
            paymentId:paymentId,
        },{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };

  export const orderVerifyNLU = async (paymentId,orderId,name,number,email,courseId,position ) => {
    const url = `${process.env.REACT_APP_baseURL}/course/anon/verify`;
    const response = await axios.post(url,
        {
          paymentId:paymentId,
          orderId:orderId,
          name:name,
          number:`+91${number.trim()}`,
          email:email,
          courseId:courseId,
          position:position,
            
        },{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }).catch((error) => {
      console.log(error.response?.data);
    });
  
    if (response) {
      console.log(response, "******************************");
      return response;
    }
  };
  

  export const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };