import React, { useEffect, useRef, useState } from 'react'

export const PlacementStories = ({reviewData}) => {
    const containerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const [contentWidth, setContentWidth] = useState(0);

    // Update container and content widths on component mount and window resize
    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.clientWidth);
                setContentWidth(containerRef.current.scrollWidth);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const scrollLeft = () => {
        if (containerRef.current) {
            const newPosition = scrollPosition - containerWidth;
            setScrollPosition(Math.max(newPosition, 0));
            containerRef.current.scrollTo({
                left: newPosition,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            const newPosition = scrollPosition + containerWidth;
            const maxScroll = contentWidth - containerWidth;
            setScrollPosition(Math.min(newPosition, maxScroll));
            containerRef.current.scrollTo({
                left: newPosition,
                behavior: 'smooth',
            });
        }
    };

  
  return (
    <div className="bg-white px-4 sm:px-8 lg:px-16 py-[70px] lg:px-[124px] w-full h-auto flex-col justify-start items-start gap-16 inline-flex">
    {/* <div className="self-stretch h-12 flex-col justify-center items-start gap-[62px] flex">
        <div className="self-stretch h-12 flex-col justify-center items-start gap-8 flex">
            <div className="self-stretch justify-center items-center gap-1 inline-flex">
                <div className="grow shrink basis-0 text-teal-950 text-[40px] font-black font-satoshi leading-[48px]">Placement Stories</div>
                <div className="justify-center items-center gap-4 flex mt-4">
                <button className="w-[30px] h-[30px] relative" disabled={scrollPosition === 0} onClick={scrollLeft}>
                    <img src='/svg/leftArrow.svg' alt="Left Arrow" style={{ cursor: 'pointer' }} />
                </button>
                <button className="w-[30px] h-[30px] relative" disabled={scrollPosition >= contentWidth - containerWidth} onClick={scrollRight}>
                    <img src='/svg/rightArrow.svg' alt="Right Arrow" style={{ cursor: 'pointer' }} />
                </button>
            </div>
            </div>
        </div>
    </div> */}
    <div className="self-stretch h-auto flex-col justify-start items-start gap-[84px] flex">
        <div className="md:hidden self-stretch justify-start items-center gap-8 inline-flex overflow-scroll no-scrollbar" ref={containerRef} >
            {reviewData?.reviews_2?.map((index)=>(
            <div key={index} className="grow shrink basis-0 px-5 py-[22px] bg-white rounded-2xl border-2 border-gray-200 flex-col justify-end items-start inline-flex" >
                <div className="h-[91px] pr-[13px] py-3 flex-col justify-end items-start gap-2.5 flex">
                    <div className="w-[275px] justify-start items-start gap-4 inline-flex">
                        <img className="w-[53.13px] h-[54px] rounded-[35px]" src={index?.image} />
                        <div className="flex-col justify-start items-start gap-[9px] inline-flex">
                            <div className="justify-start items-center gap-1.5 inline-flex">
                                <div className="p-[5px] bg-emerald-100 rounded-[7px] justify-start items-start gap-2.5 flex">
                                    <div className="text-emerald-500 text-xs font-bold font-satoshi leading-[17.88px]">{index?.from}</div>
                                </div>
                                <div className="text-slate-500 text-xs font-bold font-satoshi leading-[17.88px]"> {index?.to} at</div>
                            </div>
                            <img className="w-[74.64px] h-[30px]" src={index?.companyImage} />
                        </div>
                    </div>
                </div>
                <div className="flex-col justify-end items-start gap-4 flex">
                    <div className="flex-col justify-start items-start gap-2 flex">
                        <div className="w-8 h-8 relative">
                        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 13.6523H10.0146C10.9433 10.9628 11.7363 9.67484 14.375 8.57524C14.8154 8.39164 15.0645 7.92294 14.9707 7.45514C14.877 6.98824 14.4668 6.65234 13.9902 6.65234H13.9882C8.4833 6.66214 5.3212 8.86914 3.079 14.2636C2.3633 15.958 2 17.7714 2 19.6523C2 22.5449 2.875 24.1201 4.209 26.1933C4.3926 26.4794 4.71 26.6523 5.0498 26.6523H12C13.6543 26.6523 15 25.3066 15 23.6523V16.6523C15 14.998 13.6543 13.6523 12 13.6523ZM27 13.6523H25.0146C25.9433 10.9628 26.7363 9.67484 29.375 8.57524C29.8154 8.39164 30.0645 7.92294 29.9707 7.45514C29.877 6.98824 29.4668 6.65234 28.9902 6.65234H28.9882C23.4833 6.66214 20.3212 8.86914 18.079 14.2636C17.3633 15.958 17 17.7714 17 19.6523C17 22.5449 17.875 24.1201 19.209 26.1933C19.3926 26.4794 19.71 26.6523 20.0498 26.6523H27C28.6543 26.6523 30 25.3066 30 23.6523V16.6523C30 14.998 28.6543 13.6523 27 13.6523Z" fill="#CCD2D6"/>
</svg>

                        </div>
                        <div className="w-[279px] text-black opacity-50 text-base font-black font-satoshi leading-[17.88px]">{index?.text}</div>
                    </div>
                    <div className="text-black opacity-70 text-xs font-bold font-satoshi leading-[17.88px] tracking-tight">-{index?.name}</div>
                </div>
            </div>
            ))}
        </div>
        <div className="hidden md:flex self-stretch justify-start items-center gap-8 inline-flex flex-wrap" ref={containerRef} >
            {reviewData?.reviews_2?.map((index)=>(
            <div key={index} className="grow shrink basis-0 px-5 py-[22px] bg-white rounded-2xl border-2 border-gray-200 flex-col justify-end items-start inline-flex" >
                <div className="h-[91px] pr-[13px] py-3 flex-col justify-end items-start gap-2.5 flex">
                    <div className="w-[275px] justify-start items-start gap-4 inline-flex">
                        <img className="w-[53.13px] h-[54px] rounded-[35px]" src={index?.image} />
                        <div className="flex-col justify-start items-start gap-[9px] inline-flex">
                            <div className="justify-start items-center gap-1.5 inline-flex">
                                <div className="p-[5px] bg-emerald-100 rounded-[7px] justify-start items-start gap-2.5 flex">
                                    <div className="text-emerald-500 text-xs font-bold font-satoshi leading-[17.88px]">{index?.from}</div>
                                </div>
                                <div className="text-slate-500 text-xs font-bold font-satoshi leading-[17.88px]"> {index?.to} at</div>
                            </div>
                            <img className="h-[30px]" src={index?.companyImage} />
                        </div>
                    </div>
                </div>
                <div className="flex-col justify-end items-start gap-4 flex">
                    <div className="flex-col justify-start items-start gap-2 flex">
                        <div className="w-8 h-8 relative">
                        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 13.6523H10.0146C10.9433 10.9628 11.7363 9.67484 14.375 8.57524C14.8154 8.39164 15.0645 7.92294 14.9707 7.45514C14.877 6.98824 14.4668 6.65234 13.9902 6.65234H13.9882C8.4833 6.66214 5.3212 8.86914 3.079 14.2636C2.3633 15.958 2 17.7714 2 19.6523C2 22.5449 2.875 24.1201 4.209 26.1933C4.3926 26.4794 4.71 26.6523 5.0498 26.6523H12C13.6543 26.6523 15 25.3066 15 23.6523V16.6523C15 14.998 13.6543 13.6523 12 13.6523ZM27 13.6523H25.0146C25.9433 10.9628 26.7363 9.67484 29.375 8.57524C29.8154 8.39164 30.0645 7.92294 29.9707 7.45514C29.877 6.98824 29.4668 6.65234 28.9902 6.65234H28.9882C23.4833 6.66214 20.3212 8.86914 18.079 14.2636C17.3633 15.958 17 17.7714 17 19.6523C17 22.5449 17.875 24.1201 19.209 26.1933C19.3926 26.4794 19.71 26.6523 20.0498 26.6523H27C28.6543 26.6523 30 25.3066 30 23.6523V16.6523C30 14.998 28.6543 13.6523 27 13.6523Z" fill="#CCD2D6"/>
</svg>

                        </div>
                        <div className="w-[279px] text-black opacity-50 text-base font-black font-satoshi leading-[17.88px]">{index?.text}</div>
                    </div>
                    <div className="text-black opacity-70 text-xs font-bold font-satoshi leading-[17.88px] tracking-tight">-{index?.name}</div>
                </div>
            </div>
            ))}
        </div>
    </div>
</div>
  )
}
