import React from "react";

const AddButtonWithPlusIcon = (props) => {
  return (
    <button {...props} className="w-10 h-10 p-2.5 rounded-[10px] border border-neutral-300 justify-center items-center gap-2.5 inline-flex">
      <div className="w-5 h-5 relative">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Icon" clip-path="url(#clip0_6962_24177)">
            <path
              id="Vector (Stroke)"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0003 3.33301C10.4606 3.33301 10.8337 3.7061 10.8337 4.16634V15.833C10.8337 16.2932 10.4606 16.6663 10.0003 16.6663C9.54009 16.6663 9.16699 16.2932 9.16699 15.833V4.16634C9.16699 3.7061 9.54009 3.33301 10.0003 3.33301Z"
              fill="#16C083"
            />
            <path
              id="Vector (Stroke)_2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.33301 10.0003C3.33301 9.54009 3.7061 9.16699 4.16634 9.16699H15.833C16.2932 9.16699 16.6663 9.54009 16.6663 10.0003C16.6663 10.4606 16.2932 10.8337 15.833 10.8337H4.16634C3.7061 10.8337 3.33301 10.4606 3.33301 10.0003Z"
              fill="#16C083"
            />
          </g>
          <defs>
            <clipPath id="clip0_6962_24177">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </button>
  );
};

export default AddButtonWithPlusIcon;
