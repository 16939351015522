import React, { useEffect, useState } from "react";
import { calculateTimeLeft, formatPrice } from "../../../../services/courses";

export const TitleDegreeBanner = ({
  courseDetails,
  formatDate,
  timerStarted,
  timeLeft,
}) => {
  console.log(timeLeft);

  const bgStyle =
    courseDetails?.type === "JOB PLACEMENT"
      ? {
          backgroundImage: `url(/svg/jpBg.svg)`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }
      : {};

  return (
    <div
      className={`w-full h-full ${courseDetails?.type === "JOB PLACEMENT" ? "bg-[#000000" : "bg-gradient-to-b from-black to-[#000000]"}`}
    >
      <div className="relative px-auto py-16 flex-col justify-center items-center gap-2.5 flex">
        <div
          className="flex-col justify-center items-center gap-16 flex "
          style={{ zIndex: 1 }}
        >
          <div className="flex-col justify-start items-center gap-8 flex">
            <div className="justify-center items-center gap-[6.38px] inline-flex">
              <div className="inline-flex justify-center items-center max-w-[300px] h-[52px] relative rounded">
                <img
                  width="200"
                  className="max-w-[250px]"
                  src={courseDetails?.providerImage}
                />
              </div>
              {/* <div className="w-[18.35px] text-center text-white text-xl font-normal font-satoshi">x</div>
                        {courseDetails?.type == "MICRO DEGREE" ?
                         <img className="w-[83.77px] h-[39.09px]" src="edyoda.png" />
                         :
                         <img className="h-[39.09px]" src="/svg/jp.svg" />
                    } */}
            </div>
            {courseDetails?.type === "MICRO DEGREE" && (
              <div className="text-transparent bg-clip-text bg-gradient-to-r from-purple-500 via-white to-purple-500 text-[32px] font-black font-satoshi tracking-wider">
                {courseDetails?.type}
              </div>
            )}
            {courseDetails?.type === "JOB PLACEMENT" && (
              <div className="text-transparent bg-clip-text bg-gradient-to-r from-amber-500 via-white to-amber-500 text-[32px] font-black font-satoshi tracking-wider">
                {courseDetails?.type}
              </div>
            )}

            <div className="text-center text-neutral-50 text-[32px] lg:text-[40px] font-bold font-satoshi">
              {courseDetails?.description}
              {/* <br/>in 4 weeks */}
            </div>
          </div>
          {!courseDetails?.bypassWaitlist && (
            <div className="transparent flex-col justify-start items-center gap-[11px] flex">
              <div>
                <span className="text-gray-300 text-base font-bold font-satoshi leading-none">
                  REGISTRATION FROM{" "}
                </span>
                <span className="text-gray-300 text-base font-black font-satoshi leading-none">
                  {" "}
                  {formatDate(courseDetails?.registrationStartTime)} IST
                </span>
              </div>
              {/* <div className="text-red-500 text-sm font-black font-satoshi leading-tight">Opens in 03:10:59 Hrs</div> */}
              {timeLeft.hours >= 0 &&
              timerStarted &&
              courseDetails?.isRegistrationLive === false ? (
                <div className="text-red-500 text-sm font-black font-satoshi leading-tight">
                  Opens in {String(timeLeft.hours).padStart(2, "0")}:
                  {String(timeLeft.minutes).padStart(2, "0")}:
                  {String(timeLeft.seconds).padStart(2, "0")} Hrs
                </div>
              ) : timerStarted === false ? (
                <div className="text-red-500 text-sm font-black font-satoshi leading-tight">
                  Loading....
                </div>
              ) : !timeLeft.hours >= 0 &&
                timerStarted === true &&
                courseDetails?.isRegistrationLive === false ? (
                <div className="text-red-500 text-sm font-black font-satoshi leading-tight">
                  Registration closed.
                </div>
              ) : (
                <div className="text-green-500 text-sm font-black font-satoshi leading-tight">
                  Registration is now open.
                </div>
              )}
            </div>
          )}
          <div className="flex-col justify-start items-center gap-2.5 flex">
            <div className="flex-col justify-start items-center gap-[5px] flex">
              <div className="text-white text-sm font-bold font-satoshi leading-[18.69px]">
                Program Price
              </div>
              <div className="justify-center items-end gap-[6.32px] inline-flex">
                <div className="text-white text-[28.33px] font-black font-satoshi leading-normal">
                  ₹
                  {courseDetails?.type === "JOB PLACEMENT"
                    ? formatPrice(courseDetails?.programPrice)
                    : formatPrice(courseDetails?.finalPrice)}{" "}
                  <span className="text-white/40 text-xl font-medium font-satoshi tracking-tight line-through">
                    {" "}
                    {courseDetails?.type === "MICRO DEGREE"
                      ? `${courseDetails?.actualPrice}`
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {courseDetails?.type === "JOB PLACEMENT" &&
            courseDetails?.topSectionColors[1] === "#7A2D28" && (
              <img
                className="absolute max-h-[700px] mt-[600px] sx:mt-[400px] sm:mt-[200px] "
                style={{ zIndex: -1 }}
                src="/svg/jpBg.svg"
              />
            )}
          {courseDetails?.type === "JOB PLACEMENT" &&
            courseDetails?.topSectionColors[1] === "#725017" && (
              <img
                className="absolute max-h-[700px] mt-[600px] sx:mt-[400px] sm:mt-[200px] "
                style={{ zIndex: -1 }}
                src="/svg/jpBg2.svg"
              />
            )}
          {courseDetails?.type === "JOB PLACEMENT" &&
            courseDetails?.topSectionColors[1] === "#191772" && (
              <img
                className="absolute max-h-[700px] mt-[600px] sx:mt-[400px] sm:mt-[200px] "
                style={{ zIndex: -1 }}
                src="/svg/jpBg1.svg"
              />
            )}
        </div>
      </div>
    </div>
  );
};
