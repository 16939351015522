import React, { createContext, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const PathContext = createContext();

export const PathProvider = ({ children }) => {
  const navigate = useNavigate();

  // paths for browse-internship page
  const updatePath = useCallback(
    (jobType, category, workPlace) => {
      const updatedWorkPlace = workPlace;

      const queryParams = new URLSearchParams();
      if (updatedWorkPlace) {
        queryParams.set("workplace_type", updatedWorkPlace?.join("-"));
      }

      const queryString = queryParams.toString();
      let type = jobType;
      let categ = category;
      console.log(type, categ, "updatePath");
      if (type && categ) {
        navigate(`/${type}/${categ}?${queryString.toLowerCase()}`);
      } else if (type) {
        navigate(`/${type}?${queryString.toLowerCase()}`);
      } else if (categ) {
        navigate(`/${categ}?${queryString.toLowerCase()}`);
      } else {
        navigate(`/browse-internships`);
      }
    },
    [navigate]
  );

  // Path handled for course details page

  const goToCourseDetails = useCallback(
    (programType, programName, id, waitlist, register) => {
      const queryParams = new URLSearchParams({
        id: id,
        waitlist: waitlist.toString(),
        register: register.toString(),
      }).toString();

      navigate(
        `/programs/${programType.replace(/[\s&]+/g, "-")}/${programName
          .replace(/\//g, "-")
          .replace(/[\s&]+/g, "-")}?${queryParams}`
      );
    },
    [navigate]
  );

  // Path handled for job details page

  const goToJobDetails = useCallback(
    ({index, category, type, jobCateg, profile, id}) => {
      console.log(index,category, type,jobCateg,profile,id, "cate")
      let updatedJobType;
      if (
        (index? index?.job_info?.job_type : type) == "Full Time" ||
        index?.job_info?.job_type || type == "Part Time"
      ) {
        updatedJobType = "job";
      } else {
        updatedJobType = "internship";
      }
      const jobType = encodeURIComponent(
        updatedJobType?.replace(/[\s&]+/g, "-").replace(/,/g, "-")
      ).toLowerCase();

      const jobCategory = encodeURIComponent(
        category
          ?.find((item) => item._id === (index ? index.job_info.category : jobCateg))
          ?.name?.replace(/[\s&]+/g, "-")
          .replace(/,/g, "-")
      ).toLowerCase();
      const jobProfile = encodeURIComponent((index ? index.job_info?.job_profile : profile)?.replace(/[\s&/]+/g, "-").replace(/,/g, "-")
      ).toLowerCase();

      const jobId = index ? index?.job_id : id;
      const queryParams = new URLSearchParams({
        id: jobId,
      }).toString();

      console.log(jobType,jobCategory,jobProfile,queryParams,'params')
      navigate(
        `/${jobType.replace(
          /[\s&]+/g,
          "-"
        )}/${jobCategory}/${jobProfile}?${queryParams}`
      );
    },
    [navigate]
  );

  return (
    <PathContext.Provider
      value={{ updatePath, goToCourseDetails, goToJobDetails }}
    >
      {children}
    </PathContext.Provider>
  );
};

export const usePath = () => useContext(PathContext);
