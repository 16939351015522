import React from "react";

export const MeetYourMentor = ({ courseDetails }) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto py-8 px-8 lg:py-[72px] flex-col justify-center items-start gap-10 flex">
        <div className="w-full h-[53px] text-center text-gray-100 text-2xl font-bold font-satoshi">
          Meet your mentor
        </div>
        <div className="w-full p-4 inline-flex justify-start items-start gap-[18px] overflow-scroll no-scrollbar">
          {courseDetails?.type === "JOB PLACEMENT" &&
            courseDetails?.mentorImages?.map((image, index) => (
              <div key={index} className="min-w-[300px] h-[400px]">
                <img
                  width="300px"
                  height="400px"
                  src={image}
                  alt={`Mentor ${index}`}
                />{" "}
                {/* Ensure to add alt text for accessibility */}
              </div>
            ))}
        </div>

        <div className="w-full p-4 inline-flex justify-start lg:justify-center items-start gap-[18px] overflow-scroll no-scrollbar">
          {courseDetails?.type === "MICRO DEGREE" &&
            courseDetails?.mentorImagesWeb?.map((image, index) => (
              <div key={index} className="min-w-[300px] md:max-w-[960px]">
                <img height="290px" src={image} alt={`Mentor ${index}`} />{" "}
                {/* Ensure to add alt text for accessibility */}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
