import React, { useState, useEffect } from "react";

import { categoriesWithJobCount, fetchJobs } from "../../../../../services/landingPageApi";
import FillButton from "../../../../../components/buttons/fillButton";
import { Link, useNavigate } from "react-router-dom";
import { getSavedJobs } from "../../../../../services/jobs";
import Loader from "../../../../../components/loader/loader";
import { usePath } from "../../../../../routes/context/path/pathContext";


export const BookmarksJobList = ({
  setActiveSection,
  setJobId,
  category,
  filters,
  setFilters,
  quickFilterData,
  locationFilteredData,
  inputFilter,
  onbookmark,
  isSaved
  // setLoading,
  // loading,

}) => {
  const [allinternJob, setallInternJobs] = useState({ jobs: [] });
  const [count, setCount] = useState(0);
  const [next, setNext] = useState(true); // Assuming you have access to the 'next' value

  const [currentPage, setCurrentPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [decodedCategory, setDecodedCategory] = useState('');
  const { goToJobDetails } = usePath();


  const auth = localStorage.getItem("auth");
  
const navigate = useNavigate();

  useEffect(() => {
    const fetchJob = async () => {
      let limit = 10;
      const skip = (currentPage - 1) * limit;
      let internJobs;

      // Constructing the filters object based on non-empty filter arrays
      const appliedFilters = {};
      for (const key in filters) {
        if (filters[key].length > 0) {
          appliedFilters[key] = filters[key];
        }
      }
      if (locationFilteredData?.data?.jobs.length > 0) {
        console.log("Fetching data based on location filter");
        internJobs = locationFilteredData;
      } else if (Object.keys(appliedFilters)?.length === 0) {
        console.log("Fetching all data");
        internJobs = await getSavedJobs({
          skip,
          limit,
        });
      } else {
        console.log("Fetching filtered data");
        internJobs = await getSavedJobs({
          ...appliedFilters,
          skip,
          limit,
        });
      }
      setallInternJobs(internJobs?.data?.saved_jobs);
      setNext(internJobs?.data?.next);
      setTotalJobs(50);
      setCount(internJobs?.data?.next == true);
    };
    fetchJob();
  }, [filters, currentPage, locationFilteredData]);

  const onViewDetails = (id) => {
    setActiveSection("jobDescription");
    setJobId(id);
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalJobs / 10) || next) {
      handlePageChange(currentPage + 1);
    }
  };

  function formatDate(dateString) {
    const postedDate = new Date(dateString);
    const currentDate = new Date();

    const differenceInTime = currentDate.getTime() - postedDate.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    const differenceInWeeks = Math.floor(differenceInDays / 7);
    const differenceInMonths = Math.floor(differenceInDays / 30);

    if (differenceInDays === 0) {
      return `Posted today`;
    } else if (differenceInDays < 7) {
      return `Posted ${differenceInDays} day${
        differenceInDays === 1 ? "" : "s"
      } ago`;
    } else if (differenceInDays < 30) {
      return `Posted ${differenceInWeeks} week${
        differenceInWeeks === 1 ? "" : "s"
      } ago`;
    } else {
      return `Posted ${differenceInMonths} month${
        differenceInMonths === 1 ? "" : "s"
      } ago`;
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  // let allJobs = quickFilterData ? quickFilterData : allinternJob?.jobs;

  function checkObjectValues(obj) {
    for (const key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return true;
      } else if (typeof obj[key] === "string" && obj[key].trim() !== "") {
        return true;
      }
    }
    return false;
  }

  let allJobs = [];
  const isFilterApplied = checkObjectValues(filters);
  if (quickFilterData && quickFilterData.length > 0 && !isFilterApplied) {
    console.log("Using quickFilterData");
    allJobs = quickFilterData;
  } else if (
    !quickFilterData &&
    inputFilter &&
    inputFilter.length > 0 &&
    !isFilterApplied
  ) {
    console.log("for Not logged in users");
    allJobs = inputFilter; // handle for not logged in filter
  } else {
    console.log("Using allInternJob.jobs");
    allJobs = allinternJob || [];
  }
  
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };

    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount

  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader />;
  }
  return (
    <div
      className={`${
        auth ? "p-8" : "py-[72px] h-auto px-8 md:px-16 xl:px-[124px]"
      } w-full  bg-white justify-start items-start gap-10 inline-flex`}
    >
      <div className=" w-full xl:w-[100%] flex-col justify-center items-center gap-8 inline-flex">
        <div className="w-full justify-between items-center inline-flex flex-col sm:flex-row">
          <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
          </div>
        </div>

        <div className="w-full flex-col justify-center items-center gap-8 inline-flex">

           {/* Conditional rendering for no data */}
 {(allJobs?.length === 0 && !Loading) &&(
      <div className="w-max flex flex-col items-center justify-center mt-8">
        <p className="text-red-500 text-lg">You don't have any saved jobs.</p>
        {/* <Link to="/" className="mt-4"> */}
          <FillButton
          className={`my-4`}
         onClick={() =>{navigate("/internships")}}
            text="Go back"
            color="green"
            textColor="white"
            leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M5.80781 9.55781L12.0578 3.30781C12.1452 3.2203 12.2566 3.1607 12.3779 3.13654C12.4992 3.11238 12.625 3.12476 12.7392 3.17211C12.8535 3.21945 12.9512 3.29964 13.0198 3.40251C13.0885 3.50538 13.1251 3.62631 13.125 3.75L13.125 16.25C13.1251 16.3737 13.0885 16.4946 13.0198 16.5975C12.9512 16.7004 12.8535 16.7805 12.7392 16.8279C12.625 16.8752 12.4992 16.8876 12.3779 16.8635C12.2566 16.8393 12.1452 16.7797 12.0578 16.6922L5.80781 10.4422C5.7497 10.3841 5.7036 10.3152 5.67215 10.2393C5.6407 10.1635 5.62451 10.0821 5.62451 10C5.62451 9.91786 5.6407 9.83653 5.67215 9.76066C5.7036 9.68478 5.7497 9.61585 5.80781 9.55781Z" 
            fill="currentColor"/>
          </svg>}
          />
        {/* </Link> */}
      </div>
    )}
          <div className="w-full flex flex-col grid grid-cols-1 gap-8 ">
            {allJobs?.length > 0 && allJobs?.map((index) => (
              // {allinternJob?.jobs?.slice(0, 8).map((index) => (
              <div
                key={index}
                className="w-auto cursor-pointer self-stretch px-10 py-6 bg-white rounded-xl border border-neutral-300 justify-between items-start gap-6 flex-col md:flex-row inline-flex "
              >
                 <div
                      onClick={() => {
                        goToJobDetails({index:index,category:category})
                      }}
                    >
                <div className="w-auto justify-start items-start gap-6 flex-col lg:flex-row inline-flex ">
                  <div className="w-16 h-16 relative">
                    <div className="w-16 h-16 rounded-full flex items-center">
                      <img src={index.company_info.logo} />
                    </div>
                  </div>
                  <div className="flex-col justify-start items-start gap-2 inline-flex">
                    <div className="justify-start items-start gap-[100px] inline-flex">
                      <div className="text-teal-950 text-xl font-bold font-satoshi leading-normal">
                        {index.job_info?.job_profile}
                      </div>
                    </div>
                    <div className="w-full h-auto justify-start items-center gap-2 inline-flex flex-wrap">
                      <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                        {index.company_info.name}
                      </div>
                      <div className="w-1 h-1 bg-slate-700 rounded-full " />
                      <div className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
                        {index.job_info?.workplace_type}
                      </div>
                    </div>
                    <div className="w-full justify-start items-start gap-2 inline-flex flex-wrap">
                      <div className="px-2.5 py-1.5 bg-emerald-300 bg-opacity-10 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                        <div className="w-max text-emerald-300 text-sm font-medium font-satoshi leading-snug">
                          {index.job_info?.job_type}
                        </div>
                      </div>
                      <div className=" self-stretch bg-neutral-300" />
                      <div className="px-2.5 py-1.5 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                        <div className="w-max text-emerald-500 text-sm font-medium font-satoshi leading-snug">
                          {index.job_info?.category &&
                            category.find(
                              (item) => item._id === index.job_info.category
                            )?.name}
                        </div>
                      </div>
                      <div className="px-2.5 py-1.5 rounded-[80px] border border-emerald-500 justify-center items-center gap-2 flex">
                        {index.job_info?.job_salary ||
                        index.job_info?.job_max_salary ? (
                          <>
                            <div className="w-5 h-5 relative">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M13.125 10C13.125 10.6181 12.9417 11.2223 12.5983 11.7362C12.255 12.2501 11.7669 12.6506 11.1959 12.8871C10.6249 13.1236 9.99653 13.1855 9.39034 13.065C8.78415 12.9444 8.22733 12.6467 7.79029 12.2097C7.35325 11.7727 7.05562 11.2158 6.93505 10.6097C6.81447 10.0035 6.87635 9.37513 7.11288 8.80411C7.3494 8.2331 7.74994 7.74504 8.26384 7.40166C8.77775 7.05828 9.38193 6.875 10 6.875C10.8288 6.875 11.6237 7.20424 12.2097 7.79029C12.7958 8.37634 13.125 9.1712 13.125 10ZM19.375 5V15C19.375 15.1658 19.3092 15.3247 19.1919 15.4419C19.0747 15.5592 18.9158 15.625 18.75 15.625H1.25C1.08424 15.625 0.925268 15.5592 0.808058 15.4419C0.690848 15.3247 0.625 15.1658 0.625 15V5C0.625 4.83424 0.690848 4.67527 0.808058 4.55806C0.925268 4.44085 1.08424 4.375 1.25 4.375H18.75C18.9158 4.375 19.0747 4.44085 19.1919 4.55806C19.3092 4.67527 19.375 4.83424 19.375 5ZM18.125 8.62109C17.4153 8.41125 16.7694 8.02719 16.2461 7.50389C15.7228 6.98059 15.3387 6.33468 15.1289 5.625H4.87109C4.66125 6.33468 4.27719 6.98059 3.75389 7.50389C3.23059 8.02719 2.58468 8.41125 1.875 8.62109V11.3789C2.58468 11.5887 3.23059 11.9728 3.75389 12.4961C4.27719 13.0194 4.66125 13.6653 4.87109 14.375H15.1289C15.3387 13.6653 15.7228 13.0194 16.2461 12.4961C16.7694 11.9728 17.4153 11.5887 18.125 11.3789V8.62109Z"
                                  fill="#16C083"
                                />
                              </svg>
                            </div>
                            <div className="w-max text-slate-500 text-sm font-medium font-satoshi leading-snug">
                              ₹
                              {index.job_info?.job_salary
                                ? index.job_info?.job_salary
                                : index.job_info?.job_max_salary}{" "}
                              / {index.job_info?.job_salary_frequency}
                            </div>
                          </>
                        ) : (
                          <div className="w-max text-slate-500 text-sm font-medium font-satoshi leading-snug">
                            Unpaid
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                
                <div className="w-full md:max-w-[164px] h-full flex-row justify-between items-center inline-flex gap-4">
                <div onClick={()=>{onbookmark(index.job_id);}} className="w-5 h-5 cursor-pointer mt-[2.7rem]">
                      {!isSaved[index.job_id] ?
                      
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M14.375 2.5H5.625C5.29348 2.5 4.97554 2.6317 4.74112 2.86612C4.5067 3.10054 4.375 3.41848 4.375 3.75V17.5C4.37506 17.6115 4.40496 17.721 4.46161 17.8171C4.51826 17.9132 4.59959 17.9924 4.69716 18.0464C4.79473 18.1005 4.90498 18.1274 5.01648 18.1245C5.12798 18.1215 5.23666 18.0888 5.33125 18.0297L10 15.1117L14.6695 18.0297C14.7641 18.0886 14.8727 18.1212 14.9841 18.124C15.0955 18.1268 15.2056 18.0998 15.303 18.0458C15.4005 17.9918 15.4817 17.9127 15.5383 17.8167C15.5949 17.7208 15.6249 17.6114 15.625 17.5V3.75C15.625 3.41848 15.4933 3.10054 15.2589 2.86612C15.0245 2.6317 14.7065 2.5 14.375 2.5Z" fill="#16C083"/>
                    </svg>
                  :  
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    d="M16.043 3.11743H5.29297C4.96145 3.11743 4.64351 3.24913 4.40909 3.48355C4.17466 3.71797 4.04297 4.03591 4.04297 4.36743V18.1174C4.04302 18.229 4.07293 18.3385 4.12958 18.4345C4.18622 18.5306 4.26755 18.6098 4.36512 18.6638C4.4627 18.7179 4.57295 18.7448 4.68445 18.7419C4.79595 18.739 4.90463 18.7062 4.99922 18.6471L10.668 15.7291L16.3375 18.6471C16.4321 18.706 16.5407 18.7386 16.6521 18.7414C16.7634 18.7443 16.8736 18.7173 16.971 18.6633C17.0684 18.6092 17.1497 18.5301 17.2063 18.4342C17.2629 18.3382 17.2928 18.2289 17.293 18.1174V4.36743C17.293 4.03591 17.1613 3.71797 16.9269 3.48355C16.6924 3.24913 16.3745 3.11743 16.043 3.11743ZM16.043 16.9901L10.9984 14.4627C10.8991 14.4007 10.7843 14.3677 10.6672 14.3677C10.55 14.3677 10.4353 14.4007 10.3359 14.4627L5.29297 16.9901V4.36743H16.043V16.9901Z"
                    fill="#667984"
                  />
                </svg>
                  }
                     
                    </div>
                    <div className=" flex-col justify-between items-center inline-flex gap-4">
                  <div className="flex-col justify-start items-start gap-2 flex">
                    <div className="w-max text-center text-teal-950 text-sm font-medium font-satoshi leading-snug">
                      {formatDate(index.job_info?.created_at)}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      onClick={() => {
                        goToJobDetails({index:index,category:category})
                      }}
                    >
                      <FillButton
                        className="w-full px-4 py-4"
                        color="green"
                        textColor="white"
                        textClass="w-max"
                        text="View Details"
                      />
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          {/* <div className="justify-center items-center gap-2 inline-flex">
        
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_7774_41811)">
                  <path
                    d="M15 6L9 12L15 18"
                    stroke={`${currentPage > 1 ? "#16C083" : "#002032"}`}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7774_41811">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>

            {[...Array(Math.ceil(totalJobs / 10 || 1))].map((_, index) => {
              if (index < 3 || index === Math.ceil(totalJobs / 10) - 1) {
                return (
                  <div
                    key={index}
                    className={`px-3 py-2.5 rounded-lg justify-center items-center gap-2 flex cursor-pointer ${
                      index + 1 === currentPage
                        ? "bg-emerald-500"
                        : "bg-green-50"
                    }`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    <div
                      className={` w-[22px] text-center text-base font-semibold font-satoshi leading-relaxed ${
                        index + 1 === currentPage ? "text-white" : "text-black"
                      }`}
                    >
                      {index + 1 === Math.ceil(totalJobs / 10)
                        ? currentPage >= 4
                          ? currentPage
                          : "4"
                        : index + 1}
                    </div>
                  </div>
                );
              }
              return null;
            })}


            <button
              onClick={() => handleNextPage()}
              disabled={!next}
              className={``}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <g clip-path="url(#clip0_7774_41829)">
                  <path
                    d="M9.375 6L15.625 12L9.375 18"
                    stroke={`${next ? "#16C083" : "#002032"}`}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7774_41829">
                    <rect width="25" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};
