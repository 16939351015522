import React from "react";

export const BackedBy = () => {
  return (
    <div className="w-full h-auto p-2 sm:p-4 md:px-16 lg:px-[124px] py-8  md:py-12 bg-white flex-col justify-start items-start gap-8 inline-flex">
      <div className="opacity-50 text-gray-800 text-lg font-normal font-satoshi leading-[28.80px]">
        We are backed by
      </div>
      <div className="w-full self-stretch justify-between flex-col gap-8 items-start inline-flex overflow-scroll no-scrollbar">
        <div className="w-full flex flex-row justify-between gap-8">
        <img className="w-[87px] h-[87px]" src="small_shark_logo.png" />
        <img
          className="w-[79.46px] h-[87px]"
          src="Screenshot 2024-02-15 at 2.42 1.png"
        />
        <img
          className="w-[63.21px] h-[87px]"
          src="Screenshot 2024-02-15 at 2.39 1.png"
        />
        <img
          className="w-[79.46px] h-[87px]"
          src="Vector (4).svg"
        />
         <img
          className="w-[79.46px] h-[87px]"
          src="Vector (5).svg"
        />
         <img
          className="w-[79.46px] h-[87px]"
          src="Vector (6).svg"
        />
</div>
<div className="w-full flex flex-row justify-between gap-8">
          <img
          className="w-[79.46px] h-[87px]"
          src="Vector (7).svg"
        />
          <img
          className="w-[79.46px] h-[87px]"
          src="Vector (8).svg"
        />
          <img
          className="w-[79.46px] h-[87px]"
          src="Vector (9).svg"
        />
          <img
          className="w-[79.46px] h-[87px]"
          src="Vector (10).svg"
        />
          <img
          className="w-[79.46px] h-[87px]"
          src="Vector (11).svg"
        />  <img
        className="w-[79.46px] h-[87px]"
        src="Vector (12).svg"
      />  <img
      className="w-[79.46px] h-[87px]"
      src="Vector (13).svg"
    />
    </div>
      </div>
    </div>
  );
};
