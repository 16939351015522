import React, { useState } from "react";

function ProfileLogo({ defaultLogo, currentLogo, onLogoChange, ...props }) {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      onLogoChange(e.target.files);
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div {...props}>
      {selectedImage ? (
        <label htmlFor="imageInput" className="select-image-button">
          <div>
            <img
              className=" w-16 h-16 relative rounded-[32px] cursor-pointer"
              src={selectedImage}
              alt="Profile Logo"
            />
          </div>
        </label>
      ) : (
        <label htmlFor="imageInput" className="select-image-button ">
          <div className=" w-16 h-16 rounded-[32px] border-2 border-gray-200 justify-center items-center inline-flex cursor-pointer overflow-hidden">
            {currentLogo ? (
              <img src={currentLogo} alt="Profile" />
            ) : (
              <div>{defaultLogo}</div>
            )}
          </div>
        </label>
      )}
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
        id="imageInput"
      />
    </div>
  );
}

export default ProfileLogo;
