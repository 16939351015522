import axios from "axios";

export const recommendedJobsAfterApply = async () => {
  try {
    const url = `${process.env.REACT_APP_baseURL}/user/recommended_jobs`;
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
export const getCategories = async () => {
  try {
    const url = `${process.env.REACT_APP_baseURL}/user/categories`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    });
    console.log(response.data, "Response Categories");
    return response?.data?.categories;
  } catch (error) {
    console.log(error);
  }
};
