import React from "react";

export const Avatar = ({imageSrc, username, ...Props}) => {
  return (
    <div className="relative w-[140px] h-[140px] " {...Props}>
      <div className="absolute w-[156px] h-[156px] top-[-8px] left-[-8px] bg-accentsorange rounded-[78px] border-8 border-solid border-white">
        <img
          className="absolute w-[140px] h-[140px] top-0 left-0 object-cover rounded-full"
          alt={username}
          src={imageSrc}
        />
      </div>
      <img className="absolute w-[140px] h-[140px] top-[-13397px] left-[-14035px]" alt="Ellipse" src="ellipse-71.svg" />
    </div>
  );
};
