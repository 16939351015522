import axios from "axios";
export const updateAppPreference = async (
  location,
  jobType,
  workplaceType,
  jobTitles
) => {
  const url = `${process.env.REACT_APP_baseURL}/user/preferance`;

  try {
    const response = await axios.post(
      url,
      {
        jobType,
        locations: location,
        workplace: workplaceType,
        designations: jobTitles,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateAppPreferenceSkills = async (skills) => {
  const url = `${process.env.REACT_APP_baseURL}/user/skills`;
  try {
    const response = await axios.post(
      url,
      { skills },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getUserDetails = async () => {
  const url = `${process.env.REACT_APP_baseURL}/user/verify`;
  const response = await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    localStorage.setItem("isPhoneVerified", response?.data?.isPhoneVerified);
    localStorage.setItem("isEmailVerified", response.data?.isEmailVerified);
    // console.log(response?.data)
    return response?.data;
  }
};

export const getUserNotificationPreference = async () => {
  const url = `${process.env.REACT_APP_baseURL}/user/notification/preference`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.log(error, "Error While Getting User Notifications");
  }
};

export const updatePreferenceForEmails = async (
  emailJobRecomendations,
  emailOffersPromotions,
  emailWarningsAppUpdate
) => {
  const token = localStorage.getItem("auth");
  console.log(token);
  const url = `${process.env.REACT_APP_baseURL}/user/notification/preference`;
  try {
    const response = await axios.patch(
      url,
      {
        emailJobRecomendations,
        emailOffersPromotions,
        emailWarningsAppUpdate,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response, "response from email update");
    return response.data;
  } catch (error) {
    console.log(
      error?.response?.data?.message,
      "Error While Updating the Notification Preference for Email"
    );
  }
};
export const updatePreferenceForPhone = async (
  phoneJobRecomendations,
  phoneOffersPromotions,
  phoneWarningsAppUpdate
) => {
  const url = `${process.env.REACT_APP_baseURL}/user/notification/preference`;
  try {
    const response = await axios.patch(
      url,
      {
        phoneJobRecomendations,
        phoneOffersPromotions,
        phoneWarningsAppUpdate,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    );
    console.log(response);
    return response?.data;
  } catch (error) {
    console.log(
      error,
      "Error While Updating the Notification Preference for Phone"
    );
  }
};
export const referAndEarnURL = async () => {
  const url = `${process.env.REACT_APP_baseURL}/user/refferalUrl`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    });
    console.log(response, "referralURL");
    return response?.data;
  } catch (error) {
    console.log(error, "Error while Fetching ReferralURL");
  }
};
export const deactivateAccount = async () => {
  const url = `${process.env.REACT_APP_baseURL}/user/`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    });
    console.log("Deleted_Response", response);
    return response?.data;
  } catch (error) {
    console.log(error, "Error while Deactivate Account");
  }
};
export const userNotifications = async () => {
  const url = `${process.env.REACT_APP_baseURL}/user/notifications`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    });
    return response?.data;
  } catch (error) {
    console.log(error, "Error while fetching Notifications");
  }
};
