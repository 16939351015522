import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "../navbar/navbar";
import { Footer } from "../footer/footer";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/loader";

export const Terms = () => {
    const auth = localStorage.getItem("auth");
    const navigate = useNavigate();
     const topRef = useRef(null);
    const [loading, setLoading] = useState(true);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
  
    const redirect = () => {
      if (auth) {
        navigate("/internships");
      } else {
        navigate("/internships");
      }
    };
    useEffect(() => {
        // Simulating data loading with a setTimeout
        const timeout = setTimeout(() => {
          setLoading(false);
          topRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 1000); // Adjust the timeout as needed
    
        // Cleanup function to clear timeout
        return () => clearTimeout(timeout);
      }, []);
  return (
    <div className="w-full h-full">
         {loading ? (
        // Loader component while loading
        <div className="flex justify-center items-center h-full">
          <Loader/>
        </div>
      ) : (
      <>
        <Navbar redirect={redirect} />
    <div className="w-full p-[24px] md:px-16 lg:px-[124px] h-auto flex-col justify-start items-start gap-4 inline-flex">
      <div className="text-teal-950 text-[40px] font-bold font-satoshi leading-[48px]">
        Terms of Use
      </div>
      <div className="self-stretch">
        <span className="text-slate-700 text-base font-bold font-satoshi leading-relaxed">
          <br />
          Welcome to Beep!
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          These Terms of Use ("Terms") govern your access to and use of the Beep
          website ("Beep" or the "Website") and its associated services
          (collectively, the "Services"). Beep is a platform that connects
          college students across India with internship and job opportunities.
          <br />
          By accessing or using the Services, you agree to be bound by these
          Terms. If you disagree with any part of the Terms, then you may not
          access or use the Services.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          1. Eligibility
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          You must be at least 18 years old and a college student currently
          enrolled in a recognized institution in India to use the Services. By
          using Beep, you represent and warrant that you meet all of the
          foregoing eligibility requirements.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          2. User Accounts
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          You may need to create an account ("Account") to access certain
          features of the Services. You are responsible for maintaining the
          confidentiality of your account information, including your login
          credentials, and for all activity that occurs under your account. You
          agree to notify Beep immediately of any unauthorized use of your
          account or any other security breach. Beep reserves the right to
          suspend or terminate your account for any reason, at any time, without
          notice.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          3. Use of the Services
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          You agree to use the Services only for lawful purposes and in
          accordance with these Terms. You agree not to use the Services:
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          In any way that violates any applicable law or regulation.
          <br />
          For any purpose that is unauthorized or prohibited by these Terms.
          <br />
          To harm, abuse, harass, stalk, threaten, or defame any other person.
          <br />
          To interfere with or disrupt the Services or any servers or networks
          connected to the Services.
          <br />
          To impersonate any person or entity, or to falsely state or otherwise
          misrepresent your affiliation with a person or entity.
          <br />
          To access, tamper with, or use any other user's account.
          <br />
          To upload or transmit any viruses, malware, or other harmful code.
          <br />
          To post false, misleading, or outdated information about yourself or
          internship/job opportunities.
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          4. Content
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          The Services may contain content ("Content") uploaded by users,
          employers, or Beep. You are solely responsible for any Content you
          upload or transmit through the Services. Beep does not endorse any
          Content and takes no responsibility for its accuracy or completeness.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          5. Disclaimers
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          Job Postings: Beep strives to ensure the accuracy of job postings, but
          we cannot guarantee their completeness or legitimacy. You should
          always conduct your own research before applying for any position.
          <br />
          Information Accuracy: Beep disclaims all warranties regarding the
          accuracy, reliability, and completeness of any information or content
          on the Website or provided through the Services.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          6. Intellectual Property
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          Beep and its licensors retain all right, title, and interest in and to
          the Website and Services, including all intellectual property rights.
          You agree not to remove, alter, or obscure any copyright, trademark,
          service mark, or other proprietary notices contained on the Website or
          Services.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          7. Limitation of Liability
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          To the maximum extent permitted by law, Beep will not be liable for
          any damages arising from or related to your use of the Services,
          including without limitation, direct, indirect, incidental,
          consequential, punitive, or special damages. Beep is also not liable
          for any damages arising from your interactions with employers you find
          through the Services.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          8. Termination
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          Beep may terminate your access to the Services at any time, for any
          reason, without notice. You may also terminate your access to the
          Services at any time.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          9. Governing Law and Dispute Resolution
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          These Terms will be governed by and construed in accordance with the
          laws of India. Any dispute arising out of or relating to these Terms
          will be subject to the exclusive jurisdiction of the courts located in
          [City, State, India].
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          10. Entire Agreement
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          These Terms constitute the entire agreement between you and Beep
          regarding your use of the Services.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          11. Updates to the Terms
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          Beep may update these Terms from time to time. We will notify you of
          any material changes by posting the new Terms on the Beep website or
          by sending you an email. You are advised to review these Terms
          periodically for any changes. Your continued use of the Services after
          the revised Terms are posted will constitute your acceptance of the
          revised Terms.
          <br />
          <br />
        </span>
        <span className="text-slate-700 text-base font-medium font-satoshi leading-relaxed">
          12. Contact Us
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          If you have any questions about these Terms, please contact us at
          [email protected]
          <br />
          <br />
          This Terms of Use is more specific to your website and includes
          disclaimers regarding the accuracy of job postings and information.
          You may also want to consider adding a section about:
          <br />
        </span>
        <span className="text-slate-700 text-base font-normal font-satoshi leading-relaxed">
          Privacy Policy: A link to a separate privacy policy outlining how you
          collect, use, and disclose user data.
        </span>
      </div>
    </div>
    
    <Footer redirect={redirect} scrollToTop={scrollToTop} />
    
</>
      )}
    </div>
  );
};
