import React from "react";

export const PlacedStudents = () => {
  return <div className="bg-white w-full h-auto py-[74px]">
      <div className="h-[35px] flex-col justify-start items-center gap-8 flex">
                <div className="self-stretch h-12 text-center text-teal-950 text-2xl font-bold font-satoshi leading-[28.80px]">our students are placed at</div>
                <div className="flex-col justify-start items-center gap-2 flex">
                  
                    <div className="w-[100vw] h-[35px] justify-center items-center gap-[19.64px] inline-flex overflow-hidden hover:animate-marquee hover:whitespace-nowrap">
                      
                            
                                <img className="w-full h-[35px]" src="/placedStudent/1.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/2.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/3.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/4.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/5.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/6.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/7.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/8.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/9.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/10.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/11.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/12.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/13.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/14.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/15.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/16.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/17.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/18.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/19.png" />
                                <img className="w-full h-[35px]" src="/placedStudent/20.png" />

                    </div>
                </div>
            </div>
  </div>;
};
